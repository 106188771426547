import React, { Component } from "react";
import { connect } from "react-redux";
import "./CardSaved.scss";
import { DropDownMenu, ConfirmAlert, LoadingIcon } from "../widgets";
import { deleteCard } from "../../store/actions";
import { confirmAlert } from "react-confirm-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCreditCard,
  faEllipsisH,
  faTrashAlt,
} from "@fortawesome/pro-regular-svg-icons";

class CardSaved extends Component {
  constructor(props) {
    super();
    const { card } = props;
    const { last4, brand, paymentMethod } = card;
    this.state = {
      last4,
      brand,
      paymentMethod,
      showMenu: false,
      loading: false,
    };

    this.handleToggleShowMenu = this.handleToggleShowMenu.bind(this);
  }

  handleToggleShowMenu(e) {
    e.preventDefault();
    const { showMenu } = this.state;
    this.setState({ showMenu: !showMenu });
  }

  render() {
    const { last4, brand, showMenu, loading } = this.state;
    const { onSaveAutoCharge, overflow, onDelete } = this.props;
    const deleteButton = (
      <button
        type="click"
        onClick={(e) => {
          e.preventDefault();
          this.setState({ showMenu: false }, () => {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <ConfirmAlert
                    onConfirm={onDelete}
                    onClose={onClose}
                    actionName="delete"
                  />
                );
              },
            });
          });
        }}
      >
        delete <FontAwesomeIcon icon={faTrashAlt} />
      </button>
    );
    return (
      <div className="card-saved">
        {loading ? <LoadingIcon /> : null}
        <div className="card-info">
          <FontAwesomeIcon icon={faCreditCard} />
          <FontAwesomeIcon icon={faEllipsisH} />
          <span>{`${last4} ${brand}`}</span>
        </div>
        <DropDownMenu
          buttonClassName="card-menu-toggle"
          dropDownMenuClassName="card-menu"
          onToggleShowDropDownMenu={this.handleToggleShowMenu}
          showDropDownMenu={showMenu}
          dropDownMenu={[deleteButton]}
        />
        {onSaveAutoCharge ? (
          <div className={`form-footer ${overflow ? "show-shadow" : ""}`}>
            <button
              type="click"
              className="setup-nav next sumbit-card"
              onClick={onSaveAutoCharge}
            >
              save
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  return {
    currentUser: reduxState.currentUser,
  };
}

export default connect(mapStateToProps, { deleteCard })(CardSaved);
