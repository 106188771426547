import React from "react";
import { Input, FormWrapper } from "./FormWidgets";

const FormCuisine = ({ cuisine, onSave, onCancel, onChangeName }) => {
  const cuisineName = (
    <Input
      labelText="cuisine name"
      value={cuisine.name}
      onChange={onChangeName}
      placeholderText="Type cuisine name."
      name="name"
    />
  );
  const formSection = cuisineName;
  return (
    <FormWrapper
      formName={cuisine.name || "new cuisine"}
      formSections={[formSection]}
      onSave={onSave}
      onCancel={onCancel}
    />
  );
};

export default FormCuisine;
