import React from "react";
import "./LogInForm.scss";
import { PhoneForm, VerificationCode } from "./";
import { LoadingIcon, PopUp, ClosePage } from "../widgets";

const LogInForm = ({
  loading,
  showVerifyContact,
  verifyContactStatus,
  verifyContactCodeTyped,
  phoneNumber,
  isDelegate,
  phoneOps,
  phoneEater,
  onShowVerifyContact,
  onSubmitVerifyContact,
  onChangeVerifyContact,
  onResendVerifyContact,
  onHideVerifyContact,
  onPhoneChange,
  onPhoneFinish,
  onClose,
  errors,
}) => (
  <div className="homepage login">
    {loading ? <LoadingIcon /> : null}
    <ClosePage onClick={onClose} />
    <PopUp
      isPoppedUp={showVerifyContact}
      componentToDisplay={
        <VerificationCode
          onSubmit={onSubmitVerifyContact}
          onChange={onChangeVerifyContact}
          onResend={onResendVerifyContact}
          verificationStatus={verifyContactStatus}
          verificationCodeTyped={verifyContactCodeTyped}
          errors={errors}
        />
      }
      hidePopUp={onHideVerifyContact}
    />

    {isDelegate ? <h2>Internal Only</h2> : <h2>Welcome Back!</h2>}
    <PhoneForm
      onBook={onShowVerifyContact}
      onPhoneChange={onPhoneChange}
      onPhoneFinish={onPhoneFinish}
      phoneNumber={phoneNumber}
      phoneOps={phoneOps}
      phoneEater={phoneEater}
      actionName={isDelegate ? "delegate" : "log in"}
      isDelegate={isDelegate}
      errors={errors}
    />
  </div>
);
export default LogInForm;
