export { default as FormEaters } from "./FormEaters";
export { default as FormSchedule } from "./FormSchedule";
export { default as FormDiets } from "./FormDiets";
export { default as FormFavorites } from "./FormFavorites";
export { default as Schedule } from "./Schedule";
export { default as Diet } from "./Diet";
export { default as PageProfile } from "./PageProfile";
export { default as PageSetUp } from "./PageSetUp";

export const STEP_HEADER_HEIGHT = 104;
export const STEP_FOOTER_HEIGHT = 90;

export const getSelectFields = ({
  fields,
  objectsToCopy,
  arraysToCopy,
  data,
}) => {
  if (!data || Object.keys(data).length === 0) {
    return data;
  }
  let dataWithSelectFields = {};
  fields.forEach((field) => {
    if (field in data) {
      if (objectsToCopy && objectsToCopy.find((o) => o === field)) {
        dataWithSelectFields[field] = { ...data[field] };
      } else if (arraysToCopy && arraysToCopy.find((o) => o === field)) {
        dataWithSelectFields[field] = [...data[field]];
      } else {
        dataWithSelectFields[field] = data[field];
      }
    }
  });
  return dataWithSelectFields;
};
