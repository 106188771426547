import React, { Component } from "react";
import "./DashboardEntity.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faPen,
  faEllipsisV,
  faArchive,
} from "@fortawesome/pro-regular-svg-icons";
import { ActionList, ConfirmAlert } from "../widgets";
import { confirmAlert } from "react-confirm-alert";
import {
  DataModeCategoriesKey,
  DataModeChildrenKey,
  DataModeParentKey,
} from "./";
import { LOGGING } from "../../hocs/constants";

class Entity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMoreActions: false,
    };
    this.toggleShowMoreActions = this.toggleShowMoreActions.bind(this);
  }

  toggleShowMoreActions(e) {
    e.preventDefault();
    const { showMoreActions } = this.state;
    this.setState({ showMoreActions: !showMoreActions });
  }

  render() {
    const {
      entity,
      name,
      type,
      parent,
      numberOfChildren,
      id,
      archived,
      phone,
      opsPhone,
      categories,
      onDelete,
      onUpdate,
      activeStatus,
    } = this.props;

    if (activeStatus === false) return null;
    const { showMoreActions } = this.state;
    LOGGING >= 3 &&
      console.log("entity rendering with: ", { type, categories });
    const leftPanel = (
      <div className="entity-content-left">
        <div className="entity">
          <div className="type">
            {categories
              ? Array.isArray(categories)
                ? categories.length > 0
                  ? categories.map((c) => c.name).join(", ")
                  : `No ${DataModeCategoriesKey[entity]}`
                : typeof categories === "string"
                ? categories
                : type
              : type}
          </div>
          <div className="name">{name}</div>
        </div>
        <div className="parent">
          <div className="type">{DataModeParentKey[entity]}</div>
          <div className="name">{parent}</div>
        </div>
      </div>
    );

    const moreActionsButton = (
      <button
        type="button"
        className="thumbnail-button more-actions"
        onClick={this.toggleShowMoreActions}
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </button>
    );

    const delegateButton =
      entity === "users" ? (
        <Link
          className={`thumbnail-button delegate ${phone ? "" : "disabled"}`}
          to={{ pathname: `/delegate`, eaterPhone: phone, opsPhone }}
        >
          <span>delegate</span>
        </Link>
      ) : null;

    const deleteButton = (
      <button
        type="button"
        className={`${
          entity === "users" ? "action-item" : "thumbnail-button"
        } delete-entity`}
        onClick={(e) => {
          confirmAlert({
            customUI: ({ onClose }) => (
              <ConfirmAlert
                onConfirm={onDelete.bind(this, { mode: entity, id })}
                onClose={onClose}
                actionName={"delete"}
              />
            ),
          });
          this.setState({ showMoreActions: false });
        }}
      >
        <span>delete</span>
        <FontAwesomeIcon icon={faTrash} />
      </button>
    );

    const archiveButton = (
      <button
        type="button"
        className="action-item archive"
        onClick={(e) => {
          confirmAlert({
            customUI: ({ onClose }) => (
              <ConfirmAlert
                onConfirm={onUpdate.bind(this, {
                  mode: entity,
                  id,
                  data: { archived: !archived },
                })}
                onClose={onClose}
                actionName={archived ? "resume" : "archive"}
              />
            ),
          });
          this.setState({ showMoreActions: false });
        }}
      >
        <span>{archived ? "resume" : "archive"}</span>
        <FontAwesomeIcon icon={faArchive} />
      </button>
    );

    const editButton = (
      <Link
        className={`${
          entity === "users" ? "action-item" : "thumbnail-button"
        } edit-entity`}
        to={`/${entity}/${id}`}
      >
        <span>edit</span>
        <FontAwesomeIcon icon={faPen} />
      </Link>
    );

    let actionList = [];
    actionList.push(editButton);
    actionList.push(archiveButton);
    actionList.push(deleteButton);

    const actionButtons = (
      <div className="thumbnail-buttons">
        {entity === "users" ? (
          <React.Fragment>
            {delegateButton}
            <ActionList
              buttonComponent={moreActionsButton}
              showList={showMoreActions}
              actionComponentList={actionList}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {editButton}
            {deleteButton}
          </React.Fragment>
        )}
      </div>
    );

    const rightPanel = (
      <div className="entity-content-right">
        <div className="children">
          <span className="name">{numberOfChildren}</span>
          <span className="type">{DataModeChildrenKey[entity]}</span>
        </div>
        {actionButtons}
      </div>
    );

    return (
      <div className={`data-entity ${archived ? "archived" : ""}`}>
        {leftPanel}
        {rightPanel}
      </div>
    );
  }
}

export default Entity;
