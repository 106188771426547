import React, { Component } from "react"; // eslint-disable-line no-unused-vars
import "./Schedule.scss";
import { EaterName } from "../widgets";
import {
  lunchStart,
  lunchEnd,
  dinnerStart,
  dinnerEnd,
  Week,
} from "../../hocs/constants";
class Schedule extends Component {
  constructor(props) {
    super();
    const eater = { props };
    this.state = { ...eater };
  }

  render() {
    const { eater, onToggleDay, isMainContact } = this.props;
    const { name, schedules } = eater;

    const lunches = Array(7)
      .fill(0)
      .map((a, i) => schedules.includes(i * 2));
    const dinners = Array(7)
      .fill(0)
      .map((a, i) => schedules.includes(i * 2 + 1));

    const lunchCalendar = lunches.map((meal, dayOfWeek) => (
      <button
        key={dayOfWeek}
        className={`meal ${meal ? "selected" : "unselected"}`}
        type="button"
        onClick={onToggleDay.bind(this, {
          eaterId: eater._id,
          dayOfWeek,
          isLunch: true,
        })}
      >
        <span>{Week[dayOfWeek][0]}</span>
      </button>
    ));
    const dinnerCalendar = dinners.map((meal, dayOfWeek) => (
      <button
        key={dayOfWeek}
        className={`meal ${meal ? "selected" : "unselected"}`}
        type="button"
        onClick={onToggleDay.bind(this, {
          eaterId: eater._id,
          dayOfWeek,
          isLunch: false,
        })}
      >
        {Week[dayOfWeek][0]}
      </button>
    ));
    return (
      <div className="step-content-card schedules">
        {name ? <EaterName name={name} isMainContact={isMainContact} /> : null}
        <div className="form-field">
          <label>Lunch days</label>
          <div className="notes">
            delivered {lunchStart} to {lunchEnd}
          </div>
          <div className="week lunch">{lunchCalendar}</div>
        </div>
        <div className="form-field">
          <label>Dinner days</label>
          <div className="notes">
            delivered {dinnerStart} to {dinnerEnd}
          </div>
          <div className="week dinner">{dinnerCalendar}</div>
        </div>
      </div>
    );
  }
}
export default Schedule;
