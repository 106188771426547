import React, { Component } from "react";
import { connect } from "react-redux";
import { CardForm, CardSaved } from ".";
import { LOGGING } from "../../hocs/constants";
import { updateCard, deleteCard } from "../../store/actions";
import { LoadingIcon, CheckBox, PageHeader } from "../widgets";
import moment from "moment-timezone";

export const validateCard = ({ name, number, expiration, cvv }) => {
  if (name.length === 0) {
    let error = new Error("Name can't be empty.");
    error.name = "name";
    throw error;
  }

  if (number.length !== 16) {
    let error = new Error("Card numbers should be 16 digits.");
    error.name = "number";
    throw error;
  }

  if (expiration.length !== 4) {
    let error = new Error("Expiration date should be MM/YY.");
    error.name = "expiration";
    throw error;
  }

  const exp_month = Number(expiration.slice(0, 2));
  const exp_year = Number(expiration.slice(2, 4));
  if (exp_month > 12) {
    let error = new Error("Expiration month is invalid.");
    error.name = "exp_month";
    throw error;
  }

  const expirationMoment = moment([exp_year + 2000, exp_month - 1]).endOf(
    "month"
  );
  const nowMoment = moment();
  if (expirationMoment.isBefore(nowMoment)) {
    let error = new Error("Expiration date has past.");
    error.name = "expiration";
    throw error;
  }

  if (cvv.length === 0) {
    let error = new Error("Security code can't be empty.");
    error.name = "cvv";
    throw error;
  }

  return { card: { number, exp_month, exp_year, cvc: cvv }, name };
};

class PagePayment extends Component {
  constructor(props) {
    super();

    const { stripeInfo } = props.currentUser.user;
    LOGGING >= 1 && console.log("PagePayment constructed with: ", stripeInfo);
    this.state = {
      // 1. controlling states

      // 1.1 loading:
      // true when waiting for backend async calls to return
      // shows a loading icon
      loading: false,
      showMenu: false,
      autoCharge:
        stripeInfo && stripeInfo.autoCharge ? stripeInfo.autoCharge : false,

      // 2. data states
      stripeInfo,

      // 3. rendering states

      number: "",
      name: "",
      expiration: "",
      cvv: "",
      error: props.error,
    };

    this.handleChangeNumber = this.handleChangeNumber.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleToggleAutoCharge = this.handleToggleAutoCharge.bind(this);
    this.handleSaveAutoCharge = this.handleSaveAutoCharge.bind(this);
    // handleClose()
    this.handleClose = this.handleClose.bind(this);
    this.handleDeleteCard = this.handleDeleteCard.bind(this);
    this.handleToggleShowMenu = this.handleToggleShowMenu.bind(this);
  }
  handleToggleShowMenu(e) {
    e.preventDefault();
    const { showMenu } = this.state;
    this.setState({ showMenu: !showMenu });
  }

  handleDeleteCard() {
    const { stripeInfo } = this.state;
    const { paymentMethod } = stripeInfo;
    this.setState({ loading: true });
    this.props.deleteCard(paymentMethod).then(() => {
      this.setState({
        showMenu: false,
        loading: false,
        stripeInfo: { autoCharge: false, customerId: stripeInfo.customerId },
      });
    });
  }
  // handleSave():
  // go to next step and save if there's updates
  // called by individual child containers/forms
  handleToggleAutoCharge(e) {
    e.preventDefault();
    const { autoCharge } = this.state;
    this.setState({ autoCharge: !autoCharge });
  }
  handleSaveAutoCharge() {
    const { autoCharge } = this.state;
    this.setState({ loading: true }, () => {
      this.props
        .updateCard({ autoCharge })
        .then(() => {
          LOGGING >= 1 && console.log("handleToggleAutoCharge succeeded.");
          this.setState({ loading: false });
          this.props.history.push("/");
        })
        .catch((e) => {
          LOGGING >= 1 && console.log("handleToggleAutoCharge got error: ", e);
          this.setState({ loading: false });
        });
    });
  }

  handleClose = (e) => {
    e.preventDefault();
    this.props.history.push("/");
  };

  handleChangeName = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value, error: null });
  };

  handleChangeNumber = (field, values) => {
    this.setState({ [field]: values.value, error: null });
  };

  handleSubmit = (e) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    e.preventDefault();
    const { number, name, expiration, cvv, autoCharge } = this.state;
    try {
      const { card } = validateCard({ number, name, expiration, cvv });
      this.props
        .updateCard({ card, autoCharge })
        .then(() => {
          this.props.history.push("/");
        })
        .catch((e) => {
          LOGGING >= 1 && console.log("update card got error: ", e);
          let error = new Error(e);
          if (e.includes("card number")) {
            error.name = "number";
          } else if (e.includes("security code")) {
            error.name = "cvv";
          }
          this.setState({ error, loading: false });
        });
    } catch (error) {
      LOGGING >= 1 && console.log("validateCard got error:", error);
      this.setState({ error });
    }
  };
  // hooks
  componentDidUpdate(prevProps) {
    if (this.props.error !== prevProps.error) {
      this.setState({ error: this.props.error });
    }
  }

  componentDidMount() {
    this.setState({ error: this.props.error });
  }

  render() {
    const {
      //control
      loading,
      showMenu,

      //data
      stripeInfo,
      number,
      cvv,
      name,
      expiration,
      error,
      autoCharge,
    } = this.state;

    LOGGING >= 1 &&
      console.log("PagePayment rendering with:", {
        state: this.state,
      });

    return (
      <div className="homepage payment">
        <PageHeader
          onClose={this.handleClose}
          title="payment method"
          showShadow={true}
        />
        {loading ? <LoadingIcon /> : null}
        <div className="form-payment">
          {stripeInfo && stripeInfo.card && stripeInfo.paymentMethodId ? (
            <CardSaved
              card={stripeInfo.card}
              onDelete={this.handleDeleteCard}
              showMenu={showMenu}
              onToggleShowMenu={this.handleToggleShowMenu}
              onSaveAutoCharge={this.handleSaveAutoCharge}
              overflow={true}
            />
          ) : (
            <CardForm
              onSubmit={this.handleSubmit}
              onChangeName={this.handleChangeName}
              onChangeNumber={this.handleChangeNumber}
              number={number}
              cvv={cvv}
              name={name}
              expiration={expiration}
              error={error}
              overflow={true}
            />
          )}
          <CheckBox
            labelText="Charge automatically at delivery."
            value={autoCharge}
            onChange={this.handleToggleAutoCharge}
            className="auto-charge"
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  LOGGING >= 1 &&
    console.log(
      "PagePaymentMethod got reduxState:",
      reduxState.currentUser.user.stripeInfo
    );
  return {
    currentUser: reduxState.currentUser,
  };
}

export default connect(mapStateToProps, {
  updateCard,
  deleteCard,
})(PagePayment);
