import React, { Component } from "react"; // eslint-disable-line no-unused-vars
import "./CalendarDay.scss";
import CalendarDeliveries from "./CalendarDeliveries";

class CalendarDay extends Component {
  render() {
    const {
      // data to render
      date,
      month,
      year,
      dateKey,
      deliveries,

      // config for rendering
      isThisMonth,
      isToday,
      isTomorrow,
      isPast,
      isSelectedDay,

      // method for rendered components
      // handleShowPopUpDay,
      onClick,
    } = this.props;

    if (isThisMonth) {
      return (
        <button
          className={
            "calendar-day" +
            (isToday ? " today" : "") +
            (isSelectedDay ? " selected-day" : "") +
            (isPast ? " past" : "")
          }
          type="button"
          onClick={() => {
            onClick.call(this, {
              month,
              year,
              date,
              dateKey,
              isToday,
              isTomorrow,
            });
          }}
        >
          <div className="calendar-date">{date}</div>
          {isSelectedDay ? null : (
            <CalendarDeliveries deliveries={deliveries} />
          )}
        </button>
      );
    } else {
      return (
        <div className="calendar-day">
          <div className="calendar-date"></div>
        </div>
      );
    }
  }
}

export default CalendarDay;
