import React from "react";
import moment from "moment-timezone";
import "./TimeLabel.scss";
import TimeField from "react-simple-timefield";
import { TimeFormat, CALIFORNIA_TIMEZONE } from "../../hocs/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";

export const EditableTimeText = ({ time, onEdit, onSave, actionName }) => {
  let hour, minute;

  if (time > 0) {
    const momentTime = moment.tz(time, CALIFORNIA_TIMEZONE);
    hour = momentTime.hours();
    minute = momentTime.minutes();
  } else {
    hour = 0;
    minute = 0;
  }

  return (
    <div className="time-text">
      <div>{actionName} by</div>
      <TimeField
        value={`${TimeFormat(hour)}:${TimeFormat(minute)}`}
        onChange={onEdit}
        colon=":"
        onBlur={onSave}
        showSeconds={false}
      />
      <b>{hour < 12 ? "AM" : "PM"}</b>
    </div>
  );
};

export const TimeText = ({ time, actionName }) => {
  const momentTime = moment.tz(time, CALIFORNIA_TIMEZONE);
  const hour = momentTime.hours(),
    minute = momentTime.minutes();

  return (
    <div className="time-text">
      <span>{`${actionName ? actionName + " by " : ""} `}</span>
      <b>{`${hour}:${minute < 10 ? "0" : ""}${minute}${
        hour < 12 ? "AM" : "PM"
      }`}</b>
    </div>
  );
};

export const UnitStatus = ({ unitStatus, onAction }) => {
  const { buttonStatus, text, timeStamp } = unitStatus;
  if (buttonStatus === undefined) return null;
  return buttonStatus === "action" ? (
    <button className="time-label" type="button" onClick={onAction}>
      <span>{text}</span>
    </button>
  ) : buttonStatus === "done" ? (
    <div className="time-label done">
      <div>{text}</div>
      <FontAwesomeIcon icon={faCheck} /> <div>{timeStamp}</div>
    </div>
  ) : (
    <div className="time-label">
      <div>{text}</div>
    </div>
  );
};
