import React from "react";
import "./FormDish.scss";
import { Sticker, CheckBox, Tags } from "../widgets";
import { FormWrapper, Input, Select, EntityField } from "./FormWidgets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faGlobe } from "@fortawesome/pro-regular-svg-icons";
import {
  faCheck,
  faTimes,
  faPuzzlePiece,
} from "@fortawesome/free-solid-svg-icons";

export const FreshnessSpans = {
  0: "ASAP",
  1: "Within 30 minutes",
  2: "Within an hour",
};

export const tagIcons = { cuisines: faGlobe, ingredients: faPuzzlePiece };

export const StatusCode = {
  0: "admitted",
  1: "alpha",
  2: "beta",
  3: "production",
};

export const StageValue = {
  DISABLED: 0,
  TESTING: 1,
  APPROVED: 2,
  DISAPPROVED: 3,
};

export const StageCode = {
  0: "disabled",
  1: "testing",
  2: "approved",
  3: "disapproved",
};

const RestaurantName = ({ value }) => (
  <EntityField
    fieldLabel="restaurant"
    fieldContent={
      <Sticker
        icon={<FontAwesomeIcon icon={faMapMarkerAlt} />}
        content={<div>{value}</div>}
      />
    }
    fieldClass="restaurant-name"
  />
);

const Diets = ({ isVegetarian, isVegan, isLowCarb, onToggleOption }) => (
  <EntityField
    fieldLabel="diets"
    fieldClass="diets"
    fieldContent={
      <React.Fragment>
        <CheckBox
          labelText="Is Vegetarian"
          value={isVegetarian}
          name="isVegetarian"
          onChange={onToggleOption}
        />
        <CheckBox
          labelText="Is Vegan"
          value={isVegan}
          name="isVegan"
          onChange={onToggleOption}
        />
        <CheckBox
          labelText="Is Low-carb"
          value={isLowCarb}
          name="isLowCarb"
          onChange={onToggleOption}
        />
      </React.Fragment>
    }
  />
);

const Stage = ({ index, status, onChange }) => {
  const name = StatusCode[index];
  const stageStatus =
    index === 0
      ? StageValue.APPROVED
      : index === 3
      ? status.beta === StageValue.APPROVED
        ? StageValue.APPROVED
        : StageValue.DISABLED
      : status[name];

  return (
    <div className={`status ${name} ${StageCode[stageStatus]}`}>
      <button
        disabled={name === "admitted" || stageStatus === StageValue.DISABLED}
        className="status-index"
        onClick={(e) => {
          e.preventDefault();
          onChange(name);
        }}
      >
        {StageCode[stageStatus] === "approved" ? (
          <FontAwesomeIcon icon={faCheck} />
        ) : StageCode[stageStatus] === "disapproved" ? (
          <FontAwesomeIcon icon={faTimes} />
        ) : (
          index + 1
        )}
      </button>
      <div className="status-text">
        <span>{name}</span>
      </div>
    </div>
  );
};

const Status = ({ dishStatus, onChange }) => (
  <EntityField
    fieldLabel="dish status"
    fieldClass="statuses"
    fieldContent={Object.keys(StatusCode).map((i) => (
      <Stage
        status={dishStatus}
        index={Number(i)}
        onChange={onChange}
        key={i}
      />
    ))}
  />
);

const TagsList = ({
  selected,
  showOptions,
  onToggleShowOptions,
  onToggleTag,
  options,
  name,
  icon,
}) => (
  <EntityField
    fieldLabel={name}
    fieldContent={
      <Tags
        selected={selected}
        showOptions={showOptions}
        onToggleShowOptions={onToggleShowOptions}
        onToggleTag={onToggleTag}
        options={options}
        name={name}
        icon={icon}
      />
    }
    fieldClass={`tags ${selected.length > 0 ? "" : "empty"}`}
  />
);

const FormDish = ({
  dish,
  showFreshnessOptions,
  showTagsList,
  onToggleShowTagsList,
  onCancel,
  onSave,
  onToggleTag,
  tagsList,
  onChangeInput,
  onToggleDietOption,
  onChangeFreshness,
  onToggleShowFreshnessOptions,
  onChangeStatus,
}) => {
  const dishName = (
    <Input
      labelText="dish name"
      value={dish.name}
      onChange={onChangeInput.bind(this, "name")}
      placeholderText="Type dish name."
    />
  );

  const dishPrice = (
    <Input
      labelText="price (USD) before tax"
      value={dish.price}
      onChange={onChangeInput.bind(this, "price")}
      placeholderText="Type price."
    />
  );

  const dishFreshness = (
    <Select
      labelText="Best Consumed"
      value={dish.freshness}
      valueToTag={FreshnessSpans}
      onChange={onChangeFreshness}
      showOptions={showFreshnessOptions}
      onToggleShowOptions={onToggleShowFreshnessOptions}
      options={Object.keys(FreshnessSpans).map((f) => Number(f))}
    />
  );

  const dishDiets = (
    <Diets
      isVegetarian={dish.isVegetarian}
      isVegan={dish.isVegan}
      isLowCarb={dish.isLowCarb}
      onToggleOption={onToggleDietOption}
    />
  );

  const dishStatus = (
    <Status dishStatus={dish.status} onChange={onChangeStatus} />
  );

  const dishRestaurant = <RestaurantName value={dish.restaurant.name} />;

  const dishCuisinesIngredients = [
    "cuisines",
    "ingredients",
  ].map((name, index) => (
    <TagsList
      key={index}
      showOptions={showTagsList[name]}
      onToggleShowOptions={onToggleShowTagsList.bind(this, name)}
      onToggleTag={onToggleTag.bind(this, name)}
      options={tagsList[name]}
      selected={dish[name]}
      icon={tagIcons[name]}
      name={name}
    />
  ));

  const formSectionLeft = (
    <React.Fragment>
      {dishName}
      {dishPrice}
      {dishFreshness}
      {dishDiets}
      {dishStatus}
    </React.Fragment>
  );

  const formSectionRight = (
    <React.Fragment>
      {dishRestaurant}
      {dishCuisinesIngredients}
    </React.Fragment>
  );

  return (
    <FormWrapper
      formName={dish.name || "new dish"}
      formSections={[formSectionLeft, formSectionRight]}
      onSave={onSave}
      onCancel={onCancel}
    />
  );
};

export default FormDish;
