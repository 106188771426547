import { apiCall } from "../api";
import { LOAD_DISHES, RESET_DISHES } from "../actionTypes";
import { addError } from "./errors";
import { LOGGING } from "../../hocs/constants";
import { loadRestaurants } from "./restaurants";

export const loadDishes = (dishes) => ({
  type: LOAD_DISHES,
  dishes,
});

export const cleanDishes = (dishes) => ({
  type: RESET_DISHES,
});

export const fetchDishes = () => {
  LOGGING >= 3 && console.log("fetchDishes");

  return (dispatch, getState) => {
    const { currentUser } = getState();

    LOGGING >= 3 && console.log("fetchDishes got currentUser:", currentUser);
    if (currentUser.user.role !== "ops") {
      dispatch(addError("only admin can see all dishes"));
      return "error";
    }

    return apiCall("GET", `/users/${currentUser.user._id}/dishes`)
      .then((dishes) => {
        LOGGING >= 3 && console.log("fetchDishes got dishes", dishes);
        dispatch(loadDishes(dishes));
        // return "OK";
      })
      .catch((err) => {
        LOGGING >= 1 && console.log("fetchDishes got err", err);
        dispatch(addError(err.message));
      });
  };
};

export const fetchDish = ({ id }) => {
  LOGGING >= 1 && console.log("fetchDish got id:", id);

  return (dispatch, getState) => {
    const { currentUser } = getState();
    return apiCall("GET", `/users/${currentUser.user._id}/dishes/${id}`)
      .then((res) => {
        LOGGING >= 1 && console.log("fetchDish got", res);
        return res;
      })
      .catch((err) => {
        dispatch(addError(err.message));
      });
  };
};

export const createDish = (dish) => {
  return (dispatch, getState) => {
    const { currentUser, dishes, restaurants } = getState();
    if (currentUser.user.role !== "ops") {
      dispatch(addError("only admin can create a dish"));
      return "error";
    }
    const { restaurant, cuisines, ingredients } = dish;
    return apiCall("post", `/users/${currentUser.user._id}/dishes`, {
      data: {
        ...dish,
        restaurant: restaurant._id,
        cuisines: cuisines.map((c) => c._id),
        ingredients: ingredients.map((i) => i._id),
        price: dish.price || 0,
      },
    })
      .then((res) => {
        const { dish, restaurant } = res;
        dispatch(loadDishes([...dishes, dish]));
        dispatch(
          loadRestaurants(
            restaurants.map((r) =>
              String(r._id) === String(restaurant._id) ? restaurant : r
            )
          )
        );
        return res;
      })
      .catch((err) => {
        dispatch(addError(err.message));
      });
  };
};

export const updateDish = (dish) => {
  return (dispatch, getState) => {
    const { currentUser, dishes, restaurants } = getState();

    if (currentUser.user.role !== "ops") {
      dispatch(addError("only admin can create a dish"));
      return "error";
    }
    let data = Object.fromEntries(
      Object.entries(dish).filter(([key, val]) => key !== "_id")
    );
    const { restaurant, cuisines, ingredients } = data;
    data = {
      ...data,
      restaurant: restaurant._id,
      cuisines: cuisines.map((c) => c._id),
      ingredients: ingredients.map((i) => i._id),
    };
    return apiCall("put", `/users/${currentUser.user._id}/dishes/${dish._id}`, {
      data,
    })
      .then((res) => {
        const { updatedRestaurant, updatedDish } = res;
        const updatedDishes = dishes.map((d) =>
          d._id === dish._id ? updatedDish : d
        );
        const updatedRestaurants = restaurants.map((r) =>
          r._id === dish.restaurant._id ? updatedRestaurant : r
        );
        LOGGING >= 1 &&
          console.log("update dish returned from backend: ", {
            updatedDish,
            updatedRestaurant,
            dish,
            updatedDishes,
            updatedRestaurants,
          });
        dispatch(loadRestaurants(updatedRestaurants));
        dispatch(loadDishes(updatedDishes));
      })
      .catch((err) => {
        dispatch(addError(err.message));
      });
  };
};

export const deleteDish = (dishId) => {
  LOGGING >= 1 && console.log("deleteDish called with:", { dishId });
  return (dispatch, getState) => {
    const { currentUser, dishes, restaurants } = getState();

    LOGGING >= 3 && console.log("currentUser got user:", currentUser);
    if (currentUser.user.role !== "ops") {
      dispatch(addError("only admin can create a dish"));
      return "error";
    }

    return apiCall("DELETE", `/users/${currentUser.user._id}/dishes/${dishId}`)
      .then((res) => {
        LOGGING >= 1 && console.log("deleteDish got res", res);
        const { updatedRestaurant } = res;
        const updatedDishes = dishes.filter((dish) => dishId !== dish._id);

        const updatedRestaurants = restaurants.map((restaurant) =>
          restaurant._id !== updatedRestaurant._id
            ? restaurant
            : updatedRestaurant
        );
        LOGGING >= 1 &&
          console.log("deleteDish got:", { updatedDishes, updatedRestaurants });
        dispatch(loadRestaurants(updatedRestaurants));
        dispatch(loadDishes(updatedDishes));
        return updatedRestaurant;
      })
      .catch((err) => {
        dispatch(addError(err.message));
      });
  };
};
