import React from "react";
import "./SelectedDayDetails.scss";
import { ShowDashboard, ShowInvoice } from "./";

const SelectedDayDetails = ({
  selectedDay,
  content,
  onGoToSelectedMonth,
  onShowDashboard,
  role,
}) => {
  const quickButton =
    role === "eater" ? (
      <ShowInvoice />
    ) : role === "ops" ? (
      <ShowDashboard onShowDashboard={onShowDashboard} />
    ) : null;
  return (
    <div className="day-details">
      <div className="day-details-header">
        <button
          className="date-header-mobile "
          type="button"
          onClick={onGoToSelectedMonth}
        >
          {selectedDay.isTomorrow
            ? "Tomorrow"
            : selectedDay.isToday
            ? "Today"
            : selectedDay.dateKey}
        </button>
        <button
          className="date-header-desktop"
          type="button"
          onClick={onGoToSelectedMonth}
        >
          {selectedDay.isTomorrow
            ? "Tomorrow"
            : selectedDay.isToday
            ? "Today"
            : selectedDay.dateKey}
        </button>
        {quickButton}
      </div>
      <div className="day-content">{content}</div>
    </div>
  );
};

export default SelectedDayDetails;
