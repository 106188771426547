/*global localStorage*/
import React from "react"; // eslint-disable-line no-unused-vars
import { Provider } from "react-redux";
import { configureStore } from "../store";
import { BrowserRouter as Router } from "react-router-dom";
import StripeScriptLoader from "react-stripe-script-loader";
import Root from "./Root";
import { setAuthorizationToken, setCurrentUser } from "../store/actions/auth";
import jwtDecode from "jwt-decode";
import HttpsRedirect from "react-https-redirect";
import { PersistGate } from "redux-persist/integration/react";
import { LOGGING } from "../hocs/constants";
const PERSIST_REDUX = process.env.REACT_APP_PERSIST_REDUX === "true";

const { store, persistor } = configureStore();

if (localStorage.jwtToken) {
  LOGGING && console.log("localStorage.jwtToken found");
  setAuthorizationToken(localStorage.jwtToken);
  // prevent someone from manually tampering with the key of jwtToken in localStorage
  try {
    store.dispatch(setCurrentUser(jwtDecode(localStorage.jwtToken)));
    LOGGING && console.log("localStorage.jwtToken decoded");
  } catch (e) {
    LOGGING && console.log("localStorage.jwtToken decode e:", e);
    store.dispatch(setCurrentUser({}));
  }
} else {
  LOGGING && console.log("localStorage.jwtToken NOT found");
}

const App = () =>
  PERSIST_REDUX ? (
    <StripeScriptLoader
      uniqueId="myUniqueId"
      script="https://js.stripe.com/v3/"
      loader=""
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <HttpsRedirect>
            <Router>
              <Root logoText="abbey" persistor={persistor} />
            </Router>
          </HttpsRedirect>
        </PersistGate>
      </Provider>
    </StripeScriptLoader>
  ) : (
    <StripeScriptLoader
      uniqueId="myUniqueId"
      script="https://js.stripe.com/v3/"
      loader="my Loading..."
    >
      <Provider store={store}>
        <HttpsRedirect>
          <Router>
            <Root logoText="abbey" />
          </Router>
        </HttpsRedirect>
      </Provider>
    </StripeScriptLoader>
  );

export default App;
