import { apiCall } from "../api";
import { LOAD_DELIVERIES, RESET_DELIVERIES } from "../actionTypes";
import { addError } from "./errors";
import { IS_PRODUCTION, LOGGING } from "../../hocs/constants";
import { setCurrentUser, convertDeliveryServerToClient } from "./";

export const resetDeliveries = () => ({
  type: RESET_DELIVERIES,
});
export const loadDeliveries = ({ deliveries }) => ({
  type: LOAD_DELIVERIES,
  deliveries,
});

export const updateDelivery = ({ _id, delivery }) => {
  LOGGING >= 1 && console.log("updateDelivery called: ", { _id, delivery });
  return (dispatch, getState) => {
    const { currentUser } = getState();
    return apiCall(
      "post",
      `/users/${currentUser.user._id}/deliveries/${_id}${
        currentUser.user.role === "driver" ? "/driver" : ""
      }`,
      {
        delivery,
        debugMode: !IS_PRODUCTION,
      }
    )
      .then((res) => {
        LOGGING >= 1 && console.log("updateDelivery got res:", res);
        const convertedDelivery = convertDeliveryServerToClient(res);
        LOGGING >= 1 &&
          console.log(
            "updateDelivery got convertedDelivery:",
            convertedDelivery
          );
        const { deliveries } = getState();
        LOGGING >= 1 &&
          console.log("updateDelivery got deliveries from state:", deliveries);
        const updatedDeliveries = deliveries.map((d) =>
          d._id === _id ? { ...convertedDelivery } : d
        );
        LOGGING >= 1 &&
          console.log("updateDelivery got:", {
            convertedDelivery,
            deliveries,
            updatedDeliveries,
          });

        dispatch(loadDeliveries({ deliveries: updatedDeliveries }));
        return convertedDelivery;
      })
      .catch((err) => {
        addError(err.delivery);
      });
  };
};

export const createDelivery = (delivery) => {
  LOGGING >= 1 && console.log("createDelivery called with:", { delivery });

  return (dispatch, getState) => {
    const { currentUser } = getState();

    return apiCall(
      "post",
      `/users/${currentUser.user._id}/deliveries`,
      delivery
    )
      .then((createdDelivery) => {
        LOGGING >= 1 &&
          console.log("posted with createdDelivery:", createdDelivery);
        const convertedDelivery = convertDeliveryServerToClient(
          createdDelivery
        );
        const { deliveries } = getState();
        const updatedDeliveries = [...deliveries, convertedDelivery];
        LOGGING >= 1 &&
          console.log(
            "createDelivery got updatedDeliveries:",
            updatedDeliveries
          );
        dispatch(loadDeliveries({ deliveries: updatedDeliveries }));
        return convertedDelivery;
      })
      .catch((err) => {
        LOGGING >= 1 && console.log("createDelivery got err:", err);
        dispatch(addError(err.delivery));
      });
  };
};

export const fetchDeliveries = ({ beginUTC, endUTC }) => {
  return (dispatch, getState) => {
    const { currentUser } = getState();

    LOGGING >= 3 &&
      console.log("fetchDeliveries got from state:", {
        currentUser,
        beginUTC,
        endUTC,
      });

    return apiCall(
      "GET",
      `/users/${currentUser.user._id}/deliveries/${beginUTC}/${endUTC}`
    )
      .then((result) => {
        LOGGING >= 3 &&
          console.log("fetchDeliveries got result from server ", result);
        const convertedDeliveries = result.map((delivery) =>
          convertDeliveryServerToClient(delivery)
        );
        LOGGING >= 3 &&
          console.log("got client style deliveries: ", convertedDeliveries);
        dispatch(loadDeliveries({ deliveries: convertedDeliveries }));
      })
      .catch((err) => {
        LOGGING >= 3 &&
          console.log("fetchDeliveries returned from server with error: ", err);
        dispatch(addError(err.message));
      });
  };
};

export const fetchDeliveriesDelivered = () => {
  return (dispatch, getState) => {
    const { currentUser } = getState();

    LOGGING >= 3 &&
      console.log("fetchDeliveriesDelivered got from state:", {
        currentUser,
      });

    return apiCall("GET", `/users/${currentUser.user._id}/deliveries/delivered`)
      .then((deliveriesDelivered) => {
        LOGGING >= 3 &&
          console.log("fetchDeliveriesDelivered got result from server ", {
            deliveriesDelivered,
          });

        const deliveries = deliveriesDelivered.map((delivery) =>
          convertDeliveryServerToClient(delivery)
        );

        dispatch(loadDeliveries({ deliveries }));
      })
      .catch((err) => {
        LOGGING >= 1 &&
          console.log(
            "fetchDeliveriesDelivered returned from server with error: ",
            err
          );
        dispatch(addError(err.message));
      });
  };
};

export const createPaymentIntent = ({ amount }) => {
  LOGGING >= 1 && console.log("createPaymentIntent:", { amount });

  return (dispatch, getState) => {
    const { currentUser } = getState();
    return apiCall("put", `/users/${currentUser.user._id}/checkout`, { amount })
      .then((paymentIntent) => {
        LOGGING >= 1 &&
          console.log("createPaymentIntent got res", paymentIntent);
        return paymentIntent;
      })
      .catch((err) => {
        dispatch(addError(err.message));
      });
  };
};

export const confirmPaymentIntent = ({
  paymentIntentId,
  deliveryIds,
  autoCharge,
}) => {
  LOGGING >= 1 &&
    console.log("confirmPaymentIntent:", {
      paymentIntentId,
      deliveryIds,
      autoCharge,
    });

  return (dispatch, getState) => {
    const { currentUser, deliveries } = getState();
    return apiCall("POST", `/users/${currentUser.user._id}/checkout`, {
      paymentIntentId,
      deliveryIds,
      autoCharge,
    })
      .then(({ paidDeliveries, updatedUser }) => {
        LOGGING >= 1 &&
          console.log(
            "confirmPaymentIntent got paidDeliveries:",
            paidDeliveries
          );
        const convertedPaidDeliveries = paidDeliveries.map((delivery) =>
          convertDeliveryServerToClient(delivery)
        );
        const updatedDeliveries = deliveries.map((delivery) => {
          const paidDelivery = convertedPaidDeliveries.find(
            (d) => d._id === delivery._id
          );
          return paidDelivery || delivery;
        });
        dispatch(loadDeliveries({ deliveries: updatedDeliveries }));
        dispatch(setCurrentUser(updatedUser));
        return convertedPaidDeliveries;
      })
      .catch((err) => {
        LOGGING >= 1 && console.log("confirmPaymentIntent got err:", err);
        dispatch(addError(err.message));
      });
  };
};

export const confirmCardPayment = ({
  card,
  name,
  paymentIntentId,
  deliveryIds,
  saveCard,
  autoCharge,
}) => {
  LOGGING >= 1 &&
    console.log("confirmCardPayment called with:", {
      card,
      name,
      paymentIntentId,
      deliveryIds,
      saveCard,
      autoCharge,
    });

  return (dispatch, getState) => {
    const { currentUser, deliveries } = getState();
    return apiCall(
      saveCard ? "PUT" : "POST",
      `/users/${currentUser.user._id}/checkout/card`,
      saveCard
        ? {
            card,
            paymentIntentId,
            deliveryIds,
            autoCharge,
          }
        : {
            card,
            paymentIntentId,
            deliveryIds,
          }
    )
      .then(({ paidDeliveries, updatedUser }) => {
        LOGGING >= 1 &&
          console.log("confirmCardPayment returned with:", {
            paidDeliveries,
            updatedUser,
          });

        const convertedPaidDeliveries = paidDeliveries.map((delivery) =>
          convertDeliveryServerToClient(delivery)
        );
        const updatedDeliveries = deliveries.map((delivery) => {
          const paidDelivery = convertedPaidDeliveries.find(
            (d) => d._id === delivery._id
          );
          return paidDelivery || delivery;
        });
        if (saveCard) dispatch(setCurrentUser(updatedUser));
        dispatch(loadDeliveries({ deliveries: updatedDeliveries }));
        return "ok";
      })
      .catch((err) => {
        LOGGING >= 1 && console.log("confirmCardPayment got err:", err);
        const message = err.message.split(".");
        let error = new Error(message[0]);
        if (message[0].includes("card number")) {
          error.name = "number";
        } else if (message[0].includes("security code")) {
          error.name = "cvv";
        }
        throw error;
      });
  };
};
