export const ADD_ERROR = "ADD_ERROR";
export const REMOVE_ERROR = "REMOVE_ERROR";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const RELOAD_CURRENT_USER = "RELOAD_CURRENT_USER";
export const REMOVE_DELIVERY = "REMOVE_DELIVERY";
export const LOAD_INSTANCES = "LOAD_INSTANCES";
export const RESET_INSTANCES = "RESET_INSTANCES";
export const LOAD_DELEGATED_USER = "LOAD_DELEGATED_USER";
export const SET_DATAMODE = "SET_DATAMODE";
export const LOAD_INSTANCES_ON_DATE = "LOAD_INSTANCES_ON_DATE";
export const SET_CALENDAR_MONTH = "SET_CALENDAR_MONTH";
export const SET_SELECTED_DAY = "SET_SELECTED_DAY";
export const SET_SELECTED_DAY_MEAL = "SET_SELECTED_DAY_MEAL";
export const RESET_CALENDAR = "RESET_CALENDAR";
export const LOAD_DELIVERIES = "LOAD_DELIVERIES";
export const LOAD_USERS = "LOAD_USERS";
export const REMOVE_USERS = "REMOVE_USERS";
export const LOAD_COMPANIES = "LOAD_COMPANIES";
export const RESET_COMPANIES = "RESET_COMPANIES";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const LOAD_RESTAURANTS = "LOAD_RESTAURANTS";
export const LOAD_DISHES = "LOAD_DISHES";
export const LOAD_INGREDIENTS = "LOAD_INGREDIENTS";
export const LOAD_CUISINES = "LOAD_CUISINES";
export const RESET_RESTAURANTS = "RESET_RESTAURANTS";
export const RESET_INGREDIENTS = "RESET_INGREDIENTS";
export const RESET_CUISINES = "RESET_CUISINES";
export const RESET_DISHES = "RESET_DISHES";
export const REMOVE_RESTAURANTS = "REMOVE_RESTAURANTS";
export const UPDATE_DELIVERY_AFTER_CANCEL = "UPDATE_DELIVERY_AFTER_CANCEL";
export const LOAD_DELEGATED_USER_FOR_ADMIN = "LOAD_DELEGATED_USER_FOR_ADMIN";
export const SET_DATAMODE_SELECTED = "SET_DATAMODE_SELECTED";
export const SET_DATAMODE_SORT_BY = "SET_DATAMODE_SORT_BY";
export const SET_DATAMODE_CATEGORY_BY = "SET_DATAMODE_CATEGORY_BY";
export const RESET_DATAMODE = "RESET_DATAMODE";
export const SET_DATAMODE_LOADED = "SET_DATAMODE_LOADED";
export const LOAD_ORDERS = "LOAD_ORDERS";
export const RESET_ORDERS = "RESET_ORDERS";
export const RESET_DELIVERIES = "RESET_DELIVERIES";
