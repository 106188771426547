import React from "react";
import "./DishContent.scss";
import { FormattedRow } from "../../widgets";

const DishContent = ({ dish, name }) => {
  if (!dish) {
    return (
      <div className="dish-content">
        <div className="dish-name">Dish (TBD By Ops)</div>
      </div>
    );
  }
  const restaurantName = <div className="restaurant-name">{name}</div>;
  const dishPrice = (
    <div className={`dish-price ${dish && dish.price ? "" : " empty"}`}>
      {dish && dish.price ? ` $${dish.price.toFixed(2)}` : null}
    </div>
  );
  return (
    <div className="dish-content">
      <div className="dish-name">{dish ? dish.name : ""}</div>
      <div className="dish-description">
        <FormattedRow list={[restaurantName, dishPrice]} addBullet={true} />
      </div>
    </div>
  );
};

export default DishContent;
