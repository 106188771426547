import React, { Component } from "react"; // eslint-disable-line no-unused-vars
import { connect } from "react-redux";
import moment from "moment-timezone";
import { confirmAlert } from "react-confirm-alert";
import {
  Navbar,
  Footer,
  updateMonth,
  isDeliveryEditable,
  addDeliveryBySchedule,
  NO_USER,
  NO_DELIVERY,
  NO_INSTANCE,
} from "./";
import {
  ConfirmAlert,
  LoadingIcon,
  OnBoardingStep,
  OnBoardingStepText,
} from "../widgets";
import PageInvoices from "../payment/PageInvoices";
import { EaterDay, CalendarWrapperShared } from "./UserSignedIn";

import { DateKeyFormat, LOGGING, InstanceStatus } from "../../hocs/constants";
import {
  createDelivery,
  updateDelivery,
  fetchDeliveries,
  goToNextMonth,
  goToPrevMonth,
  goToCurrent,
  goToDate,
  reloadUser,
  goToSelectedDayMonth,
} from "../../store/actions";

import { Redirect } from "react-router-dom";

class PageUserEater extends Component {
  constructor() {
    super();
    LOGGING >= 3 && console.log("PageUserEater constructor called");
    this.state = {
      selectedDay: null,
      loading: true,
      deliveries: null,
      monthGrid: null,
      showTeamActions: NO_DELIVERY,
      showTooLateWarning: NO_INSTANCE, //{ user: NO_USER, isLunch: NO_DELIVERY };
      showDeliveredDetails: NO_DELIVERY,
      showRatingOptions: NO_DELIVERY,
      isEdittingComment: NO_DELIVERY,

      // to be cleaned up
      showInvoices: false,
    };

    // for navigating on month calendar
    this.handleSelectDay = this.handleSelectDay.bind(this);
    this.handleGoToSelectedDayMonth = this.handleGoToSelectedDayMonth.bind(
      this
    );

    // for preparing data needed for rendering, on load or change
    this.handleLoadDataForCalendarMonth = this.handleLoadDataForCalendarMonth.bind(
      this
    );
    this.handleGetDeliveriesWithSchedule = this.handleGetDeliveriesWithSchedule.bind(
      this
    );
    this.handleGetDataForSelectedDay = this.handleGetDataForSelectedDay.bind(
      this
    );

    // for pure rendering maneuver on the selected day details
    // for undelivered
    this.handleToggleShowTeamActions = this.handleToggleShowTeamActions.bind(
      this
    );
    this.handleShowTooLateWarning = this.handleShowTooLateWarning.bind(this);
    this.handleHideTooLateWarning = this.handleHideTooLateWarning.bind(this);
    // for delivered
    this.handleToggleShowDeliveredDetails = this.handleToggleShowDeliveredDetails.bind(
      this
    );
    this.handleToggleShowRatingOptions = this.handleToggleShowRatingOptions.bind(
      this
    );

    // for changing data on the server
    // for undelivered
    this.handleSetTeamStatus = this.handleSetTeamStatus.bind(this);
    this.handleToggleInstanceStatus = this.handleToggleInstanceStatus.bind(
      this
    );

    // for delivered
    this.handleSelectRating = this.handleSelectRating.bind(this);
    this.handleChangeDelivery = this.handleChangeDelivery.bind(this);
    this.handleEditComment = this.handleEditComment.bind(this);
    this.handleSaveComment = this.handleSaveComment.bind(this);

    // to be cleaned up
    this.handleShowInvoice = this.handleShowInvoice.bind(this);
    this.handleCloseInvoice = this.handleCloseInvoice.bind(this);
  }

  // for navigating on month calendar
  handleGoToSelectedDayMonth(e) {
    e.preventDefault();
    LOGGING >= 1 && console.log("handleGoToSelectedDayMonth called");
    this.props.goToSelectedDayMonth();
    window.scrollTo(0, 0);
  }

  handleSelectDay(selectedDay) {
    LOGGING >= 1 && console.log("handleSelectDay called with:", selectedDay);
    const { year, month, date } = selectedDay;
    this.props.goToDate({ year, month, day: date });
    this.handleGetDataForSelectedDay();
    this.setState({
      showTeamActions: NO_DELIVERY,
      showTooLateWarning: NO_INSTANCE, //{ _id: NO_USER, isLunch: NO_DELIVERY };
      showDeliveredDetails: NO_DELIVERY,
      showRatingOptions: NO_DELIVERY,
      isEdittingComment: NO_DELIVERY,
    });
  }

  // for preparing data needed for rendering, on load or change
  handleGetDeliveriesWithSchedule() {
    const { monthGrid } = this.state;
    const { deliveriesSavedInMonth, currentUser } = this.props;
    const { company } = currentUser.user;

    const deliveries = deliveriesSavedInMonth.concat(
      addDeliveryBySchedule({
        company,
        monthGrid,
        deliveriesFromDB: deliveriesSavedInMonth,
      })
    );
    LOGGING >= 3 &&
      console.log(
        "handleGetDeliveriesWithSchedule got deliveries:",
        deliveries
      );
    return deliveries;
  }

  handleLoadDataForCalendarMonth() {
    const { calendarYear, calendarMonth } = this.props;
    const { beginUTC, endUTC, monthGrid } = updateMonth({
      calendarYear,
      calendarMonth,
    });

    this.setState({ loading: true, monthGrid, beginUTC, endUTC }, () => {
      this.props
        .fetchDeliveries({ beginUTC, endUTC })
        .then((result) => {
          LOGGING >= 3 &&
            console.log("fetchDeliveries got result from store:", result);
          this.setState(
            {
              deliveries: this.handleGetDeliveriesWithSchedule(),
              loading: false,
            },
            () => {
              const { selectedDay } = this.state;
              if (
                selectedDay.lunchDelivery === null ||
                selectedDay.dinnerDelivery === null
              ) {
                // this would be true when componentDidMount called
                // handleGetDataForSelectedDay while deliveries are missing
                this.handleGetDataForSelectedDay();
              }
            }
          );
        })
        .catch((err) => {
          LOGGING >= 1 &&
            console.log("fetchDeliveries got error from store:", err);
        });
    });
  }

  handleGetDataForSelectedDay = (callbackState) => {
    const { selectedDay, calendarMonth, calendarYear } = this.props;
    const { deliveries } = this.state;
    const { day, month, year } = selectedDay;
    LOGGING >= 3 &&
      console.log(
        "handleGetDataForSelectedDay called with:",
        selectedDay,
        calendarMonth,
        calendarYear,
        deliveries
      );
    const selectedMoment = moment().set({
      year: year,
      month: month,
      date: day,
    });

    const dateKey = selectedMoment.format(DateKeyFormat);

    const lunchDelivery =
      deliveries && deliveries.find((d) => d.dateKey === dateKey && d.isLunch);

    const dinnerDelivery =
      deliveries && deliveries.find((d) => d.dateKey === dateKey && !d.isLunch);

    this.setState({
      selectedDay: {
        dateKey,
        month: calendarMonth,
        year: calendarYear,
        lunchDelivery,
        dinnerDelivery,
      },
      ...callbackState,
    });
  };

  // for pure rendering maneuver on the selected day details
  // for the undelivered:
  handleToggleShowTeamActions(delivery, e) {
    e.preventDefault();
    const { showTeamActions, showTooLateWarning } = this.state;
    const { byTime, isLunch } = delivery;

    LOGGING >= 1 &&
      console.log("handleToggleShowTeamActions called with: ", {
        showTeamActions,
        showTooLateWarning,
        byTime,
        isLunch,
      });

    if (showTeamActions === isLunch) {
      this.setState({ showTeamActions: NO_DELIVERY });
    } else {
      if (isDeliveryEditable(isLunch, byTime)) {
        this.setState({ showTeamActions: isLunch });
      } else {
        LOGGING >= 1 && console.log("handleToggleShowTeamActions is too late");
        if (
          showTooLateWarning.user === NO_USER &&
          showTooLateWarning.isLunch === isLunch
        ) {
          LOGGING >= 1 && console.log("turning too late off");
          this.setState({
            showTooLateWarning: NO_INSTANCE,
          });
        } else {
          LOGGING >= 1 && console.log("turning too late on");
          this.setState({
            showTooLateWarning: { user: NO_USER, isLunch },
          });
        }
      }
    }
  }

  handleShowTooLateWarning(isLunch, user, text) {
    this.setState({
      showTooLateWarning: { isLunch, user, text },
    });
  }

  handleHideTooLateWarning(e) {
    e.stopPropagation();
    this.setState({
      showTooLateWarning: NO_INSTANCE,
    });
  }
  //for the delivered:
  handleToggleShowDeliveredDetails(isLunch) {
    const { showDeliveredDetails } = this.state;
    if (showDeliveredDetails === isLunch) {
      this.setState({ showDeliveredDetails: NO_DELIVERY });
    } else {
      this.setState({ showDeliveredDetails: isLunch });
    }
  }

  handleToggleShowRatingOptions(isLunch) {
    const { showRatingOptions } = this.state;
    LOGGING >= 1 &&
      console.log("handleToggleShowRatingOptions called with:", {
        isLunch,
        showRatingOptions,
      });

    if (showRatingOptions !== NO_DELIVERY) {
      // if is currenting showing either lunch or dinner,
      // hide it.
      this.setState({
        showRatingOptions: NO_DELIVERY,
      });
    } else {
      // if is currenting showing nothing,
      // show as specified in isLunch
      this.setState({
        showRatingOptions: isLunch,
      });
    }
  }

  // handlers with server writes
  // for the undelivered:

  handleChangeDelivery(delivery, callbackState) {
    // called by:
    // 1. handleSetTeamStatus
    // 2. handleToggleInstanceStatus
    // 3. handleSelectRating
    // 4. handleSaveComment
    //
    // Input format
    // delivery: {
    // _id,
    // byTime, // mandatory when delivery._id is undefined
    // company, // mandatory when delivery._id is undefined
    // instances: [{
    //    _id,
    //    user, // mandatory when instance._id is undefined
    //    status, // mandatory when instance._id is undefined
    //}] // mandatory when delivery._id is undefined
    // rating,
    // ratingTime,
    // comment,
    // commentTime,
    //}

    const { _id } = delivery;
    LOGGING >= 1 &&
      console.log("handleChangeDelivery called with:", {
        delivery,
      });
    this.setState({ loading: true });
    if (_id) {
      this.props
        .updateDelivery({ _id, delivery })
        .then((result) => {
          LOGGING >= 1 &&
            console.log("updateDelivery done from stores with result:", result);
          this.setState(
            {
              loading: false,
              deliveries: this.handleGetDeliveriesWithSchedule(),
            },
            () => {
              LOGGING >= 1 &&
                console.log("updateDelivery done updating deliveries");
              this.handleGetDataForSelectedDay(callbackState);
            }
          );
        })
        .catch((error) => {
          LOGGING >= 1 &&
            console.log("updateDelivery done from stores with error:", error);
          this.setState({ loading: false, ...callbackState });
        });
    } else {
      this.props
        .createDelivery(delivery)
        .then((result) => {
          LOGGING >= 1 &&
            console.log("createDelivery done from stores with result:", result);
          this.setState(
            {
              loading: false,
              deliveries: this.handleGetDeliveriesWithSchedule(),
            },
            () => {
              LOGGING >= 1 &&
                console.log("createDelivery done updating deliveries");
              this.handleGetDataForSelectedDay(callbackState);
            }
          );
        })
        .catch((error) => {
          LOGGING >= 1 &&
            console.log("createDelivery done from stores  with error:", error);
          this.setState({ loading: false, ...callbackState });
        });
    }
  }

  handleSetTeamStatus({ delivery, actionName, status }) {
    // actionName is calculated when rendering button,
    // so no need to infer it from status
    LOGGING >= 1 &&
      console.log("handleSetTeamStatus called with: ", {
        delivery,
        actionName,
        status,
      });

    const { company, byTime, _id, instances } = delivery;

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmAlert
            onConfirm={this.handleChangeDelivery.bind(
              this,
              {
                _id,
                byTime, // in case _id is undefined
                company: company._id, // in case _id is undefined
                instances: instances.map((i) => ({ ...i, status })),
              },
              {
                showTeamActions: NO_DELIVERY,
              }
            )}
            onClose={onClose}
            actionName={actionName.toLowerCase()}
          />
        );
      },
    });
  }

  handleToggleInstanceStatus(instance, delivery, e) {
    e.preventDefault();

    const {
      byTime,
      dispatchTime,
      orderTime,
      pickUpTime,
      deliverTime,
      company,
      instances,
      isLunch,
      _id,
    } = delivery;

    const { user } = instance;
    LOGGING >= 1 &&
      console.log("handleToggleInstanceStatus called with:", {
        instance,
        delivery,
      });
    // recheck at click time
    if (
      !isDeliveryEditable(isLunch, byTime) ||
      orderTime > 0 ||
      pickUpTime > 0 ||
      dispatchTime > 0 ||
      deliverTime > 0
    ) {
      const text =
        deliverTime > 0
          ? "Delivered!"
          : dispatchTime > 0
          ? "On the way!"
          : pickUpTime > 0
          ? "Picked up!"
          : orderTime > 0
          ? "Order placed!"
          : "Too late!";
      this.handleShowTooLateWarning(isLunch, user, text);
    } else {
      const updatedDelivery = {
        _id,
        company: company._id, //needed incase it's a new delivery
        byTime, //needed incase it's a new delivery
        instances: instances.map((i) =>
          i.user === instance.user
            ? {
                ...instance,
                status:
                  instance.status === InstanceStatus.CANCELLED
                    ? InstanceStatus.SCHEDULED
                    : InstanceStatus.CANCELLED,
              }
            : i
        ),
      };
      LOGGING >= 1 &&
        console.log("handleToggleInstanceStatus got:", {
          updatedDelivery,
        });
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmAlert
              onConfirm={this.handleChangeDelivery.bind(this, updatedDelivery)}
              onClose={onClose}
              actionName={
                instance.status === InstanceStatus.CANCELLED
                  ? "resume"
                  : "cancel"
              }
            />
          );
        },
      });
    }
  }

  //for delivered:
  handleSelectRating(deliveryId, rating, e) {
    e.preventDefault();
    LOGGING >= 1 &&
      console.log("handleSelectRating called with: ", { deliveryId, rating });
    this.handleChangeDelivery(
      {
        _id: deliveryId,
        rating,
        ratingTime: new Date().valueOf(),
      },
      { showRatingOptions: NO_DELIVERY }
    );
  }

  handleEditComment(delivery, e) {
    LOGGING >= 1 &&
      console.log("handleEditComment called with:", {
        delivery,
        eTarget: e.target.value,
      });
    // e.preventDefault();
    // e.stopPropagation();

    const comment = e.target.value;
    const { deliveries } = this.state;
    LOGGING >= 1 &&
      console.log("handleEditComment called with:", {
        delivery,
        comment,
        deliveries,
      });
    this.setState(
      {
        deliveries: deliveries.map((d) =>
          delivery.isLunch === d.isLunch && delivery.dateKey === d.dateKey
            ? { ...delivery, comment }
            : d
        ),
        isEdittingComment: {
          isLunch: delivery.isLunch,
          dateKey: delivery.dateKey,
        },
      },
      () => {
        this.handleGetDataForSelectedDay();
      }
    );
  }

  handleSaveComment(delivery, e) {
    e.preventDefault();
    e.stopPropagation();
    LOGGING >= 1 && console.log("handleSaveComment called with:", delivery);
    const { _id, comment, byTime, company, instances } = delivery;
    this.handleChangeDelivery(
      {
        _id,
        comment,
        commentTime: new Date().valueOf(),
        byTime, // in case it's a new delivery
        company: company._id, // in case it's a new delivery
        instances, // in case it's a new delivery
      },
      {
        isEdittingComment: NO_DELIVERY,
      }
    );
  }

  componentDidUpdate(prevProps) {
    LOGGING >= 3 && console.log("PAGEUSER EATER componentDidUpdate");
    const {
      calendarYear: prevYear,
      calendarMonth: prevMonth,
      selectedDay: prevSelectedDay,
    } = prevProps;

    const { calendarYear, calendarMonth, selectedDay } = this.props;

    LOGGING >= 3 &&
      console.log("PAGEUSER EATER componentDidUpdate with: ", {
        prevSelectedDay,
        selectedDay,
        prevYear,
        prevMonth,
        calendarYear,
        calendarMonth,
      });

    if (prevMonth !== calendarMonth || prevYear !== calendarYear) {
      LOGGING >= 1 &&
        console.log("PAGEUSER EATER componentDidUpdate reloading deliveries.");
      this.handleLoadDataForCalendarMonth();
    }
    if (prevSelectedDay !== selectedDay) {
      LOGGING >= 1 &&
        console.log("PAGEUSER EATER componentDidUpdate with:", {
          selectedDay,
          prevSelectedDay,
        });
      this.handleGetDataForSelectedDay();
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    LOGGING >= 3 &&
      console.log(
        "PageUserEater componentDidMount called with currentUser: ",
        this.props.currentUser
      );

    this.setState({ loading: true }, () => {
      const {
        selectedDay,
        calendarMonth,
        calendarYear,
        currentUser,
      } = this.props;

      LOGGING >= 3 &&
        console.log("PAGEUSER EATER componentDidMount with:", {
          selectedDay,
          calendarMonth,
          calendarYear,
          currentUser,
        });
      if (currentUser.isAuthenticated) {
        LOGGING >= 3 && console.log("reloading user called");
        this.props
          .reloadUser()
          .then(() => {
            LOGGING >= 3 && console.log("authUser returend with success");
            // this.props.history.push("/");
            // this.setState({ loading: false });
          })
          .catch((err) => {
            LOGGING >= 1 && console.log("authUser returend with err:", err);
            LOGGING >= 1 &&
              console.log(
                "onAuth returend with props.errors:",
                this.props.errors
              );
            // this.setState({ loading: false });
          });
      }

      if (!selectedDay || !calendarMonth || !calendarYear) {
        this.props.goToCurrent();
      }

      if (selectedDay) {
        this.handleGetDataForSelectedDay();
      }

      this.handleLoadDataForCalendarMonth();
    });
  }

  handleShowDisclaimers({ whichClaimer }) {
    LOGGING >= 3 &&
      console.log("PageInstances shows whichClaimer:", whichClaimer);
    this.setState({ showDisclaimers: true, whichClaimer });
  }

  handleCloseInvoice(e) {
    e.preventDefault();
    this.setState({ showInvoices: false });
  }

  handleShowInvoice(e) {
    LOGGING >= 3 && console.log("PageInstances handleShowInvoice clicked");
    e.preventDefault();
    this.setState({ showInvoices: true });
  }

  render() {
    const {
      currentUser,
      calendarYear,
      calendarMonth,
      history,
      isDelegate,
      hasInvoice,
    } = this.props;

    const {
      showInvoices,
      selectedDay,
      loading,
      monthGrid,
      deliveries,
      showRatingOptions,
      showDeliveredDetails,
      showTooLateWarning,
      showTeamActions,
      isEdittingComment,
    } = this.state;

    if (!selectedDay) {
      return <LoadingIcon />;
    }
    const { lunchDelivery, dinnerDelivery } = selectedDay;
    const { user } = currentUser;
    const { company } = user;
    const { onBoardingStep } = company;

    LOGGING >= 1 &&
      console.log("PageEaters rendering with:", {
        props: this.props,
        state: this.state,
        lunchDelivery,
        dinnerDelivery,
      });

    if (onBoardingStep < OnBoardingStep.DONE) {
      return <Redirect to={`/setup/${OnBoardingStepText[onBoardingStep]}`} />;
    }
    if (showInvoices)
      return (
        <PageInvoices onClose={this.handleCloseInvoice} history={history} />
      );

    const navbar = (
      <Navbar
        isDelegate={isDelegate}
        hasInvoice={hasInvoice}
        onShowInvoice={this.handleShowInvoice}
        setupDone={currentUser.user.company.onBoardingStep >= 3}
      />
    );

    const content = (
      <div className="page-content eater">
        {loading ? <LoadingIcon /> : null}
        {deliveries ? (
          <CalendarWrapperShared
            role="eater"
            deliveries={deliveries}
            onClickDayGrid={this.handleSelectDay}
            onNextMonth={this.props.goToNextMonth}
            onPrevMonth={this.props.goToPrevMonth}
            calendarYear={calendarYear}
            calendarMonth={calendarMonth}
            selectedDay={selectedDay}
            monthGrid={monthGrid}
          />
        ) : null}
        {deliveries ? (
          //  &&calendarMonth === selectedDay.month
          //  &&calendarYear === selectedDay.year
          <EaterDay
            currentUser={currentUser.user}
            selectedDay={selectedDay}
            lunchDelivery={lunchDelivery}
            dinnerDelivery={dinnerDelivery}
            // display control states
            showTooLateWarning={showTooLateWarning}
            showTeamActions={showTeamActions}
            showDeliveredDetails={showDeliveredDetails}
            showRatingOptions={showRatingOptions}
            isEdittingComment={isEdittingComment}
            // display control handlers
            onGoToSelectedMonth={this.handleGoToSelectedDayMonth}
            // for undelivered
            onToggleShowTeamActions={this.handleToggleShowTeamActions}
            onHideTooLateWarning={this.handleHideTooLateWarning}
            // for delivered
            onToggleShowDeliveredDetails={this.handleToggleShowDeliveredDetails}
            onToggleShowRatingOptions={this.handleToggleShowRatingOptions}
            onEditComment={this.handleEditComment}
            // server write handlers
            onSetTeamStatus={this.handleSetTeamStatus}
            onToggleInstanceStatus={this.handleToggleInstanceStatus}
            onSelectRating={this.handleSelectRating}
            onSaveComment={this.handleSaveComment}
          />
        ) : null}
      </div>
    );

    const footer = (
      <Footer handleShowDisclaimers={this.handleShowDisclaimers} />
    );

    return (
      <div className="homepage">
        {navbar}
        {content}
        {footer}
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  LOGGING >= 1 &&
    console.log("PageUserEater mapStateToProps with reduxState:", reduxState);
  const { currentUser, calendar, deliveries } = reduxState;
  const { calendarMonth, calendarYear, selectedDay } = calendar;
  return {
    currentUser,
    deliveriesSavedInMonth: deliveries,
    calendarMonth,
    calendarYear,
    selectedDay,
  };
}

export default connect(
  mapStateToProps,
  {
    fetchDeliveries,
    createDelivery,
    updateDelivery,
    goToNextMonth,
    goToPrevMonth,
    goToCurrent,
    goToDate,
    goToSelectedDayMonth,
    reloadUser,
  },
  null,
  {
    forwardRef: true,
  }
)(PageUserEater);
