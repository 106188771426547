import {
  SET_SELECTED_DAY,
  SET_SELECTED_DAY_MEAL,
  SET_CALENDAR_MONTH,
  RESET_CALENDAR,
} from "../actionTypes";
import moment from "moment-timezone";
export const getCurrent = () => {
  const { months: month, years: year, date: day } = moment(
    new Date()
  ).toObject();
  return {
    calendarMonth: month,
    calendarYear: year,
    selectedDay: { day, month, year },
  };
};
export default (state = getCurrent(), action) => {
  switch (action.type) {
    case SET_CALENDAR_MONTH:
      const { calendarMonth, calendarYear } = action;
      return {
        ...state,
        calendarMonth,
        calendarYear,
      };
    case SET_SELECTED_DAY:
      return {
        ...state,
        selectedDay: { ...action.selectedDay, isLunch: true },
      };
    case SET_SELECTED_DAY_MEAL:
      return {
        ...state,
        selectedDay: { ...state.selectedDay, isLunch: action.isLunch },
      };
    case RESET_CALENDAR:
      return {
        selectedDay: null,
        calendarYear: null,
        calendarMonth: null,
      };
    default:
      return state;
  }
};
