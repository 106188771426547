import React from "react";
import "./DashboardCategory.scss";
import Entity from "./DashboardEntity";
import {
  ThumbnailSortBy,
  DataModeChildrenKey,
  DataModeParentKey,
  DataModeTypes,
  EntityChildrenIcon,
  DataModeCategoriesKey,
  DataTypes,
} from "./";

export const sortAscend = (a, b) =>
  a.sortKey > b.sortKey ? -1 : a.sortKey < b.sortKey ? 1 : 0;
export const sortDescend = (a, b) =>
  a.sortKey > b.sortKey ? 1 : a.sortKey < b.sortKey ? -1 : 0;

const Category = ({
  keyedData,
  categoryEntry,
  sortBy,
  entity,
  activeOnly,
  onSelectDish,
  onClose,
  onDelete,
  onUpdate,
  index,
  opsPhone,
}) => {
  const dataCategory = keyedData
    .filter((entry) => entry.categoryKey === categoryEntry)
    .sort(sortBy === ThumbnailSortBy.BY_CHILDREN ? sortAscend : sortDescend);

  const categoryTitle = (
    <div className="category-title" key={"category+" + String(index)}>
      <span>
        {categoryEntry} ({dataCategory.length})
      </span>
      <div className="filler"></div>
    </div>
  );

  let category = dataCategory.map((entry) => (
    <Entity
      entity={entity}
      name={entry.name}
      key={entry._id}
      archived={entry.archived}
      opsPhone={opsPhone}
      activeStatus={!activeOnly || !entry.archived}
      onDelete={onDelete}
      onUpdate={onUpdate}
      onSelect={entity === DataModeTypes.DISHES ? onSelectDish : null}
      phone={entry.phone}
      onClose={onClose}
      icon={EntityChildrenIcon[entity]}
      type={
        entry.type !== null && entry.type !== undefined
          ? DataTypes[entity][entry.type]
          : null
      }
      categories={
        DataModeCategoriesKey[entity]
          ? entry[DataModeCategoriesKey[entity]]
          : null
      }
      parent={
        entry[DataModeParentKey[entity]]
          ? entry[DataModeParentKey[entity]].name || ""
          : ""
      }
      numberOfChildren={
        entity === "dishes"
          ? `$${entry.price.toFixed(2)}`
          : DataModeChildrenKey[entity] && entry[DataModeChildrenKey[entity]]
          ? entry[DataModeChildrenKey[entity]].length
          : null
      }
      id={entry._id}
    />
  ));

  category.push(<div key="1" className="data-entity empty"></div>);
  category.push(<div key="2" className="data-entity empty"></div>);
  category.push(<div key="3" className="data-entity empty"></div>);

  return (
    <div className="data-category" key={index}>
      {categoryTitle}
      {category}
    </div>
  );
};

export default Category;
