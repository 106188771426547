import { apiCall } from "../api";
import { LOAD_USERS, REMOVE_USERS } from "../actionTypes";
import { addError } from "./errors";
import { LOGGING } from "../../hocs/constants";
import { loadCompanies } from "./companies";
import moment from "moment-timezone";

export const loadUsers = (users) => ({
  type: LOAD_USERS,
  users,
});

export const cleanUsers = (users) => ({
  type: REMOVE_USERS,
});

export const fetchUsers = () => {
  LOGGING >= 3 && console.log("fetchUsers");

  return (dispatch, getState) => {
    const { currentUser } = getState();

    LOGGING >= 3 && console.log("fetchUsers got user:", currentUser);
    if (currentUser.user.role !== "ops") {
      dispatch(addError("only ops can see all users"));
      return "error";
    }

    return apiCall("GET", `/users/${currentUser.user._id}/users`)
      .then((res) => {
        LOGGING >= 1 && console.log("fetchUsers got res", res);
        const { users } = res;
        const usersWithType = users.map((user) => ({
          ...user,
          type: user.role
            ? user.role !== "eater"
              ? 0
              : user.isOrganizerInCompany
              ? 1
              : 2
            : 2,
          company: user.company ? user.company : "(no company)",
          name: user.name || `${user.firstName} ${user.lastName}`,
        }));
        LOGGING >= 1 && console.log("usersWithType:", usersWithType);
        dispatch(loadUsers(usersWithType));
        return "OK";
      })
      .catch((err) => {
        dispatch(addError(err.message));
      });
  };
};
//if it's from admin, should contain managerid in the field manager
export const createManagedUser = ({ managedUser, company }) => {
  LOGGING >= 3 && console.log("createManagedUser called with: ", managedUser);

  return (dispatch, getState) => {
    const { currentUser } = getState();

    LOGGING >= 3 && console.log("createManagedUser got user:", currentUser);
    if (
      currentUser.user.role !== "ops" &&
      !currentUser.user.isOrganizerInCompany
    ) {
      LOGGING >= 3 && console.log("currentUser is not authorized");
      dispatch(
        addError("only ops or company organizer can create managed user")
      );
    }
    // const user = currentUser.user.role==="ops"?{...managedUser, manager}:managedUser;
    return apiCall(
      "POST",
      `/users/${currentUser.user._id}/managed_users`,
      managedUser
    )
      .then((createdUser) => {
        LOGGING >= 3 && console.log("createdUser got res", createdUser);
        return createdUser;
      })
      .catch((err) => {
        dispatch(addError(err.message));
      });
  };
};

export const updateManagedUser = ({ managedUser }) => {
  LOGGING >= 3 && console.log("updateManagedUser called with: ", managedUser);

  return (dispatch, getState) => {
    const { currentUser } = getState();

    LOGGING >= 3 && console.log("updateManagedUser got user:", currentUser);
    if (
      currentUser.user.role !== "ops" &&
      !currentUser.user.isOrganizerInCompany
    ) {
      LOGGING >= 3 && console.log("currentUser is not authorized");
      dispatch(
        addError("only ops or company organizer can create managed user")
      );
    }

    return apiCall(
      "POST",
      `/users/${currentUser.user._id}/managed_users/${managedUser._id}`,
      managedUser
    )
      .then((updateManagedUser) => {
        LOGGING >= 3 &&
          console.log("updateManagedUser got res", updateManagedUser);
        return updateManagedUser;
      })
      .catch((err) => {
        dispatch(addError(err.message));
      });
  };
};

export const fetchDelegatedUserForAdmin = ({ userId }) => {
  LOGGING >= 3 && console.log("fetchDelegatedUserForAdmin");

  return (dispatch, getState) => {
    const { currentUser } = getState();

    LOGGING >= 3 &&
      console.log("fetchDelegatedUserForAdmin got user:", currentUser);
    if (currentUser.user.role !== "ops") {
      dispatch(addError("only ops can see all users"));
      return "error";
    }

    return apiCall(
      "GET",
      `/users/${currentUser.user._id}/delegatedUserForAdmin`
    )
      .then((res) => {
        LOGGING >= 3 && console.log("fetchDelegatedUserForAdmin got res", res);
        const { users } = res;
        dispatch(loadUsers(users));
        return "OK";
      })
      .catch((err) => {
        dispatch(addError(err.message));
      });
  };
};

export const deleteUser = (id) => {
  LOGGING >= 1 && console.log("deleteUser called with:", id);
  return (dispatch, getState) => {
    const { currentUser, users, companies } = getState();

    LOGGING >= 3 && console.log("currentUser got user:", currentUser);
    if (currentUser.user.role !== "ops") {
      dispatch(addError("only ops can create a dish"));
      return "error";
    }

    return apiCall("DELETE", `/users/${currentUser.user._id}/users/${id}`)
      .then((res) => {
        LOGGING >= 1 && console.log("deleteUser got res", res);
        const { updatedCompany } = res;
        const updatedUsers = users.filter((user) => id !== user._id);
        const updatedCompanies = companies.map((company) =>
          company._id !== updatedCompany._id ? company : updatedCompany
        );
        dispatch(loadUsers(updatedUsers));
        dispatch(loadCompanies(updatedCompanies));
        return res;
      })
      .catch((err) => {
        dispatch(addError(err.message));
      });
  };
};

export const setUserOnLeave = ({
  user,
  leaveStartYear,
  leaveStartMonth,
  leaveStartDate,
  leaveEndYear,
  leaveEndMonth,
  leaveEndDate,
}) => (dispatch, getState) => {
  const { currentUser } = getState();
  LOGGING &&
    console.log("setUserOnLeave called with: ", {
      user,
      leaveStartYear,
      leaveStartMonth,
      leaveStartDate,
      leaveEndYear,
      leaveEndMonth,
      leaveEndDate,
    });
  const leaveStartUTC = moment
    .tz(
      [leaveStartYear, leaveStartMonth, leaveStartDate, 0, 0, 1, 0],
      "America/Los_Angeles"
    )
    .valueOf();
  const leaveEndUTC = moment
    .tz(
      [leaveEndYear, leaveEndMonth, leaveEndDate, 23, 59, 59, 0],
      "America/Los_Angeles"
    )
    .valueOf();

  LOGGING >= 1 && console.log("leaveStartUTC:", leaveStartUTC);
  LOGGING >= 1 && console.log("leaveEndUTC:", leaveEndUTC);

  return apiCall("POST", `/users/${currentUser.user._id}/users/leave/${user}`, {
    leaveStartUTC,
    leaveEndUTC,
  })
    .then((res) => {
      LOGGING >= 1 && console.log("posted with res:", res);
      return "ok";
    })
    .catch((err) => {
      LOGGING >= 3 && console.log("posted with err:", err);
      dispatch(addError(err));
    });
};

export const updateUser = ({ id, data }) => {
  LOGGING >= 1 && console.log("updateUser called with id:", id);
  LOGGING >= 1 && console.log("updateUser called with data:", data);
  return (dispatch, getState) => {
    const { currentUser, users } = getState();

    LOGGING >= 3 && console.log("currentUser got user:", currentUser);
    if (currentUser.user.role !== "ops") {
      dispatch(addError("only ops can create a dish"));
      return "error";
    }

    return apiCall("POST", `/users/${currentUser.user._id}/users/${id}`, data)
      .then((res) => {
        LOGGING >= 1 && console.log("updateUser got res", res);
        // const { updatedCompany } = res;
        const oldUsers = users.filter((user) => id !== user._id);
        LOGGING >= 1 && console.log("updateUser got users:", users);
        LOGGING >= 1 && console.log("updateUser got oldUsers:", oldUsers);
        const updatedUsers = [...oldUsers, res];
        LOGGING >= 1 &&
          console.log("updateUser got updatedUsers:", updatedUsers);
        // const updatedCompanies = companies.map(company=>company._id!==updatedCompany._id?
        //   company:updatedCompany);
        dispatch(loadUsers(updatedUsers));
        // dispatch(loadCompanies(updatedCompanies));
        // return res;
      })
      .catch((err) => {
        dispatch(addError(err.message));
      });
  };
};
