import React from "react";
import "./UnitDetails.scss";
import { InstanceDetails, MealStatus, DishContent } from "./";
import {
  InstanceStatus,
  DateKeyFormat,
  DateKeyFormatShort,
} from "../../../hocs/constants";
import { getUnitStatus } from "./";
import { Sticker, EaterName } from "../../widgets";
import { LOGGING } from "../../../hocs/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/pro-regular-svg-icons";

const InstancePlanner = ({
  instance,
  onToggleInstance,
  isLunch,
  onShowDishInventory,
}) => {
  return instance ? (
    <Sticker
      icon={
        <button
          className={`round-icon ${
            instance && instance.isOnFocus ? "purple-icon" : "grey-icon"
          } `}
          type="button"
          onClick={onToggleInstance}
        >
          <span>{isLunch ? "L" : "D"}</span>
        </button>
      }
      content={
        instance.status !== InstanceStatus.CANCELLED ? (
          instance.dish ? (
            <DishContent
              dish={instance.dish}
              name={instance.dish.restaurant.name}
            />
          ) : null
        ) : (
          <div className="dish-content">cancelled</div>
        )
      }
      action={
        <button type="button" onClick={onShowDishInventory}>
          <FontAwesomeIcon icon={faPencil} />
        </button>
      }
      className="instance-planner"
    />
  ) : (
    <Sticker
      icon={
        <div className="round-icon deleted-icon">
          <span>{isLunch ? "L" : "D"}</span>
        </div>
      }
      content={<div className="dish-content">not scheduled</div>}
      className="instance-planner"
    />
  );
};
const InstancePlannerWithContext = ({
  instance,
  selectedMeal,
  contextDeliveries,
  contextDateMoments,
  onShowDishInventory,
  onToggleInstance,
}) => {
  LOGGING >= 3 &&
    console.log("InstancePlannerWithContext rendering with: ", {
      instance,
      contextDeliveries,
      contextDateMoments,
    });
  return (
    <React.Fragment>
      {contextDateMoments.map((day, dayIndex) => {
        const contextDateKey = day.format(DateKeyFormat);
        const contextDateDeliveries = contextDeliveries.filter(
          (d) => d.dateKey === contextDateKey
        );

        LOGGING >= 3 &&
          console.log("InstancePlannerWithContext looping through 1: ", {
            contextDateKey,
            contextDateDeliveries,
            day,
            instance,
          });

        const lunchDelivery = contextDateDeliveries.find(
          (d) =>
            d.isLunch && d.company.eaters.find((e) => e._id === instance.user)
        );
        const dinnerDelivery = contextDateDeliveries.find((d) => !d.isLunch);
        LOGGING >= 3 &&
          console.log("InstancePlannerWithContext looping through 2: ", {
            lunchDelivery,
            dinnerDelivery,
          });

        let lunch;
        if (lunchDelivery) {
          LOGGING >= 3 &&
            console.log(
              "InstancePlannerWithContext rendering lunchDelivery.instances: ",
              lunchDelivery.instances
            );
          LOGGING >= 3 &&
            console.log(
              "InstancePlannerWithContext rendering for instance: ",
              instance
            );
          // if there's lunch for the company
          lunch = lunchDelivery.instances.find((i) => i.user === instance.user);
          LOGGING >= 3 &&
            console.log(
              "InstancePlannerWithContext found lunch instance: ",
              lunch
            );
          if (
            lunch && // if there's lunch for the user
            contextDateKey === selectedMeal.dateKey && // if this is right date
            selectedMeal.isLunch // if this is the right meal
          ) {
            lunch = { ...lunch, isOnFocus: true };
          } // there's lunch for the company, but none for the user, lunch will be undefined
        } // there's no lunch for the company, lunch will be undefined

        let dinner;
        if (dinnerDelivery) {
          // there's lunch for the company
          dinner = dinnerDelivery.instances.find(
            (i) => i.user === instance.user
          );
          if (
            dinner && // if there's dinner for the user
            contextDateKey === selectedMeal.dateKey && // if this is right date
            !selectedMeal.isLunch // if this is the right meal
          ) {
            dinner = { ...dinner, isOnFocus: true };
          } // there's dinner for the company, but none for the user, lunch will be undefined
        } // there's no dinner for the company, dinner will be undefined

        return (
          <div className="eater-day" key={dayIndex}>
            <div className="eater-day-title">
              {day.format(DateKeyFormatShort)}
            </div>
            {[
              { instance: lunch, delivery: lunchDelivery },
              { instance: dinner, delivery: dinnerDelivery },
            ].map(({ instance, delivery }, index) => (
              <InstancePlanner
                instance={instance}
                key={index}
                onToggleInstance={onToggleInstance.bind(
                  this,
                  delivery,
                  instance
                )}
                isLunch={index === 0}
                onShowDishInventory={onShowDishInventory.bind(
                  this,
                  delivery,
                  instance
                )}
              />
            ))}
          </div>
        );
      })}
    </React.Fragment>
  );
};
const UnitDetailsDelivery = ({
  role,
  mainContact,
  // time data
  selectedMeal,
  contextDateMoments,
  dispatchTime,
  deliverTime,
  // meal data
  contextDeliveries, // instances on context dates: both meals, all users, cancelled or not
  instances, // instances for that meal and date: all users, cancelled or not
  //handler
  onShowDishInventory,
  onToggleInstance,
}) => {
  const dispatchStatus = getUnitStatus({
    action: "dispatch",
    time: dispatchTime,
    readyForAction: false,
    actionRole: "driver",
    displayRole: role,
  });

  const deliverStatus = getUnitStatus({
    action: "deliver",
    time: deliverTime,
    readyForAction: dispatchTime > 0,
    actionRole: "driver",
    displayRole: role,
  });

  const dispatchInfo = (
    <MealStatus time={dispatchTime} status={dispatchStatus} />
  );

  const deliverInfo = <MealStatus time={deliverTime} status={deliverStatus} />;

  let restaurants = [];
  instances.forEach((instance) => {
    const restaurant = instance.dish
      ? instance.dish.restaurant
      : { _id: -1, name: "Restaurant (TBD By Ops)" };
    const restaurantIndex = restaurants.findIndex(
      (c) => c._id === restaurant._id
    );
    if (restaurantIndex < 0) {
      restaurants.push({
        ...restaurant,
        instances: [instance],
      });
    } else {
      restaurants[restaurantIndex].instances.push(instance);
    }
  });

  const mealsSection =
    role === "ops"
      ? instances
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((instance, index) => (
            <div className="unit-sub-section" key={index}>
              <EaterName
                name={instance.name}
                isMainContact={instance.name === mainContact.name}
              />
              <InstancePlannerWithContext
                instance={instance}
                key={index}
                selectedMeal={selectedMeal}
                contextDeliveries={contextDeliveries}
                contextDateMoments={contextDateMoments}
                onShowDishInventory={onShowDishInventory}
                onToggleInstance={onToggleInstance}
              />
            </div>
          ))
      : restaurants.map((restaurant, restaurantIndex) => (
          <div className="unit-sub-section" key={restaurantIndex}>
            <div className="unit-sub-section-title restaurant-name">
              {restaurant.name}
            </div>
            {restaurant.instances.map((instance, index) => (
              <InstanceDetails
                dish={instance.dish}
                eaterName={instance.name}
                key={index}
              />
            ))}
          </div>
        ));

  return (
    <div className="unit-details">
      <div className="unit-section unit-section-timeline">
        <div className="unit-section-title">timeline</div>
        <div className="unit-section-body">
          {dispatchInfo}
          {deliverInfo}
        </div>
      </div>
      <div
        className={`unit-section unit-section-${
          role === "ops" ? "planning" : "meals"
        }`}
      >
        <div className="unit-section-title">meals</div>
        <div className="unit-section-body">{mealsSection}</div>
      </div>
    </div>
  );
};
export default UnitDetailsDelivery;
