import React from "react";
import { TeamMeal, SelectedDayDetails, NoMeal } from "./";
const EaterDay = ({
  // data
  currentUser,
  lunchDelivery,
  dinnerDelivery,
  selectedDay,

  // rendering controls
  showDeliveredDetails,
  showRatingOptions,
  showTooLateWarning,
  showTeamActions,
  isEdittingComment,

  // rendering handlers
  onToggleShowDeliveredDetails,
  onToggleShowRatingOptions,
  onToggleShowTeamActions,
  onHideTooLateWarning,
  onEditComment,
  onGoToSelectedMonth,

  // server write handlers
  onSelectRating,
  onToggleInstanceStatus,
  onSetTeamStatus,
  onSaveComment,
}) => {
  const lunchCard = lunchDelivery ? (
    <TeamMeal
      key="lunch"
      // data
      currentUser={currentUser}
      isLunch={true}
      delivery={lunchDelivery}
      // rendering controls
      showDetails={showDeliveredDetails === "lunch"}
      showRatingOptions={showRatingOptions}
      showTooLateWarning={showTooLateWarning}
      showTeamActions={showTeamActions}
      isEdittingComment={isEdittingComment}
      // rendering handlers
      onToggleShowDetails={onToggleShowDeliveredDetails.bind(this, "lunch")}
      onToggleShowRatingOptions={onToggleShowRatingOptions}
      onToggleShowTeamActions={onToggleShowTeamActions}
      onEditComment={onEditComment}
      onHideTooLateWarning={onHideTooLateWarning}
      // server write handlers
      onToggleInstanceStatus={onToggleInstanceStatus}
      onSetTeamStatus={onSetTeamStatus}
      onSelectRating={onSelectRating}
      onSaveComment={onSaveComment}
    />
  ) : null;

  const dinnerCard = dinnerDelivery ? (
    <TeamMeal
      key="dinner"
      // data
      currentUser={currentUser}
      isLunch={false}
      delivery={dinnerDelivery}
      // rendering controls
      showDetails={showDeliveredDetails === "dinner"}
      showRatingOptions={showRatingOptions}
      showTooLateWarning={showTooLateWarning}
      showTeamActions={showTeamActions}
      isEdittingComment={isEdittingComment}
      // rendering handlers
      onToggleShowDetails={onToggleShowDeliveredDetails.bind(this, "dinner")}
      onToggleShowRatingOptions={onToggleShowRatingOptions}
      onToggleShowTeamActions={onToggleShowTeamActions}
      onEditComment={onEditComment}
      onHideTooLateWarning={onHideTooLateWarning}
      // server write handlers
      onToggleInstanceStatus={onToggleInstanceStatus}
      onSetTeamStatus={onSetTeamStatus}
      onSelectRating={onSelectRating}
      onSaveComment={onSaveComment}
    />
  ) : null;

  const noMealCard =
    dinnerDelivery === undefined && lunchDelivery === undefined ? (
      <NoMeal meal="meal" />
    ) : null;

  return (
    <SelectedDayDetails
      selectedDay={selectedDay}
      onGoToSelectedMonth={onGoToSelectedMonth}
      role="eater"
      content={
        <React.Fragment>
          {lunchCard}
          {dinnerCard}
          {noMealCard}
        </React.Fragment>
      }
    />
  );
};

export default EaterDay;
