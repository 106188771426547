import { combineReducers } from "redux";
import currentUser from "./currentUser";
import errors from "./errors";
import deliveries from "./deliveries";
import users from "./users";
import { instances } from "./instances";
import dataMode from "./dataMode";
import calendar from "./calendar";
import companies from "./companies";
import restaurants from "./restaurants";
import dishes from "./dishes";
import cuisines from "./cuisines";
import ingredients from "./ingredients";
import orders from "./orders";
// The keys in redux state are defined in the
// following object-shorthand as input to combineReducers

const rootReducer = combineReducers({
  currentUser,
  errors,
  deliveries,
  users,
  instances,
  restaurants,
  dishes,
  cuisines,
  ingredients,
  calendar,
  companies,
  dataMode,
  orders,
});

export default rootReducer;
