import React from "react"; // eslint-disable-line no-unused-vars
import "./CalendarDeliveries.scss";
import { InstanceStatus } from "../../../hocs/constants";

const MealDot = ({ isLunch, allCancelled }) => (
  <div
    className={
      "meal-dot " +
      (isLunch ? "lunch" : "dinner") +
      (allCancelled ? " all-cancelled" : "")
    }
  ></div>
);

const CalendarDeliveries = ({ deliveries }) => {
  let mealDots = [];
  if (deliveries) {
    const lunchDelivery = deliveries.find((d) => d.isLunch);
    const dinnerDelivery = deliveries.find((d) => !d.isLunch);
    lunchDelivery !== undefined &&
      mealDots.push(
        <MealDot
          key={0}
          isLunch={true}
          allCancelled={
            !lunchDelivery.instances.some(
              (i) => i.status !== InstanceStatus.CANCELLED
            )
          }
        />
      );

    dinnerDelivery !== undefined &&
      mealDots.push(
        <MealDot
          key={1}
          isLunch={false}
          allCancelled={
            !dinnerDelivery.instances.some(
              (i) => i.status !== InstanceStatus.CANCELLED
            )
          }
        />
      );
  }
  return <div className="calendar-instances">{mealDots}</div>;
};

export default CalendarDeliveries;
