import React from "react";
import "./CTA.scss";
import { PhoneForm } from "../../auth/";

const CTA = ({ onBook, onPhoneChange, phoneNumber, errors, actionName }) => (
  <div className="user-anonymous-section cta">
    <div className="section-body">
      <PhoneForm
        onBook={onBook}
        onPhoneChange={onPhoneChange}
        phoneNumber={phoneNumber}
        actionName={actionName}
        isDelegate={false}
        errors={errors}
      />
    </div>
  </div>
);

export default CTA;
