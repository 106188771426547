import React from "react";
import "./MealStatus.scss";
import { Sticker, FormattedRow } from "../../widgets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClock } from "@fortawesome/pro-regular-svg-icons";

const MealStatus = ({ time, status }) => (
  <Sticker
    icon={
      <div className={`round-icon ${time > 0 ? "green-icon" : "grey-icon"}`}>
        <FontAwesomeIcon icon={time > 0 ? faCheck : faClock} />
      </div>
    }
    className="meal-status"
    content={
      <React.Fragment>
        <div className="status-title">{status.text}</div>
        <div className="status-timestamp">
          <FormattedRow
            list={[<div>{status.timeStamp}</div>]}
            addBullet={true}
          />
        </div>
      </React.Fragment>
    }
  />
);
export default MealStatus;
