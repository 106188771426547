import React from "react";
import "./TeamMeal.scss";
import moment from "moment-timezone";
import {
  Select,
  BubbleUpperRight,
  BubbleUpperMiddle,
  DropDownMenu,
  ExpandToggle,
} from "../../widgets";

import DeliveryComment from "./DeliveryComment";
import InstanceDelivered from "./InstanceDelivered";

// import from other modules
import {
  InstanceStatus,
  actionTimeFormat,
  DateKeyFormatShort,
  LOGGING,
  lunchEnd,
  dinnerEnd,
} from "../../../hocs/constants";
import { NO_USER } from "../";
// import icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import {
  faPlayCircle,
  faTimesCircle as faTimesCircleLight,
} from "@fortawesome/pro-light-svg-icons";
import {
  faTruck,
  faMapMarkerAlt,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";

const feedbackTimeFormat = `${actionTimeFormat}, ${DateKeyFormatShort}`;
const RatingWords = {
  "-1": "unrated",
  1: "bad",
  2: "acceptable",
  3: "great",
  4: "awesome",
};

const TooLateWarning = ({ onHide, hasMiddleArrow, text }) =>
  hasMiddleArrow ? (
    <BubbleUpperMiddle
      content={<span>{text}</span>}
      className="too-late"
      onHide={onHide}
    />
  ) : (
    <BubbleUpperRight
      content={<span>{text}</span>}
      className="too-late"
      onHide={onHide}
    />
  );

export const EaterButton = ({
  delivery,
  instance,
  onClick,
  onHideTooLateWarning,
  isMainContact,
  showTooLateWarning,
}) => (
  <React.Fragment>
    <button
      className={`eater ${
        instance.status === InstanceStatus.CANCELLED ? "cancelled " : ""
      }`}
      onClick={onClick.bind(this, instance, delivery)}
    >
      {isMainContact ? <FontAwesomeIcon icon={faStar} /> : null}
      {instance.name}
      {showTooLateWarning.user === instance.user &&
      showTooLateWarning.isLunch === delivery.isLunch ? (
        <TooLateWarning
          onHide={onHideTooLateWarning}
          hasMiddleArrow={true}
          text={showTooLateWarning.text}
        />
      ) : null}
    </button>
  </React.Fragment>
);

const TeamAction = ({
  onSetTeamStatus,
  delivery,
  status,
  actionName,
  actionIcon,
}) => (
  <button
    className={`${actionName.toLowerCase()}-team`}
    onClick={(e) => {
      e.preventDefault();
      onSetTeamStatus({
        delivery,
        status,
        actionName,
      });
    }}
    disabled={delivery.instances.every((i) => i.status === status)}
  >
    {`${actionName} for group`}
    <FontAwesomeIcon icon={actionIcon} />
  </button>
);
const TeamEditButton = ({
  delivery,
  showTeamActions,
  showTooLateWarning,
  onClick,
  onSetTeamStatus,
}) => {
  const { isLunch } = delivery;
  LOGGING >= 3 &&
    console.log("TeamEditButton rendering with:", {
      delivery,
      showTooLateWarning,
    });
  const resumeButton = (
    <TeamAction
      actionIcon={faPlayCircle}
      onSetTeamStatus={onSetTeamStatus}
      delivery={delivery}
      status={InstanceStatus.SCHEDULED}
      actionName="Resume"
    />
  );
  const cancelButton = (
    <TeamAction
      actionIcon={faTimesCircleLight}
      onSetTeamStatus={onSetTeamStatus}
      delivery={delivery}
      status={InstanceStatus.CANCELLED}
      actionName="Cancel"
    />
  );
  const tooLateWarning = <span>Too late to edit delivery.</span>;

  const showTooLateWarningMenu =
    showTooLateWarning.user === NO_USER &&
    showTooLateWarning.isLunch === isLunch;

  const showTeamActionsMenu = showTeamActions === isLunch;
  return (
    <DropDownMenu
      buttonClassName="team-button"
      dropDownMenuClassName={`team-button-menu ${
        showTooLateWarningMenu ? "too-late" : ""
      }`}
      onToggleShowDropDownMenu={onClick && onClick.bind(this, delivery)}
      showDropDownMenu={showTooLateWarningMenu || showTeamActionsMenu}
      dropDownMenu={
        showTooLateWarningMenu
          ? [tooLateWarning]
          : showTeamActionsMenu
          ? [resumeButton, cancelButton]
          : null
      }
    />
  );
};

const TeamMeal = ({
  // data
  currentUser,
  delivery,
  // rendering controls
  showDetails,
  showRatingOptions,
  showTeamActions,
  showTooLateWarning,
  isEdittingComment,
  // rendering handlers
  onToggleShowDetails,
  onToggleShowRatingOptions,
  onToggleShowTeamActions,
  onEditComment,
  onHideTooLateWarning,
  // server write hanlders
  onSelectRating,
  onSaveComment,
  onToggleInstanceStatus,
  onSetTeamStatus,
}) => {
  const { company } = currentUser;
  const { address, mainContact } = company;
  LOGGING >= 3 && console.log("TeamMeal rendering with delivery:", delivery);

  // 1. timeRow

  // assuming all live instances share the same {status, dispatchTime, deliveryTime}
  const {
    deliverTime,
    dispatchTime,
    instances,
    isLunch,
    rating,
    ratingTime,
    commentTime,
  } = delivery;
  const timeLabel =
    deliverTime > 0
      ? `deliveried ${moment(deliverTime).format(actionTimeFormat)}`
      : dispatchTime > 0
      ? `dispatched ${moment(dispatchTime).format(actionTimeFormat)}`
      : `by ${isLunch ? lunchEnd : dinnerEnd}`;

  const timeRow = (
    <div className="team-summary-row">
      <div className="icon-wrapper">
        <FontAwesomeIcon icon={faTruck} />
      </div>
      <div className="info-content">{timeLabel}</div>
    </div>
  );

  // 2. addressRow
  const addressRow = (
    <div className="team-summary-row">
      <div className="icon-wrapper">
        <FontAwesomeIcon icon={faMapMarkerAlt} />
      </div>
      <div className="info-content">{address}</div>
    </div>
  );

  // 3. eatersRow
  const sortedInstances = instances.sort((a, b) =>
    a.name > b.name ? 1 : a.name < b.name ? -1 : 0
  );

  LOGGING >= 3 &&
    console.log("TeamMeal got: ", {
      instances,
      currentUser,
      sortedInstances,
      rating,
    });

  const eatersRow = (
    <div className="team-summary-row scheduled-eaters">
      <div className="icon-wrapper">
        <FontAwesomeIcon icon={faUser} />
      </div>
      {sortedInstances.map((instance, index) => (
        <EaterButton
          key={index}
          delivery={delivery}
          instance={instance}
          onClick={onToggleInstanceStatus}
          onHideTooLateWarning={onHideTooLateWarning}
          isMainContact={instance.user === mainContact}
          showTooLateWarning={showTooLateWarning}
        />
      ))}
    </div>
  );

  //4. teamButton
  const teamButton =
    (!dispatchTime || dispatchTime < 0) && instances.length > 1 ? (
      <TeamEditButton
        delivery={delivery}
        showTooLateWarning={showTooLateWarning}
        showTeamActions={showTeamActions}
        onSetTeamStatus={onSetTeamStatus}
        onClick={onToggleShowTeamActions}
      />
    ) : null;

  const seeMoreButton =
    deliverTime > 0 ? (
      <ExpandToggle
        onToggle={onToggleShowDetails}
        showDetails={showDetails}
        toggleOnText="see more"
        toggleOffText="see less"
        className={`team-details see-${showDetails ? "more" : "less"}`}
      />
    ) : null;

  const ratingTimeInfo =
    ratingTime && ratingTime > 0
      ? `${moment
          .tz(ratingTime, "America/Los_Angeles")
          .format(feedbackTimeFormat)}`
      : "";

  const commentTimeInfo =
    commentTime > 0
      ? `${moment
          .tz(commentTime, "America/Los_Angeles")
          .format(feedbackTimeFormat)}`
      : "";
  LOGGING >= 3 &&
    console.log("TeamMeal got:", {
      ratingTimeInfo,
      commentTimeInfo,
      showRatingOptions,
    });
  const ratingRow =
    deliverTime > 0 ? (
      <div className="team-summary-row rating-info">
        <div className="rating-info-wrapper">
          <span className="info-label">rating</span>{" "}
          <Select
            selected={rating}
            options={[1, 2, 3, 4]}
            optionWords={RatingWords}
            onSelect={onSelectRating.bind(this, delivery._id)}
            onHideOptions={onToggleShowRatingOptions.bind(this, isLunch)}
            showOptions={showRatingOptions === isLunch}
            onToggleShowOptions={onToggleShowRatingOptions.bind(this, isLunch)}
          />
        </div>
        <span className="info-timestamp">{ratingTimeInfo}</span>
      </div>
    ) : null;

  const commentRow = (
    <div className="team-summary-row comment-info">
      <span className="info-label">comment</span>
      <DeliveryComment
        delivery={delivery}
        onEdit={onEditComment}
        onSave={onSaveComment}
        isEditting={
          isEdittingComment.isLunch === delivery.isLunch &&
          isEdittingComment.dateKey === delivery.dateKey
        }
      />
      <span className="info-timestamp">{commentTimeInfo}</span>
    </div>
  );

  const deliveredItems = instances.map((instance, index) => (
    <InstanceDelivered
      key={index}
      instance={instance}
      isMainContact={instance.user === mainContact}
    />
  ));

  return (
    <div className={`team-meal ${isLunch ? "lunch" : "dinner"}`}>
      <div className={`team-summary ${showDetails ? "expanded" : "collapsed"}`}>
        {teamButton}
        {timeRow}
        {addressRow}
        {eatersRow}
        {ratingRow}
        {commentRow}
      </div>
      {seeMoreButton}
      {showDetails ? deliveredItems : null}
    </div>
  );
};

export default TeamMeal;
