import moment from "moment-timezone";
import { CALIFORNIA_TIMEZONE, LOGGING } from "../../../hocs/constants";
export { default as DishCatelog } from "./DishCatelog";
export {
  default as CalendarWrapperShared,
  ShowInvoice,
  ShowDashboard,
} from "./Calendar";
export { default as CalendarDay } from "./CalendarDay";
export { default as CalendarDeliveries } from "./CalendarDeliveries";
export { default as OpsDay } from "./OpsDay";
export { default as EaterDay } from "./EaterDay";
export { default as DriverDay } from "./DriverDay";
export { default as ListHeader } from "./ListHeader";
export { default as UnitHeader } from "./UnitHeader";
export { default as ListOfUnits } from "./ListOfUnits";
export { default as Nothing, NoMeal } from "./Nothing";
export { default as UnitDetailsDelivery } from "./UnitDetailsDelivery";
export {
  default as UnitDetailsOrder,
  InstanceDetails,
} from "./UnitDetailsOrder";
export { default as SelectMealType } from "./SelectMealType";
export { default as SelectedDayDetails } from "./SelectedDayDetails";
export { default as TeamMeal } from "./TeamMeal";
export { default as DishContent } from "./DishContent";
export { default as MealStatus } from "./MealStatus";
export { default as DeliveryComment } from "./DeliveryComment";

const ActionPastTense = {
  order: "ordered",
  "order and pay": "ordered and paid",
  "order, pay and pick-up": "ordered, paid and picked-up",
  "pay and pick-up": "paid and picked-up",
  "pick-up": "picked-up",
  dispatch: "dispatched",
  deliver: "delivered",
};

export const getUnitStatus = ({
  action,
  time,
  readyForAction,
  actionRole,
  displayRole,
}) => {
  // delivery won't get orderStatus or pickUpStatus
  // order won't get dispatchStatus or deliverStatus
  if (!time) return null;
  const status = time > 0;
  const momentTime = moment.tz(time, CALIFORNIA_TIMEZONE);
  const hour = momentTime.hours(),
    minute = momentTime.minutes();
  LOGGING >= 3 && console.log("getUnitStatus called with:", { action, time });
  const timeStamp = status
    ? `${hour}:${minute < 10 ? "0" : ""}${minute} ${hour < 12 ? "A" : "P"}M`
    : "Time (TBD By Driver)";

  const text =
    !status && actionRole === displayRole && readyForAction
      ? action
      : `${status ? "" : "To be "}${ActionPastTense[action]} by ${actionRole}`;

  const buttonStatus = status
    ? "done"
    : actionRole === displayRole && readyForAction
    ? "action"
    : "todo";

  return { buttonStatus, timeStamp, text };
};
