import React from "react"; // eslint-disable-line no-unused-vars
import "./Calendar.scss";
import { Link } from "react-router-dom";
import { CalendarDay } from "./";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Week, CALIFORNIA_TIMEZONE, LOGGING } from "../../../hocs/constants";
import moment from "moment-timezone";

export const ShowInvoice = () => (
  <Link className="quick-link" to="/invoices">
    <span>See Invoices</span>
  </Link>
);
export const ShowDashboard = () => (
  <Link className="quick-link" to={"/dashboard"}>
    <span>dashboard</span>
  </Link>
);
const CalendarWrapperShared = ({
  role,
  deliveries,
  onClickDayGrid,
  onShowDashboard,
  onNextMonth,
  onPrevMonth,
  calendarYear,
  calendarMonth,
  selectedDay,
  monthGrid,
}) => {
  LOGGING >= 3 &&
    console.log("CalendarWrapperShared rendering with: ", {
      deliveries,
      onClickDayGrid,
      onNextMonth,
      onPrevMonth,
      calendarYear,
      calendarMonth,
      selectedDay,
      monthGrid,
    });
  // header content
  const monthMoment = moment
    .tz([calendarYear, calendarMonth], CALIFORNIA_TIMEZONE)
    .startOf("month");

  const quickButton =
    role === "eater" ? (
      <ShowInvoice />
    ) : role === "ops" ? (
      <ShowDashboard onShowDashboard={onShowDashboard} />
    ) : null;

  const header = (
    <div className="calendar-header">
      <div className="calendar-navigation">
        <button onClick={onPrevMonth}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <span className="full-month">{monthMoment.format("MMMM YYYY")}</span>
        <span className="short-month">{monthMoment.format("MMM YYYY")}</span>
        <button onClick={onNextMonth}>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
      {quickButton}
    </div>
  );

  // card content
  const daysOfWeek = Week.map((day) => (
    <div className="day-of-week" key={day}>
      {day.slice(0, 1)}
    </div>
  ));

  const daysOfMonth = monthGrid.map((columnOfGrids, dayOfWeek) => (
    <div className="calendar-column" key={dayOfWeek}>
      {columnOfGrids.map((grid) => (
        <CalendarDay
          date={grid.date}
          month={grid.month}
          year={grid.year}
          key={grid.dateKey}
          dateKey={grid.dateKey}
          isThisMonth={grid.isThisMonth}
          isToday={grid.isToday}
          isTomorrow={grid.isTomorrow}
          isSelectedDay={selectedDay.dateKey === grid.dateKey}
          isPast={grid.isPast}
          deliveries={deliveries.filter((i) => i.dateKey === grid.dateKey)}
          onClick={onClickDayGrid}
        />
      ))}
    </div>
  ));

  const body = (
    <div className="calendar-card">
      <div className="days-of-week">{daysOfWeek}</div>
      <div className="days-of-month">{daysOfMonth}</div>
    </div>
  );

  return (
    <div className="calendar-container">
      {header}
      {body}
    </div>
  );
};
export default CalendarWrapperShared;
