import React, { Component } from "react"; // eslint-disable-line no-unused-vars
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  PageUserInternal,
  PageUserEater,
  PageUserAnonymous,
  PageTerms,
  PagePrivacy,
} from "./home";

import { PageSetUp, PageProfile } from "./config";
import { PagePaymentMethod, PageInvoices } from "./payment";
import PageLogIn from "./auth/PageLogIn";
import { LoadingPage } from "./widgets";
import {
  PageDashboard,
  PageRestaurant,
  PageCuisine,
  PageIngredient,
  PageDish,
} from "./dashboard";
import { authUser, reloadUser } from "../store/actions";
import { LOGGING } from "../hocs/constants";

class Root extends Component {
  // componentDidMount() {
  //   const { currentUser } = this.props;
  //   LOGGING >= 3 &&
  //     console.log("Root componentDidMount with currentUser:", currentUser);
  //   if (
  //     currentUser.user &&
  //     currentUser.user._idUU &&
  //     currentUser.user.role !== "admin"
  //   ) {
  //     this.props.reloadUser().then((user) => {
  //       LOGGING >= 3 && console.log("Root reoladed user:", user);
  //     });
  //   }
  // }

  render() {
    const { isAuthenticated, role, logoText } = this.props;

    LOGGING >= 3 && console.log("Root logoText:", logoText);
    LOGGING >= 1 && console.log("Root, props:", this.props);
    return (
      <Switch>
        <Route
          exact
          path="/"
          render={(props) =>
            !isAuthenticated ? (
              <PageUserAnonymous {...props} key={Date.now()} />
            ) : role === "ops" || role === "driver" ? (
              <PageUserInternal {...props} key={Date.now()} />
            ) : (
              <PageUserEater {...props} key={Date.now()} />
            )
          }
        />
        <Route exact path="/loading" render={(props) => <LoadingPage />} />
        <Route
          exact
          path="/restaurants/:restaurantId/dishes/:dishId/"
          render={(props) => (
            <PageDish logoText={logoText} {...props} key={Date.now()} />
          )}
        />

        <Route
          exact
          path="/dishes/:dishId"
          render={(props) => (
            <PageDish logoText={logoText} {...props} key={Date.now()} />
          )}
        />
        <Route
          exact
          path="/cuisines/:id/:mode"
          render={(props) => (
            <PageCuisine logoText={logoText} {...props} key={Date.now()} />
          )}
        />
        <Route
          exact
          path="/cuisines/:id/"
          render={(props) => (
            <PageCuisine logoText={logoText} {...props} key={Date.now()} />
          )}
        />
        <Route
          exact
          path="/restaurants/:id/"
          render={(props) => <PageRestaurant {...props} key={Date.now()} />}
        />
        <Route
          exact
          path="/restaurants/:id/"
          render={(props) => <PageRestaurant {...props} key={Date.now()} />}
        />
        <Route
          exact
          path="/ingredients/:id/"
          render={(props) => (
            <PageIngredient logoText={logoText} {...props} key={Date.now()} />
          )}
        />
        <Route
          exact
          path="/ingredients/:id/"
          render={(props) => (
            <PageIngredient logoText={logoText} {...props} key={Date.now()} />
          )}
        />
        <Route
          exact
          path="/dashboard"
          render={(props) => <PageDashboard {...props} key={Date.now()} />}
        />
        <Route
          exact
          path="/invoices"
          render={(props) => <PageInvoices {...props} key={Date.now()} />}
        />

        <Route
          exact
          path={`/setup/:specified_step`}
          render={(props) =>
            isAuthenticated ? (
              <PageSetUp
                logoText={logoText}
                {...props}
                isPopUp={true}
                key={Math.random()}
              />
            ) : (
              <Redirect to="/" />
            )
          }
        />
        <Route
          exact
          path={`/profile/:edit`}
          render={(props) =>
            isAuthenticated ? (
              <PageProfile
                logoText={logoText}
                {...props}
                isPopUp={true}
                key={Math.random()}
              />
            ) : (
              <Redirect to="/" />
            )
          }
        />
        <Route
          exact
          path={`/paymentmethod`}
          render={(props) =>
            isAuthenticated ? (
              <PagePaymentMethod
                logoText={logoText}
                {...props}
                isPopUp={true}
                key={Math.random()}
              />
            ) : (
              <Redirect to="/" />
            )
          }
        />
        <Route
          exact
          path="/login"
          render={(props) => {
            return (
              <PageLogIn
                isDelegate={false}
                onAuth={authUser}
                heading="Welcome back!"
                {...props}
              />
            );
          }}
        />
        <Route
          exact
          path="/delegate"
          render={(props) => {
            return (
              <PageLogIn isDelegate={true} heading="Welcome back!" {...props} />
            );
          }}
        />
        <Route
          exact
          path="/terms"
          render={(props) => <PageTerms {...props} />}
        />
        <Route
          exact
          path="/privacy"
          render={(props) => <PagePrivacy {...props} />}
        />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    );
  }
}
function mapStateToProps(reduxState) {
  const { currentUser } = reduxState;
  LOGGING >= 1 && console.log("Root mapStateToProps:", reduxState);
  return {
    isAuthenticated: currentUser.isAuthenticated,
    role: currentUser.user.role,
  };
}

export default withRouter(
  connect(mapStateToProps, { authUser, reloadUser })(Root)
);
