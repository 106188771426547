import React, { Component } from "react"; // eslint-disable-line no-unused-vars
import { connect } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import FormRestaurant from "./FormRestaurant";
import {
  createRestaurant,
  updateRestaurant,
  deleteDish,
} from "../../store/actions";
import { GoogleApiWrapper } from "google-maps-react";
import { LoadingIcon, ConfirmAlert } from "../widgets";
import { Week, LOGGING } from "../../hocs/constants";

const newRestaurant = {
  name: "",
  address: "",
  location: {},
  phone: "",
  city: "",
  schedule: {
    monday: {
      open: { hour: 12, minute: 0 },
      close: { hour: 12, minute: 0 },
    },
    tuesday: {
      open: { hour: 12, minute: 0 },
      close: { hour: 12, minute: 0 },
    },
    wednesday: {
      open: { hour: 12, minute: 0 },
      close: { hour: 12, minute: 0 },
    },
    thursday: {
      open: { hour: 12, minute: 0 },
      close: { hour: 12, minute: 0 },
    },
    friday: {
      open: { hour: 12, minute: 0 },
      close: { hour: 12, minute: 0 },
    },
  },
  dishes: [],
  orderingMethod: -1,
  orderingLatency: -1,
  orderingMethodSpecify: "",
  website: "",
  appName: "",
  notes: "",
  showOptions: { orderingMethod: false, orderingLatency: false },
};
class PageRestaurant extends Component {
  constructor(props) {
    super();
    const { id } = props.match.params;
    const { restaurants } = props;
    const restaurant =
      id === "new" ? newRestaurant : restaurants.find((r) => r._id === id);

    this.state = {
      loading: false,
      restaurant,
      showOptions: {
        orderingMethod: false,
        orderingLatency: false,
      },
      showDishOptions: null,
    };

    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleSelectAddress = this.handleSelectAddress.bind(this);
    this.handleChangeAddress = this.handleChangeAddress.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.handleToggleSelectOptions = this.handleToggleSelectOptions.bind(this);
    this.handleChangeTime = this.handleChangeTime.bind(this);
    this.handleDeleteTime = this.handleDeleteTime.bind(this);
    this.handleAddTime = this.handleAddTime.bind(this);
    this.handleDeleteDish = this.handleDeleteDish.bind(this);
    this.handleToggleDish = this.handleToggleDish.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }
  handleToggleDish(value, e) {
    e.preventDefault();
    const { showDishOptions } = this.state;
    this.setState({
      showDishOptions: showDishOptions === value._id ? null : value._id,
    });
  }
  handleChangeInput(name, e) {
    e.preventDefault();
    const { restaurant } = this.state;
    this.setState({
      restaurant: { ...restaurant, [name]: e.target.value },
    });
  }

  handleChangeAddress(address) {
    const { restaurant } = this.state;
    this.setState({ restaurant: { ...restaurant, address } });
  }

  handleSelectAddress(address) {
    if (address) {
      const city = address.gmaps.address_components.find((c) =>
        c.types.includes("locality")
      ).short_name;
      const number = address.gmaps.address_components.find((c) =>
        c.types.includes("street_number")
      ).short_name;
      const street = address.gmaps.address_components.find((c) =>
        c.types.includes("route")
      ).short_name;
      const state = address.gmaps.address_components.find((c) =>
        c.types.includes("administrative_area_level_1")
      ).short_name;
      const zip = address.gmaps.address_components.find((c) =>
        c.types.includes("postal_code")
      ).short_name;

      const { restaurant } = this.state;
      const { location } = restaurant;
      this.setState({
        restaurant: {
          ...restaurant,
          address: `${number} ${street}, ${city}, ${state} ${zip}`,
          city,
          location,
        },
      });
    }
  }

  handleChangePhone = (values) => {
    const { restaurant } = this.state;
    this.setState({
      restaurant: { ...restaurant, phone: values.value },
    });
  };

  handleChangeSelect = (name, value, e) => {
    e.preventDefault();
    const { restaurant, showOptions } = this.state;
    this.setState({
      restaurant: { ...restaurant, [name]: value },
      showOptions: { ...showOptions, [name]: false },
    });
  };

  handleToggleSelectOptions = (name) => {
    const { showOptions } = this.state;
    this.setState({
      showOptions: {
        ...showOptions,
        [name]: !showOptions[name],
      },
    });
  };

  handleAddTime(dayOfWeek) {
    const { restaurant } = this.state;
    let { schedule } = restaurant;
    schedule[Week[dayOfWeek]] = {
      open: { hour: 12, minute: 0 },
      close: { hour: 12, minute: 0 },
    };
    this.setState({ restaurant: { ...restaurant, schedule } });
  }

  handleChangeTime = (dayOfWeek, e, value) => {
    const { restaurant } = this.state;
    let { schedule } = restaurant;
    const time = value.split(":");
    schedule[Week[dayOfWeek]][e.target.name] = {
      hour: Number(time[0]),
      minute: Number(time[1]),
    };
    this.setState({ restaurant: { ...restaurant, schedule } });
  };

  handleDeleteTime = (dayOfWeek) => {
    const { restaurant } = this.state;
    let { schedule } = restaurant;
    schedule[Week[dayOfWeek]] = {};
    this.setState({ restaurant: { ...restaurant, schedule } });
  };

  handleDeleteDish(dishId, e) {
    LOGGING >= 1 && console.log("handleDeleteDish called with:", dishId);
    e.preventDefault();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmAlert
            onConfirm={this.props.deleteDish.bind(this, dishId)}
            onClose={onClose}
            actionName="delete"
          />
        );
      },
    });
  }

  componentDidUpdate(prevProps) {
    const { restaurants } = this.props;
    const { id } = this.props.match.params;
    if (prevProps.restaurants !== restaurants) {
      LOGGING >= 1 && console.log("restaurants changed");
      this.setState({
        restaurant: restaurants.find((r) => r._id === id),
      });
    }
  }

  componentDidMount() {
    LOGGING >= 1 && console.log("PageRestaurant componentDidMount");
  }

  handleSave() {
    const { restaurant } = this.state;
    const { _id } = restaurant;
    this.setState({ loading: true });
    if (_id) {
      this.props.updateRestaurant(restaurant).then(() => {
        this.props.history.goBack();
      });
    } else {
      this.props.createRestaurant(restaurant).then(() => {
        this.props.history.goBack();
      });
    }
  }

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    const { restaurant, loading, showOptions, showDishOptions } = this.state;

    return (
      <div className="homepage entity">
        {loading ? (
          <LoadingIcon />
        ) : (
          <FormRestaurant
            restaurant={restaurant}
            showOptions={showOptions}
            showDishOptions={showDishOptions}
            onChangeInput={this.handleChangeInput}
            onChangeAddress={this.handleChangeAddress}
            onSelectAddress={this.handleSelectAddress}
            onChangePhone={this.handleChangePhone}
            onChangeSelect={this.handleChangeSelect}
            onToggleSelectOptions={this.handleToggleSelectOptions}
            onAddTime={this.handleAddTime}
            onChangeTime={this.handleChangeTime}
            onDeleteTime={this.handleDeleteTime}
            onDeleteDish={this.handleDeleteDish}
            onToggleDish={this.handleToggleDish}
            onSave={this.handleSave}
            onCancel={this.handleCancel}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  const { restaurants } = reduxState;
  LOGGING >= 1 &&
    console.log("PageRestaurant reduxState.restaurants:", restaurants);
  return {
    restaurants,
  };
}

export default connect(mapStateToProps, {
  createRestaurant,
  updateRestaurant,
  deleteDish,
})(
  GoogleApiWrapper({
    apiKey: "AIzaSyBjfTfgJdRah5wXvVj8U7fH4ACoqKry9HI",
  })(PageRestaurant)
);
