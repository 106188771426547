import React from "react";
import "./DishList.scss";
import { Link } from "react-router-dom";
import { Sticker, BubbleUpperRight, AddEntry } from "../widgets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { DataModeParentMode } from ".";

const RedirectNew = ({ entity, parent, parentRequired }) => (
  <AddEntry
    path={
      parentRequired
        ? `/${DataModeParentMode[entity]}/${
            parent.id || parent._id
          }/${entity}/new`
        : `/${entity}/new`
    }
    buttonText="create"
  />
);

const DishList = ({
  entries,
  entity,
  parent,
  onDeleteEntry,
  onToggleEntry,
  parentRequired,
  icon,
  showOptions,
}) => (
  <React.Fragment>
    {entries.length > 0
      ? entries.map((entry) => (
          <button key={entry._id} onClick={onToggleEntry.bind(this, entry)}>
            <Sticker
              icon={<FontAwesomeIcon icon={icon} />}
              content={
                <React.Fragment>
                  <span className="name">{entry.name}</span>
                  <span className="price">${entry.price.toFixed(2)}</span>
                </React.Fragment>
              }
              className="list-entry"
            />
            {showOptions === entry._id ? (
              <BubbleUpperRight
                content={
                  <React.Fragment>
                    <li key="edit">
                      <Link to={`/${entity}/${entry._id}`}>
                        <span>edit</span>
                        <FontAwesomeIcon icon={faPen} />
                      </Link>
                    </li>
                    <li
                      key="delete"
                      onClick={onDeleteEntry.bind(this, entry._id)}
                      className="delete-dish"
                    >
                      <span>delete</span>
                      <FontAwesomeIcon icon={faTrash} />
                    </li>
                  </React.Fragment>
                }
                className="entry-actions"
                onHide={onToggleEntry.bind(this, entry)}
              />
            ) : null}
          </button>
        ))
      : null}
    <RedirectNew
      entity={entity}
      parent={parent}
      parentRequired={parentRequired}
    />
  </React.Fragment>
);

export default DishList;
