import React, { Component } from "react";
import { connect } from "react-redux";
import { FormEaters, FormDiets, FormSchedule, FormFavorites } from ".";
import { updateCompany, fetchCuisines } from "../../store/actions";
import { LoadingIcon, ProfileHeader, ProfileStepText } from "../widgets";

import { LOGGING } from "../../hocs/constants";
class PageProfile extends Component {
  constructor(props) {
    super();
    const { company, phone } = props.currentUser.user;
    const { eaters } = company;
    this.state = {
      // 1. controlling states

      // 1.1 loading:
      // true when waiting for backend async calls to return
      // shows a loading icon
      loading: false,
      offsetY: 0,

      // 2. data states

      company: {
        ...company,

        // eaters:
        // sorting so that the current user sees himself
        // on top of the company's eater list.
        eaters: eaters
          .sort((a, _) => (a.phone === phone ? -1 : 1))
          .map((eater) => ({
            ...eater,
            name: eater.name || `${eater.firstName} ${eater.lastName}`,
          })),
      },

      // 3. rendering states

      // 3.1 contentOverflow:
      // true turns on the top border shadow for step-form-footer
      contentOverflow: false,

      // 3.2 contentScrolled:
      // true turns on the bottom border shadow for step-header
      contentScrolled: false,

      error: null,
      goto: -1,
    };

    // handleSave():
    // go to next step and save if there's updates
    this.handleSave = this.handleSave.bind(this);

    // handleClose()
    this.handleClose = this.handleClose.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleGoTo = this.handleGoTo.bind(this);
    this.handleCancelGoTo = this.handleCancelGoTo.bind(this);

    // updateShadow():
    // updates states contentOverflow and contentOverflow,
    // which decide the shadow of header and footer.
    //
    // It is
    // a. called by child components when their height changes due to component changes,
    // b. called by componentDidMount at initialization
    // c. listening to window scrolls in general.

    this.updateShadow = this.updateShadow.bind(this);

    // ref for styling purpose
    this.contentRef = React.createRef();
  }

  handleScroll() {
    this.setState({ offsetY: window.pageYOffset });
  }

  handleGoTo(index, e) {
    e.preventDefault();
    this.setState({ goto: index });
  }

  handleCancelGoTo(e) {
    e.preventDefault();
    this.setState({ goto: -1 });
  }

  // handleSave():
  // go to next step and save if there's updates
  // called by individual child containers/forms
  handleSave(data, goto) {
    if (data) {
      const { edit } = this.props.match.params;
      // need company to re-compose state data with new data
      const { company } = this.props.currentUser.user;

      // need _id to direct api call
      const { _id } = company;

      LOGGING >= 1 && console.log("handleSave called with: ", { data, goto });

      this.setState({ loading: true }, () => {
        LOGGING >= 1 &&
          console.log("calling updateCompany with:", { data, edit });
        this.props
          .updateCompany({
            id: _id,
            data,
            edit,
          })
          .then((res) => {
            LOGGING >= 1 &&
              console.log(" updateCompany got:", {
                res,
                goto: ProfileStepText[goto - 1],
              });
            if (goto > 0) {
              this.props.history.push(`/profile/${ProfileStepText[goto - 1]}`);
            } else {
              this.props.history.push("/");
            }
          })
          .catch((err) => {
            LOGGING >= 1 &&
              console.log("updateCompany returned with error:", err);
          });
      });
    } else {
      if (goto > 0) {
        this.props.history.push(`/profile/${ProfileStepText[goto - 1]}`);
      } else {
        this.props.history.push("/");
      }
    }
  }

  handleClose = () => {
    this.props.history.push("/");
  };
  // updateShadow():
  // updates contentOverflow and contentOverflow
  // a. called by child components when their height changes due to component changes,
  // b. called by componentDidMount at initialization
  // c. listening to window scrolls in general.

  updateShadow() {
    const windowHeight = window.innerHeight;
    let contentOverflow = false;
    let contentScrolled = false;

    if (this.contentRef.current) {
      const { top, bottom } = this.contentRef.current.getBoundingClientRect();
      contentOverflow = bottom > windowHeight;
      contentScrolled = top < 0;
    }

    this.setState({ contentOverflow, contentScrolled });
  }

  // hooks
  componentDidMount() {
    window.addEventListener("scroll", this.updateShadow);
    this.updateShadow();

    if (this.props.cuisines.length === 0) {
      this.setState({ loading: true });
      this.props.fetchCuisines().then(() => {
        this.setState({ loading: false });
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.updateShadow);
  }

  render() {
    const {
      //control
      loading,
      goto,

      //data
      company,

      //styling
      contentOverflow,
    } = this.state;

    const { edit } = this.props.match.params;

    LOGGING >= 1 &&
      console.log("PageProfile rendering with:", {
        state: this.state,
        props: this.props,
        goto,
      });

    const stepHeader = (
      <ProfileHeader
        edit={edit}
        onClose={this.handleClose}
        onGoTo={this.handleGoTo}
      />
    );
    const form =
      edit === "eaters" ? (
        <FormEaters
          //data
          eaters={company.eaters}
          mainContact={company.mainContact}
          sharedDiet={company.sharedDiet}
          //navigation
          goto={goto}
          onCancelGoTo={this.handleCancelGoTo}
          onSave={this.handleSave}
          //dynamic styling
          contentOverflow={contentOverflow}
          updateShadow={this.updateShadow}
          contentRef={this.contentRef}
        />
      ) : edit === "diets" ? (
        <FormDiets
          //data
          mainContact={company.mainContact}
          sharedDiet={company.sharedDiet}
          eaters={company.eaters}
          companyDiet={company.diet}
          cuisineCatelog={this.props.cuisines}
          //navigation
          goto={goto}
          onCancelGoTo={this.handleCancelGoTo}
          onSave={this.handleSave}
          //dynamic styling
          contentOverflow={contentOverflow}
          updateShadow={this.updateShadow}
          contentRef={this.contentRef}
        />
      ) : edit === "favorites" ? (
        <FormFavorites
          //data
          favorites={company.favorites}
          //navigation
          onSave={this.handleSave}
          goto={goto}
          onCancelGoTo={this.handleCancelGoTo}
          //dynamic styling
          contentOverflow={contentOverflow}
          updateShadow={this.updateShadow}
          contentRef={this.contentRef}
        />
      ) : edit === "schedule" ? (
        <FormSchedule
          //data
          mainContact={company.mainContact}
          address={company.address}
          deliveryInstructions={company.deliveryInstructions}
          eaters={company.eaters}
          //navigation
          goto={goto}
          onCancelGoTo={this.handleCancelGoTo}
          onSave={this.handleSave}
          //dynamic styling
          contentOverflow={contentOverflow}
          updateShadow={this.updateShadow}
          contentRef={this.contentRef}
        />
      ) : null;

    return (
      <div className="homepage profile">
        {stepHeader}
        {loading ? <LoadingIcon /> : null}
        {form}
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  return {
    currentUser: reduxState.currentUser,
    cuisines: reduxState.cuisines,
  };
}

export default connect(mapStateToProps, {
  updateCompany,
  fetchCuisines,
})(PageProfile);
