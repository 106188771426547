import React, { Component } from "react";
import { connect } from "react-redux";
import {
  HeroPage,
  HowItWorks,
  ServiceDetails,
  FAQ,
  Contacts,
  CTA,
} from "./UserAnonymous";
import { VerificationCode } from "../auth";
import { LOGGING, signUpActionName } from "../../hocs/constants";
import { Navbar, Footer } from "./";
import { PopUp, LoadingIcon } from "../widgets";
import {
  verifyContact,
  authUserWithPhoneOnly,
  removeErrors,
} from "../../store/actions";

class PageUserAnonymous extends Component {
  constructor(props) {
    super();
    LOGGING >= 1 && console.log("constructor called with props:", props);
    this.state = {
      phoneInput: ["", "", ""],
      phoneNumber: "",
      verifyContactCode: "",
      verifyContactCodeTyped: "",
      verifyContactStatus: true,
      alertMessage: null,
      isComponentSwitch: true,
      isGetStartedState: true,
      offsetY: 0,
      loading: false,
      currentSection: -1,
    };

    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleGetStarted = this.handleGetStarted.bind(this);
    this.handleHideVerifyContact = this.handleHideVerifyContact.bind(this);
    this.handleSubmitVerifyContactAndSignUp = this.handleSubmitVerifyContactAndSignUp.bind(
      this
    );
    this.handleResendVerifyContact = this.handleResendVerifyContact.bind(this);
    this.handleChangeVerifyContact = this.handleChangeVerifyContact.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleGoToSection = this.handleGoToSection.bind(this);
    this.sectionRefs = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
    ];
  }

  handleScroll() {
    LOGGING >= 3 && console.log("window.pageYOffset:", window.pageYOffset);
    LOGGING >= 3 &&
      console.log(
        "this.sectionRefs offsetTops: ",
        this.sectionRefs.map((ref) => ref.current.offsetTop)
      );
    let currentSection = 0;
    if (
      window.pageYOffset <
      (this.sectionRefs[0].current.offsetTop +
        this.sectionRefs[1].current.offsetTop) /
        2
    ) {
      currentSection = 0;
    } else if (
      window.pageYOffset <
      (this.sectionRefs[1].current.offsetTop +
        this.sectionRefs[2].current.offsetTop) /
        2
    ) {
      currentSection = 1;
    } else if (
      window.pageYOffset <
      (this.sectionRefs[2].current.offsetTop +
        this.sectionRefs[3].current.offsetTop) /
        2
    ) {
      currentSection = 2;
    } else if (
      window.pageYOffset <
      (this.sectionRefs[3].current.offsetTop +
        this.sectionRefs[4].current.offsetTop) /
        2
    ) {
      currentSection = 3;
    } else {
      currentSection = 4;
    }
    this.setState({ offsetY: window.pageYOffset, currentSection });
  }

  handleGoToSection(ref, e) {
    e.preventDefault();
    window.scrollTo({
      top: ref.current.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  }

  handleResendVerifyContact = () => {
    LOGGING >= 1 &&
      console.log("handleResendVerifyContact called with state!", this.state);
    const { phoneNumber } = this.state;
    this.setState(
      {
        loading: true,
        verifyContactStatus: true,
        verifyContactCodeTyped: "",
      },
      function () {
        this.props.verifyContact({ phone: phoneNumber }).then(({ token }) => {
          LOGGING >= 1 && console.log("verifyContact returned: ", token);
          this.setState({
            showVerifyContact: true,
            verifyContactCode: token,
            loading: false,
          });
        });
      }
    );
  };

  handleGetStarted(phoneNumber) {
    this.setState({ loading: true, verifyContactCodeTyped: "" }, function () {
      this.props
        .verifyContact({ phone: phoneNumber, isNewUser: true })
        .then(({ token }) => {
          LOGGING >= 1 && console.log("verifyContact returned: ", token);
          this.setState({
            showVerifyContact: true,
            verifyContactCode: token,
            loading: false,
          });
        })
        .catch((err) => {
          LOGGING >= 1 && console.log("verifyContact err: ", err);
          this.setState({ loading: false });
        });
    });
  }

  handleShowDisclaimers({ whichClaimer }) {
    this.setState({ showDisclaimers: true, whichClaimer });
  }

  handlePhoneChange(whichForm, fieldName, values) {
    //fieldname to conform
    this.props.removeErrors();
    const { phoneInput: phoneInputOld } = this.state;
    const number = values.value;
    let phoneInput = ["", "", ""];
    phoneInput[whichForm] = number;
    if (
      phoneInputOld.some(
        (phone, index) => phone.length > 0 && index !== whichForm
      )
    ) {
      this.setState({ phoneInput: ["", "", ""] }, () => {
        this.setState({ phoneInput, phoneNumber: number });
      });
    } else {
      this.setState({ phoneInput, phoneNumber: number });
    }
  }

  handleSubmitVerifyContactAndSignUp = (e) => {
    e.preventDefault();
    const {
      verifyContactCode,
      verifyContactCodeTyped,
      phoneNumber,
    } = this.state;

    if (verifyContactCodeTyped === verifyContactCode) {
      LOGGING >= 1 &&
        console.log("handleSubmitVerifyContactAndSignUp got:", phoneNumber);
      this.props
        .authUserWithPhoneOnly({ phone: phoneNumber })
        .then((res) => {
          LOGGING >= 1 && console.log("authUserWithPhoneOnly got res: ", res);
          this.setState({
            showSuccess: true,
            showLoadingPage: false,
            verifyContactStatus: true,
            showVerifyContact: false,
          });
          this.props.history.push("/");
        })
        .catch((error) => {
          LOGGING >= 1 &&
            console.log("authUserWithPhoneOnly got error: ", error);
          this.setState({
            alertMessage: error.message,
            showVerifyContact: false,
          });
        });
    } else {
      this.setState({ verifyContactStatus: false, showVerifyContact: true });
    }
  };

  handleChangeVerifyContact = (e) => {
    if (e.target.value.length <= 6) {
      this.setState({ verifyContactCodeTyped: e.target.value });
    }
  };

  handleResendVerifyContact = () => {
    LOGGING && console.log("handleResendVerifyContact called!");
    const { phoneNumber } = this.state;
    this.setState({ loading: true, verifyContactStatus: true }, function () {
      this.props
        .verifyContact({ phone: phoneNumber, isNewUser: true })
        .then(({ token }) => {
          LOGGING && console.log("verifyContact returned: ", token);
          this.setState({
            showVerifyContact: true,
            verifyContactCode: token,
            loading: false,
          });
        })
        .catch((error) => {
          LOGGING && console.log("handleResendVerifyContact got error:", error);
        });
    });
  };

  handleHideVerifyContact = () => {
    this.setState({ showVerifyContact: false });
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.props.removeErrors();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    const {
      verifyContactStatus,
      verifyContactCodeTyped,
      showVerifyContact,
      phoneInput,
      offsetY,
      loading,
      currentSection,
    } = this.state;
    const { errors } = this.props;
    LOGGING >= 1 &&
      console.log("PageLandingAnonymous got", {
        props: this.props,
        state: this.state,
      });

    const verifyContactForm = (
      <VerificationCode
        onSubmit={this.handleSubmitVerifyContactAndSignUp}
        onChange={this.handleChangeVerifyContact}
        onResend={this.handleResendVerifyContact}
        verificationStatus={verifyContactStatus}
        verificationCodeTyped={verifyContactCodeTyped}
      />
    );

    const verificationForm = (
      <PopUp
        isPoppedUp={showVerifyContact}
        componentToDisplay={verifyContactForm}
        hidePopUp={this.handleHideVerifyContact}
      />
    );

    return (
      <div className="homepage landing-anonymous">
        <Navbar
          scrolled={offsetY > 0}
          {...this.props}
          sectionNames={["how it works", "service details", "FAQ", "contacts"]}
          sectionRefs={this.sectionRefs}
          onGoToSection={this.handleGoToSection}
          homeRef={this.sectionRefs[0]}
          currentSection={currentSection}
        />
        {loading ? <LoadingIcon /> : null}
        {verificationForm}
        <div className="section" ref={this.sectionRefs[0]}>
          <HeroPage
            phoneNumber={phoneInput[0]}
            onPhoneChange={this.handlePhoneChange.bind(this, 0)}
            onBook={this.handleGetStarted.bind(this, phoneInput[0])}
            errors={errors}
            actionName={signUpActionName}
          />
        </div>
        <div className="section" ref={this.sectionRefs[1]}>
          <HowItWorks />
        </div>
        <div className="section" ref={this.sectionRefs[2]}>
          <ServiceDetails
            phoneNumber={phoneInput[1]}
            onPhoneChange={this.handlePhoneChange.bind(this, 1)}
            onBook={this.handleGetStarted.bind(this, phoneInput[1])}
            errors={errors}
            actionName={signUpActionName}
          />
        </div>
        <div className="section" ref={this.sectionRefs[3]}>
          <FAQ />
          <CTA
            phoneNumber={phoneInput[2]}
            onPhoneChange={this.handlePhoneChange.bind(this, 2)}
            onBook={this.handleGetStarted.bind(this, phoneInput[2])}
            errors={errors}
            actionName={signUpActionName}
          />
        </div>
        <div className="section" ref={this.sectionRefs[4]}>
          <Contacts />
        </div>
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    errors: state.errors,
  };
}

export default connect(mapStateToProps, {
  authUserWithPhoneOnly,
  verifyContact,
  removeErrors,
})(PageUserAnonymous);
