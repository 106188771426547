import React, { Component } from "react"; // eslint-disable-line no-unused-vars
import { connect } from "react-redux";
import DashboardContent from "./DashboardContent";
import DashboardNav from "./DashboardNav";

import {
  fetchCompanies,
  deleteCompany,
  updateCompany,
  fetchUsers,
  deleteUser,
  updateUser,
  fetchRestaurants,
  deleteRestaurant,
  updateRestaurant,
  fetchDishes,
  deleteDish,
  updateDish,
  fetchCuisines,
  deleteCuisine,
  updateCuisine,
  fetchIngredients,
  deleteIngredient,
  updateIngredient,
  setDataModeSortBy,
  setDataModeCategoryBy,
  setDataModeSelected,
  setDataModeLoaded,
} from "../../store/actions";
import { LOGGING } from "../../hocs/constants";
import { DataModeTypes, DataModeParentKey, DataModeChildrenKey } from "./";

class PageDashboard extends Component {
  constructor(props) {
    super();

    this.state = {
      loading: false,
      loaded: Object.values(DataModeTypes).map((type) => false),
      mapStatus: false,
      activeOnly: true, //true: active only, false: show all
      numberEntriesByMode: {},
    };

    this.fetchByMode = {
      companies: props.fetchCompanies,
      users: props.fetchUsers,
      restaurants: props.fetchRestaurants,
      dishes: props.fetchDishes,
      ingredients: props.fetchIngredients,
      cuisines: props.fetchCuisines,
    };

    this.deleteByMode = {
      companies: props.deleteCompany,
      users: props.deleteUser,
      restaurants: props.deleteRestaurant,
      dishes: props.deleteDish,
      ingredients: props.deleteIngredient,
      cuisines: props.deleteCuisine,
    };

    this.updateByMode = {
      companies: props.updateCompany,
      users: props.updateUser,
      restaurants: props.updateRestaurant,
      dishes: props.updateDish,
      ingredients: props.updateIngredient,
      cuisines: props.updateCuisines,
    };

    this.handleChangeDataModeSelected = this.handleChangeDataModeSelected.bind(
      this
    );
    this.handleChangeSortBy = this.handleChangeSortBy.bind(this);
    this.handleChangeCategoryBy = this.handleChangeCategoryBy.bind(this);
    this.handleLoadData = this.handleLoadData.bind(this);
    this.handleCloseDashboard = this.handleCloseDashboard.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleClosePopUp = this.handleClosePopUp.bind(this);
    this.handleToggleMap = this.handleToggleMap.bind(this);
    this.handleToggleActive = this.handleToggleActive.bind(this);
    this.handleReCalcEntityNumbers = this.handleReCalcEntityNumbers.bind(this);
  }

  handleReCalcEntityNumbers() {
    LOGGING >= 1 && console.log("handleReCalcEntityNumbers called");
    const { activeOnly } = this.state;
    let numberEntriesByMode = {};
    for (let key in DataModeTypes) {
      const mode = DataModeTypes[key];
      LOGGING >= 3 && console.log("mode:", mode);
      let entriesToShow = this.props[mode];
      LOGGING >= 3 && console.log("entriesToShow:", entriesToShow);
      if (activeOnly) {
        entriesToShow = entriesToShow.filter(
          (entry) => entry.archived === false
        );
        LOGGING >= 1 &&
          console.log("entriesToShow after filter:", entriesToShow);
      }
      numberEntriesByMode[mode] = entriesToShow.length;
    }

    this.setState({ numberEntriesByMode });
  }

  handleToggleMap() {
    LOGGING >= 1 && console.log("handleToggleMap called");
    const { mapStatus } = this.state;
    this.setState({ mapStatus: !mapStatus });
  }

  handleToggleActive() {
    LOGGING >= 1 && console.log("handleToggleActive called");
    const { activeOnly } = this.state;
    let numberEntriesByMode = {};
    for (let key in DataModeTypes) {
      const mode = DataModeTypes[key];
      LOGGING >= 3 && console.log("mode:", mode);
      let entriesToShow = this.props[mode];
      LOGGING >= 1 && console.log("entriesToShow:", entriesToShow);
      if (!activeOnly) {
        entriesToShow = entriesToShow.filter(
          (entry) => entry.archived === false
        );
        LOGGING >= 1 &&
          console.log("entriesToShow after filter:", entriesToShow);
      }
      numberEntriesByMode[mode] = entriesToShow.length;
    }

    this.setState({ activeOnly: !activeOnly, numberEntriesByMode });
  }

  handleDelete({ mode, id }) {
    LOGGING >= 1 &&
      console.log("PageDashboard handleDelete called with:", { mode, id });
    this.deleteByMode[mode](id);
    // this.props.history.goBack();
  }
  handleUpdate({ mode, id, data }) {
    LOGGING >= 1 &&
      console.log("PageDashboard handleUpdate called with:", {
        mode,
        id,
        data,
      });
    this.updateByMode[mode]({ id, data });

    // this.props.history.goBack();
  }

  handleCloseDashboard() {
    LOGGING >= 1 && console.log("PageDashboard handleCloseDashboard clicked");
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    } else {
      this.props.history.goBack();
    }
  }

  handleClosePopUp() {
    LOGGING >= 1 && console.log("PageDashboard handleClosePopUp clicked");
    // this.props.history.goBack();
  }

  handleLoadData({ mode, forceLoad }) {
    LOGGING >= 1 && console.log("handleLoadData with:", { mode, forceLoad });
    const data = this.props[mode];
    LOGGING >= 1 && console.log("handleLoadData with data:", data);
    const { loaded } = this.props.dataMode;
    if (forceLoad || !data || !loaded[mode]) {
      this.setState({ loading: true });
      this.fetchByMode[mode].call(this).then((res) => {
        LOGGING >= 1 && console.log("fetchByMode[mode] got res:", res);
        this.props.setDataModeLoaded({ dataType: mode, loadedStatus: true });
        this.setState({ loading: false, loaded: { ...loaded, [mode]: true } });
      });
    }
    this.handleReCalcEntityNumbers();
  }

  handleChangeDataModeSelected(selected) {
    LOGGING >= 1 &&
      console.log("handleChangeDataMode called with dataMode", selected);
    this.props.setDataModeSelected({ selected });
    this.handleLoadData({ mode: selected, forceLoad: false });
    if (selected === DataModeTypes.RESTAURANTS) {
      this.handleLoadData({ mode: "dishes", forceLoad: false });
    }
    if (selected !== DataModeTypes.RESTAURANTS) {
      this.setState({ mapStatus: false });
    }
  }

  handleChangeSortBy(e) {
    LOGGING >= 1 &&
      console.log(
        "handleChangeSortBy called with e.target.name",
        e.target.name
      );
    LOGGING >= 1 &&
      console.log(
        "handleChangeSortBy called with e.target.value",
        e.target.value
      );
    const { dataMode } = this.props;
    const { selected } = dataMode;
    LOGGING >= 1 &&
      console.log("handleChangeSortBy called with dataMode:", dataMode);
    LOGGING >= 1 &&
      console.log("handleChangeSortBy called with selected:", selected);
    this.props.setDataModeSortBy({
      dataType: selected,
      sortByKey: e.target.value,
    });
  }

  handleChangeCategoryBy(e) {
    LOGGING >= 1 &&
      console.log(
        "handleChangeCategoryBy called with e.target.name",
        e.target.name
      );
    LOGGING >= 1 &&
      console.log(
        "handleChangeCategoryBy called with e.target.value",
        e.target.value
      );
    const { dataMode } = this.props;
    const { selected } = dataMode;
    LOGGING >= 1 &&
      console.log("handleChangeCategoryBy called with dataMode:", dataMode);
    LOGGING >= 1 &&
      console.log("handleChangeCategoryBy called with selected:", selected);
    this.props.setDataModeCategoryBy({
      dataType: selected,
      categoryByKey: e.target.value,
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.handleReCalcEntityNumbers();
    this.props.fetchCompanies().then(() => {
      this.props.fetchUsers().then(() => {
        this.props.fetchRestaurants().then(() => {
          this.props.fetchDishes().then(() => {
            this.props.fetchCuisines().then(() => {
              this.props.fetchIngredients().then(() => {
                LOGGING >= 1 && console.log("all fetching done");
              });
            });
          });
        });
      });
    });
  }

  render() {
    const { dataMode, onSelectDish, currentUser } = this.props;
    let { loading, mapStatus, activeOnly, numberEntriesByMode } = this.state;

    LOGGING >= 1 &&
      console.log("PageDashboard rendering with:", {
        props: this.props,
        state: this.state,
      });
    return (
      <div className="dashboard">
        <DashboardNav
          mode={dataMode.selected}
          sortBy={dataMode.sortBy[dataMode.selected]}
          categoryBy={dataMode.categoryBy[dataMode.selected]}
          onChangeMode={this.handleChangeDataModeSelected}
          onChangeSortBy={this.handleChangeSortBy}
          onChangeCategoryBy={this.handleChangeCategoryBy}
          onClose={this.handleCloseDashboard}
          onDataReload={this.handleLoadData}
          onToggleMap={this.handleToggleMap}
          onToggleActive={this.handleToggleActive}
          mapStatus={mapStatus}
          activeOnly={activeOnly}
          numberEntriesByMode={numberEntriesByMode}
        />
        <DashboardContent
          type={dataMode.selected}
          data={this.props[dataMode.selected]}
          sortKey={dataMode.sortBy[dataMode.selected]}
          categoryKey={dataMode.categoryBy[dataMode.selected]}
          parentKey={DataModeParentKey[dataMode.selected]}
          childrenKey={DataModeChildrenKey[dataMode.selected]}
          loading={loading}
          opsPhone={currentUser.user.phone}
          onUpdate={this.handleUpdate}
          onDelete={this.handleDelete}
          onClose={this.handleClosePopUp}
          onSelectDish={onSelectDish}
          // unused
          activeOnly={activeOnly}
          mapStatus={mapStatus}
        />
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  LOGGING >= 1 &&
    console.log("PageDashboard mapStateToProps with reduxState:", reduxState);
  return {
    currentUser: reduxState.currentUser,
    dataMode: reduxState.dataMode,
    companies: reduxState.companies,
    users: reduxState.users,
    restaurants: reduxState.restaurants,
    dishes: reduxState.dishes,
    ingredients: reduxState.ingredients,
    cuisines: reduxState.cuisines,
  };
}

export default connect(mapStateToProps, {
  setDataModeSortBy,
  setDataModeCategoryBy,
  setDataModeSelected,
  setDataModeLoaded,
  updateCompany,
  updateUser,
  updateRestaurant,
  updateDish,
  updateCuisine,
  updateIngredient,
  deleteCompany,
  deleteUser,
  deleteRestaurant,
  deleteDish,
  deleteCuisine,
  deleteIngredient,
  fetchCompanies,
  fetchUsers,
  fetchRestaurants,
  fetchDishes,
  fetchCuisines,
  fetchIngredients,
})(PageDashboard);
