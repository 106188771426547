import React, { Component } from "react"; // eslint-disable-line no-unused-vars
import "./index.scss";
import { connect } from "react-redux";
import FormCuisine from "./FormCuisine";
import { createCuisine, updateCuisine } from "../../store/actions";
import { LoadingIcon } from "../widgets";

class PageCuisine extends Component {
  constructor(props) {
    super(props);
    const { id } = props.match.params;
    const { cuisines } = props;
    const cuisine =
      id === "new"
        ? { name: "", type: null }
        : cuisines.find((i) => i._id === id);
    this.state = {
      loading: false,
      cuisine,
    };
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleChangeName(e) {
    e.preventDefault();
    const { cuisine } = this.state;
    this.setState({ cuisine: { ...cuisine, name: e.target.value } });
  }

  handleSave() {
    const { cuisine } = this.state;
    const { _id, name } = cuisine;
    this.setState({ loading: true });
    if (_id) {
      this.props.updateCuisine(_id, { name }).then(() => {
        this.setState({ loading: false });
        this.props.history.goBack();
      });
    } else {
      this.props.createCuisine({ name }).then(() => {
        this.setState({ loading: false });
        this.props.history.goBack();
      });
    }
  }

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    const { cuisine, loading } = this.state;

    return (
      <div className="homepage entity">
        {loading ? (
          <LoadingIcon />
        ) : (
          <FormCuisine
            cuisine={cuisine}
            onChangeName={this.handleChangeName}
            onSave={this.handleSave}
            onCancel={this.handleCancel}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  return {
    cuisines: reduxState.cuisines,
  };
}

export default connect(mapStateToProps, {
  createCuisine,
  updateCuisine,
})(PageCuisine);
