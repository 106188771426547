import React from "react";
import "./EaterName.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
const EaterName = ({ name, isMainContact }) => (
  <div className="eater-name">
    <FontAwesomeIcon icon={faUser} />
    <span className="eater-name-text">
      {isMainContact ? <FontAwesomeIcon icon={faStar} /> : null}
      {name}
    </span>
  </div>
);

export default EaterName;
