import { apiCall } from "../api";
import {
  convertInstanceServerToClient,
  convertDeliveryServerToClient,
} from "./";
import {
  LOAD_INSTANCES,
  LOAD_INSTANCES_ON_DATE,
  LOAD_DELEGATED_USER,
  RESET_INSTANCES,
} from "../actionTypes";
import { loadDeliveries } from "./deliveries";
import { addError } from "./errors";
import { InstanceStatus, LOGGING } from "../../hocs/constants";

export const loadInstancesOnDate = (instances, year, month, date) => ({
  type: LOAD_INSTANCES_ON_DATE,
  instances,
  year,
  month,
  date,
});

export const loadInstances = ({ instances }) => ({
  type: LOAD_INSTANCES,
  instances,
});
export const resetInstances = () => ({
  type: RESET_INSTANCES,
});

export const resetDelegatedUser = () => {
  return (dispatch, getState) => {
    dispatch(
      loadDelegatedUser({
        deliveryId: undefined,
        participantId: undefined,
        deliveryInfo: undefined,
        participantInfo: undefined,
      })
    );
  };
};
export const loadDelegatedUser = ({
  deliveryId,
  participantId,
  deliveryInfo,
  participantInfo,
}) => ({
  type: LOAD_DELEGATED_USER,
  deliveryId,
  participantId,
  deliveryInfo,
  participantInfo,
});

export const fetchInstances = (props) => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    const { beginUTC, endUTC } = props;
    const fetchLink = `/users/${currentUser.user._id}/instances/window/${beginUTC}/${endUTC}`;

    return apiCall("GET", fetchLink)
      .then((result) => {
        LOGGING >= 1 &&
          console.log("fetchInstances got from server result:", result);
        const instances = result.map((instance) =>
          convertInstanceServerToClient(instance)
        );
        dispatch(loadInstances({ instances }));
      })
      .catch((err) => {
        LOGGING >= 1 && console.log("get got an error: ", err);
        dispatch(addError(err.message));
      });
  };
};

export const changeInstances = (instances) => {
  LOGGING >= 1 && console.log("changeInstances called with:", instances);
  return (dispatch, getState) => {
    const { currentUser } = getState();
    return apiCall("POST", `/users/${currentUser.user._id}/instances/batch`, {
      instances,
    })
      .then((result) => {
        LOGGING >= 1 && console.log("changeInstances result:", result);
        const { instances, updatedDeliveries, createdDeliveries } = result;
        const updatedInstances = instances.map((instance) =>
          convertInstanceServerToClient(instance)
        );
        LOGGING >= 1 && console.log("updatedInstances:", updatedInstances);

        let { instances: instancesOld } = getState();
        LOGGING >= 1 && console.log("instances before update:", instances);

        updatedInstances.forEach((newInstance) => {
          LOGGING >= 1 &&
            console.log("looping through newInstance:", newInstance);
          const instanceIndex = instancesOld.findIndex(
            (oldInstance) =>
              oldInstance.byTime === newInstance.byTime &&
              oldInstance.userId === newInstance.userId
          );
          LOGGING >= 1 &&
            console.log("instanceIndex in old instances:", instanceIndex);
          if (instanceIndex >= 0) {
            instancesOld[instanceIndex] = { ...newInstance };
          } else {
            instancesOld.push({ ...newInstance });
          }
        });
        LOGGING >= 1 && console.log("instances after update:", instancesOld);

        dispatch(loadInstances({ instances: instancesOld }));

        let { deliveries } = getState();
        LOGGING >= 1 &&
          console.log("updateDelivery got deliveries from state:", deliveries);
        if (updatedDeliveries !== undefined) {
          deliveries = deliveries.map((deliveryOld) => {
            const updatedDelivery = updatedDeliveries.find(
              (deliveryNew) => deliveryNew._id === deliveryOld._id
            );
            if (updatedDelivery) {
              return convertDeliveryServerToClient(updatedDelivery);
            } else {
              return deliveryOld;
            }
          });
        }
        if (createdDeliveries !== undefined) {
          deliveries = deliveries.concat(
            createdDeliveries.map((deliveryNew) =>
              convertDeliveryServerToClient(deliveryNew)
            )
          );
        }

        dispatch(loadDeliveries({ deliveries }));
        return updatedInstances;
      })
      .catch((err) => {
        LOGGING >= 1 && console.log("err:", err);
        dispatch(addError(err.message));
      });
  };
};

// instancesWithGoods is an Object with (key, value) = (instanceId, goods)
export const changeInstanceGoods = (userId, instancesWithGoods) => {
  LOGGING >= 1 &&
    console.log(`changeInstanceGoods called with userId ${userId}`);
  LOGGING >= 1 &&
    console.log(
      "changeInstanceGoods called with instanceGoods:",
      instancesWithGoods
    );

  return (dispatch, getState) => {
    LOGGING >= 1 && console.log("dispatching  put");

    return apiCall("PUT", `/users/${userId}/instances`, { instancesWithGoods })
      .then((updatedGoods) => {
        LOGGING >= 1 &&
          console.log("updated instance with goods res:", updatedGoods);
        const { instances } = getState();
        var updatedInstances = {};

        for (let date in instances) {
          const oldInstancesOnDate = instances[date];
          const updatedInstancesOnDate = oldInstancesOnDate.map((instance) =>
            instance._id in updatedGoods
              ? {
                  ...instance,
                  goods: updatedGoods[instance._id].goods,
                  seller: updatedGoods[instance._id].seller,
                  price: updatedGoods[instance._id].price,
                  dish: updatedGoods[instance._id].dish,
                  order: updatedGoods[instance._id].order,
                }
              : { ...instance }
          );

          LOGGING >= 1 &&
            console.log(
              "goods updated updatedInstancesOnDate:",
              updatedInstancesOnDate
            );
          updatedInstances[date] = [...updatedInstancesOnDate];
        }

        LOGGING >= 1 &&
          console.log("goods updated upDatedInstances:", updatedInstances);
        dispatch(loadInstances({ instances: updatedInstances }));
        return updatedInstances;
      })
      .catch((err) => {
        LOGGING >= 1 && console.log("got error:", err);
        dispatch(addError(err.message));
      });
  };
};

export const markInstancesOrdered = (userId, orderedInstances) => {
  LOGGING >= 1 &&
    console.log(`markInstancesOrdered called with userId ${userId}`);
  LOGGING >= 1 &&
    console.log(
      "markInstancesOrdered called with orderedInstances:",
      orderedInstances
    );

  return (dispatch, getState) => {
    LOGGING >= 1 && console.log("dispatching  put");

    return apiCall("POST", `/users/${userId}/instances/order`, {
      orderedInstances,
    })
      .then((result) => {
        LOGGING >= 1 &&
          console.log("updated instance got res from backend result:", result);
        const { updatedOrderedInstances } = result;
        LOGGING >= 1 &&
          console.log(
            "updated instance got res from backend with updatedOrderedInstances:",
            updatedOrderedInstances
          );
        const { instances } = getState();
        var updatedInstances = {};

        for (let date in instances) {
          const oldInstancesOnDate = instances[date];
          const updatedInstancesOnDate = oldInstancesOnDate.map((instance) =>
            instance._id in updatedOrderedInstances
              ? {
                  ...instance,
                  etaAtOrder: updatedOrderedInstances[instance._id].etaAtOrder,
                  orderMethod:
                    updatedOrderedInstances[instance._id].orderMethod,
                  paidAtOrder:
                    updatedOrderedInstances[instance._id].paidAtOrder,
                  orderTime: updatedOrderedInstances[instance._id].orderTime,
                }
              : { ...instance }
          );

          LOGGING >= 1 &&
            console.log(
              "markInstancesOrdered updated with updatedInstancesOnDate:",
              updatedInstancesOnDate
            );
          updatedInstances[date] = [...updatedInstancesOnDate];
        }

        LOGGING >= 1 &&
          console.log(
            "markInstancesOrdered updated with upDatedInstances:",
            updatedInstances
          );
        dispatch(loadInstances({ instances: updatedInstances }));
        return updatedInstances;
      })
      .catch((err) => {
        LOGGING >= 1 && console.log("got error:", err);
        dispatch(addError(err.message));
      });
  };
};
export const changeMultipleInstanceComments = (
  userId,
  instancesWithComments
) => {
  LOGGING >= 1 &&
    console.log(`changeMultipleInstanceComments called with userId ${userId}`);
  LOGGING >= 1 &&
    console.log(
      "changeMultipleInstanceComments called with instancesWithComments:",
      instancesWithComments
    );

  return (dispatch, getState) => {
    LOGGING >= 1 && console.log("dispatching  put");

    return apiCall("POST", `/users/${userId}/instances/comment`, {
      instancesWithComments,
    })
      .then((updatedInstancesWithComment) => {
        //

        LOGGING >= 1 &&
          console.log(
            "updated instance with comment res:",
            updatedInstancesWithComment
          );
        const { instances } = getState();
        var updatedInstances = {};

        for (let date in instances) {
          const oldInstancesOnDate = instances[date];
          const updatedInstancesOnDate = oldInstancesOnDate.map((instance) =>
            instance._id in updatedInstancesWithComment
              ? {
                  ...instance,
                  comment: updatedInstancesWithComment[instance._id].comment,
                }
              : { ...instance }
          );

          LOGGING >= 1 &&
            console.log(
              "comment updated updatedInstancesOnDate:",
              updatedInstancesOnDate
            );
          updatedInstances[date] = [...updatedInstancesOnDate];
        }

        LOGGING >= 1 &&
          console.log("goods updated upDatedInstances:", updatedInstances);
        dispatch(loadInstances({ instances: updatedInstances }));
        return updatedInstances;
      })
      .catch((err) => {
        LOGGING >= 1 && console.log("got error:", err);
        dispatch(addError(err.message));
      });
  };
};

export const cancelInstance = (userId, instanceId, isCancel) => {
  LOGGING >= 1 &&
    console.log(
      `cancelInstance with instanceId:${instanceId}, userId:${userId}, isCancel:${isCancel}`
    );

  return (dispatch, getState) => {
    LOGGING >= 1 && console.log("cancelInstance call apiCall");

    // return apiCall("DELETE", `/users/${userId}/instances/${instanceId}/`)
    return (
      apiCall(
        "POST",
        `/users/${userId}/instances/${
          isCancel === false ? "resume" : "cancel"
        }`,
        { instances: [instanceId] }
      )
        // step 1: remove deleted instance from reduxState.instances
        .then((deletedInstance) => {
          LOGGING >= 1 &&
            console.log(
              "cancelInstance api delete done with res/deletedInstance: ",
              deletedInstance
            );

          var updatedInstances = {};

          const currentInstances = getState().instances;

          LOGGING >= 1 &&
            console.log("cancelInstance currentInstances:", currentInstances);

          for (const [key, value] of Object.entries(currentInstances)) {
            LOGGING >= 1 && console.log("value:", value);
            LOGGING >= 1 && console.log("key:", key);

            const filteredValue = value.filter((instance) => {
              LOGGING >= 1 && console.log("instance:", instance);
              LOGGING >= 1 &&
                console.log(
                  "instance._id!==instanceId:",
                  instance._id !== instanceId
                );
              return instance._id !== instanceId;
            });

            const updatedValue = value.map((instance) =>
              instance._id === instanceId
                ? {
                    ...instance,
                    status:
                      isCancel === false
                        ? InstanceStatus.SCHEDULED
                        : InstanceStatus.CANCELLED,
                  }
                : instance
            );

            LOGGING >= 1 && console.log("filtered value:", filteredValue);
            LOGGING >= 1 && console.log("updatedValue:", updatedValue);

            // if(filteredValue.length>0) {
            //   // updatedInstances.set(key, filteredValue);
            //   updatedInstances[key] = filteredValue;
            // }
            updatedInstances[key] = updatedValue;
          } //end of currentInstances for loop

          LOGGING >= 1 &&
            console.log("REMOVE_INSTANCE with newInstances:", updatedInstances);
          dispatch(loadInstances({ instances: updatedInstances }));

          return deletedInstance;
        })
        .catch((err) => {
          dispatch(addError(err.message));
        })
    );
  };
};
