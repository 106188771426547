import React from "react";
import "./ActionList.scss";

const ActionList = ({ buttonComponent, actionComponentList, showList }) => (
  <div className={"action-list " + (showList ? "showing" : "")}>
    {buttonComponent}
    {showList ? (
      <ul className="action-items">
        {actionComponentList.map((action, index) => (
          <li key={index}>{action}</li>
        ))}
      </ul>
    ) : null}
  </div>
);

export default ActionList;
