import React, { Component } from "react"; // eslint-disable-line no-unused-vars
import "./index.scss";
import { connect } from "react-redux";
import FormDish, { StageValue } from "./FormDish";
import { createDish, updateDish } from "../../store/actions";
import { LoadingIcon } from "../widgets";
import { LOGGING } from "../../hocs/constants";

class PageDish extends Component {
  constructor(props) {
    super();
    const { restaurantId, dishId } = props.match.params;
    const { dishes, restaurants } = props;
    const restaurant = restaurants.find((r) => r._id === restaurantId);
    const dish =
      dishId === "new"
        ? {
            name: "",
            price: "",
            freshness: 0,
            isVegetarian: false,
            isVegan: false,
            isLowCarb: false,
            status: {
              alpha: StageValue.TESTING,
              beta: StageValue.DISABLED,
            },
            restaurant,
            cuisines: [],
            ingredients: [],
          }
        : dishes.find((d) => d._id === dishId);

    this.state = {
      loading: false,
      dish,
      showFreshnessOptions: false,
      showTagsList: { cuisines: false, ingredients: false },
    };

    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleToggleDietOption = this.handleToggleDietOption.bind(this);
    this.handleToggleShowFreshnessOptions = this.handleToggleShowFreshnessOptions.bind(
      this
    );
    this.handleToggleTag = this.handleToggleTag.bind(this);
    this.handleToggleShowTagsList = this.handleToggleShowTagsList.bind(this);
    this.handleChangeFreshness = this.handleChangeFreshness.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleToggleTag = (category, tag, e) => {
    e.preventDefault();
    const { dish } = this.state;
    let tags = dish[category];
    if (tags.findIndex((t) => t._id === tag._id) < 0) {
      tags = [...tags, tag].sort((a, b) =>
        a.name > b.name ? 1 : a.name < b.name ? -1 : 0
      );
    } else {
      tags = tags.filter((t) => t._id !== tag._id);
    }
    this.setState({
      dish: {
        ...dish,
        [category]: tags,
      },
    });
  };

  handleToggleShowTagsList = (category, e) => {
    e.preventDefault();
    const { showTagsList } = this.state;
    this.setState({
      showTagsList: { ...showTagsList, [category]: !showTagsList[category] },
    });
  };

  handleToggleShowFreshnessOptions = (e) => {
    e.preventDefault();
    const { showFreshnessOptions } = this.state;
    this.setState({ showFreshnessOptions: !showFreshnessOptions });
  };

  handleChangeFreshness = (option, e) => {
    e.preventDefault();
    const { dish } = this.state;
    this.setState({
      dish: { ...dish, freshness: option },
      showFreshnessOptions: false,
    });
  };

  handleToggleDietOption = (e) => {
    const { dish } = this.state;
    const oldValue = dish[e.target.name];
    this.setState({ dish: { ...dish, [e.target.name]: !oldValue } });
  };

  handleChangeInput(name, e) {
    e.preventDefault();
    const { dish } = this.state;
    const field = name.split("-")[0];
    this.setState({ dish: { ...dish, [field]: e.target.value } });
  }

  handleChangeStatus(stage) {
    const { dish } = this.state;
    let { status } = dish;
    status[stage] = (status[stage] % 3) + 1;
    if (stage === "alpha") {
      switch (status.alpha) {
        case StageValue.TESTING:
        case StageValue.DISAPPROVED:
          status.beta = StageValue.DISABLED;
          break;
        case StageValue.APPROVED:
          status.beta = StageValue.TESTING;
          break;
        default:
          throw new Error(`invalid alpha code: ${status.alpha}`);
      }
    }
    this.setState({ status });
  }

  handleSave() {
    const { dish } = this.state;
    const { _id } = dish;
    this.setState({ loading: true });
    if (_id) {
      this.props.updateDish(dish).then(() => {
        this.props.history.goBack();
      });
    } else {
      this.props.createDish(dish).then(() => {
        this.props.history.goBack();
      });
    }
  }

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    const { dish, loading, showFreshnessOptions, showTagsList } = this.state;
    const { cuisines: cuisineList, ingredients: ingredientList } = this.props;
    LOGGING >= 1 && console.log("PageDish rendering with: ", dish);
    return (
      <div className="homepage entity">
        {loading ? (
          <LoadingIcon />
        ) : (
          <FormDish
            dish={dish}
            showFreshnessOptions={showFreshnessOptions}
            showTagsList={showTagsList}
            tagsList={{ cuisines: cuisineList, ingredients: ingredientList }}
            // editing handlers
            onChangeInput={this.handleChangeInput}
            onToggleDietOption={this.handleToggleDietOption}
            onChangeFreshness={this.handleChangeFreshness}
            onToggleShowFreshnessOptions={this.handleToggleShowFreshnessOptions}
            onChangeStatus={this.handleChangeStatus}
            // for cuisines and ingredients tags
            onToggleTag={this.handleToggleTag}
            onToggleShowTagsList={this.handleToggleShowTagsList}
            // save and cancel
            onSave={this.handleSave}
            onCancel={this.handleCancel}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  return {
    restaurants: reduxState.restaurants,
    dishes: reduxState.dishes,
    cuisines: reduxState.cuisines,
    ingredients: reduxState.ingredients,
  };
}

export default connect(mapStateToProps, {
  createDish,
  updateDish,
})(PageDish);
