import axios from "axios";
import { LOGGING } from "../hocs/constants";
export function setTokenHeader(token) {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
}

const serverPath =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PROD_SERVER
    : process.env.REACT_APP_DEV_SERVER;

LOGGING >= 3 &&
  console.log("app is running with process.env.NODE_ENV:", process.env);
LOGGING >= 3 && console.log("app is running with serverPath:", serverPath);

export function apiCall(method, path, data) {
  const fullPath = serverPath + path;
  LOGGING >= 3 && console.log("apicall with serverPath", serverPath);
  LOGGING >= 3 && console.log("apicall with path", path);
  LOGGING >= 3 && console.log("apicall with fullPath", fullPath);
  LOGGING >= 3 &&
    console.log(`
  method:${method}, 
  path:${fullPath}, 
  data:${data}, 
  token:${axios.defaults.headers.common["Authorization"]}`);
  return new Promise((resolve, reject) => {
    return axios[method.toLowerCase()](fullPath, data)
      .then((res) => {
        LOGGING >= 3 && console.log("api got res:", res);
        return resolve(res.data);
      })
      .catch((err) => {
        LOGGING >= 3 && console.log("api got err:", err);
        return reject(err.response.data.error);
      });
  });
}
