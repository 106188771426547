import React from "react";
import "./Bubble.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
export const BubbleUpperRight = ({ content, className, onHide }) => (
  <React.Fragment>
    {onHide ? <div className="bubble-overlay" onClick={onHide}></div> : null}
    <div className={`bubble upper-right ${className}`}>{content}</div>
  </React.Fragment>
);

export const BubbleUpperMiddle = ({ content, className, onHide }) => (
  <React.Fragment>
    {onHide ? <div className="bubble-overlay" onClick={onHide}></div> : null}
    <div className={`bubble upper-middle ${className}`}>{content}</div>
  </React.Fragment>
);

export const BubbleUpperMiddleSmallArrow = ({ content, className, onHide }) => (
  <React.Fragment>
    {onHide ? <div className="bubble-overlay" onClick={onHide}></div> : null}
    <div className={`bubble small-arrow upper-middle ${className}`}>
      {content}
    </div>
  </React.Fragment>
);

export const DropDownMenu = ({
  buttonClassName,
  dropDownMenuClassName,
  onToggleShowDropDownMenu,
  showDropDownMenu,
  dropDownMenu,
}) => (
  <React.Fragment>
    <button
      className={`dropdown-menu-toggle ${buttonClassName}`}
      type="click"
      onClick={onToggleShowDropDownMenu}
    >
      <FontAwesomeIcon icon={faEllipsisV} />
    </button>
    {showDropDownMenu ? (
      <BubbleUpperRight
        className={`dropdown-menu ${dropDownMenuClassName}`}
        content={
          <React.Fragment>
            {dropDownMenu.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </React.Fragment>
        }
        onHide={onToggleShowDropDownMenu}
      />
    ) : null}
  </React.Fragment>
);
