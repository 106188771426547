import React from "react"; // eslint-disable-line no-unused-vars
import "./ListOfUnits.scss";
import {
  ListHeader,
  UnitHeader,
  NoMeal,
  UnitDetailsDelivery,
  UnitDetailsOrder,
} from "./";
const ListOfUnits = ({
  // shared by deliveries and orders
  role,
  unitType,
  unitsForMeal,
  showDetails,
  showAllDetails,
  onSaveETA,
  onEditETA,
  onToggleShowDetails,
  onToggleShowAllDetails,

  // for deliveries only
  selectedMeal,
  contextDeliveries,
  contextDateMoments,
  onToggleInstance,
  onShowDishInventory,

  // for orders only
  onOrder,
  onPickUp,
  // for deliveries only
  onDeliver,
}) => {
  const { dateKey, isLunch } = selectedMeal;
  const mealKey = { dateKey, isLunch };
  return (
    <div className="unit-list">
      {role === "ops" ? (
        <ListHeader
          showToggle={unitsForMeal.length > 0}
          showAll={showAllDetails[mealKey]}
          onToggleShowAll={onToggleShowAllDetails.bind(this, mealKey)}
          title={unitType === "delivery" ? "Deliveries" : "Orders"}
        />
      ) : null}
      {unitsForMeal.length > 0 ? (
        unitsForMeal.map(
          (
            {
              _id,
              unit,
              type,
              instances,
              etaByOps,
              orderTime,
              pickUpTime,
              dispatchTime,
              deliverTime,
            },
            index
          ) => {
            const showDetailsForUnit =
              role === "ops"
                ? showDetails[unitType][mealKey] &&
                  showDetails[unitType][mealKey].find((i) => i === unit._id)
                : showDetails[mealKey] &&
                  showDetails[mealKey].find((i) => i === unit._id);
            return (
              <div
                className={`unit ${showDetailsForUnit ? "show-details" : ""}`}
                key={index}
              >
                <UnitHeader
                  unit={unit}
                  role={role}
                  unitType={type}
                  instances={instances}
                  etaByOps={etaByOps}
                  showDetails={showDetailsForUnit}
                  onToggleShowDetails={onToggleShowDetails.bind(
                    this,
                    // ops view doesn't use unit._id since it might not exist
                    // driver view unit already exists
                    mealKey,
                    unit._id
                  )}
                  onSaveETA={onSaveETA && onSaveETA.bind(this, unit._id)}
                  onEditETA={onEditETA && onEditETA.bind(this, unit._id)}
                  onOrder={onOrder && onOrder.bind(this, _id)}
                  orderTime={orderTime}
                  //bind the next unit here
                  onPickUp={
                    onPickUp &&
                    onPickUp.bind(this, {
                      orderPickedUp: { _id, restaurant: unit },
                      nextStop:
                        index < unitsForMeal.length - 1
                          ? unitsForMeal[index + 1]
                          : null,
                    })
                  }
                  onDeliver={
                    onDeliver &&
                    onDeliver.bind(this, _id, {
                      deliveryDropped: { _id, company: unit },
                      nextStop:
                        index < unitsForMeal.length - 1
                          ? unitsForMeal[index + 1]
                          : null,
                    })
                  }
                  pickUpTime={pickUpTime}
                  dispatchTime={dispatchTime}
                  deliverTime={deliverTime}
                />

                {showDetailsForUnit ? (
                  type === "delivery" ? (
                    <UnitDetailsDelivery
                      role={role}
                      mainContact={unit.mainContact}
                      // time data
                      selectedMeal={selectedMeal}
                      contextDateMoments={contextDateMoments}
                      // meal data
                      instances={instances} // filtered by selectedMeal's date and isLunch: all users, cancelled or not
                      contextDeliveries={contextDeliveries} // instances filtered by context dates: both meals, all users, cancelled or not
                      onToggleInstance={onToggleInstance}
                      onShowDishInventory={onShowDishInventory}
                      dispatchTime={dispatchTime}
                      deliverTime={deliverTime}
                    />
                  ) : (
                    <UnitDetailsOrder
                      role={role}
                      restaurant={unit}
                      instances={instances}
                      orderTime={orderTime}
                      pickUpTime={pickUpTime}
                    />
                  )
                ) : null}
              </div>
            );
          }
        )
      ) : (
        <NoMeal meal="order" />
      )}
    </div>
  );
};

export default ListOfUnits;
