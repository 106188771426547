export { default as CardForm } from "./CardForm";
export { default as CardSaved } from "./CardSaved";
export { default as TotalDueForm } from "./TotalDueForm";
export { default as InvoiceList } from "./InvoiceList";
export {
  default as PagePaymentMethod,
  validateCard,
} from "./PagePaymentMethod";
export { default as PageInvoices } from "./PageInvoices";
export const taxRate = 0.09;
export const taxMultiplier = 1.09;
