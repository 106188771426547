/* eslint-disable no-useless-escape */
import moment from "moment-timezone";
export const IS_PRODUCTION = window.location.host === "abbey.today";
const LOGGING_LEVEL = 1;
export const LOGGING = IS_PRODUCTION ? null : LOGGING_LEVEL;
export const lunchDinnerBorderHour = 15;
export const LunchWindow = {
  start: { hour: 11, minute: 0 },
  minutes: 90,
  end: { hour: 12, minute: 30 },
};
export const DinnerWindow = {
  start: { hour: 17, minute: 30 },
  minutes: 90,
  end: { hour: 19, minute: 0 },
};
export const cancellationMinutes = 120;

const timeFormat = "HH:mma";
let lunch = moment()
  .hour(LunchWindow.start.hour)
  .minute(LunchWindow.start.minute);
export const lunchStart = lunch.format(timeFormat);

lunch.add(LunchWindow.minutes, "minute");
export const lunchEnd = lunch.format(timeFormat);
lunch.add(-LunchWindow.minutes - cancellationMinutes, "minute");
export const lunchCancelBy = lunch.format(timeFormat);

let dinner = moment()
  .hour(DinnerWindow.start.hour)
  .minute(DinnerWindow.start.minute);
export const dinnerStart = dinner.format(timeFormat);

dinner.add(DinnerWindow.minutes, "minute");
export const dinnerEnd = dinner.format(timeFormat);
dinner.add(-DinnerWindow.minutes - cancellationMinutes, "minute");
export const dinnerCancelBy = dinner.format(timeFormat);

export const actionTimeFormat = "HH:mm A";
export const DateKeyFormatLong = "dddd, MMM Do YYYY";
export const DateKeyFormat = "dddd, MMM Do";
export const DateKeyFormatShort = "ddd, MMM Do";
export const CALIFORNIA_TIMEZONE = "America/Los_Angeles";

export const PhoneFormat = (number) =>
  `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6)}`;

export const TimeFormat = (number) =>
  number < 10 ? "0" + String(number) : String(number);

export const InstanceStatus = {
  CANCELLED: -1,
  SCHEDULED: 0,
};

export const Week = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export const validateEmail = (email) => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const signUpActionName = "sign up";
