import React from "react";
import "./UnitHeader.scss";
import { UnitSummary } from "./UnitHeaderMobile";
import { faPhoneAlt } from "@fortawesome/pro-regular-svg-icons";
import { PhoneFormat } from "../../../hocs/constants";
import {
  FormattedRow,
  ButtonClickToCopy,
  ButtonCallToAction,
} from "../../widgets";

const UnitHeaderDesktop = ({ widgets, data }) => {
  const { addressButton, unitAddress, expandButton, infoList } = widgets;

  const {
    role,
    unit,
    unitType,
    instances,
    etaByOps,
    onSaveETA,
    onEditETA,
  } = data;

  // position: relative

  // phone button
  const phone = unit.phone || unit.mainContact.phone;

  const phoneButtonDesktop = (
    <ButtonClickToCopy
      buttonToClick={
        <ButtonCallToAction icon={faPhoneAlt} text={PhoneFormat(phone)} />
      }
      textToCopy={phone}
    />
  );

  // unit name: restaurant or company name
  const unitNameDesktop = (
    <div className="unit-name">
      <span>{unit.name || unit.mainContact.name}</span>
    </div>
  );

  // number of instances, sum, ETA by ops
  const unitSummaryDesktop = (
    <UnitSummary
      role={role}
      unitType={unitType}
      instances={instances}
      etaByOps={etaByOps}
      onSaveETA={onSaveETA}
      onEditETA={onEditETA}
      mobile={false}
    />
  );

  const unitStatusDesktop = (
    <div className="unit-status">
      <FormattedRow list={infoList} />
    </div>
  );

  const actionSectionDesktop = (
    <div className="unit-header-right-section">
      {phoneButtonDesktop}
      {addressButton}
      {expandButton}
    </div>
  );

  const infoSectionDesktop = (
    <div className="unit-header-left-section">
      {unitNameDesktop}
      {unitAddress}
      {unitSummaryDesktop}
      {unitStatusDesktop}
    </div>
  );

  return (
    <div className="unit-header-desktop">
      {/* flexible */}
      {infoSectionDesktop}
      {actionSectionDesktop}
    </div>
  );
};

export default UnitHeaderDesktop;
