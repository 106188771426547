import React, { Component } from "react"; // eslint-disable-line no-unused-vars
import "./FormFavorites.scss";
import { GoogleApiWrapper } from "google-maps-react";
import { confirmAlert } from "react-confirm-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faTimesCircle,
  faSearch,
} from "@fortawesome/pro-regular-svg-icons";
import Textarea from "react-textarea-autosize";
import { LOGGING } from "../../hocs/constants";
import {
  PopUp,
  StepFormFooter,
  ProfileFormFooter,
  ProfileStepText,
  ConfirmAlert,
} from "../widgets";
import { Redirect } from "react-router-dom";
import Geosuggest from "react-geosuggest";
const EmptyRestaurant = { name: "", address: "", comments: "" };

const ExistingRestaurant = ({ restaurant, onEditComments, onDelete }) => (
  <div className="existing-restaurant">
    <button
      className="delete-restaurant"
      onClick={(e) => {
        e.preventDefault();
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <ConfirmAlert
                onConfirm={onDelete}
                onClose={onClose}
                actionName="delete"
              />
            );
          },
        });
      }}
    >
      <FontAwesomeIcon icon={faTimesCircle} />
    </button>
    <div className="restaurant-info">
      <div className="restaurant-icon">
        <FontAwesomeIcon icon={faMapMarkerAlt} />
      </div>
      <div className="restaurant-name">{restaurant.name}</div>
      <div className="restaurant-address">{restaurant.address}</div>
    </div>
    <Textarea
      className="restaurant-comments"
      value={restaurant.comments}
      onChange={onEditComments}
      placeholder="What do you like to order?"
      spellCheck={false}
    />
  </div>
);
class FormFavorites extends Component {
  constructor(props) {
    super();
    this.state = {
      newRestaurant: { ...EmptyRestaurant },
      isSearching: false,
      favorites: props.favorites || [],
    };
    this.handleChangeRestaurantName = this.handleChangeRestaurantName.bind(
      this
    );
    this.handleSelectRestaurantAddress = this.handleSelectRestaurantAddress.bind(
      this
    );
    this.handleEditRestaurantComments = this.handleEditRestaurantComments.bind(
      this
    );
    this.handleDeleteRestaurant = this.handleDeleteRestaurant.bind(this);
  }

  handleChangeRestaurantName(name) {
    LOGGING >= 1 &&
      console.log("handleChangeRestaurantName called with ", name);
    const { newRestaurant } = this.state;
    this.setState({
      newRestaurant: { ...newRestaurant, name },
      isSearching: true,
    });
  }

  handleSelectRestaurantAddress(address) {
    LOGGING >= 1 && console.log("handleSelectAddress called with ", address);
    if (address) {
      const { gmaps } = address;
      const { address_components, name } = gmaps;
      const city = address_components.find((c) => c.types.includes("locality"))
        .short_name;
      const number = address_components.find((c) =>
        c.types.includes("street_number")
      ).short_name;
      const street = address_components.find((c) => c.types.includes("route"))
        .short_name;
      const state = address_components.find((c) =>
        c.types.includes("administrative_area_level_1")
      ).short_name;
      const zip = address_components.find((c) =>
        c.types.includes("postal_code")
      ).short_name;

      const { newRestaurant, favorites } = this.state;
      const newAddress = `${number} ${street}, ${city}, ${state} ${zip}`;

      this.setState({
        newRestaurant: { ...EmptyRestaurant },
        favorites: [
          { ...newRestaurant, address: newAddress, name },
          ...favorites,
        ],
        isSearching: false,
      });
    }
  }
  handleEditRestaurantComments(index, e) {
    LOGGING >= 1 &&
      console.log("handleEditRestaurantComments called with: ", { index, e });
    const { favorites } = this.state;
    this.setState({
      favorites: favorites.map((f, i) =>
        i === index ? { ...f, comments: e.target.value } : f
      ),
    });
  }
  handleDeleteRestaurant(index) {
    LOGGING >= 1 &&
      console.log("handleDeleteRestaurant called with: ", { index });
    const { favorites } = this.state;
    this.setState({
      favorites: favorites.filter((f, i) => i !== index),
    });
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.updateShadow();
  }

  render() {
    const { newRestaurant, favorites, isSearching } = this.state;
    const {
      favorites: favoritesOld,
      onNext,
      onPrevious,
      onSave,
      contentOverflow,
      contentRef,
      goto,
      onCancelGoTo,
    } = this.props;

    const equal =
      JSON.stringify(favoritesOld) === JSON.stringify(favorites) &&
      newRestaurant.name === "";
    LOGGING >= 1 &&
      console.log("FormFavorites rendering with:", {
        newRestaurant,
        favoritesOld,
        favorites,
        goto,
        isSearching,
        equal,
      });
    if (onSave && goto > 0 && equal) {
      return <Redirect to={`/profile/${ProfileStepText[goto - 1]}`} />;
    }
    const gotoConfirm = onSave ? (
      <PopUp
        isPoppedUp={onSave && goto >= 0 && !equal}
        componentToDisplay={
          <div className="goto-confirm">
            <span>Save the changes you made?</span>
            <div className="button-wrapper">
              <button
                className="no-save-goto"
                onClick={onSave.bind(this, null, goto)}
              >
                no
              </button>
              <button
                className="save-goto"
                onClick={onSave.bind(this, { favorites }, goto)}
              >
                save
              </button>
            </div>
          </div>
        }
        hidePopUp={onCancelGoTo}
      />
    ) : null;

    const { name } = newRestaurant;

    const addRestaurant = (
      <div className="step-form-header">
        <div className={`add-restaurant ${isSearching ? "is-searching" : ""}`}>
          <div className="search-icon">
            <FontAwesomeIcon icon={faSearch} />
          </div>
          <Geosuggest
            name={`restaurant-address-${Math.random()}`}
            placeholder="Find your favorite local restaurants..."
            initialValue={name}
            onSuggestSelect={this.handleSelectRestaurantAddress}
            onChange={this.handleChangeRestaurantName}
            placeDetailFields={["address_components", "adr_address", "name"]}
          />
        </div>
      </div>
    );
    const existingRestaurants = favorites.map((f, index) => (
      <ExistingRestaurant
        restaurant={f}
        key={index}
        onEditComments={this.handleEditRestaurantComments.bind(this, index)}
        onDelete={this.handleDeleteRestaurant.bind(this, index)}
      />
    ));

    const formFooter = onSave ? (
      <ProfileFormFooter
        onSave={onSave.bind(this, { favorites })}
        showShadow={contentOverflow}
      />
    ) : (
      <StepFormFooter
        isFirstStep={false}
        isLastStep={false}
        onPrevious={onPrevious}
        onNext={onNext.bind(this, { favorites: favoritesOld }, { favorites })}
        showShadow={contentOverflow}
      />
    );

    return (
      <div className="step-form favorites" ref={contentRef}>
        {gotoConfirm}
        {addRestaurant}
        {existingRestaurants}
        {formFooter}
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBjfTfgJdRah5wXvVj8U7fH4ACoqKry9HI",
})(FormFavorites);
