import React from "react"; // eslint-disable-line no-unused-vars
import ReactDOM from "react-dom";
import WebFont from "webfontloader";
import "./index.scss";
import { LOGGING } from "./hocs/constants";
import App from "./screens/App";
// import registerServiceWorker from "./registerServiceWorker";
import { unregister } from "./registerServiceWorker";

LOGGING >= 3 && console.log("window.location.host:", window.location.host);
LOGGING >= 3 && console.log("process.env.NODE_ENV:", process.env.NODE_ENV);
LOGGING >= 3 && console.log("process.env.PUBLIC_URL:", process.env.PUBLIC_URL);
LOGGING >= 3 && console.log("process.env:", process.env);

WebFont.load({
  google: {
    families: [
      "Montserrat:200,300,400,500,600,700",
      "Theano Didot:200,300,400,500,600,700",
      "sans-serif",
      "Font Awesome",
      "Noto Sans:200,300,400,500,600,700",
      "Quando: 200,300,400,500,600,700",
      "Great Vibes:200,300,400,500,600,700",
      "Crimson Text:200,300,400,500,600,700",
    ],
  },
});

ReactDOM.render(
  // <HttpsRedirect>
  <App />,
  // </HttpsRedirect>,
  document.getElementById("root")
);

// registerServiceWorker();
unregister();
