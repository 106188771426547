import React from "react"; // eslint-disable-line no-unused-vars
import "./InstanceDelivered.scss";
import { EaterName } from "../../widgets";

const InstanceDelivered = ({ instance, isMainContact }) => {
  const { name, dish } = instance;

  return (
    <div className="instance-delivered">
      <div className="instance-info">
        <div className="instance-info-row">
          <EaterName name={name} isMainContact={isMainContact} />
        </div>
        <div className="instance-info-row">
          <span className="info-label">restaurant</span>
          <span className="info-text">{dish.restaurant.name}</span>
        </div>
        <div className="instance-info-row">
          <span className="info-text">
            <span className="info-label">meal</span> {dish.name}
          </span>
        </div>
        <div className="instance-info-row">
          <span className="info-label">price</span>
          <span className="info-text">${dish.price.toFixed(2)}</span>
        </div>
      </div>
    </div>
  );
};

export default InstanceDelivered;
