import React from "react";
import "./DishCatelog.scss";
import { DishContent } from "./";
import { Sticker, LoadingIcon, ClosePage } from "../../widgets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTimes,
  faTimesCircle,
} from "@fortawesome/pro-regular-svg-icons";

const MealSelect = ({
  dish,
  selected,
  selecteDishRef,
  onSelect,
  onDeselect,
}) => (
  <button
    className={`meal-select ${selected ? "selected-dish" : ""}`}
    ref={selected ? selecteDishRef : null}
    onClick={selected ? onDeselect : onSelect.bind(this, dish)}
  >
    <Sticker
      icon={<FontAwesomeIcon icon={selected ? faTimes : faPlus} />}
      content={<DishContent dish={dish} name={dish.restaurant.name} />}
    />
  </button>
);

const DishCatelog = ({
  loading,
  instance,
  selecteDishRef,
  categories,
  keyedData,
  onSelect,
  onDeselect,
  onDecide,
  onCancel,
}) => {
  let list = [];
  const { dish } = instance;
  // step 1. categorize data
  categories.forEach((categoryEntry, index) => {
    const dataCategory = keyedData.filter(
      (entry) => entry.categoryKey === categoryEntry
    );
    dataCategory.sort((a, b) =>
      a.sortKey > b.sortKey ? 1 : a.sortKey < b.sortKey ? -1 : 0
    );

    const categoryTitle = categoryEntry ? (
      <div className="restaurant-title" key={"category+" + String(index)}>
        <span>
          {categoryEntry} ({dataCategory.length})
        </span>
        <div className="filler"></div>
      </div>
    ) : null;

    let category = dataCategory.map((entry) => (
      <MealSelect
        dish={entry}
        key={entry._id}
        onSelect={onSelect}
        onDeselect={onDeselect}
        selected={dish && entry._id === dish._id}
        selecteDishRef={selecteDishRef}
      />
    ));

    list.push(
      <div className="restaurant-entry" key={index}>
        {categoryTitle}
        <div className="restaurant-dish-list">{category}</div>
      </div>
    );
  });

  return (
    <React.Fragment>
      <div className="dish-list-header">
        <span>Select A Dish</span>
        <ClosePage onClick={onCancel} />
      </div>
      {loading ? <LoadingIcon /> : <div className="dish-list">{list}</div>}
      <div className="dish-list-footer">
        <button
          type="button"
          className="reset"
          disabled={!dish}
          onClick={onDeselect}
        >
          <FontAwesomeIcon icon={faTimesCircle} /> <span>reset</span>
        </button>
        <button type="button" className="submit" onClick={onDecide}>
          ok
        </button>
      </div>
    </React.Fragment>
  );
};

export default DishCatelog;
