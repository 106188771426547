import React from "react";
import "./index.scss";
import { IngredientType } from "./";
import { FormWrapper, Input, Select } from "./FormWidgets";

const FormIngredient = ({
  ingredient,
  showTypeOptions,
  onEditName,
  onToggleShowTypeOptions,
  onSelectType,
  onSave,
  onCancel,
}) => {
  const types = Object.keys(IngredientType).map((t) => Number(t));
  const { name, type } = ingredient;

  const ingredientType = (
    <Select
      labelText="ingredient type"
      value={type}
      valueToTag={IngredientType}
      name={"type"}
      options={types}
      showOptions={showTypeOptions}
      onChange={onSelectType}
      onToggleShowOptions={onToggleShowTypeOptions}
    />
  );

  const ingredientName = (
    <Input
      labelText="ingredient name"
      value={name}
      onChange={onEditName}
      placeholderText="Type ingredient name."
      name="name"
    />
  );
  const formSection = (
    <React.Fragment>
      {ingredientType}
      {ingredientName}
    </React.Fragment>
  );
  return (
    <FormWrapper
      formName={name || "new ingredient"}
      formSections={[formSection]}
      onSave={onSave}
      onCancel={onCancel}
    />
  );
};

export default FormIngredient;
