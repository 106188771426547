import React from "react";
import "./UnitHeader.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faMapMarkerAlt } from "@fortawesome/pro-regular-svg-icons";
import { InstanceStatus } from "../../../hocs/constants";
import {
  FormattedRow,
  ButtonCallToPhone,
  TimeText,
  EditableTimeText,
} from "../../widgets";

const ETAName = { delivery: "deliver", order: "pick-up" };

export const UnitIcon = ({ unitType }) => (
  <div className="unit-icon round-icon purple-icon">
    <FontAwesomeIcon
      icon={unitType === "delivery" ? faBuilding : faMapMarkerAlt}
    />
  </div>
);

export const UnitSummary = ({
  instances,
  etaByOps,
  role,
  unitType,
  onSaveETA,
  onEditETA,
  mobile,
}) => {
  const actionName = ETAName[unitType];
  const etaInfo =
    role === "ops" ? (
      <EditableTimeText
        time={etaByOps}
        onSave={onSaveETA}
        onEdit={onEditETA}
        actionName={actionName}
      />
    ) : (
      <TimeText time={etaByOps} actionName={actionName} />
    );

  const numAll = instances.length;
  const numCancelled = instances.filter(
    (i) => i.status === InstanceStatus.CANCELLED
  ).length;

  const numValid = numAll - numCancelled;
  const eatersInfo =
    role === "ops" ? (
      <div>
        {numAll}
        {` meal${numAll > 1 ? "s" : ""}`}
      </div>
    ) : (
      <div>
        {numValid}
        {` meal${numValid > 1 ? "s" : ""}`}
      </div>
    );
  const sumInfo = (
    <div>
      <b>{`$${instances
        .map((i) => (i.dish ? i.dish.price : 0))
        .reduce((a, b) => a + b, 0)
        .toFixed(2)}`}</b>
    </div>
  );
  const summary = mobile ? (
    <div className="unit-summary">
      <FormattedRow list={[eatersInfo, sumInfo]} addBullet={true} />
      <FormattedRow list={[etaInfo]} addBullet={true} />
    </div>
  ) : (
    <div className="unit-summary">
      <FormattedRow list={[eatersInfo, sumInfo, etaInfo]} addBullet={true} />
    </div>
  );
  return summary;
};

const UnitHeaderMobile = ({ widgets, data }) => {
  const {
    addressButton,
    unitAddress,
    expandButton,
    infoList,
    priorityActionIndex,
    priorityButtonIndex,
  } = widgets;

  const {
    role,
    unit,
    unitType,
    instances,
    showDetails,
    etaByOps,
    onSaveETA,
    onEditETA,
  } = data;

  // phone
  const phone = unit.phone || unit.mainContact.phone;
  const phoneButtonMobile = <ButtonCallToPhone phone={phone} />;

  // unit name: restaurant or company name
  const unitNameMobile = (
    <div className="unit-name">
      <span>{unit.name || unit.mainContact.name}</span>
      {expandButton}
    </div>
  );

  // number of instances, sum, ETA by ops
  const unitSummaryMobile = (
    <UnitSummary
      role={role}
      unitType={unitType}
      instances={instances}
      etaByOps={etaByOps}
      onSaveETA={onSaveETA}
      onEditETA={onEditETA}
      mobile={true}
    />
  );

  // order, pickup, dispatch, deliver statuses
  const unitStatusMobileExpanded = (
    <div className="unit-status">{infoList[priorityButtonIndex]}</div>
  );
  const unitStatusMobileCollapsed = (
    <div className="unit-status">{infoList[priorityActionIndex]}</div>
  );

  const actionSectionMobile = (
    <div className="unit-header-lower-section">
      {phoneButtonMobile}
      {addressButton}
      {showDetails ? unitStatusMobileExpanded : unitStatusMobileCollapsed}
    </div>
  );

  const infoSectionMobile = (
    <div className="unit-header-upper-section">
      {unitNameMobile}
      {unitAddress}
      {unitSummaryMobile}
    </div>
  );

  return (
    <div className="unit-header-mobile">
      {infoSectionMobile}
      {actionSectionMobile}
    </div>
  );
};

export default UnitHeaderMobile;
