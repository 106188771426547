import React from "react";
import "./FormattedRow.scss";

const FormattedRow = ({ list, addBullet }) => (
  <div className={`formatted-row ${addBullet ? "bulletted" : ""}`}>
    {list.map((item, index) =>
      item ? (
        <div className="formatted-item" key={index}>
          {item}
        </div>
      ) : null
    )}
  </div>
);
export default FormattedRow;
