import moment from "moment-timezone";
import {
  LOGGING,
  DateKeyFormat,
  CALIFORNIA_TIMEZONE,
  InstanceStatus,
  LunchWindow,
  DinnerWindow,
} from "../../hocs/constants";

export { default as PageUserInternal } from "./PageUserInternal";
export { default as PageUserEater } from "./PageUserEater";
export { default as PageUserAnonymous } from "./PageUserAnonymous";
export { default as Navbar } from "./Navbar";
export { default as Footer } from "./Footer";
export { PagePrivacy, PageTerms } from "./PageDisclaimers";

export const CUTOFF_IN_HOURS = 2;
export const NO_USER = -1;
export const NO_DELIVERY = -1;
export const NO_INSTANCE = { user: NO_USER, isLunch: NO_DELIVERY };

export const CONTEXT_WINDOW_IN_DAYS = 2;
export const CONTEXT_DAY_INDEX = Array(CONTEXT_WINDOW_IN_DAYS * 2 + 1)
  .fill(0)
  .map((_, index) => index - CONTEXT_WINDOW_IN_DAYS);
export const getContextDateMoments = (summaryDay) => {
  const { year, month, day } = summaryDay;
  return CONTEXT_DAY_INDEX.map((i) =>
    moment([year, month, day]).tz(CALIFORNIA_TIMEZONE).add(i, "d").startOf("d")
  );
};
export const UNIT_ENTITY_NAME = { delivery: "company", order: "restaurant" };
export const UNIT_LIST_NAME = {
  delivery: "deliveriesForDay",
  order: "ordersForDay",
};

const momentToDateGrid = ({
  dayMoment,
  todayMoment,
  tomorrowMoment,
  isThisMonth,
}) => ({
  dateKey: dayMoment.format(DateKeyFormat),
  date: dayMoment.date(),
  month: dayMoment.month(),
  year: dayMoment.year(),
  dayOfWeek: dayMoment.day(),
  isThisMonth,
  isPast: dayMoment.isBefore(todayMoment),
  isToday: dayMoment.isSame(todayMoment),
  isTomorrow: dayMoment.isSame(tomorrowMoment),
});

const getMonthGrid = ({ month, year }) => {
  LOGGING >= 3 && console.log("getMonthGrid called with: ", { month, year });
  const momentMonth = moment({ year, month });
  const todayMoment = moment(new Date()).startOf("d");
  const tomorrowMoment = moment(new Date()).startOf("d").add(1, "d");

  const numberDays = momentMonth.daysInMonth();

  let calendarDays = Array(numberDays)
    .fill(0)
    .map((_, index) => {
      const dayMoment = moment({ year, month, day: index + 1 }).startOf("d");

      return momentToDateGrid({
        dayMoment,
        todayMoment,
        tomorrowMoment,
        isThisMonth: true,
      });
    });

  const dayOfWeekFirstDay = calendarDays[0].dayOfWeek;
  const dayOfWeekLastDay = calendarDays[numberDays - 1].dayOfWeek;

  for (let day = dayOfWeekFirstDay; day > 0; day--) {
    const dayMoment = moment({ year, month, day: 1 })
      .add(day - dayOfWeekFirstDay - 1, "d")
      .startOf("d");

    calendarDays.unshift(
      momentToDateGrid({
        dayMoment,
        todayMoment,
        tomorrowMoment,
        isThisMonth: false,
      })
    );
  }

  for (let day = dayOfWeekLastDay; day < 6; day++) {
    const dayMoment = moment({ year, month, day: numberDays }).add(
      day - dayOfWeekLastDay + 1,
      "d"
    );
    calendarDays.push(
      momentToDateGrid({
        dayMoment,
        todayMoment,
        tomorrowMoment,
        isThisMonth: false,
      })
    );
  }

  let calendarGrid = Array(7)
    .fill()
    .map(() => []);

  calendarDays.forEach((d) => {
    calendarGrid[d.dayOfWeek].push({ ...d });
  });
  LOGGING >= 3 && console.log("getMonthGrid returning:", calendarGrid);
  return calendarGrid;
};

export const updateMonth = ({ calendarYear, calendarMonth }) => {
  LOGGING >= 3 &&
    console.log("updateMonth called with:", { calendarYear, calendarMonth });
  // get the rectangle calendar grid for this month,
  // including preceding days and trailing days.
  const monthGrid = getMonthGrid({
    year: calendarYear,
    month: calendarMonth,
  });

  // get start and end UTC for this month,
  // to fetch instances in db
  const beginUTC = moment
    .tz([calendarYear, calendarMonth], CALIFORNIA_TIMEZONE)
    .startOf("month")
    .add(-CONTEXT_WINDOW_IN_DAYS, "days")
    .valueOf();

  const endUTC = moment
    .tz([calendarYear, calendarMonth], CALIFORNIA_TIMEZONE)
    .endOf("month")
    .add(CONTEXT_WINDOW_IN_DAYS, "days")
    .valueOf();

  LOGGING >= 3 &&
    console.log("updateMonth called and got monthGrid:", monthGrid);
  return { monthGrid, beginUTC, endUTC };
};

export const isDeliveryEditable = (isLunch, byTime) =>
  byTime - new Date().getTime() >
  (isLunch ? LunchWindow.minutes : DinnerWindow.minutes) * 60 * 1000;

// addDeliveryBySchedule
// delivery: {
//  instances: [{ // mandatory when delivery._id is undefined
//    _id,
//    user, // userId, mandatory when instance._id is undefined
//    status, // scheduled or cancelled, mandatory when instance._id is undefined
//    dish, // dishId
//    name, // userName
//  }]
//}

export const addDeliveryBySchedule = ({
  company,
  monthGrid,
  deliveriesFromDB,
}) => {
  let deliveries = [];
  const { eaters } = company;
  const mealByHour = {
    lunch: LunchWindow.end.hour,
    dinner: DinnerWindow.end.hour,
  };
  const mealByMinute = {
    lunch: LunchWindow.end.minute,
    dinner: DinnerWindow.end.minute,
  };
  const now = moment().valueOf();
  monthGrid.forEach((column) => {
    column.forEach((grid) => {
      const { dayOfWeek, year, month, date, dateKey } = grid;
      ["lunch", "dinner"].forEach((meal) => {
        const byTime = moment([
          year,
          month,
          date,
          mealByHour[meal],
          mealByMinute[meal],
        ])
          .tz(CALIFORNIA_TIMEZONE)
          .startOf("h")
          .valueOf();

        const mealDeliveryOnDate = deliveriesFromDB.find(
          (i) =>
            i.dateKey === dateKey && (meal === "lunch" ? i.isLunch : !i.isLunch)
        );
        if (mealDeliveryOnDate) {
          deliveries.push(mealDeliveryOnDate);
        } else {
          if (byTime > now) {
            const mealWeekly = dayOfWeek * 2 + (meal === "lunch" ? 0 : 1);
            const mealEaters = eaters.filter((eater) =>
              eater.schedules.find((s) => s.weekly === mealWeekly)
            );
            if (mealEaters.length > 0) {
              deliveries.push({
                dateKey,
                instances: mealEaters.map((eater) => ({
                  user: eater._id,
                  status: InstanceStatus.SCHEDULED,
                  name: eater.name,
                })),
                isLunch: meal === "lunch",
                byTime,
                dispatchTime: -1,
                deliverTime: -1,
                commentTime: -1,
                comment: "",
                etaByOps: byTime,
                company,
              });
            }
          }
        }
      });
    });
  });

  return deliveries;
};

export const getNumOfInstances = (deliveries) => {
  const isLunch = { lunch: true, dinner: false };
  let numOfInstances = {};
  ["lunch", "dinner"].forEach((meal) => {
    numOfInstances[meal] = deliveries
      .filter((d) => d.isLunch === isLunch[meal])
      .reduce(
        (a, b) =>
          a +
          b.instances.filter((i) => i.status === InstanceStatus.SCHEDULED)
            .length,
        0
      );
  });
  return numOfInstances;
};

export const getOrderIdsFromDeliveries = (deliveries) => {
  const instances = deliveries.reduce(
    (a, delivery) =>
      a.concat(
        delivery.instances.filter((i) => i.status === InstanceStatus.SCHEDULED)
      ),
    []
  );

  const orderIds = instances.reduce(
    (a, instance) => (instance.order ? a.add(instance.order) : a),
    new Set()
  );
  return Array.from(orderIds);
};
