import React from "react";
import "./AddEntry.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";

const AddEntry = ({ path, buttonText }) => (
  <Link
    className="add-entry"
    to={{
      pathname: path,
    }}
  >
    <FontAwesomeIcon icon={faPlusCircle} />
    <span>{buttonText}</span>
  </Link>
);

export default AddEntry;
