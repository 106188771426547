import { LOAD_RESTAURANTS, REMOVE_RESTAURANTS } from "../actionTypes";
import { PURGE } from "redux-persist";

const restaurants = (state = [], action) => {
  switch (action.type) {
    case LOAD_RESTAURANTS:
      return [...action.restaurants];
    case REMOVE_RESTAURANTS:
      return [];
    case PURGE:
      return [];
    default:
      return state;
  }
};

export default restaurants;
