import React, { Component } from "react"; // eslint-disable-line no-unused-vars
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { InvoiceList, TotalDueForm, taxMultiplier, validateCard } from "./";
import { LoadingIcon, PageHeader } from "../widgets";
import {
  deleteCard,
  fetchDeliveriesDelivered,
  createPaymentIntent,
  confirmPaymentIntent,
  confirmCardPayment,
} from "../../store/actions";
import { LOGGING } from "../../hocs/constants";

const getTotalDue = (outstandingDeliveries) => {
  const totalSum = outstandingDeliveries
    .map((delivery) =>
      delivery.instances
        .map((instance) => (instance.dish ? Number(instance.dish.price) : 0))
        .reduce((a, b) => a + b, 0)
    )
    .reduce((a, b) => a + b, 0)
    .toFixed(2);
  return totalSum * taxMultiplier;
};
class PageInvoices extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      processing: false,
      viewOutstanding: true,
      outstandingDeliveries: [],
      totalDue: 0,
      paymentIntent: null,
      showCard: false,
      saveCard: false,
      autoCharge: false,
      number: "",
      name: "",
      expiration: "",
      cvv: "",
      error: null,
      showCardMenu: false,
    };
    this.handleClose = this.handleClose.bind(this);
    this.handlePay = this.handlePay.bind(this);
    this.handleToggleShowCard = this.handleToggleShowCard.bind(this);
    this.handleToggleSaveCard = this.handleToggleSaveCard.bind(this);
    this.handleToggleAutoCharge = this.handleToggleAutoCharge.bind(this);
    this.handleChangeNumber = this.handleChangeNumber.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleDeleteCard = this.handleDeleteCard.bind(this);
    this.handleToggleShowCardMenu = this.handleToggleShowCardMenu.bind(this);
  }
  handleToggleShowCardMenu(e) {
    e.preventDefault();
    const { showCardMenu } = this.state;
    this.setState({ showCardMenu: !showCardMenu });
  }
  handleChangeName = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value, error: null });
  };

  handleChangeNumber = (field, values) => {
    this.setState({ [field]: values.value, error: null });
  };

  handlePay = (e) => {
    e.preventDefault();
    const { paymentIntent, outstandingDeliveries, autoCharge } = this.state;
    const { stripeInfo } = this.props.currentUser.user;
    LOGGING >= 1 &&
      console.log("handlePay called with: ", {
        paymentIntent,
        outstandingDeliveries,
        stripeInfo,
      });

    this.setState({ processing: true });
    // case 1: if the user has saved a card, then use the card
    if (stripeInfo && stripeInfo.paymentMethodId) {
      this.props
        .confirmPaymentIntent({
          paymentIntentId: paymentIntent.id,
          deliveryIds: outstandingDeliveries.map((i) => i._id),
          autoCharge,
        })
        .then(() => {
          this.setState({
            processing: false,
            outstandingDeliveries: [],
            totalDue: 0,
          });
        })
        .catch((error) => {
          LOGGING >= 1 &&
            console.log("confirmPaymentIntent returned with error: ", error);
          this.setState({ processing: false, error });
        });
    } else {
      // case 2: if the user has NOT saved a card,
      // then use the newly input card and
      // save it (if saveCard is true).
      //
      // case 3: if the user has NOT saved a card,
      // then use the newly input card and
      // NOT save it (if saveCard is false).
      const {
        number,
        name: enteredName,
        expiration,
        cvv,
        saveCard,
      } = this.state;
      LOGGING >= 1 &&
        console.log("handlePay called without card: ", {
          number,
          enteredName,
          cvv,
          expiration,
          saveCard,
          autoCharge,
        });

      //step 1. validate card info
      try {
        const { card, name } = validateCard({
          number,
          name: enteredName,
          expiration,
          cvv,
        });

        //step 2. charge card by server,
        // confirmCardPayment inside will reset the user's paymentMethod and deliveries.
        this.props
          .confirmCardPayment({
            card,
            name,
            paymentIntentId: paymentIntent.id,
            deliveryIds: outstandingDeliveries.map((i) => i._id),
            saveCard,
            autoCharge,
          })
          .then(() => {
            //step 3. reset outstandingDeliveries and totalDue
            this.setState({
              processing: false,
              outstandingDeliveries: [],
              totalDue: 0,
            });
          })
          .catch((error) => {
            LOGGING >= 1 &&
              console.log("confirmPaymentIntent returned with error: ", error);
            this.setState({ processing: false, error });
          });
      } catch (error) {
        LOGGING >= 1 &&
          console.log("validateCard got error:", {
            name: error.name,
            message: error.message,
          });
        this.setState({ error, processing: false });
      }
    }
  };

  handleClose = () => {
    // this.props.history.goBack();
    this.props.history.push("/");
  };

  handleToggleShowCard(e) {
    e.preventDefault();
    const { showCard } = this.state;
    this.setState({ showCard: !showCard });
  }
  handleDeleteCard() {
    const { currentUser } = this.props;
    const { stripeInfo } = currentUser.user;
    const { paymentMethod } = stripeInfo;
    this.setState({ loading: true });
    this.props.deleteCard(paymentMethod).then(() => {
      this.setState({
        showMenu: false,
        loading: false,
        // stripeInfo: { autoCharge: false, customerId: stripeInfo.customerId },
      });
    });
  }
  handleToggleSaveCard(e) {
    const { saveCard } = this.state;
    this.setState({ saveCard: !saveCard });
  }

  handleToggleAutoCharge(e) {
    const { autoCharge } = this.state;
    this.setState({ autoCharge: !autoCharge });
  }

  componentDidMount() {
    const { currentUser } = this.props;
    const { stripeInfo } = currentUser.user;
    const { autoCharge } = stripeInfo;

    this.setState({ loading: true, autoCharge });

    this.props.fetchDeliveriesDelivered().then(() => {
      // step 1. get all the users' delivered deliveries (a.k.a. invoices).
      const { deliveries } = this.props;

      // step 2. filter out the users' outstanding (unpaid) deliveries.
      const outstandingDeliveries = deliveries.filter(
        (delivery) => delivery.payTime < 0
      );

      // step 3. calculate the users' outstanding total due.
      const totalDue = getTotalDue(outstandingDeliveries);
      LOGGING >= 1 &&
        console.log("fetchDeliveriesDelivered returned with:", {
          state: this.state,
          props: this.props,
          totalDue,
          outstandingDeliveries,
        });

      if (totalDue > 0) {
        // step 4. create a paymentIntent for the total due,
        // and store it in state for later use
        this.props
          .createPaymentIntent({ amount: totalDue * 100 })
          .then((paymentIntent) => {
            this.setState({
              loading: false,
              totalDue,
              outstandingDeliveries,
              paymentIntent,
            });
          });
      } else {
        this.setState({
          loading: false,
          totalDue,
          outstandingDeliveries,
          paymentIntent: null,
        });
      }
    });
  }

  render() {
    LOGGING >= 1 &&
      console.log("rendering PageInvoices with", {
        props: this.props,
        state: this.state,
      });

    const { history, onCloseFromHome, currentUser, deliveries } = this.props;
    const {
      loading,
      processing,
      viewOutstanding,
      showCard,
      saveCard,
      autoCharge,
      totalDue,
      outstandingDeliveries,
      error,
      showCardMenu,
    } = this.state;

    if (!currentUser.isAuthenticated) {
      return <Redirect to="/howitworks" />;
    }

    const filterOptions = (
      <div className="mode-options">
        <button
          type="button"
          name="outstanding"
          onClick={(e) => {
            e.preventDefault();
            this.setState({ viewOutstanding: true });
          }}
          className={viewOutstanding ? "selected" : ""}
        >
          <span>Outstanding</span>
        </button>
        <button
          name="paid"
          type="button"
          onClick={(e) => {
            e.preventDefault();
            this.setState({ viewOutstanding: false });
          }}
          className={viewOutstanding ? "" : "selected"}
        >
          <span>Paid</span>
        </button>
      </div>
    );

    const invoiceList = (
      <InvoiceList
        invoices={deliveries}
        viewOutstanding={viewOutstanding}
        mainContact={currentUser.user.company.mainContact}
      />
    );

    const paymentSection =
      outstandingDeliveries.length > 0 ? (
        <TotalDueForm
          history={history}
          error={error}
          currentUser={currentUser}
          totalDue={totalDue}
          processing={processing}
          invoices={outstandingDeliveries}
          showCard={showCard}
          onToggleShowCard={this.handleToggleShowCard}
          saveCard={saveCard}
          onToggleSaveCard={this.handleToggleSaveCard}
          autoCharge={autoCharge}
          onToggleAutoCharge={this.handleToggleAutoCharge}
          onPay={this.handlePay}
          onChangeName={this.handleChangeName}
          onChangeNumber={this.handleChangeNumber}
          onDeleteCard={this.handleDeleteCard}
          showCardMenu={showCardMenu}
          onToggleShowCardMenu={this.handleToggleShowCardMenu}
        />
      ) : null;

    const { stripeInfo } = currentUser.user;
    const hasCard = stripeInfo.card && stripeInfo.paymentMethodId;

    return (
      <div className="homepage invoice">
        <PageHeader
          onClose={onCloseFromHome || this.handleClose}
          title="Invoices"
          showShadow={true}
        />
        <div
          className={`invoice-page-content ${
            showCard ? "show-card" : "hide-card"
          } ${hasCard ? "with-card" : "without-card"} ${
            viewOutstanding ? "outstanding" : "paid"
          }`}
        >
          <div className="invoices-wrapper">
            {filterOptions}
            {loading ? <LoadingIcon /> : invoiceList}
          </div>
          {viewOutstanding ? paymentSection : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  LOGGING >= 3 &&
    console.log("PageInvoices mapStateToProps got reduxState:", reduxState);
  return {
    currentUser: reduxState.currentUser,
    deliveries: reduxState.deliveries,
  };
}

export default connect(mapStateToProps, {
  fetchDeliveriesDelivered,
  createPaymentIntent,
  confirmPaymentIntent,
  confirmCardPayment,
  deleteCard,
})(PageInvoices);
