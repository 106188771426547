import { apiCall } from "../api";
import { LOAD_INGREDIENTS, RESET_INGREDIENTS } from "../actionTypes";
import { addError } from "./errors";
import { LOGGING } from "../../hocs/constants";

export const loadIngredients = (ingredients) => ({
  type: LOAD_INGREDIENTS,
  ingredients,
});

export const cleanIngredients = (ingredients) => ({
  type: RESET_INGREDIENTS,
});

export const fetchIngredients = () => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    if (currentUser.user.role !== "ops") {
      dispatch(addError("only admin can see all ingredients"));
      return "error";
    }

    return apiCall("GET", `/users/${currentUser.user._id}/ingredients`)
      .then((ingredients) => {
        dispatch(loadIngredients(ingredients));
      })
      .catch((err) => {
        dispatch(addError(err.message));
      });
  };
};

export const fetchIngredient = ({ id }) => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    return apiCall("GET", `/users/${currentUser.user._id}/ingredients/${id}`)
      .then((ingredient) => {
        return ingredient;
      })
      .catch((err) => {
        dispatch(addError(err.message));
      });
  };
};

export const createIngredient = ({ name, type }) => {
  return (dispatch, getState) => {
    const { currentUser, ingredients } = getState();
    if (currentUser.user.role !== "ops") {
      dispatch(addError("only admin can create a ingredient"));
      return "error";
    }

    return apiCall("post", `/users/${currentUser.user._id}/ingredients`, {
      name,
      type,
    })
      .then((ingredient) => {
        dispatch(loadIngredients([...ingredients, ingredient]));
      })
      .catch((err) => {
        dispatch(addError(err.message));
      });
  };
};

export const updateIngredient = (ingredientId, { name, type }) => {
  return (dispatch, getState) => {
    const { currentUser, ingredients } = getState();
    if (currentUser.user.role !== "ops") {
      dispatch(addError("only admin can create a ingredient"));
      return "error";
    }

    return apiCall(
      "put",
      `/users/${currentUser.user._id}/ingredients/${ingredientId}`,
      { name, type }
    )
      .then((updatedIngredient) => {
        const updatedIngredients = ingredients.map((ingredient) =>
          ingredient._id === ingredientId ? updatedIngredient : ingredient
        );
        dispatch(loadIngredients(updatedIngredients));
      })
      .catch((err) => {
        dispatch(addError(err.message));
      });
  };
};

export const deleteIngredient = (ingredientId) => {
  return (dispatch, getState) => {
    const { currentUser, ingredients } = getState();

    LOGGING >= 1 && console.log("currentUser got user:", currentUser);
    if (currentUser.user.role !== "ops") {
      dispatch(addError("only admin can create a ingredient"));
      return "error";
    }

    return apiCall(
      "delete",
      `/users/${currentUser.user._id}/ingredients/${ingredientId}`
    )
      .then(() => {
        const updatedIngredients = ingredients.filter(
          (ingredient) => ingredient._id !== ingredientId
        );
        dispatch(loadIngredients(updatedIngredients));
      })
      .catch((err) => {
        dispatch(addError(err.message));
      });
  };
};
