import React from "react";
import "./FormWidgets.scss";
import { ClosePage, ConfirmAlert, Select as SelectWidget } from "../widgets";
import { confirmAlert } from "react-confirm-alert";

const FormWrapper = ({ formName, formSections, onSave, onCancel }) => {
  const buttons = (
    <div className="button-wrapper">
      <button
        type="button"
        onClick={() => {
          confirmAlert({
            customUI: ({ onClose }) => (
              <ConfirmAlert
                onConfirm={onCancel}
                onClose={onClose}
                actionName={"cancel"}
              />
            ),
          });
        }}
      >
        cancel
      </button>
      <button type="submit">save</button>
    </div>
  );
  return (
    <React.Fragment>
      <div className="entity-form-header">
        <h2>{formName}</h2>
        <ClosePage onClick={onCancel} />
      </div>
      <form onSubmit={onSave} className="entity-form">
        <div className="form-body">
          {formSections.map((formSection, index) => (
            <div className="form-section" key={index}>
              {formSection}
            </div>
          ))}
        </div>
        {buttons}
      </form>
    </React.Fragment>
  );
};

const EntityField = ({ fieldLabel, fieldContent, fieldClass }) => (
  <div className="entity-field">
    <div className="entity-field-label">{fieldLabel}</div>
    <div className={`entity-field-content ${fieldClass || ""}`}>
      {fieldContent}
    </div>
  </div>
);

const Input = ({
  labelText,
  value,
  onChange,
  placeholderText,
  disabled = false,
}) => (
  <EntityField
    fieldLabel={labelText}
    fieldContent={
      <input
        value={value || ""}
        placeholder={placeholderText}
        type="text"
        onChange={onChange}
        disabled={disabled}
      />
    }
  />
);

const Select = ({
  labelText,
  value,
  valueToTag,
  onChange,
  options,
  onToggleShowOptions,
  showOptions,
}) => (
  <EntityField
    fieldLabel={labelText}
    fieldContent={
      <SelectWidget
        selected={value}
        onSelect={onChange}
        options={options}
        optionWords={valueToTag}
        showOptions={showOptions}
        onToggleShowOptions={onToggleShowOptions}
        onHideOptions={onToggleShowOptions}
        className="select-handle"
      />
    }
  />
);

export {
  FormWrapper,
  EntityField,
  // ingredient, cuisine, restaurant, dish
  Input,
  // ingredient: name
  // dish: name
  // cuisine: name
  // restaurant: name, app/website
  Select,
  // ingredient: type
  // restaurant: ordering method, ordering latency
  // dish: best consuming latency
};
