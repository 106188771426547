import { LOAD_DISHES, RESET_DISHES } from "../actionTypes";
import { PURGE } from "redux-persist";

const dishes = (state = [], action) => {
  switch (action.type) {
    case LOAD_DISHES:
      return [...action.dishes];
    case RESET_DISHES:
      return [];
    case PURGE:
      return [];
    default:
      return state;
  }
};

export default dishes;
