import {
  DateKeyFormat,
  CALIFORNIA_TIMEZONE,
  LOGGING,
  lunchDinnerBorderHour,
} from "../../hocs/constants";

import { OrderingMethodNames } from "../../screens/dashboard/FormRestaurant";

import moment from "moment-timezone";
export { changeInstances, fetchInstances, resetInstances } from "./instances";
export {
  fetchCompanies,
  deleteCompany,
  updateCompany,
  resetCompanies,
} from "./companies";
export {
  fetchDishes,
  fetchDish,
  deleteDish,
  updateDish,
  createDish,
} from "./dishes";
export {
  fetchDeliveries,
  fetchDeliveriesDelivered,
  createDelivery,
  updateDelivery,
  resetDeliveries,
  loadDeliveries,
  createPaymentIntent,
  confirmPaymentIntent,
  confirmCardPayment,
} from "./deliveries";
export {
  fetchOrders,
  fetchOrder,
  updateOrder,
  fetchOrdersById,
  addInstanceToOrder,
  removeInstanceFromOrder,
} from "./orders";
export {
  goToNextMonth,
  goToPrevMonth,
  goToCurrent,
  goToDate,
  resetCalendar,
  goToSelectedDayMonth,
  setMeal,
} from "./calendar";
export {
  verifyContact,
  authUser,
  authWithFacebook,
  authUserWithPhoneOnly,
  logout,
  updateUserPassword,
  reloadUser,
  setCurrentUser,
  deleteCard,
  updateCard,
} from "./auth";
export { removeError } from "./errors";
export {
  fetchRestaurants,
  deleteRestaurant,
  updateRestaurant,
  fetchRestaurant,
  createRestaurant,
} from "./restaurants";
export {
  fetchCuisine,
  fetchCuisines,
  createCuisine,
  updateCuisine,
  deleteCuisine,
  cleanCuisines,
} from "./cuisines";
export { fetchUsers, deleteUser, updateUser, cleanUsers } from "./users";
export {
  fetchIngredients,
  deleteIngredient,
  updateIngredient,
  createIngredient,
  fetchIngredient,
} from "./ingredients";
export {
  setDataModeSortBy,
  setDataModeCategoryBy,
  setDataModeSelected,
  setDataModeLoaded,
  resetDataMode,
} from "./datamodes";

export { removeErrors } from "./errors";

export const convertInstanceServerToClient = (instance) => {
  const {
    byTime,
    user,
    status,
    _id,
    dish,
    delivery,
    order,
    rating,
    ratingTime,
    comment,
    commentTime,
  } = instance;
  const { company, name, _id: userId } = user;
  const { address, _id: companyId, mainContact } = company;
  const momentByTime = moment.tz(byTime, CALIFORNIA_TIMEZONE);

  return {
    _id,
    byTime,
    deliveryId: delivery ? delivery._id : null,
    dispatchTime: delivery ? delivery.dispatchTime : -1,
    deliverTime: delivery ? delivery.deliverTime : -1,
    name,
    dish,
    orderId: order ? order._id : null,
    orderTime: order ? order.orderTime : -1,
    pickUpTime: order ? order.pickUpTime : -1,
    userId,
    address,
    mainContact,
    companyId,
    status,
    dateKey: momentByTime.format(DateKeyFormat),
    isLunch: momentByTime.toDate().getHours() < lunchDinnerBorderHour,
    rating,
    ratingTime,
    comment,
    commentTime,
  };
};

// Client-Side Delivery Specs: Redundant, Quick To Access.
// {
//   _id,
//   company: {_id, address}
//   byTime,
//   dateKey,
//   isLunch,
//   eaters: [{ _id, instanceId, name, dish, showDetails }],
//   etaByOps: { hour, minute, utc },
//   etaByDriver: { hour, minute, utc },
// }
//
// Server-Side Delivery Specs: Minimum
// {
//   _id,
//   company,
//   byTime,
//   instances,
//   etaByOps,
//   etaUTCByDriver,
// }
export const convertDeliveryServerToClient = (delivery) => {
  const {
    _id,
    company,
    byTime,
    dispatchTime,
    deliverTime,
    payTime,
    ratingTime,
    commentTime,
    instances,
    rating,
    comment,
    etaByOps,
    etaUTCByDriver,
  } = delivery;

  const momentByTime = moment.tz(byTime, CALIFORNIA_TIMEZONE);
  const convertedInstances = instances
    .filter((instance) => instance.user)
    .map((i) => {
      return {
        _id: i._id,
        user: i.user._id,
        name: i.user.name,
        status: i.status,
        showDetails: false,
        dish: i.dish,
        order: i.order,
        delivery: i.delivery,
      };
    });

  return {
    _id,
    company: {
      ...company,
      name: company && company.mainContact ? company.mainContact.name : "",
      phone: company && company.mainContact ? company.mainContact.phone : "",
    },
    rating,
    comment,
    byTime,
    dispatchTime,
    deliverTime,
    ratingTime,
    commentTime,
    payTime,
    dateKey: momentByTime.format(DateKeyFormat),
    isLunch: momentByTime.toDate().getHours() < lunchDinnerBorderHour,
    instances: convertedInstances,
    etaByOps: etaByOps || momentByTime.valueOf(),
    etaByDriver: etaUTCByDriver || momentByTime.valueOf(),
  };
};

export const convertOrderServerToClient = (order) => {
  const {
    _id,
    restaurant,
    instances,
    etaByOps,
    orderTime,
    payTime,
    pickUpTime,
  } = order;

  const momentETAByOps = moment.tz(etaByOps, CALIFORNIA_TIMEZONE);
  const { orderingMethod } = restaurant;

  const orderBy =
    orderingMethod === OrderingMethodNames.ORDER_PAY_SITE ? "driver" : "ops";
  const payBy = [
    OrderingMethodNames.ORDER_PAY_WEB,
    OrderingMethodNames.ORDER_PAY_APP,
  ].includes(orderingMethod)
    ? "ops"
    : "driver";
  LOGGING >= 1 &&
    console.log("converting order: ", {
      order,
      orderBy,
      payBy,
      orderingMethod,
    });

  return {
    _id,
    restaurant: { ...restaurant, orderBy, payBy },
    dateKey: momentETAByOps.format(DateKeyFormat),
    isLunch: momentETAByOps.toDate().getHours() < lunchDinnerBorderHour,
    instances: instances.map((i) => ({
      user: i.user._id,
      company: {
        name: i.user.company.mainContact.name,
        address: i.user.company.address,
        _id: i.user.company._id,
      },
      _id: i._id,
      name: i.user.name,
      status: i.status,
      showDetails: false,
      dish: i.dish,
    })),
    etaByOps,
    orderTime,
    payTime,
    pickUpTime,
  };
};
