import { apiCall } from "../api";
import { LOAD_RESTAURANTS, RESET_RESTAURANTS } from "../actionTypes";
import { addError } from "./errors";
import { loadDishes } from "./dishes";
import { LOGGING } from "../../hocs/constants";

const SetOfObjects = (arrayOfObjects) => {
  return [...new Set(arrayOfObjects.map(JSON.stringify))].map(JSON.parse);
};

export const loadRestaurants = (restaurants) => ({
  type: LOAD_RESTAURANTS,
  restaurants,
});

export const cleanRestaurants = () => ({
  type: RESET_RESTAURANTS,
});

export const fetchRestaurants = () => {
  LOGGING >= 3 && console.log("fetchRestaurants");

  return (dispatch, getState) => {
    const { currentUser } = getState();

    LOGGING >= 3 &&
      console.log("fetchRestaurants got currentUser:", currentUser);
    if (currentUser.user.role !== "ops") {
      dispatch(addError("only admin can see all restaurants"));
      return "error";
    }

    return apiCall("GET", `/users/${currentUser.user._id}/restaurants`)
      .then((restaurants) => {
        LOGGING >= 1 &&
          console.log("fetchRestaurants got restaurants", restaurants);
        const restaurantsWithInferredCuisines = restaurants.map(
          (restaurant) => {
            LOGGING >= 3 &&
              console.log("fetchRestaurants got restaurant", restaurant);
            const { dishes } = restaurant;
            LOGGING >= 3 && console.log("fetchRestaurants got dishes", dishes);
            const cuisineFromDishes = dishes
              .map((d) => d.cuisines)
              .reduce((a, b) => SetOfObjects(a.concat(b)), []);
            LOGGING >= 3 &&
              console.log(
                "fetchRestaurants got cuisineFromDishes",
                cuisineFromDishes
              );
            return { ...restaurant, cuisines: cuisineFromDishes };
          }
        );
        LOGGING >= 3 &&
          console.log(
            "fetchRestaurants got restaurantsWithInferredCuisines",
            restaurantsWithInferredCuisines
          );
        dispatch(loadRestaurants(restaurantsWithInferredCuisines));
        // dispatch(loadRestaurants(restaurants));
        // return "OK";
      })
      .catch((err) => {
        dispatch(addError(err.message));
      });
  };
};
export const fetchRestaurant = ({ id }) => {
  LOGGING >= 1 && console.log("fetchRestaurant got id:", id);

  return (dispatch, getState) => {
    const { currentUser } = getState();

    return apiCall("GET", `/users/${currentUser.user._id}/restaurants/${id}`)
      .then((restaurant) => {
        LOGGING >= 3 &&
          console.log("fetchRestaurant got restaurant", restaurant);
        const { dishes } = restaurant;
        LOGGING >= 3 && console.log("fetchRestaurant got dishes", dishes);
        const cuisineFromDishes = dishes
          .map((d) => d.cuisines)
          .reduce((a, b) => SetOfObjects(a.concat(b)), []);
        LOGGING >= 3 &&
          console.log(
            "fetchRestaurant got cuisineFromDishes",
            cuisineFromDishes
          );
        return { ...restaurant, cuisines: cuisineFromDishes };
      })
      .catch((err) => {
        dispatch(addError(err.message));
      });
  };
};
export const createRestaurant = (data) => {
  LOGGING >= 3 && console.log("createRestaurant called with data:", data);

  return (dispatch, getState) => {
    const { currentUser, restaurants } = getState();

    LOGGING >= 3 && console.log("currentUser got user:", currentUser);
    if (currentUser.user.role !== "ops") {
      dispatch(addError("only admin can create a restaurant"));
      return "error";
    }

    return apiCall("post", `/users/${currentUser.user._id}/restaurants`, data)
      .then((res) => {
        LOGGING >= 3 && console.log("createRestaurant got res", res);
        // const { restaurants } = res;
        dispatch(loadRestaurants([...restaurants, res]));
        return res;
      })
      .catch((err) => {
        dispatch(addError(err.message));
      });
  };
};

export const updateRestaurant = (restaurant) => {
  return (dispatch, getState) => {
    const { currentUser, restaurants } = getState();

    LOGGING >= 1 && console.log("currentUser got user:", currentUser.user.role);
    if (currentUser.user.role !== "ops") {
      dispatch(addError("only admin can update a restaurant"));
      return "error";
    }

    const { _id } = restaurant;
    let data = Object.fromEntries(
      Object.entries(restaurant).filter(([key, val]) => key !== "_id")
    );
    const { dishes } = data;
    data = {
      ...data,
      dishes: dishes.map((d) => d._id),
    };
    return apiCall("put", `/users/${currentUser.user._id}/restaurants/${_id}`, {
      ...data,
    })
      .then((res) => {
        LOGGING >= 1 && console.log("updateRestaurant got res", res);
        // const { restaurants } = res;
        const updatedRestaurants = restaurants.map((restaurant) =>
          restaurant._id === _id ? res : restaurant
        );
        const restaurantsWithInferredCuisines = updatedRestaurants.map(
          (restaurant) => {
            LOGGING >= 1 &&
              console.log("updateRestaurant got restaurant", restaurant);
            const { dishes } = restaurant;
            LOGGING >= 1 && console.log("updateRestaurant got dishes", dishes);
            const cuisineFromDishes = dishes
              .map((d) => d.cuisines)
              .reduce((a, b) => SetOfObjects(a.concat(b)), []);
            LOGGING >= 1 &&
              console.log(
                "updateRestaurant got cuisineFromDishes",
                cuisineFromDishes
              );
            return { ...restaurant, cuisines: cuisineFromDishes };
          }
        );
        LOGGING >= 1 &&
          console.log(
            "updateRestaurant got restaurantsWithInferredCuisines",
            restaurantsWithInferredCuisines
          );

        dispatch(loadRestaurants(restaurantsWithInferredCuisines));
        return res;
      })
      .catch((err) => {
        dispatch(addError(err.message));
      });
  };
};

export const deleteRestaurant = (id) => {
  LOGGING >= 1 && console.log("deleteRestaurant");

  return (dispatch, getState) => {
    const { currentUser, restaurants, dishes } = getState();

    LOGGING >= 1 && console.log("currentUser got user:", currentUser);
    if (currentUser.user.role !== "ops") {
      dispatch(addError("only admin can create a restaurant"));
      return "error";
    }

    return apiCall("delete", `/users/${currentUser.user._id}/restaurants/${id}`)
      .then((res) => {
        LOGGING >= 1 && console.log("deleteRestaurant got res", res);
        const { deletedId, deletedChildrenIds } = res;
        const updated = restaurants.filter((entry) => entry._id !== deletedId);
        const updatedChildren = dishes.filter(
          (entry) => !deletedChildrenIds.includes(entry._id)
        );
        dispatch(loadRestaurants(updated));
        dispatch(loadDishes(updatedChildren));
        return res;
      })
      .catch((err) => {
        dispatch(addError(err.message));
      });
  };
};
