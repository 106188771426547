import { LOAD_COMPANIES, RESET_COMPANIES } from "../actionTypes";
import { PURGE } from "redux-persist";

const users = (state = [], action) => {
  switch (action.type) {
    case LOAD_COMPANIES:
      return [...action.companies];
    case RESET_COMPANIES:
      return [];
    case PURGE:
      return [];
    default:
      return state;
  }
};

export default users;
