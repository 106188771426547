import {
  LOAD_USERS,
  LOAD_DELEGATED_USER_FOR_ADMIN,
  REMOVE_USERS,
} from "../actionTypes";
import { PURGE } from "redux-persist";

const users = (state = [], action) => {
  switch (action.type) {
    case LOAD_USERS:
      return [...action.users];
    // case REHYDRATE:    // This added just to show that this action type also exists, can be omitted.
    //   LOGGING>=3 && console.log("REHYDRATING!!!!");
    //   return state;
    case LOAD_DELEGATED_USER_FOR_ADMIN:
      return [...action.delegatedUserForAdmin];
    // case REHYDRATE:    // This added just to show that this action type also exists, can be omitted.
    //   LOGGING>=3 && console.log("REHYDRATING!!!!");
    //   return state;
    case REMOVE_USERS:
      return [];
    case PURGE:
      return [];
    default:
      return state;
  }
};

export default users;
