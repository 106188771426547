import {
  SET_DATAMODE_SORT_BY,
  SET_DATAMODE_CATEGORY_BY,
  SET_DATAMODE_SELECTED,
  RESET_DATAMODE,
  SET_DATAMODE_LOADED,
} from "../actionTypes";
import { LOGGING } from "../../hocs/constants";

export const setDataModeSortBy = ({ dataType, sortByKey }) => {
  LOGGING >= 1 &&
    console.log("setDataModeSortBy got {dataType, sortByKey}:", {
      dataType,
      sortByKey,
    });
  return {
    type: SET_DATAMODE_SORT_BY,
    dataType,
    sortByKey,
  };
};

export const setDataModeCategoryBy = ({ dataType, categoryByKey }) => {
  LOGGING >= 1 &&
    console.log("setDataModeCategoryBy got {dataType, categoryByKey}:", {
      dataType,
      categoryByKey,
    });
  return {
    type: SET_DATAMODE_CATEGORY_BY,
    dataType,
    categoryByKey,
  };
};

export const setDataModeLoaded = ({ dataType, loadedStatus }) => {
  LOGGING >= 1 &&
    console.log("setDataModeLoaded got {dataType, loadedStatus}:", {
      dataType,
      loadedStatus,
    });
  return {
    type: SET_DATAMODE_LOADED,
    dataType,
    loadedStatus,
  };
};

export const setDataModeSelected = ({ selected }) => {
  LOGGING >= 3 && console.log("setDataModeSelected got selected:", selected);
  return {
    type: SET_DATAMODE_SELECTED,
    selected,
  };
};

export const resetDataMode = () => {
  LOGGING >= 3 && console.log("resetDataMode CALLED");
  return {
    type: RESET_DATAMODE,
  };
};
