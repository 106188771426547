import React from "react";
import NumberFormat from "react-number-format";
import { ErrorMessage } from "../widgets";
import "./PhoneForm.scss";

const PhoneForm = (props) => {
  const {
    onBook,
    onPhoneChange,
    phoneNumber,
    phoneEater,
    phoneOps,
    actionName,
    isDelegate,
    errors,
  } = props;

  const hasError = errors && errors.message && errors.message.length > 0;
  const errorMessage = (
    <ErrorMessage message={hasError ? errors.message : ""} />
  );
  const ContactPhoneDisplay = (
    <div
      className={`form-field ${
        !isDelegate && hasError ? "has-error" : "no-error"
      }`}
    >
      <NumberFormat
        format="(###) ###-####"
        mask="#"
        type="tel"
        placeholder="Enter your phone number"
        onValueChange={onPhoneChange.bind(this, "phoneNumber")}
        value={phoneNumber}
      />
    </div>
  );

  const EaterPhoneDisplay = (
    <div className="form-field">
      <label>eater phone</label>
      <NumberFormat
        format="(###) ###-####"
        mask="#"
        type="tel"
        placeholder="Enter eater's phone number"
        onValueChange={onPhoneChange.bind(this, "phoneEater")}
        value={phoneEater}
      />
    </div>
  );

  const OpsPhoneDisplay = (
    <div className="form-field">
      <label>ops phone</label>
      <NumberFormat
        format="(###) ###-####"
        mask="#"
        type="tel"
        placeholder="Enter ops' phone number"
        onValueChange={onPhoneChange.bind(this, "phoneOps")}
        value={phoneOps}
      />
    </div>
  );

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onBook.call(this);
      }}
      className="phone-form"
    >
      {isDelegate ? (
        <React.Fragment>
          {EaterPhoneDisplay}
          {OpsPhoneDisplay}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {errorMessage}
          {ContactPhoneDisplay}
        </React.Fragment>
      )}

      <button
        type="submit"
        disabled={
          isDelegate
            ? phoneOps.length < 0 || phoneEater.length < 0
            : phoneNumber.length < 10
        }
      >
        {actionName}
      </button>
    </form>
  );
};

export default PhoneForm;
