import React from "react"; // eslint-disable-line no-unused-vars
import "./ExpandToggle.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import {
  faChevronDown as faChevronDownBold,
  faChevronUp as faChevronUpBold,
} from "@fortawesome/free-solid-svg-icons";
const ExpandToggle = ({
  toggleOffText,
  toggleOnText,
  onToggle,
  showDetails,
  bold = false,
  className,
}) => (
  <button
    type="button"
    className={`expand-toggle ${className || ""}`}
    onClick={onToggle}
  >
    <span>{showDetails ? toggleOffText : toggleOnText}</span>
    {bold ? (
      <FontAwesomeIcon
        icon={showDetails ? faChevronUpBold : faChevronDownBold}
      />
    ) : (
      <FontAwesomeIcon icon={showDetails ? faChevronUp : faChevronDown} />
    )}
  </button>
);
export default ExpandToggle;
