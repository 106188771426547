import React from "react";
import "./SelectMealType.scss";
const SelectMealType = ({ isLunch, onToggle, numOfInstances }) => (
  <div className="select-meal-type">
    <button
      className={`${isLunch ? "selected" : "unselected"}`}
      type="click"
      onClick={onToggle.bind(this, true)}
    >
      {`Lunch (${numOfInstances ? numOfInstances.lunch : "error"})`}
    </button>
    <button
      className={`${isLunch ? "unselected" : "selected"}`}
      type="click"
      onClick={onToggle.bind(this, false)}
    >
      {`Dinner (${numOfInstances ? numOfInstances.dinner : "error"})`}
    </button>
  </div>
);
export default SelectMealType;
