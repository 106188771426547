import React, { Component } from "react"; // eslint-disable-line no-unused-vars
import "./index.scss";
import { connect } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css";
import FormIngredient from "./FormIngredient";
import { createIngredient, updateIngredient } from "../../store/actions";
import { LoadingIcon } from "../widgets";

class PageIngredient extends Component {
  constructor(props) {
    super(props);
    const { id } = props.match.params;
    const { ingredients } = props;
    const ingredient =
      id === "new"
        ? { name: "", type: null }
        : ingredients.find((i) => i._id === id);
    this.state = {
      loading: false,
      ingredient,
      showTypeOptions: false,
    };
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleToggleShowTypeOptions = this.handleToggleShowTypeOptions.bind(
      this
    );
    this.handleSelectType = this.handleSelectType.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleChangeName(e) {
    e.preventDefault();
    const { ingredient } = this.state;
    this.setState({ ingredient: { ...ingredient, name: e.target.value } });
  }

  handleToggleShowTypeOptions() {
    const { showTypeOptions } = this.state;
    this.setState({ showTypeOptions: !showTypeOptions });
  }

  handleSelectType(type) {
    const { ingredient } = this.state;

    this.setState({
      ingredient: { ...ingredient, type },
      showTypeOptions: false,
    });
  }
  handleSave(e) {
    e.preventDefault();
    const { ingredient } = this.state;
    const { _id, name, type } = ingredient;

    this.setState({ loading: true });
    if (_id) {
      this.props.updateIngredient(_id, { name, type }).then((res) => {
        this.setState({ loading: false });
        this.props.history.goBack();
      });
    } else {
      this.props.createIngredient({ name, type }).then((res) => {
        this.setState({ loading: false });
        this.props.history.goBack();
      });
    }
  }

  handleCancel = () => {
    this.props.history.goBack();
  };

  render() {
    const { ingredient, loading, showTypeOptions } = this.state;

    return (
      <div className="homepage entity">
        {loading ? (
          <LoadingIcon />
        ) : (
          <FormIngredient
            ingredient={ingredient}
            showTypeOptions={showTypeOptions}
            onEditName={this.handleChangeName}
            onToggleShowTypeOptions={this.handleToggleShowTypeOptions}
            onSelectType={this.handleSelectType}
            onSave={this.handleSave}
            onCancel={this.handleCancel}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  return {
    ingredients: reduxState.ingredients,
  };
}

export default connect(mapStateToProps, {
  createIngredient,
  updateIngredient,
})(PageIngredient);
