import React from "react"; // eslint-disable-line no-unused-vars
import "./ConfirmAlert.css";

const ConfirmAlert = ({ onConfirm, onClose, actionName }) => (
  <div className="confirm-window">
    <div className="confirm-alert">
      <p>Are you sure you want to {actionName} this?</p>
      <div className="confirm-buttons">
        <button className="no-button" onClick={onClose}>
          <span>No.</span>
        </button>
        <button
          className="yes-button"
          onClick={() => {
            onConfirm && onConfirm();
            onClose && onClose();
          }}
        >
          <span>Yes, {actionName} it!</span>
        </button>
      </div>
    </div>
  </div>
);

export default ConfirmAlert;
