import { apiCall } from "../api";
import { LOAD_CUISINES, RESET_CUISINES } from "../actionTypes";
import { addError } from "./errors";
import { LOGGING } from "../../hocs/constants";

export const loadCuisines = (cuisines) => ({
  type: LOAD_CUISINES,
  cuisines,
});

export const cleanCuisines = (cuisines) => ({
  type: RESET_CUISINES,
});

export const fetchCuisines = () => {
  LOGGING && console.log("fetchCuisines");

  return (dispatch, getState) => {
    const { currentUser } = getState();

    return apiCall("GET", `/users/${currentUser.user._id}/cuisines`)
      .then((cuisines) => {
        dispatch(loadCuisines(cuisines));
        return cuisines;
      })
      .catch((err) => {
        dispatch(addError(err.message));
      });
  };
};

export const fetchCuisine = ({ id }) => {
  return (dispatch, getState) => {
    const { currentUser } = getState();

    return apiCall("GET", `/users/${currentUser.user._id}/cuisines/${id}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        dispatch(addError(err.message));
      });
  };
};

export const createCuisine = ({ name }) => {
  return (dispatch, getState) => {
    const { currentUser, cuisines } = getState();

    if (currentUser.user.role !== "ops") {
      dispatch(addError("only admin can create a cuisine"));
      return "error";
    }

    return apiCall("post", `/users/${currentUser.user._id}/cuisines`, { name })
      .then((res) => {
        dispatch(loadCuisines([...cuisines, res]));
        return res;
      })
      .catch((err) => {
        dispatch(addError(err.message));
      });
  };
};

export const updateCuisine = (
  cuisineId,
  { name, address, notes, shortName }
) => {
  LOGGING && console.log("updateCuisine got cuisineId:", cuisineId);
  LOGGING &&
    console.log("updateCuisine got attributes:", {
      name,
      address,
      notes,
      shortName,
    });

  return (dispatch, getState) => {
    const { currentUser, cuisines } = getState();

    LOGGING && console.log("currentUser got user:", currentUser.user.role);
    if (currentUser.user.role !== "ops") {
      dispatch(addError("only admin can create a cuisine"));
      return "error";
    }

    return apiCall(
      "put",
      `/users/${currentUser.user._id}/cuisines/${cuisineId}`,
      { name, address, notes, shortName }
    )
      .then((res) => {
        LOGGING && console.log("createCuisine got res", res);
        // const { cuisines } = res;
        const updatedCuisines = cuisines.map((cuisine) =>
          cuisine._id === cuisineId ? res : cuisine
        );
        dispatch(loadCuisines(updatedCuisines));
        return res;
      })
      .catch((err) => {
        dispatch(addError(err.message));
      });
  };
};

export const deleteCuisine = (cuisineId) => {
  LOGGING && console.log("deleteCuisine");

  return (dispatch, getState) => {
    const { currentUser, cuisines } = getState();

    LOGGING && console.log("currentUser got user:", currentUser);
    if (currentUser.user.role !== "ops") {
      dispatch(addError("only admin can create a cuisine"));
      return "error";
    }

    return apiCall(
      "delete",
      `/users/${currentUser.user._id}/cuisines/${cuisineId}`
    )
      .then((res) => {
        LOGGING && console.log("deleteCuisine got res", res);
        const updatedCuisines = cuisines.filter(
          (cuisine) => cuisine._id !== cuisineId
        );
        dispatch(loadCuisines(updatedCuisines));
        return res;
      })
      .catch((err) => {
        dispatch(addError(err.message));
      });
  };
};
