import React from "react";
import "./UnitDetails.scss";
import { getUnitStatus } from "./";
import { Sticker, FormattedRow } from "../../widgets";
import { LOGGING } from "../../../hocs/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUtensils } from "@fortawesome/pro-regular-svg-icons";
import { DishContent, MealStatus } from "./";

export const InstanceDetails = ({ dish, eaterName }) => (
  <Sticker
    icon={
      <div className="round-icon purple-icon">
        <FontAwesomeIcon icon={faUtensils} />
      </div>
    }
    content={<DishContent dish={dish} name={eaterName} />}
    className="instance-details"
  />
);

const UnitDetailsOrder = ({
  role,
  restaurant,
  instances, // cancelled or not
  orderTime,
  pickUpTime,
}) => {
  let companies = [];
  LOGGING >= 1 &&
    console.log("UnitDetailsOrder rendering with: ", {
      role,
      restaurant,
      instances, // cancelled or not
      orderTime,
      pickUpTime,
    });
  instances.forEach((instance) => {
    const { dish, name, company } = instance;
    const companyIndex = companies.findIndex((c) => c._id === company._id);
    if (companyIndex < 0) {
      companies.push({
        ...company,
        eaters: [{ dish, name }],
      });
    } else {
      companies[companyIndex].eaters.push({ dish, name });
    }
  });

  const mealsSection = companies.map((company, index) => (
    <div className="unit-sub-section" key={index}>
      <div className="unit-sub-section-title company-info">
        <FormattedRow
          list={[company.name, <div>{company.address}</div>]}
          addBullet={true}
        />
      </div>
      {company.eaters.map((eater, index) => (
        <InstanceDetails key={index} dish={eater.dish} eaterName={eater.name} />
      ))}
    </div>
  ));

  const { orderBy, payBy } = restaurant;
  const orderStatus = getUnitStatus({
    action: "order",
    time: orderTime,
    readyForAction: false, //so that it will never be a button in the details section
    actionRole: orderBy,
    displayRole: role,
  });

  const pickUpStatus = getUnitStatus({
    action: "pick-up",
    time: pickUpTime,
    readyForAction: orderTime && orderTime.utc > 0,
    actionRole: payBy,
    displayRole: role,
  });

  const orderInfo = <MealStatus time={orderTime} status={orderStatus} />;
  const pickUpInfo = <MealStatus time={pickUpTime} status={pickUpStatus} />;

  return (
    <div className="unit-details">
      <div className="unit-section unit-section-timeline">
        <div className="unit-section-title">timeline</div>
        <div className="unit-section-body">
          {orderInfo}
          {pickUpInfo}
        </div>
      </div>
      <div className="unit-section unit-section-meals">
        <div className="unit-section-title">meals</div>
        <div className="unit-section-body">{mealsSection}</div>
      </div>
    </div>
  );
};
export default UnitDetailsOrder;
