import React, { Component } from "react"; // eslint-disable-line no-unused-vars
import "./FormEaters.scss";
import NumberFormat from "react-number-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faMinusCircle,
  faTimesCircle,
} from "@fortawesome/pro-light-svg-icons";
import { LOGGING } from "../../hocs/constants";
import {
  EaterName,
  PopUp,
  StepFormFooter,
  ProfileFormFooter,
  ProfileStepText,
} from "../widgets";
import { Redirect } from "react-router-dom";

const EaterCounter = ({ eaters, onInc, onDec }) => (
  <div className="eater-counter">
    <button
      className="flex-by-row"
      type="button"
      onClick={onDec}
      disabled={eaters === 1}
    >
      <FontAwesomeIcon icon={faMinusCircle} />
    </button>
    <span>{eaters}</span>
    <button className="flex-by-row" type="button" onClick={onInc}>
      <FontAwesomeIcon icon={faPlusCircle} />
    </button>
  </div>
);

const EaterContact = ({
  phone,
  name,
  index,
  onPhoneChange,
  onNameChange,
  onDelete,
  isMainContact,
}) => (
  <div
    className={`step-content-card 
    ${index === 0 ? "first-eater" : ""} 
    ${phone && phone.length < 10 && phone.length > 0 ? "phone-invalid" : ""}    
    `}
  >
    <div className="eater-contact-header">
      {name ? (
        <EaterName name={name} isMainContact={isMainContact} />
      ) : (
        <span className="eater-index">{`eater ${index + 1}`}</span>
      )}
      <button
        className="delete-eater"
        type="button"
        onClick={onDelete}
        disabled={index === 0}
      >
        {index === 0 ? null : <FontAwesomeIcon icon={faTimesCircle} />}
      </button>
    </div>

    <div
      className={`form-field required-field ${
        !name || name.length === 0 ? "invalid" : ""
      }`}
    >
      <label>name</label>
      <input
        value={name || ""}
        placeholder="name"
        autoComplete="new-name"
        className="contact-name"
        onChange={onNameChange}
      />
    </div>
    <div className="form-field">
      <label>phone</label>
      <NumberFormat
        format="(###) ###-####"
        mask="#"
        type="tel"
        placeholder="Phone number"
        autoComplete="new-phone"
        onValueChange={onPhoneChange}
        value={phone}
      />
    </div>
  </div>
);

class FormEaters extends Component {
  constructor(props) {
    super();
    let { eaters, sharedDiet } = props;
    this.state = {
      sharedDiet,
      eaters: eaters.map((eater) => ({
        name: eater.name,
        phone: eater.phone,
        _id: eater._id,
      })),
    };
    this.handleAddEater = this.handleAddEater.bind(this);
    this.handleDeductEater = this.handleDeductEater.bind(this);
    this.handleDeleteEater = this.handleDeleteEater.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
  }

  handleAddEater() {
    let { eaters, sharedDiet } = this.state;
    if (eaters.length === 1) {
      sharedDiet = true;
    }
    eaters.push({ name: "", phone: "" });
    this.setState({ eaters, sharedDiet }, () => {
      this.props.updateShadow();
    });
  }

  handleDeductEater() {
    let { eaters, sharedDiet } = this.state;

    eaters.pop();
    if (eaters.length === 1) {
      sharedDiet = true;
    }
    this.setState({ eaters, sharedDiet }, () => {
      this.props.updateShadow();
    });
  }

  handleDeleteEater(index, e) {
    e.preventDefault();
    let { eaters } = this.state;
    LOGGING >= 1 &&
      console.log("handleDeleteEater got eaters before splice:", {
        eaters,
        index,
      });
    eaters.splice(index, 1);
    LOGGING >= 1 &&
      console.log("handleDeleteEater got eaters after splice:", eaters);
    this.setState({ eaters }, () => {
      this.props.updateShadow();
    });
  }

  handleNameChange(index, e) {
    e.preventDefault();
    let { eaters } = this.state;
    eaters = eaters.map((eater, i) =>
      i !== index ? eater : { ...eater, name: e.target.value }
    );
    this.setState({ eaters });
  }

  handlePhoneChange(index, values) {
    let { eaters } = this.state;

    eaters = eaters.map((eater, i) =>
      i === index ? { ...eater, phone: values.value } : eater
    );

    this.setState({ eaters });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.updateShadow();
  }

  render() {
    const { eaters, sharedDiet } = this.state;
    const {
      eaters: eatersOld,
      mainContact,
      sharedDiet: sharedDietOld,
      onNext,
      onPrevious,
      onSave,
      contentOverflow,
      contentRef,
      goto,
      onCancelGoTo,
    } = this.props;

    LOGGING >= 1 &&
      console.log("FormEaters rendering with:", {
        eaters,
        eatersOld,
        sharedDiet,
        sharedDietOld,
        goto,
      });

    const eatersOriginal = eatersOld.map((eater) => ({
      name: eater.name,
      phone: eater.phone,
      _id: eater._id,
    }));

    const eatersUpdated = eaters.map((eater) => ({
      name: eater.name,
      phone: eater.phone,
      _id: eater._id,
    }));

    LOGGING >= 1 &&
      console.log("FormEaters rendering with:", {
        eatersOriginal,
        eatersUpdated,
      });
    const numberOfEaters = (
      <div className="step-form-header">
        <span>eaters</span>
        <EaterCounter
          eaters={eaters.length}
          onInc={this.handleAddEater}
          onDec={this.handleDeductEater}
        />
      </div>
    );

    const eaterContactList = eaters.map((eater, index) => (
      <EaterContact
        key={index}
        name={eater.name}
        phone={eater.phone}
        index={index}
        onNameChange={this.handleNameChange.bind(this, index)}
        onPhoneChange={this.handlePhoneChange.bind(this, index)}
        onDelete={this.handleDeleteEater.bind(this, index)}
        isMainContact={mainContact ? eater._id === mainContact : index === 0}
      />
    ));

    const dataHasError = eaters.find(
      (eater) =>
        (eater.phone && eater.phone.length < 10 && eater.phone.length > 0) ||
        !eater.name ||
        eater.name.length === 0
    );

    const oldData = { eaters: [...eatersOriginal], sharedDiet: sharedDietOld };
    const newData = {
      eaters: [...eatersUpdated],
      sharedDiet,
    };
    const equal = JSON.stringify(oldData) === JSON.stringify(newData);
    LOGGING >= 1 &&
      console.log({
        oldData,
        newData,
        equal,
      });
    if (equal && onSave && goto > 0) {
      return <Redirect to={`/profile/${ProfileStepText[goto - 1]}`} />;
    }
    const gotoConfirm = onSave ? (
      <PopUp
        isPoppedUp={onSave && goto >= 0 && !equal}
        componentToDisplay={
          <div className="goto-confirm">
            <span>Save the changes you made?</span>
            <div className="button-wrapper">
              <button
                className="no-save-goto"
                onClick={onSave.bind(this, null, goto)}
              >
                no
              </button>
              <button
                className="save-goto"
                onClick={onSave.bind(this, newData, goto)}
              >
                yes
              </button>
            </div>
          </div>
        }
        hidePopUp={onCancelGoTo}
      />
    ) : null;

    const formFooter = onSave ? (
      <ProfileFormFooter
        onSave={onSave.bind(this, newData)}
        showShadow={contentOverflow}
        dataHasError={dataHasError}
      />
    ) : (
      <StepFormFooter
        isFirstStep={true}
        isLastStep={false}
        onPrevious={onPrevious}
        onNext={onNext.bind(this, oldData, newData)}
        showShadow={contentOverflow}
        dataHasError={dataHasError}
      />
    );

    return (
      <div className="step-form eaters" ref={contentRef}>
        {gotoConfirm}
        {numberOfEaters}

        <div className="required-field-notes">
          <span>*</span>
          <span>required fields</span>
        </div>

        {eaterContactList}
        {formFooter}
      </div>
    );
  }
}

export default FormEaters;
