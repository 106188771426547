import { LOAD_INGREDIENTS, RESET_INGREDIENTS } from "../actionTypes";
import { PURGE } from "redux-persist";

const ingredients = (state = [], action) => {
  switch (action.type) {
    case LOAD_INGREDIENTS:
      return [...action.ingredients];
    case RESET_INGREDIENTS:
      return [];
    case PURGE:
      return [];
    default:
      return state;
  }
};

export default ingredients;
