import { LOAD_ORDERS, RESET_ORDERS } from "../actionTypes";
import { PURGE } from "redux-persist";

const orders = (state = [], action) => {
  switch (action.type) {
    case LOAD_ORDERS:
      return [...action.orders];
    case RESET_ORDERS:
      return [];
    case PURGE:
      return [];
    default:
      return state;
  }
};

export default orders;
