import React from "react";
import "./Select.scss";
import ExpandToggle from "./ExpandToggle";
import { BubbleUpperRight } from "./";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPlus } from "@fortawesome/pro-regular-svg-icons";

const Select = ({
  selected,
  onSelect,
  options,
  optionWords,
  showOptions,
  onToggleShowOptions,
  onHideOptions,
  className,
}) => {
  const handleText =
    options.findIndex((o) => o === selected) >= 0
      ? optionWords
        ? optionWords[selected]
        : selected
      : "please select";
  return (
    <div className="select-wrapper">
      <ExpandToggle
        onToggle={onToggleShowOptions}
        showDetails={showOptions}
        toggleOnText={handleText}
        toggleOffText={handleText}
        className={className}
      />
      {showOptions ? (
        <BubbleUpperRight
          className="select-option-list"
          onHide={onHideOptions}
          content={options.map((o, index) => (
            <button
              className={`select-option ${
                selected === o ? "selected-option" : ""
              }`}
              key={index}
              type="button"
              onClick={onSelect.bind(this, o)}
            >
              {optionWords ? optionWords[o] : o}
              <FontAwesomeIcon icon={faCheck} />
            </button>
          ))}
        />
      ) : null}
    </div>
  );
};

export const SelectChip = ({ onToggle, isSelected, text }) => (
  <button
    type="button"
    onClick={onToggle}
    className={`select-chip ${isSelected ? "selected-chip" : ""}`}
  >
    <div className="select-chip-name">
      <span>{text}</span>
    </div>
    <div className="select-chip-status">
      <FontAwesomeIcon icon={isSelected ? faCheck : faPlus} />
    </div>
  </button>
);

export default Select;
