import React from "react"; // eslint-disable-line no-unused-vars
import "./Navigation.scss";
import { Link } from "react-router-dom";
import { ClosePage } from ".";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
  faCheck,
  faPencil,
} from "@fortawesome/pro-regular-svg-icons";
export const OnBoardingNextStep = {
  eaters: "diets",
  diets: "favorites",
  favorites: "schedule",
  schedule: "done",
};

export const OnBoardingPreviousStep = {
  diets: "eaters",
  favorites: "diets",
  schedule: "favorites",
};
export const OnBoardingStep = {
  STEP_ONE: 0, // todo: eaters
  STEP_TWO: 1, // todo: diets
  STEP_THREE: 2, // todo: favorites
  STEP_FOUR: 3, // todo: schedule
  DONE: 4, // done
};

export const OnBoardingStepText = {
  0: "eaters",
  1: "diets",
  2: "favorites",
  3: "schedule",
  4: "done",
};

export const ProfileStep = {
  STEP_ONE: 0, // todo: eaters
  STEP_TWO: 1, // todo: diets
  STEP_THREE: 2, // todo: favorites
  STEP_FOUR: 3, // todo: schedule
};

export const ProfileStepText = {
  0: "eaters",
  1: "diets",
  2: "favorites",
  3: "schedule",
};

export const OnBoardingStepIndex = {
  eaters: 0,
  diets: 1,
  favorites: 2,
  schedule: 3,
  done: 4,
};

let steps = Object.values(OnBoardingStep);
steps.pop();

let profiles = Object.values(ProfileStep);

const StepDot = ({ index, status, isEditing, isDisabled, setUpDone }) => (
  <div className={`step-wrapper ${setUpDone ? "setup-done" : ""}`}>
    <Link
      to={`/setup/${OnBoardingStepText[index - 1]}`}
      className={`step-dot ${status} ${isEditing ? "editting" : ""} ${
        isDisabled ? "disabled" : ""
      }`}
    >
      {isEditing ? (
        <FontAwesomeIcon icon={faPencil} />
      ) : status === "todo" ? (
        index
      ) : (
        <FontAwesomeIcon icon={faCheck} />
      )}
    </Link>
    <div className="step-text">{OnBoardingStepText[index - 1]}</div>
  </div>
);

const SetupHeader = ({ step, edit, showShadow }) => (
  <div className={`page-header ${showShadow ? "show-shadow" : ""}`}>
    <div className="page-sub-header">
      {steps.map((i) => (
        <React.Fragment key={i}>
          {i > 0 ? (
            <div
              className={`filler ${
                i > OnBoardingStepIndex[step] ? "" : "done"
              }`}
            ></div>
          ) : null}
          <StepDot
            index={i + 1}
            key={i}
            isEditing={edit === OnBoardingStepText[i]}
            isDisabled={i > OnBoardingStepIndex[step]}
            status={i >= OnBoardingStepIndex[step] ? "todo" : "done"}
            setUpDone={step === "done"}
          />
        </React.Fragment>
      ))}
    </div>
  </div>
);

const ProfileTabWithConfirm = ({ index, isEditing, onGoTo }) => (
  <button
    className={`profile-tab  ${isEditing ? "editting" : ""}`}
    onClick={onGoTo.bind(this, index)}
  >
    <span>{ProfileStepText[index - 1]}</span>
  </button>
);

const PageHeader = ({ showShadow, onClose, subHeader, title }) => (
  <div className={`page-header ${showShadow ? "show-shadow" : ""}`}>
    <div className="page-title">{title}</div>
    <ClosePage onClick={onClose} />
    {subHeader ? subHeader : null}
  </div>
);

const ProfileHeader = ({ edit, onClose, onGoTo }) => (
  <PageHeader
    showShadow={true}
    onClose={onClose}
    title="profile"
    subHeader={
      <div className="page-sub-header">
        {profiles.map((i) => (
          <ProfileTabWithConfirm
            index={i + 1}
            key={i}
            onGoTo={onGoTo}
            isEditing={edit === ProfileStepText[i]}
          />
        ))}
      </div>
    }
  />
);

const StepFormFooter = ({
  onNext,
  onPrevious,
  isFirstStep,
  isLastStep,
  showShadow,
  dataHasError,
}) => (
  <div className={`form-footer ${showShadow ? "show-shadow" : ""}`}>
    {/* previous button */}
    <div className="form-footer-content">
      <button
        className="setup-nav previous"
        type="button"
        disabled={isFirstStep}
        onClick={onPrevious}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
        <span>previous</span>
      </button>
      {/* previous button */}

      {/* next button */}
      <button
        className="setup-nav next"
        type="button"
        onClick={onNext}
        disabled={dataHasError}
      >
        <span>{isLastStep ? "done" : "next"}</span>
        {isLastStep ? null : <FontAwesomeIcon icon={faChevronRight} />}
      </button>
      {/* next button */}
    </div>
  </div>
);

const ProfileFormFooter = ({ onSave, showShadow, dataHasError }) => (
  <div className={`form-footer ${showShadow ? "show-shadow" : ""}`}>
    <button
      className="setup-nav next"
      type="button"
      onClick={onSave}
      disabled={dataHasError}
    >
      <span>save</span>
    </button>
  </div>
);
export {
  SetupHeader,
  ProfileHeader,
  PageHeader,
  StepFormFooter,
  ProfileFormFooter,
};
