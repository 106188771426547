import React, { Component } from "react";
import "./ButtonClickToCopy.scss";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BubbleUpperMiddleSmallArrow } from "./";
class ButtonClickToCopy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
    };
    this.handleShowCopied = this.handleShowCopied.bind(this);
    this.handleHideCopied = this.handleHideCopied.bind(this);
  }
  handleShowCopied() {
    this.setState({ copied: true }, () => {
      setTimeout(() => {
        this.setState({ copied: false });
      }, 200);
    });
  }
  handleHideCopied(e) {
    e.stopPropagation();
    this.setState({ copied: false });
  }
  render() {
    const { buttonToClick, textToCopy } = this.props;
    const { copied } = this.state;
    return (
      <CopyToClipboard text={textToCopy} onCopy={this.handleShowCopied}>
        <div className="button-click-to-copy">
          {buttonToClick}
          <BubbleUpperMiddleSmallArrow
            className={`button-info-transient ${copied ? "show" : "hide"}`}
            content={<div>copied</div>}
          />
        </div>
      </CopyToClipboard>
    );
  }
}

export default ButtonClickToCopy;
