import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright } from "@fortawesome/pro-light-svg-icons";

const Footer = () => (
  <div className="footer">
    <Link to="/terms" className="footer-field">
      terms of service
    </Link>
    <Link to="/privacy" className="footer-field">
      privacy
    </Link>
    <span className="footer-field">
      <FontAwesomeIcon icon={faCopyright} />
      2020 abbey.today
    </span>
  </div>
);
export default Footer;
