import { apiCall } from "../api";
import { addError } from "./errors";
import { LOAD_COMPANIES, RESET_COMPANIES } from "../actionTypes";
import { LOGGING } from "../../hocs/constants";
import { setCurrentUser } from "./auth";

export const loadCompanies = (companies) => ({
  type: LOAD_COMPANIES,
  companies,
});

export const resetCompanies = (companies) => ({
  type: RESET_COMPANIES,
  companies,
});

export const updateCompany = ({ data, edit }) => (dispatch, getState) => {
  const { currentUser } = getState();
  LOGGING >= 1 &&
    console.log("updateCompany called with: ", {
      data,
      edit,
      by: currentUser.user,
    });

  // post - /api/auth/:user_id/payment
  const route = `/users/${currentUser.user._id}/companies/${edit}`;

  const action = "POST";

  return apiCall(action, route, {
    data,
  })
    .then((updatedCompany) => {
      LOGGING >= 1 &&
        console.log(
          "updateCompany returns with updatedCompany: ",
          updatedCompany
        );
      const updatedUser = updatedCompany.eaters.find(
        (eater) => eater._id === currentUser.user._id
      );
      dispatch(
        setCurrentUser({
          ...currentUser.user,
          ...updatedUser,
          company: { ...updatedCompany },
        })
      );
      return updatedCompany;
    })
    .catch((err) => {
      LOGGING >= 3 && console.log("posted with err:", err);
      dispatch(addError(err));
    });
};

export const deleteCompany = ({ companyId }) => (dispatch, getState) => {
  const { currentUser, companies } = getState();
  return apiCall(
    "delete",
    `/users/${currentUser.user._id}/companies/${companyId}`
  )
    .then((res) => {
      LOGGING >= 1 && console.log("delete with res:", res);
      const updatedCompanies = companies.filter((i) => i._id !== companyId);
      LOGGING >= 1 &&
        console.log("delete with updatedCompanies:", updatedCompanies);
      dispatch(loadCompanies(updatedCompanies));
    })
    .catch((err) => {
      LOGGING >= 3 && console.log("posted with err:", err);
      dispatch(addError(err));
    });
};

export const fetchCompanies = () => {
  LOGGING >= 3 && console.log("fetchCompanies");

  return (dispatch, getState) => {
    const { currentUser } = getState();

    LOGGING >= 3 && console.log("fetchCompanies got user:", currentUser);
    if (currentUser.user.role !== "ops") {
      dispatch(addError("only admin can see all companies"));
      return "error";
    }

    return apiCall("GET", `/users/${currentUser.user._id}/companies`)
      .then((res) => {
        LOGGING >= 3 && console.log("fetchCompanies got res", res);
        const { companies } = res;
        dispatch(loadCompanies(companies));
        return "OK";
      })
      .catch((err) => {
        dispatch(addError(err.message));
      });
  };
};

// unused
export const fetchCompany = ({ companyId }) => {
  LOGGING && console.log("fetchCompany got companyId:", companyId);

  return (dispatch, getState) => {
    const { currentUser } = getState();

    LOGGING && console.log("fetchCompany got user:", currentUser);

    return apiCall(
      "GET",
      `/users/${currentUser.user._id}/companies/${companyId}`
    )
      .then((fetchedCompany) => {
        LOGGING &&
          console.log("fetchCompany got fetchedCompany", fetchedCompany);
        return fetchedCompany;
      })
      .catch((err) => {
        dispatch(addError(err.message));
      });
  };
};
