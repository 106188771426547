import React from "react";
import "./Contacts.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/pro-light-svg-icons";
const Contacts = () => (
  <div className="user-anonymous-section contacts">
    <h3>contact us</h3>
    <div className="section-body">
      <div className="item">
        <div className="item-title">email</div>
        <div className="item-content">
          <FontAwesomeIcon icon={faEnvelope} />
          <span>info@abbey.today</span>
        </div>
      </div>
      <div className="item">
        <div className="item-title">phone</div>
        <div className="item-content">
          <FontAwesomeIcon icon={faPhone} />
          <span>(650) 670-8855</span>
        </div>
      </div>
    </div>
  </div>
);

export default Contacts;
