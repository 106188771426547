import {
  SET_CALENDAR_MONTH,
  SET_SELECTED_DAY,
  SET_SELECTED_DAY_MEAL,
  RESET_CALENDAR,
} from "../actionTypes";
import moment from "moment-timezone";
import { LOGGING } from "../../hocs/constants";
import { getCurrent } from "../reducers/calendar";

export const setSelectedDay = (selectedDay) => ({
  type: SET_SELECTED_DAY,
  selectedDay,
});

export const setSelectedDayMeal = (isLunch) => ({
  type: SET_SELECTED_DAY_MEAL,
  isLunch,
});

export const setCalendarMonth = (calendarMonth, calendarYear) => ({
  type: SET_CALENDAR_MONTH,
  calendarMonth,
  calendarYear,
});

export const resetCalendar = () => ({
  type: RESET_CALENDAR,
});

export const setMeal = (isLunch) => {
  return (dispatch) => {
    dispatch(setSelectedDayMeal(isLunch));
  };
};

export const goToCurrent = () => {
  LOGGING && console.log("goToCurrent: ");
  const { calendarMonth, calendarYear, selectedDay } = getCurrent();
  return (dispatch) => {
    dispatch(setSelectedDay(selectedDay));
    dispatch(setCalendarMonth(calendarMonth, calendarYear));
  };
};

export const goToDate = (date) => {
  LOGGING && console.log("goToDate at date:", date);
  return (dispatch) => {
    dispatch(setSelectedDay(date));
  };
};

export const goToNextMonth = () => {
  return (dispatch, getState) => {
    LOGGING && console.log("goToNextMonth:", getState());
    const { calendarMonth, calendarYear } = getState().calendar;
    const nextMonth = moment([calendarYear, calendarMonth]).add(1, "month");
    LOGGING &&
      console.log("goToNextMonth, nextMonth", nextMonth.format("LLLL"));
    dispatch(setCalendarMonth(nextMonth.month(), nextMonth.year()));
  };
};

export const goToSelectedDayMonth = () => {
  return (dispatch, getState) => {
    LOGGING && console.log("goToSelectedDayMonth:", getState());
    const { selectedDay } = getState().calendar;
    dispatch(setCalendarMonth(selectedDay.month, selectedDay.year));
  };
};

export const goToPrevMonth = () => {
  return (dispatch, getState) => {
    LOGGING && console.log("goToPrevMonth:", getState());
    const { calendarMonth, calendarYear } = getState().calendar;
    const prevMonth = moment([calendarYear, calendarMonth]).add(-1, "month");
    LOGGING &&
      console.log("goToPrevMonth, prevMonth", prevMonth.format("LLLL"));
    LOGGING && console.log("goToPrevMonth, month", prevMonth.month());
    LOGGING && console.log("goToPrevMonth, year", prevMonth.year());
    dispatch(setCalendarMonth(prevMonth.month(), prevMonth.year()));
  };
};
