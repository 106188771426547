import React from "react";
import "./CheckBox.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
const CheckBox = ({
  labelText,
  value,
  name,
  onChange,
  className,
  disabled,
}) => (
  <div className={`checkbox ${className || ""}`}>
    <input
      type="checkbox"
      name={name}
      defaultChecked={value}
      onChange={onChange}
      disabled={disabled}
    />
    <div className="check-mark-container">
      <div className="check-mark-wrapper">
        <FontAwesomeIcon
          className={value ? "check-mark checked" : "check-mark unchecked"}
          icon={faCheck}
        />
      </div>
    </div>
    <span>{labelText}</span>
  </div>
);
export default CheckBox;
