import React from "react";
import "./VerificationCode.scss";
import { ErrorMessage } from "../widgets";

const VerificationCode = ({
  onSubmit,
  onChange,
  onResend,
  verificationStatus,
  verificationCodeTyped,
}) => (
  <div className="verify-contact-wrapper">
    <form className="verify-contact-form" onSubmit={onSubmit}>
      <div
        className={`form-field ${
          verificationStatus ? "no-error" : "has-error"
        }`}
      >
        <ErrorMessage
          message={verificationStatus ? "" : "Wrong verification code!"}
        />
        <input onChange={onChange} value={verificationCodeTyped} />
      </div>
      <button
        type="submit"
        className="verify"
        disabled={verificationCodeTyped.length < 6}
      >
        verify
      </button>
      <button type="button" className="resend" onClick={onResend}>
        Resend code.
      </button>
    </form>
  </div>
);

export default VerificationCode;
