import {
  faUserCircle,
  faSuitcase,
  faMapMarkerAlt,
  faUtensils,
  faGlobe,
} from "@fortawesome/pro-regular-svg-icons";
import { faPuzzlePiece } from "@fortawesome/free-solid-svg-icons";

export { default as PageDashboard } from "./PageDashboard";
export { default as PageCuisine } from "./PageCuisine";
export { default as PageDish } from "./PageDish";
export { default as PageIngredient } from "./PageIngredient";
export { default as PageRestaurant } from "./PageRestaurant";
export { default as DishList } from "./DishList";
export { Address } from "./FormRestaurant";
export { Input } from "./FormWidgets";

export const ThumbnailSortBy = {
  BY_TYPE: "type",
  BY_PARENT: "parent",
  BY_CHILDREN: "children",
  BY_NAME: "name",
};

export const ThumbnailCategoryBy = {
  BY_TYPE: "type",
  BY_PARENT: "parent",
};

export const DataModeSortByDefault = {
  companies: "type",
  users: "role",
  restaurants: "cuisines",
  dishes: "parent",
  ingredients: "type",
  cuisines: "name",
};

export const DataModeCategoryByDefault = {
  companies: "type",
  users: "type",
  restaurants: "parent",
  dishes: "parent",
  ingredients: "type",
  cuisines: "",
};

export const DataModeLoadedDefault = {
  companies: false,
  users: false,
  restaurants: false,
  dishes: false,
  ingredients: false,
  cuisines: false,
};

export const DataModeChildrenKey = {
  companies: "eaters",
  users: "",
  cuisines: "",
  restaurants: "dishes",
  dishes: "price",
  ingredients: "",
};

export const DataModeParentKey = {
  companies: "city",
  users: "company",
  restaurants: "city",
  dishes: "restaurant",
  ingredients: "dish",
  cuisines: null,
};

export const DataModeCategoriesKey = {
  companies: "",
  users: "role",
  restaurants: "cuisines",
  dishes: "cuisines",
  ingredients: "type",
  cuisines: "",
};

export const DataModeParentMode = {
  companies: "cities",
  users: "companies",
  restaurants: "cities",
  dishes: "restaurants",
  ingredients: "dishes",
  cuisines: "",
};

export const CompanyKey = {
  type: "type",
  name: "name",
  children: "eaters",
  parent: "",
};

export const UserKey = {
  // type: "type",
  name: "name",
  parent: "company",
  role: "role",
};

export const RestaurantKey = {
  // "type": "type",
  name: "name",
  parent: "city",
  children: "dishes",
};

export const DishKey = {
  // "type": "type",
  parent: "restaurant",
  name: "name",
  // "children": "ingredients",
};

export const IngredientKey = {
  type: "type",
  name: "name",
};

export const CuisineKey = {
  name: "name",
  // "children": "restaurant",
};

export const DataKeys = {
  companies: CompanyKey,
  users: UserKey,
  restaurants: RestaurantKey,
  dishes: DishKey,
  ingredients: IngredientKey,
  cuisines: CuisineKey,
};

export const EntityChildrenIcon = {
  companies: faUserCircle,
  cuisines: faMapMarkerAlt,
  restaurants: faUtensils,
  dishes: faPuzzlePiece,
};

export const IngredientType = {
  0: "sauce",
  1: "vegetable",
  2: "diary",
  3: "protein",
  4: "carbs",
};

export const UserType = {
  0: "abbey internal",
  1: "company admin",
  2: "regular eater",
};

export const RestaurantType = {
  0: "sandwich",
  1: "poke bowl",
  2: "thai",
  3: "salad",
};

export const EaterType = {
  0: "admin",
  1: "eater",
};

export const CompanyType = {
  0: "corporate",
  1: "family",
};

export const DataTypes = {
  companies: CompanyType,
  users: UserType,
  restaurants: RestaurantType,
  ingredients: IngredientType,
};

export const DataModeTypes = {
  COMPANIES: "companies",
  USERS: "users",
  RESTAURANTS: "restaurants",
  DISHES: "dishes",
  INGREDIENTS: "ingredients",
  CUISINES: "cuisines",
};

export const DataModeIcons = {
  companies: faSuitcase,
  users: faUserCircle,
  restaurants: faMapMarkerAlt,
  dishes: faUtensils,
  ingredients: faPuzzlePiece,
  cuisines: faGlobe,
};

export const DefaultDataModeState = {
  selected: DataModeTypes.COMPANIES,
  sortBy: DataModeSortByDefault,
  categoryBy: DataModeCategoryByDefault,
  loaded: DataModeLoadedDefault,
};
