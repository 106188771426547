import React from "react";
import "./UnitHeader.scss";
import UnitHeaderMobile from "./UnitHeaderMobile";
import UnitHeaderDesktop from "./UnitHeaderDesktop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faMapMarkerAlt,
  faCompass,
} from "@fortawesome/pro-regular-svg-icons";
import { getUnitStatus } from "./";
import { InstanceStatus, LOGGING } from "../../../hocs/constants";
import {
  ExpandToggle,
  ButtonClickToCopy,
  ButtonCallToAction,
  FormattedRow,
  TimeText,
  UnitStatus,
  EditableTimeText,
} from "../../widgets";

const ETAName = { delivery: "deliver", order: "pick-up" };

const findPriorityActionIndex = (actionList) => {
  // 1. if there's any button, show button
  const buttonActionIndex = actionList.findIndex(
    (action) => action && action.buttonStatus === "action"
  );
  if (buttonActionIndex > -1) return buttonActionIndex;

  // 2. if there's any to-do, show first todo
  const todoActionIndex = actionList.findIndex(
    (action) => action && action.buttonStatus === "todo"
  );
  if (todoActionIndex > -1) return todoActionIndex;

  // 3. if all is done, show the last done
  return actionList
    .map((action) => action && action.buttonStatus)
    .lastIndexOf("done");
};

const findPriorityButtonIndex = (actionList) =>
  // 1. if there's any button, show button
  actionList.findIndex((action) => action && action.buttonStatus === "action");

export const UnitIcon = ({ unitType }) => (
  <div className="unit-icon round-icon purple-icon">
    <FontAwesomeIcon
      icon={unitType === "delivery" ? faBuilding : faMapMarkerAlt}
    />
  </div>
);

export const UnitSummary = ({
  instances,
  etaByOps,
  role,
  unitType,
  onSaveETA,
  onEditETA,
  mobile,
}) => {
  const actionName = ETAName[unitType];
  const etaInfo =
    role === "ops" ? (
      <EditableTimeText
        time={etaByOps}
        onSave={onSaveETA}
        onEdit={onEditETA}
        actionName={actionName}
      />
    ) : (
      <TimeText time={etaByOps} actionName={actionName} />
    );

  const numAll = instances.length;
  const numCancelled = instances.filter(
    (i) => i.status === InstanceStatus.CANCELLED
  ).length;

  const numValid = numAll - numCancelled;
  const eatersInfo =
    role === "ops" ? (
      <div>
        {numAll}
        {` meal${numAll > 1 ? "s" : ""}`}
      </div>
    ) : (
      <div>
        {numValid}
        {` meal${numValid > 1 ? "s" : ""}`}
      </div>
    );
  const sumInfo = (
    <div>
      <b>{`$${instances
        .map((i) => (i.dish !== undefined ? i.dish.price : 0))
        .reduce((a, b) => a + b, 0)
        .toFixed(2)}`}</b>
    </div>
  );
  const summary = mobile ? (
    <div className="unit-summary">
      <FormattedRow list={[eatersInfo, sumInfo]} addBullet={true} />
      <FormattedRow list={[etaInfo]} addBullet={true} />
    </div>
  ) : (
    <div className="unit-summary">
      <FormattedRow list={[eatersInfo, sumInfo, etaInfo]} addBullet={true} />
    </div>
  );
  return summary;
};

const UnitHeader = ({
  unit,
  unitType,
  role,
  instances,
  showDetails,
  onToggleShowDetails,
  etaByOps,
  onSaveETA,
  onEditETA,
  orderTime,
  onOrder,
  pickUpTime,
  onPickUp,
  dispatchTime,
  deliverTime,
  onDeliver,
}) => {
  LOGGING >= 3 &&
    console.log("UnitHeader rendering with: ", {
      unitType,
      pickUpTime,
      dispatchTime,
      deliverTime,
      etaByOps,
    });
  // position: absolute
  const unitIcon = <UnitIcon unitType={unitType} />;

  // position: relative

  const { address, orderBy, payBy } = unit;
  LOGGING >= 3 && console.log("UnitHeader got unit:", unit);
  // address button
  const addressButton = (
    <ButtonClickToCopy
      buttonToClick={
        <ButtonCallToAction icon={faCompass} text="copy address" />
      }
      textToCopy={address}
    />
  );

  // show details button
  const expandButton = (
    <ExpandToggle
      toggleOnText={""}
      showDetails={showDetails}
      onToggle={onToggleShowDetails}
    />
  );

  // address info
  const unitAddress = <div className="unit-address">{unit.address}</div>;

  // order, pickup, dispatch, deliver statuses
  const orderStatus = getUnitStatus({
    action: `order${payBy === orderBy ? " and pay" : ""}`,
    time: orderTime,
    readyForAction: true,
    actionRole: orderBy,
    displayRole: role,
  });

  const pickUpStatus = getUnitStatus({
    action: `${
      payBy === "driver" && orderBy === "ops" ? "pay and " : ""
    }pick-up`,
    time: pickUpTime,
    readyForAction: orderTime > 0,
    actionRole: "driver",
    displayRole: role,
  });

  const dispatchStatus = getUnitStatus({
    action: "dispatch",
    time: dispatchTime,
    readyForAction: false,
    actionRole: "driver",
    displayRole: role,
  });

  const deliverStatus = getUnitStatus({
    action: "deliver",
    time: deliverTime,
    readyForAction: dispatchTime && dispatchTime > 0,
    actionRole: "driver",
    displayRole: role,
  });

  const orderInfo = orderStatus ? (
    <UnitStatus unitStatus={orderStatus} onAction={onOrder} />
  ) : null;
  const pickUpInfo = pickUpStatus ? (
    <UnitStatus unitStatus={pickUpStatus} onAction={onPickUp} />
  ) : null;
  const dispatchInfo = dispatchStatus ? (
    <UnitStatus unitStatus={dispatchStatus} />
  ) : null;
  const deliverInfo = deliverStatus ? (
    <UnitStatus unitStatus={deliverStatus} onAction={onDeliver} />
  ) : null;

  const statusList = [orderStatus, pickUpStatus, dispatchStatus, deliverStatus];
  const infoList = [orderInfo, pickUpInfo, dispatchInfo, deliverInfo];

  const priorityActionIndex = findPriorityActionIndex(statusList);
  const priorityButtonIndex = findPriorityButtonIndex(statusList);

  const widgets = {
    addressButton,
    unitAddress,
    expandButton,
    statusList,
    infoList,
    priorityActionIndex,
    priorityButtonIndex,
  };

  const data = {
    role,
    unit,
    unitType,
    instances,
    showDetails,
    etaByOps,
    onSaveETA,
    onEditETA,
  };

  return (
    <div className="unit-header">
      {/* fixed */}
      {unitIcon}
      {/* flexible */}
      <UnitHeaderMobile widgets={widgets} data={data} />
      <UnitHeaderDesktop widgets={widgets} data={data} />
    </div>
  );
};

export default UnitHeader;
