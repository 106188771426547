import React from "react";
import "./InvoiceList.scss";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTruck,
  faUtensils,
  faCalendarDay,
  faTag,
  faReceipt,
} from "@fortawesome/pro-regular-svg-icons";
import { Nothing } from "../home/UserSignedIn";
import { actionTimeFormat, DateKeyFormatShort } from "../../hocs/constants";
import { taxRate, taxMultiplier } from "./";
import { EaterName } from "../widgets";

const InvoiceRowShort = ({ icon, text, className }) => (
  <div className={`invoice-row invoice-row-short ${className}`}>
    <label className="icon">
      <FontAwesomeIcon icon={icon} />
    </label>
    <span>{text}</span>
  </div>
);

const InvoiceRowLong = ({ label, content, className }) => (
  <div className={`invoice-row invoice-row-long ${className}`}>
    <label>{label}</label>
    <div className="invoice-info">{content}</div>
  </div>
);

const InvoiceHeader = ({ deliverTime, payTime, sum, average }) => (
  <div className="invoice-card-section invoice-header">
    <InvoiceRowShort
      icon={faCalendarDay}
      text={moment(deliverTime).format(DateKeyFormatShort)}
    />
    <InvoiceRowShort
      icon={faTruck}
      text={moment(deliverTime).format(actionTimeFormat)}
    />
    {payTime > 0 ? (
      <React.Fragment>
        <InvoiceRowLong
          label="total paid"
          content={`$${(sum * taxMultiplier).toFixed(2)}`}
        />
        <InvoiceRowLong
          label="paid at"
          content={moment(payTime).format(
            `${DateKeyFormatShort}, ${actionTimeFormat}`
          )}
        />
      </React.Fragment>
    ) : (
      <InvoiceRowShort
        icon={faTag}
        text={`$${average.toFixed(2)} plus tax/person`}
      />
    )}
  </div>
);

const InvoiceByEater = ({ name, goods, price, isMainContact }) => (
  <div className="invoice-card-section invoice-by-eater">
    <EaterName name={name} isMainContact={isMainContact} />
    <InvoiceRowShort icon={faUtensils} text={goods} className="goods" />
    <InvoiceRowLong label="price" content={`$${price.toFixed(2)}`} />
    <InvoiceRowLong label="tax" content={`$${(price * taxRate).toFixed(2)}`} />
    <InvoiceRowLong
      label="personal total"
      content={`$${(price * taxMultiplier).toFixed(2)}`}
    />
  </div>
);

const InvoiceFooter = ({ payTime, sum }) => (
  <div className="invoice-card-section invoice-footer">
    <InvoiceRowLong
      label={`Day Total ${payTime < 0 ? "Due" : ""}`}
      content={`$${(sum * taxMultiplier).toFixed(2)}`}
    />
  </div>
);

const InvoiceCard = ({ invoice, mainContact }) => {
  const { instances: instancesRaw, deliverTime, payTime } = invoice;

  const instances = instancesRaw
    .filter((i) => (i.dish ? i.dish.price : i.price))
    .map((i) => ({
      ...i,
      goods: i.dish && i.dish.name,
      price: i.dish && i.dish.price,
    }))
    .sort((a, b) => (a.name > b.name ? 1 : -1));

  const sum = instances
    .map((instance) => Number(instance.price))
    .reduce((a, b) => a + b, 0);

  return (
    <div key={invoice._id} className="invoice-card">
      <InvoiceHeader
        deliverTime={deliverTime}
        payTime={payTime}
        sum={sum}
        average={sum / instances.length}
      />
      {instances.map(({ _id, name, goods, price, user }) => (
        <InvoiceByEater
          key={_id}
          name={name}
          goods={goods}
          price={price}
          isMainContact={mainContact === user}
        />
      ))}
      <InvoiceFooter payTime={payTime} sum={sum} />
    </div>
  );
};

const InvoiceList = ({ invoices, viewOutstanding, mainContact }) => {
  const invoiceList = invoices
    .filter((invoice) =>
      viewOutstanding ? invoice.payTime < 0 : invoice.payTime > 0
    )
    .sort((a, b) => (a.deliverTime < b.deliverTime ? 1 : -1))
    .map((invoice, index) => (
      <InvoiceCard invoice={invoice} key={index} mainContact={mainContact} />
    ));
  return (
    <div
      className={`invoice-list ${
        invoiceList.length > 0 ? "has-content" : "no-content"
      }`}
    >
      {invoiceList.length > 0 ? (
        invoiceList
      ) : (
        <Nothing
          icon={faReceipt}
          text={`You have no ${
            viewOutstanding ? "outstanding" : "paid"
          } invoices.`}
        />
      )}
    </div>
  );
};

export default InvoiceList;
