import React, { Component } from "react"; // eslint-disable-line no-unused-vars
import { connect } from "react-redux";
import moment from "moment-timezone";
import {
  Navbar,
  updateMonth,
  getNumOfInstances,
  getOrderIdsFromDeliveries,
  getContextDateMoments,
  addDeliveryBySchedule,
  UNIT_ENTITY_NAME,
  UNIT_LIST_NAME,
} from ".";
import {
  OpsDay,
  DriverDay,
  CalendarWrapperShared,
  DishCatelog,
} from "./UserSignedIn";
import { LoadingIcon } from "../widgets";
import {
  DateKeyFormat,
  LOGGING,
  CALIFORNIA_TIMEZONE,
  LunchWindow,
  DinnerWindow,
  InstanceStatus,
} from "../../hocs/constants";
import {
  fetchCompanies,
  fetchDishes,
  fetchDeliveries,
  fetchRestaurants,
  createDelivery,
  updateDelivery,
  fetchOrdersById,
  updateOrder,
  addInstanceToOrder,
  removeInstanceFromOrder,
  goToNextMonth,
  goToPrevMonth,
  goToCurrent,
  goToDate,
  setMeal,
  goToSelectedDayMonth,
} from "../../store/actions";

const getCategoriesFromData = ({ data, parentKey }) => {
  LOGGING >= 1 && console.log("data:", data);
  LOGGING >= 1 && console.log("parentKey:", parentKey);
  const keyedData = data.map((b) => ({
    ...b,
    categoryKey: `${b[parentKey].name} ${b[parentKey]._id}`,
  }));
  LOGGING >= 1 && console.log("keyedData:", keyedData);
  // step 3. generate thumbnail with processed data

  let categories = Array.from(
    new Set(keyedData.map((entry) => entry.categoryKey))
  );

  LOGGING >= 1 && console.log("categories:", categories);
  categories = categories.sort((a, b) =>
    a.toLowerCase() > b.toLowerCase()
      ? 1
      : a.toLowerCase() < b.toLowerCase()
      ? -1
      : 0
  );
  return { categories, keyedData };
};
class PageUserInternal extends Component {
  constructor(props) {
    super();
    // const todayMoment = moment(new Date()).startOf("d");

    this.state = {
      // 1. Main states

      // controls date and meal (lunch or dinner) of the rendering
      selectedMeal: { ...props.selectedDay },
      // {
      //   year: todayMoment.year(),
      //   month: todayMoment.month(),
      //   date: todayMoment.date(),
      //   dateKey: todayMoment.format(DateKeyFormat),
      //   isLunch: true,
      // },
      showDetailsOneList: {}, // driver view: { {dateKey, isLunch}: [restaurantId or companyId] }
      showDetailsTwoLists: { delivery: {}, order: {} }, // ops view: { {dateKey, isLunch}: [restaurantId or companyId] }
      showAllDetails: { delivery: {}, order: {} }, // { { dateKey, isLunch } }
      numOfInstances: { lunch: 0, dinner: 0 },
      contextDateMoments: [], // store all context dates, both dinner and lunch
      contextDeliveries: [], // store all context dates, both dinner and lunch
      deliveries: [], // from db and from schedule for the month
      // deliveriesForMeal: [], // store only the selected date, selected meal,
      // ordersForMeal: [], // store only the selected date, selected meal,

      // 2. Rendering states

      // matrix defined by month and year
      monthGrid: null,
      // rendering control
      loading: true,

      // controls if dishcatelog is shown
      showDishCatelog: false,
      pageOffsetY: 0,
      restaurants: [],
      dishCatelog: {},
      instance: null, // the instance being selected dish for
      delivery: null, // the delivery for the instance being selected dish for
    };

    // 1. for calculating data in state

    // called by componentDidMount and componentDidUpdate
    this.handleLoadDataForCalendarMonth = this.handleLoadDataForCalendarMonth.bind(
      this
    );

    // called by all navigating and saving clicks, almost always
    // followed by handleGetDataForSelectedMeal, except in the case
    // of loading month data triggered by next or prev month
    this.handleGetDeliveriesWithSchedule = this.handleGetDeliveriesWithSchedule.bind(
      this
    );

    // called by all navigating and saving clicks, almost always
    // preceded by handleGetDeliveriesWithSchedule, except in the case
    // of updating orders status/ETA
    this.handleGetDataForSelectedMeal = this.handleGetDataForSelectedMeal.bind(
      this
    );

    // 2. for navigating
    this.handleSelectDay = this.handleSelectDay.bind(this);
    this.handleSelectLunchDinner = this.handleSelectLunchDinner.bind(this);
    this.handleGoToSelectedDayMonth = this.handleGoToSelectedDayMonth.bind(
      this
    );

    // 3. for delivery/order header changes
    this.handleSaveETA = this.handleSaveETA.bind(this);
    this.handleEditETA = this.handleEditETA.bind(this);
    this.handleOrderByOps = this.handleOrderByOps.bind(this);
    this.handleToggleShowDetailsOneList = this.handleToggleShowDetailsOneList.bind(
      this
    );
    this.handleToggleShowDetailsTwoLists = this.handleToggleShowDetailsTwoLists.bind(
      this
    );
    this.handleToggleShowAllDetails = this.handleToggleShowAllDetails.bind(
      this
    );

    // 4. for instance changes
    this.handleInsertInstance = this.handleInsertInstance.bind(this);
    this.handleToggleInstance = this.handleToggleInstance.bind(this);

    // 5. for dish catelog
    this.handleSelectDish = this.handleSelectDish.bind(this);
    this.handleDeselectDish = this.handleDeselectDish.bind(this);
    this.handleDecideDish = this.handleDecideDish.bind(this);
    this.handleShowDishCatelog = this.handleShowDishCatelog.bind(this);
    this.handleHideDishCatelog = this.handleHideDishCatelog.bind(this);

    // 6. for rendering
    this.selectedDishRef = React.createRef();

    // 7. for drivers
    // 3. for delivery/order header changes
    this.handleOrderByDriver = this.handleOrderByDriver.bind(this);
    this.handlePickUp = this.handlePickUp.bind(this);
    this.handleDeliver = this.handleDeliver.bind(this);
  }

  // 1. for calculating data in state
  handleLoadDataForCalendarMonth() {
    // step 1. get { beginUTC, endUTC, monthGrid }
    const { calendarYear, calendarMonth, currentUser } = this.props;
    const { selectedMeal } = this.state;
    const { beginUTC, endUTC, monthGrid } = updateMonth({
      calendarYear,
      calendarMonth,
    });
    LOGGING >= 1 &&
      console.log("handleLoadDataForCalendarMonth called with: ", {
        calendarYear,
        calendarMonth,
        beginUTC,
        endUTC,
        selectedMeal,
      });
    this.setState({ loading: true, beginUTC, endUTC, monthGrid }, () => {
      // step 2. get deliveriesSavedInMonth
      this.props
        .fetchDeliveries({ beginUTC, endUTC })
        .then((deliveriesSavedInMonth) => {
          LOGGING >= 1 &&
            console.log(
              "fetchDeliveries returned with:",
              this.props.deliveriesSavedInMonth,
              deliveriesSavedInMonth
            );
          this.setState(
            {
              deliveries:
                currentUser.user.role === "ops"
                  ? this.handleGetDeliveriesWithSchedule()
                  : this.props.deliveriesSavedInMonth, // driver just blindly reads deliveries
              loading: false,
            },
            () => {
              // step 3. calculate all data for the meal, e.g. deliveriesForDay, ordersForDay
              if (
                !selectedMeal.deliveriesForDay ||
                !selectedMeal.ordersForDay
              ) {
                this.handleGetDataForSelectedMeal();
              }
            }
          );
        })
        .catch((err) => {
          LOGGING >= 1 && console.log("fetchDeliveries got err", err);
          throw err;
        });
    });
  }

  handleGetDeliveriesWithSchedule() {
    const { companies, deliveriesSavedInMonth } = this.props;
    const { monthGrid } = this.state;
    let deliveries = [];
    companies.forEach((company) => {
      LOGGING >= 3 && console.log("looping thorugh company:", company._id);
      const companyDeliveries = addDeliveryBySchedule({
        company,
        monthGrid,
        deliveriesFromDB: deliveriesSavedInMonth.filter(
          (delivery) => delivery.company._id === company._id
        ),
      });
      LOGGING >= 3 && console.log("got companyDeliveries:", companyDeliveries);
      deliveries = deliveries.concat(companyDeliveries);
      LOGGING >= 3 && console.log("got deliveries:", deliveries);
    });
    return deliveries;
  }
  // for preparing data needed for rendering, on load or change
  // always update all the 5 states
  handleGetDataForSelectedMeal() {
    const { deliveries } = this.state;
    const { selectedDay } = this.props;
    LOGGING >= 1 &&
      console.log("handleGetDataForSelectedMeal called:", {
        props: this.props,
        state: this.state,
      });

    // step 1. calculate selectedMeal
    const { year, month, day, isLunch } = selectedDay;
    const selectedMoment = moment().set({
      year: year,
      month: month,
      date: day,
    });
    const dateKey = selectedMoment.format(DateKeyFormat);

    const selectedMeal = {
      year,
      month,
      day,
      isLunch,
      dateKey,
      hour: isLunch ? LunchWindow.end.hour : DinnerWindow.end.hour,
      minute: isLunch ? LunchWindow.end.minute : DinnerWindow.end.minute,
    };

    LOGGING >= 1 &&
      console.log(
        "handleGetDataForSelectedMeal got selectedMeal:",
        selectedMeal
      );

    // step 2. populate deliveries with
    // both the saved and scheduled for the month
    // state: deliveries

    // step 3. get numOfInstances for the selectedMeal
    // state: numOfInstances
    const deliveriesOnDate = deliveries.filter(
      (d) => d.dateKey === selectedMeal.dateKey
    );
    const numOfInstances = getNumOfInstances(deliveriesOnDate);

    // step 3. get context dates and deliveries
    // for the selectedMeal
    // state: contextDateMoments, contextDeliveries
    const contextDateMoments = getContextDateMoments(selectedMeal);
    const contextDays = contextDateMoments.map((d) => d.format(DateKeyFormat));
    const contextDeliveries = deliveries.filter((d) =>
      contextDays.includes(d.dateKey)
    );

    // step 4. get deliveries for the selectedMeal
    // state: deliveriesForDay
    const deliveriesForDay = deliveries.filter(
      (d) => d.dateKey === selectedMeal.dateKey
    );

    // step 5. get orders for the selectedMeal
    // state: ordersForDay
    const orderIdsForDay = getOrderIdsFromDeliveries(deliveriesForDay);
    LOGGING >= 1 &&
      console.log("handleGetDataForSelectedMeal called with: ", {
        orderIdsForDay,
        deliveriesForDay,
        selectedMeal,
      });

    this.props
      .fetchOrdersById(orderIdsForDay)
      .then((ordersForDay) => {
        // step 6. set all states
        this.setState({
          // from step 2
          numOfInstances,
          // from step 3
          contextDeliveries,
          contextDateMoments,
          // from step 4
          // from step 5
          selectedMeal: { ...selectedMeal, deliveriesForDay, ordersForDay },
          // set loading to false regardless
          loading: false,
        });
      })
      .catch((error) => {
        LOGGING >= 1 &&
          console.log("fetchOrdersById returns with error:", error);
      });
  }

  // navigating
  handleSelectDay(selectedDate) {
    LOGGING >= 1 && console.log("handleSelectDay called with:", selectedDate);
    const { year, month, date: day } = selectedDate;
    this.props.goToDate({ year, month, day });
    LOGGING >= 1 &&
      console.log("goToDate done with selectedDay:", this.props.selectedDay);
  }

  handleSelectLunchDinner(isLunch, e) {
    e.preventDefault();
    const { selectedMeal } = this.state;
    this.props.setMeal(isLunch);
    this.setState({ selectedMeal: { ...selectedMeal, isLunch } });
  }

  handleGoToSelectedDayMonth(e) {
    e.preventDefault();
    LOGGING >= 1 && console.log("handleGoToSelectedDayMonth called");
    this.props.goToSelectedDayMonth();
    window.scrollTo(0, 0);
  }
  // 3. for delivery/order changes
  handleChangeDelivery(delivery, callback) {
    // called by:
    // 1. handleToggleInstance
    // 2. handleDecideDish
    // 3. handleSaveETA
    // 4.
    //
    // caller functions are in charge of making sure
    // of the following input format:
    // delivery: {
    // _id,
    // byTime, // mandatory when delivery._id is undefined
    // company, // mandatory when delivery._id is undefined
    // instances: [{ // mandatory when delivery._id is undefined
    //    _id,
    //    user, // mandatory when instance._id is undefined
    //    status, // mandatory when instance._id is undefined
    //    dish,
    //}]
    // etaByOps,
    //}

    const { _id } = delivery;
    LOGGING >= 1 &&
      console.log("handleChangeDelivery called with:", {
        delivery,
      });
    this.setState({ loading: true });
    let action;
    if (_id) {
      action = this.props.updateDelivery.bind(this, { _id, delivery });
    } else {
      action = this.props.createDelivery.bind(this, delivery);
    }

    action()
      .then((result) => {
        LOGGING >= 1 &&
          console.log(
            "handleChangeDelivery done from stores with result:",
            result
          );
        this.setState(
          { deliveries: this.handleGetDeliveriesWithSchedule() },
          () => {
            LOGGING >= 1 &&
              console.log(
                "handleChangeDelivery done updating delivereis in state"
              );
            this.handleGetDataForSelectedMeal();
            callback && callback(result);
          }
        );
      })
      .catch((error) => {
        LOGGING >= 1 &&
          console.log(
            "handleChangeDelivery done from stores with error:",
            error
          );
        this.setState({ loading: false });
      });
  }

  handleSaveETA = async (unitType, entityId) => {
    const { selectedMeal } = this.state;
    LOGGING >= 1 &&
      console.log("handleSaveETA called with: ", {
        unitType,
        entityId,
        selectedMeal,
      });

    const listName = UNIT_LIST_NAME[unitType]; // unitType: delivery, order
    const listOfUnits = selectedMeal[listName]; // listName: delivereisForDay, ordersForDay
    const entityName = UNIT_ENTITY_NAME[unitType]; // entityName: company, restaurant
    const unit = listOfUnits
      .filter((u) => u.isLunch === selectedMeal.isLunch)
      .find((i) => i[entityName]._id === entityId);

    if (!unit) {
      throw new Error(`${listName} has no ${entityName}`);
    }

    const { instances, byTime, _id, etaByOps } = unit;
    LOGGING >= 1 &&
      console.log("handleSaveETA got ", {
        unit,
        etaByOps,
      });

    if (unitType === "delivery") {
      let delivery = { _id, etaByOps };
      if (!_id) {
        delivery = {
          ...delivery,
          company: entityId,
          byTime,
          instances: instances.map((i) => ({
            user: i.user,
            status: InstanceStatus.SCHEDULED,
          })),
        };
      }
      this.handleChangeDelivery(delivery);
    } else {
      if (_id) {
        this.props
          .updateOrder({ _id, order: { etaByOps } })
          .then(() => {
            this.handleGetDataForSelectedMeal();
          })
          .catch((error) => {
            LOGGING >= 1 &&
              console.log("updateOrder got error from stores:", error);
          });
      } else {
        throw new Error("order id can't be null");
      }
    }
  };

  handleEditETA = (unitType, entityId, _, value) => {
    const [hour, minute] = value.split(":");
    const { selectedMeal } = this.state;
    const listName = UNIT_LIST_NAME[unitType];
    const listOfUnits = selectedMeal[listName];
    const entityName = UNIT_ENTITY_NAME[unitType];
    LOGGING >= 1 &&
      console.log("handleEditETA called with: ", {
        unitType,
        entityId,
        entityName,
        _,
        value,
        listOfUnits,
      });
    const { year, month, day, isLunch } = selectedMeal;

    const etaByOps = moment
      .tz([year, month, day, hour, minute], CALIFORNIA_TIMEZONE)
      .valueOf();
    LOGGING >= 1 && console.log("editted etaByOps: ", etaByOps);

    const updatedListOfUnits = listOfUnits.map((unit) => {
      let updatedUnit;

      if (unit[entityName]._id === entityId && unit.isLunch === isLunch) {
        updatedUnit = {
          ...unit,
          etaByOps,
        };
      } else {
        updatedUnit = { ...unit };
      }
      return updatedUnit;
    });
    LOGGING >= 1 &&
      console.log("handleEditETA got: ", {
        updatedListOfUnits,
        listName,
      });
    const updatedSelectedMeal = {
      ...selectedMeal,
      [listName]: updatedListOfUnits,
    };
    LOGGING >= 1 &&
      console.log(
        "handleEditETA got updatedSelectedMeal: ",
        updatedSelectedMeal
      );
    this.setState({
      selectedMeal: updatedSelectedMeal,
    });
  };

  handleInsertInstance({ ordersForMeal, instance, byTime, callback }) {
    LOGGING >= 1 &&
      console.log("handleInsertInstance called with: ", {
        ordersForMeal,
        instance,
        byTime,
      });
    // case 1: order for instance exists but order for restaurant doesn't;
    // case 2: order for instance exists, order for restaurant also exists,
    // but the existing order for instance is for a different restaurant;
    // case 3: order for instance exists, order for restaurant also exists,
    // but the existing order for instance is for the same restaurant;
    // case 4: order for instance doesn't exist, order for restaurant exists;
    // case 5: order for instance doesn't exist, order for restaurant doesn't either.
    const foundInstanceOrder = ordersForMeal.find((order) =>
      order.instances.find((i) => i._id === instance._id)
    );

    const foundRestaurantOrder =
      instance.dish &&
      ordersForMeal.find(
        (order) => order.restaurant._id === instance.dish.restaurant._id
      );

    LOGGING >= 1 &&
      console.log("handleInsertInstance got: ", {
        foundInstanceOrder,
        foundRestaurantOrder,
      });

    if (foundInstanceOrder) {
      if (
        // case 1:
        // if there's an existing order with the instance, but no order with the
        // restaurant, then we need to remove the instance from its old restaurant order
        !foundRestaurantOrder ||
        // case 2:
        // if there's an existing order with the instance, but its order has a different
        // restaurant, then we need to remove the instance from its old restaurant order
        foundRestaurantOrder._id !== foundInstanceOrder._id
      ) {
        this.props
          .removeInstanceFromOrder({
            orderId: foundInstanceOrder._id,
            instanceId: instance._id,
          })
          .then(() => {
            LOGGING >= 1 && console.log("removeInstanceFromOrder done");
            if (instance.dish) {
              this.props
                .addInstanceToOrder({
                  orderId: foundRestaurantOrder
                    ? foundRestaurantOrder._id
                    : null,
                  restaurantId: instance.dish.restaurant._id,
                  instanceId: instance._id,
                  deliveryId: instance.delivery,
                })
                .then(() => {
                  callback();
                });
            } else {
              callback();
            }
          });
      } else {
        // case 3:
        // restaurant order exists and is the same as dish's restaurant,
        // nothing needs to be done
        LOGGING >= 1 &&
          console.log("same restaurant, reload orders to get the updated dish");
        this.props.fetchOrdersById([foundInstanceOrder._id]).then(callback);
      }
    } else {
      // case 4 & 5:
      // no order exists for this instance
      this.props
        .addInstanceToOrder({
          // case 4: if foundRestaurantOrder is not null
          // case 5: if foundRestaurantOrder is null
          orderId: foundRestaurantOrder && foundRestaurantOrder._id,
          restaurantId: instance.dish.restaurant._id,
          instanceId: instance._id,
          deliveryId: instance.delivery,
        })
        .then(() => {
          callback();
        });
    }
  }

  handleOrderByOps(orderId, e) {
    // orderId is always created by handleDecideDish
    // handleOrderByOps will always get non-null orderId
    e.preventDefault();
    const now = moment(new Date()).valueOf();
    let order = { orderTime: now };
    this.setState({ loading: true });
    this.props
      .updateOrder({ _id: orderId, order })
      .then(() => {
        this.handleGetDataForSelectedMeal();
      })
      .catch((error) => {
        LOGGING >= 1 && console.log("handleorderByops got error:", error);
        this.setState({ loading: false });
      });
  }

  handleToggleInstance(delivery, instance) {
    LOGGING >= 1 &&
      console.log("handleToggleInstance called with: ", { delivery, instance });
    const { _id, status: statusOld, user } = instance;
    let data = {};
    if (statusOld === InstanceStatus.CANCELLED) {
      data = { status: InstanceStatus.SCHEDULED };
    } else if (statusOld === InstanceStatus.SCHEDULED) {
      data = { status: InstanceStatus.CANCELLED };
    } else {
      return;
    }
    if (_id === undefined) {
      data = { ...data, user };
    }

    LOGGING >= 1 && console.log("handleToggleInstance got: ", data);
    this.setState({ loading: true });

    this.handleChangeDelivery({
      _id: delivery._id,
      company: delivery.company._id,
      byTime: delivery.byTime,
      instances: delivery.instances.map((i) =>
        i.user === user
          ? {
              _id: instance._id,
              status:
                instance.status === InstanceStatus.SCHEDULED
                  ? InstanceStatus.CANCELLED
                  : InstanceStatus.SCHEDULED,
              user: instance.user,
              dish: instance.dish._id,
            }
          : i
      ),
    });
  }

  // for dish catelog
  handleDecideDish(e) {
    e.preventDefault();
    const { instance, delivery } = this.state;
    const { user } = instance;

    this.setState({ showDishCatelog: false, loading: true }, () => {
      window.scrollTo(0, this.state.pageOffsetY);
      const { _id, company, byTime, instances } = delivery;
      // step 1: change delivery, instance, dish
      this.handleChangeDelivery(
        {
          _id,
          byTime,
          company: company._id,
          instances: instances.map((i) =>
            i.user === user ? { ...instance, dish: instance.dish._id } : i
          ),
        },
        (changedDelivery) => {
          const updatedInstance = changedDelivery.instances.find(
            (i) => i.user === instance.user
          );
          const { selectedMeal } = this.state;
          const { ordersForDay, isLunch } = selectedMeal;
          const ordersForMeal = ordersForDay.filter(
            (o) => o.isLunch === isLunch
          );
          // step 2: insert instance into order
          this.handleInsertInstance({
            instance: updatedInstance,
            ordersForMeal,
            byTime,
            callback: () => {
              this.setState(
                {
                  instance: null,
                  delivery: null,
                  deliveries: this.handleGetDeliveriesWithSchedule(),
                },
                () => {
                  // step 3: update state
                  this.handleGetDataForSelectedMeal();
                }
              );
            },
          });
        }
      );
    });
  }

  // 7. for dish inventory rendering
  handleSelectDish(dish, e) {
    e.preventDefault();

    const { instance } = this.state;
    LOGGING >= 1 &&
      console.log("handleSelectDish called with: ", { instance, dish });
    this.setState({ instance: { ...instance, dish } });
  }

  handleDeselectDish(e) {
    e.preventDefault();
    const { instance } = this.state;
    this.setState({ instance: { ...instance, dish: null } });
  }

  handleHideDishCatelog(e) {
    e.preventDefault();
    const { pageOffsetY } = this.state;
    window.scrollTo(0, pageOffsetY);
    this.setState({ showDishCatelog: false });
  }

  handleShowDishCatelog(delivery, instance, e) {
    e.preventDefault();
    LOGGING >= 1 &&
      console.log("handleShowDishCatelog called with: ", {
        instance,
        delivery,
      });
    this.setState(
      {
        showDishCatelog: true,
        instance,
        delivery,
        pageOffsetY: window.pageYOffset,
      },
      () => {
        if (this.selectedDishRef.current) {
          this.selectedDishRef.current.scrollIntoView({
            behavior: "auto",
            block: "center",
          });
        } else {
          window.scrollTo(0, 0);
        }
      }
    );
  }

  // 8. for rendering
  // showDetailsOneList:
  //{ {dateKey, isLunch}: [restaurantId or companyId] }
  handleToggleShowDetailsOneList = (mealKey, entityId, e) => {
    e.preventDefault();
    let { showDetailsOneList } = this.state;
    LOGGING >= 1 &&
      console.log(
        "PageUserDriver handleToggleShowDetailsOneList called with:",
        {
          mealKey,
          entityId,
          showDetailsOneList,
        }
      );

    if (showDetailsOneList[mealKey]) {
      const entityIndex = showDetailsOneList[mealKey].findIndex(
        (i) => i === entityId
      );
      if (entityIndex > -1) {
        showDetailsOneList[mealKey].splice(entityIndex, 1);
      } else {
        showDetailsOneList[mealKey].push(entityId);
      }
    } else {
      showDetailsOneList[mealKey] = [entityId];
    }
    this.setState({ showDetailsOneList });
  };

  // showDetailsTwoLists:
  // { [unitType]: {[mealKey]: [unitId]} }
  // { delivery: {[{dateKey, isLunch}]: [companyIds]} }
  // { order: {[{dateKey, isLunch}]: [restaurantIds]} }

  handleToggleShowDetailsTwoLists = (unitType, mealKey, entityId, e) => {
    e.preventDefault();
    let { showDetailsTwoLists, showAllDetails } = this.state;
    const { selectedMeal } = this.state;
    const { deliveriesForDay, ordersForDay } = selectedMeal;
    LOGGING >= 3 &&
      console.log("handleToggleShowDetailsTwoLists called with:", {
        unitType,
        mealKey,
        entityId,
        showDetailsTwoLists,
        showAllDetails,
        deliveriesForDay,
        ordersForDay,
      });

    // Get the flag array for this meal and this panel (companies or restaurants)
    let showDetailsForUnits = showDetailsTwoLists[unitType][mealKey];

    if (showDetailsForUnits) {
      const itemIndex = showDetailsForUnits.findIndex((i) => i === entityId);
      if (itemIndex < 0) {
        showDetailsForUnits.push(entityId);
      } else {
        showDetailsForUnits.splice(itemIndex, 1);
      }
      showDetailsTwoLists[unitType][mealKey] = showDetailsForUnits;
    } else {
      showDetailsTwoLists[unitType][mealKey] = [entityId];
    }

    const listOfUnits = selectedMeal[UNIT_LIST_NAME[unitType]];
    const entityName = UNIT_ENTITY_NAME[unitType];

    showAllDetails[unitType][mealKey] = showAllDetails[unitType][mealKey]
      ? listOfUnits.some((unit) =>
          showDetailsTwoLists[unitType][mealKey].find(
            (i) => i === unit[entityName]._id
          )
        )
      : listOfUnits.every((unit) =>
          showDetailsTwoLists[unitType][mealKey].find(
            (i) => i === unit[entityName]._id
          )
        ); // if the list is partially flipped, the showAllDetails flag remains on

    this.setState({ showDetailsTwoLists, showAllDetails });
  };

  // showAllDetails:
  // { [unitType]: {[mealKey]: Boolean} }
  // { delivery: {[{dateKey, isLunch}]: true or false} }
  // { order: {[{dateKey, isLunch}]: true or false} }

  handleToggleShowAllDetails(unitType, mealKey, e) {
    e.preventDefault();
    const { showAllDetails, showDetailsTwoLists, selectedMeal } = this.state;
    const listOfUnits = selectedMeal[UNIT_LIST_NAME[unitType]];
    const entityName = UNIT_ENTITY_NAME[unitType];
    const value = !showAllDetails[unitType][mealKey];
    LOGGING >= 3 &&
      console.log(
        "handleToggleShowAllDetails called with old value:",
        showAllDetails[unitType][mealKey]
      );
    LOGGING >= 3 &&
      console.log("handleToggleShowAllDetails called with new value:", value);
    const listDetails = value
      ? listOfUnits.map((unit) => unit[entityName]._id)
      : [];

    this.setState({
      showAllDetails: {
        ...showAllDetails,
        [unitType]: { ...showAllDetails[unitType], [mealKey]: value },
      },
      showDetailsTwoLists: {
        ...showDetailsTwoLists,
        [unitType]: {
          ...showDetailsTwoLists[unitType],
          [mealKey]: listDetails,
        },
      },
    });
  }
  handleOrderByDriver(orderId, e) {
    e.preventDefault();
    const now = moment(new Date()).valueOf();
    let order = { orderTime: now };
    this.props.updateOrder({ _id: orderId, order }).then(() => {
      // this.setState({ deliveries: this.props.deliveriesSavedInMonth }, () => {
      this.handleGetDataForSelectedMeal(() => {
        this.setState({ loading: false });
      });
    });
    // });
  }

  handlePickUp(routeAfterPickUp, e) {
    e.preventDefault();
    this.setState({ loading: true });
    LOGGING >= 1 && console.log("handlePickUp called with: ", routeAfterPickUp);
    const { orderPickedUp, nextStop } = routeAfterPickUp;
    const { _id, restaurant } = orderPickedUp;
    LOGGING >= 1 &&
      console.log("handlePickUp called with restaurant: ", restaurant);
    const now = moment(new Date()).valueOf();
    const order = { pickUpTime: now };
    this.props.updateOrder({ _id, order }).then(() => {
      //find the next stop
      const { type, _id } = nextStop;
      if (type === "delivery") {
        this.props
          .updateDelivery({
            _id,
            delivery: {
              dispatchTime: now,
              dispatchOrigin: { restaurant: restaurant._id },
            },
          })
          .then(() => {
            this.setState(
              { deliveries: this.props.deliveriesSavedInMonth },
              () => {
                this.handleGetDataForSelectedMeal(() => {
                  this.setState({ loading: false });
                });
              }
            );
          });
      } else {
        this.handleGetDataForSelectedMeal(() => {
          this.setState({ loading: false });
        });
      }
    });
  }

  handleDeliver(_id, routeAfterDelivery, e) {
    e.preventDefault();
    LOGGING >= 1 &&
      console.log("handleDeliver called with: ", { _id, routeAfterDelivery });
    this.setState({ loading: true });
    const now = moment(new Date()).valueOf();
    this.props
      .updateDelivery({
        _id,
        delivery: {
          deliverTime: now,
        },
      })
      .then(() => {
        const { deliveryDropped, nextStop } = routeAfterDelivery;
        if (nextStop) {
          this.props
            .updateDelivery({
              _id: nextStop._id,
              delivery: {
                dispatchTime: now,
                dispatchOrigin: {
                  company: deliveryDropped.company._id,
                },
              },
            })
            .then(() => {
              this.setState(
                { deliveries: this.props.deliveriesSavedInMonth },
                () => {
                  this.handleGetDataForSelectedMeal(() => {
                    this.setState({ loading: false });
                  });
                }
              );
            });
        } else {
          this.setState(
            { deliveries: this.props.deliveriesSavedInMonth },
            () => {
              this.handleGetDataForSelectedMeal(() => {
                this.setState({ loading: false });
              });
            }
          );
        }
      });
  }

  // 2. for navigating
  componentDidUpdate(prevProps) {
    const {
      calendarYear: prevYear,
      calendarMonth: prevMonth,
      selectedDay: prevSelectedDay,
    } = prevProps;
    const { calendarYear, calendarMonth, selectedDay } = this.props;
    LOGGING >= 1 &&
      console.log("PageUserInternal componentDidUpdate with:", {
        prevSelectedDay,
        selectedDay,
        calendarYear,
        calendarMonth,
        prevMonth,
        prevYear,
      });
    if (prevMonth !== calendarMonth || prevYear !== calendarYear) {
      LOGGING >= 1 &&
        console.log(
          "componentDidUpdate calling handleLoadDataForCalendarMonth"
        );
      this.handleLoadDataForCalendarMonth();
    }
    if (
      selectedDay.year !== prevSelectedDay.year ||
      selectedDay.month !== prevSelectedDay.month ||
      selectedDay.day !== prevSelectedDay.day
    ) {
      LOGGING >= 1 &&
        console.log("componentDidUpdate calling handleGetDataForSelectedMeal");
      this.handleGetDataForSelectedMeal();
    }
  }

  componentDidMount() {
    const {
      selectedDay,
      calendarYear,
      calendarMonth,
      currentUser,
    } = this.props;
    LOGGING >= 1 &&
      console.log("componentDidMount with: selectedDay:", selectedDay);
    if (!selectedDay || !calendarYear || !calendarMonth) {
      this.props.goToCurrent();
    }
    if (currentUser.user.role === "ops") {
      this.props.fetchCompanies().then(() => {
        this.props.fetchRestaurants().then(() => {
          this.props.fetchDishes().then(() => {
            const {
              categories: restaurants,
              keyedData: dishCatelog,
            } = getCategoriesFromData({
              data: this.props.dishes,
              parentKey: "restaurant",
            });
            LOGGING >= 1 &&
              console.log(
                "getCategoriesFromData got restaurants: ",
                restaurants,
                this.props.dishes
              );
            this.setState({ restaurants, dishCatelog }, () => {
              LOGGING >= 1 &&
                console.log(
                  "componentDidMount calling handleLoadDataForCalendarMonth"
                );
              this.handleLoadDataForCalendarMonth();
            });
          });
        });
      });
    } else {
      this.handleLoadDataForCalendarMonth();
    }
  }

  render() {
    const {
      currentUser,
      calendarYear,
      calendarMonth,
      goToNextMonth,
      goToPrevMonth,
    } = this.props;

    const {
      selectedMeal,
      monthGrid,
      loading,
      numOfInstances,
      instance,
      showDetailsOneList,
      showDetailsTwoLists,
      showAllDetails,
      contextDateMoments,
      contextDeliveries,
      showDishCatelog,
      dishCatelog,
      restaurants,
      deliveries,
    } = this.state;

    LOGGING >= 1 &&
      console.log("PageUserInternal rendering with", {
        props: this.props,
        state: this.state,
        monthGrid,
        selectedMeal,
      });

    if (!monthGrid || !selectedMeal) {
      return <LoadingIcon />;
    }
    const navbar = <Navbar />;

    const content = (
      <div className={`page-content ${currentUser.user.role}`}>
        {loading ? <LoadingIcon /> : null}
        <CalendarWrapperShared
          deliveries={deliveries}
          role={currentUser.user.role}
          onShowDashboard={this.handleShowDashboard}
          onClickDayGrid={this.handleSelectDay}
          onNextMonth={goToNextMonth}
          onPrevMonth={goToPrevMonth}
          calendarYear={calendarYear}
          calendarMonth={calendarMonth}
          selectedDay={selectedMeal}
          monthGrid={monthGrid}
        />
        {selectedMeal.deliveriesForDay && selectedMeal.ordersForDay ? (
          currentUser.user.role === "ops" ? (
            <OpsDay
              selectedMeal={selectedMeal}
              month={calendarMonth}
              year={calendarYear}
              onToggleLunchDinner={this.handleSelectLunchDinner}
              onGoToSelectedMonth={this.handleGoToSelectedDayMonth}
              numOfInstances={numOfInstances}
              contextDateMoments={contextDateMoments}
              contextDeliveries={contextDeliveries}
              showDetails={showDetailsTwoLists}
              showAllDetails={showAllDetails}
              onToggleDetails={this.handleToggleShowDetailsTwoLists}
              onToggleAllDetails={this.handleToggleShowAllDetails}
              onToggleInstance={this.handleToggleInstance}
              onSaveETA={this.handleSaveETA}
              onEditETA={this.handleEditETA}
              onOrder={this.handleOrderByOps}
              onShowDishInventory={this.handleShowDishCatelog}
            />
          ) : currentUser.user.role === "driver" ? (
            <DriverDay
              selectedMeal={selectedMeal}
              numOfInstances={numOfInstances}
              month={calendarMonth}
              year={calendarYear}
              onToggleLunchDinner={this.handleSelectLunchDinner}
              onGoToSelectedMonth={this.handleGoToSelectedDayMonth}
              showDetails={showDetailsOneList}
              onToggleShowDetails={this.handleToggleShowDetailsOneList}
              onOrder={this.handleOrderByDriver}
              onPickUp={this.handlePickUp}
              onDeliver={this.handleDeliver}
            />
          ) : null
        ) : null}
      </div>
    );

    return (
      <div className="homepage">
        {showDishCatelog ? (
          <DishCatelog
            loading={loading}
            keyedData={dishCatelog}
            categories={restaurants}
            instance={instance}
            selecteDishRef={this.selectedDishRef}
            onSelect={this.handleSelectDish}
            onDeselect={this.handleDeselectDish}
            onDecide={this.handleDecideDish}
            onCancel={this.handleHideDishCatelog}
          />
        ) : (
          <React.Fragment>
            {loading ? <LoadingIcon /> : null}
            {navbar}
            {content}
          </React.Fragment>
        )}
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  LOGGING >= 1 &&
    console.log(
      "PageUserInternal mapStateToProps with reduxState:",
      reduxState
    );
  const {
    currentUser,
    companies,
    restaurants,
    dishes,
    calendar,
    deliveries,
  } = reduxState;
  const { calendarMonth, calendarYear, selectedDay } = calendar;
  return {
    // perenial data
    currentUser,
    companies,
    restaurants,
    dishes,
    // monthly data
    deliveriesSavedInMonth: deliveries, // deliveries of the month
    // navigational controls
    calendarMonth,
    calendarYear,
    selectedDay,
  };
}

export default connect(mapStateToProps, {
  fetchCompanies,
  fetchDishes,
  fetchDeliveries,
  fetchRestaurants,
  createDelivery,
  updateDelivery,
  fetchOrdersById,
  updateOrder,
  addInstanceToOrder,
  removeInstanceFromOrder,
  goToNextMonth,
  goToPrevMonth,
  goToCurrent,
  goToDate,
  goToSelectedDayMonth,
  setMeal,
})(PageUserInternal);
