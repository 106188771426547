import React from "react";
import "./ClosePage.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-regular-svg-icons";
const ClosePage = ({ onClick }) => (
  <button type="button" className="close-page" onClick={onClick}>
    <FontAwesomeIcon icon={faTimesCircle} />
  </button>
);

export default ClosePage;
