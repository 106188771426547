import React from "react";
import "./ListHeader.scss";
import { ExpandToggle } from "../../widgets";

const SectionHeader = ({ showToggle, showAll, onToggleShowAll, title }) => (
  <div className="section-header">
    <span className="title-20">{title}</span>
    {showToggle ? (
      <ExpandToggle
        toggleOffText="collapse all"
        toggleOnText="expand all"
        onToggle={onToggleShowAll}
        showDetails={showAll}
      />
    ) : null}
  </div>
);

export default SectionHeader;
