import {
  LOAD_INSTANCES,
  LOAD_DELEGATED_USER,
  RESET_INSTANCES,
} from "../actionTypes";
import { PURGE, REHYDRATE } from "redux-persist";

export const instances = (state = [], action) => {
  switch (action.type) {
    case LOAD_INSTANCES:
      // The input instances are read from mongodb so still in the
      // form of a vanilla object. In all client side memory and logic,
      // intances are in the form of a Map.
      return action.instances;
    case REHYDRATE: // This added just to show that this action type also exists, can be omitted.
      return state;
    case PURGE:
      return [];
    case RESET_INSTANCES:
      return [];
    default:
      return state;
  }
};

export const delegatedUser = (state = new Map(), action) => {
  switch (action.type) {
    case LOAD_DELEGATED_USER:
      const {
        participantId,
        deliveryId,
        deliveryInfo,
        participantInfo,
      } = action;
      return { participantId, deliveryId, deliveryInfo, participantInfo };
    default:
      return state;
  }
};
