import React from "react"; // eslint-disable-line no-unused-vars
import "./LoadingPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons";

const LoadingIcon = () => (
  <div className="loading-icon">
    <FontAwesomeIcon icon={faSpinnerThird} className="layer-one" />
    <FontAwesomeIcon icon={faSpinnerThird} className="layer-two" />
    <FontAwesomeIcon icon={faSpinnerThird} className="layer-three" />
    <FontAwesomeIcon icon={faSpinnerThird} className="layer-four" />
    <FontAwesomeIcon icon={faSpinnerThird} className="layer-five" />
  </div>
);

const LoadingPage = () => (
  <div className="loading-page">
    <LoadingIcon />
  </div>
);

export { LoadingPage, LoadingIcon };
