import { ADD_ERROR, REMOVE_ERROR } from "../actionTypes";
import { LOGGING } from "../../hocs/constants";

export const addError = (error) => ({
  type: ADD_ERROR,
  error,
});

export const removeError = () => ({
  type: REMOVE_ERROR,
});

export const removeErrors = () => {
  return (dispatch) => {
    LOGGING >= 3 && console.log("remove errors called.");
    dispatch(removeError());
  };
};
