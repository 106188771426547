import React, { Component } from "react"; // eslint-disable-line no-unused-vars
import "./FormDiets.scss";
import { connect } from "react-redux";
import { Diet, getSelectFields } from ".";
import {
  LoadingIcon,
  PopUp,
  StepFormFooter,
  ProfileFormFooter,
  ProfileStepText,
} from "../widgets";
import { Redirect } from "react-router-dom";

const EATER_FIELDS = ["_id", "name", "diet"];
const DIET_FIELDS = ["_id", "likes", "cuisines", "comments"];

const DefaultLikesSelected = {
  chicken: true,
  beef: true,
  pork: true,
  seafood: true,
  vegetarian: true,
  spicy: false,
  lowCarb: false,
};

const createDefaultDietWithCuisineIds = ({ cuisineCatelog }) => {
  let likes = {};
  const DefaultLikes = DefaultLikesSelected;
  Object.keys(DefaultLikes).forEach((like) => {
    likes[like] = DefaultLikes[like];
  });
  const cuisineIds = []; //cuisineCatelog.map((cuisine) => cuisine._id);
  return { cuisines: cuisineIds, likes: { ...likes }, comments: "" };
};

// leaf props (with *********) read by FormDiets
// currentUser
//   |_user
//      |_company
//        |_sharedDiet******* true
//        |_diet as companyDiet
//        | |_likes******* {chicken: true, ...}
//        | |_cuisines******* [ "1343fdfjety4t", "dafjdje5435" ]
//        | |_comments******* "blha"
//        |_eaters
//          |_eater
//          |   |_diet
//          |     |_likes******* {chicken: true, ...}
//          |     |_cuisines******* [ "1343fdfjety4t", "dafjdje5435" ]
//          |     |_comments******* "blha"
//          |_eater
//
// Rule of thumb for cuisines
//
// in state and internal handlers, cuisines will always be populated as
// an Object {"sandwich": {status: true, _id: "erewft113416"}}
//
// in props and server RWs, cuisines will always be raw ids as
// an array ["feqa4er4yddt", "dfe4y00654dd"]
//
// for eater's diet and companyDiet
// constructor filters and copies from props to state
// componentDidMount handles empty diet and transform from server format to client's.
//

class FormDiets extends Component {
  constructor(props) {
    super();

    const { eaters, companyDiet, sharedDiet } = props;

    this.state = {
      // sharedDiet:
      // if true, then only companyDiet matters
      // if false, check each eater.diet
      // when there's only one eater, it's false
      sharedDiet,

      // constructor on eaters and diet
      // 1. Select only fields that matter.
      // 2. Copy likes, as they will be changed,
      //    but not copy cuisines, as they will be
      //    transformed and copied by componentDidMount.
      // 3. Doesn't handle empty diet, as they will be
      //    handled by componentDidMount after loading cuisines.

      companyDiet: getSelectFields({
        data: companyDiet,
        fields: DIET_FIELDS,
        objectsToCopy: ["likes"],
      }),

      eaters: eaters
        .map((eater) => getSelectFields({ data: eater, fields: EATER_FIELDS }))
        .map((eater) => ({
          ...eater,
          diet: getSelectFields({
            data: eater.diet,
            fields: DIET_FIELDS,
            objectsToCopy: ["likes"],
          }),
        })),
    };

    // handlers for the form header
    this.handleToggleSharedDiet = this.handleToggleSharedDiet.bind(this);

    // called when any like button is toggled.
    this.handleToggleLike = this.handleToggleLike.bind(this);
    // called when any cuisine button is toggled.
    this.handleToggleCuisine = this.handleToggleCuisine.bind(this);
    // called when the comments input is edited.
    this.handleChangeComments = this.handleChangeComments.bind(this);
  }

  handleToggleSharedDiet(e) {
    e.preventDefault();
    const { sharedDiet, eaters } = this.state;
    const showCuisineList = !sharedDiet
      ? false
      : Array(eaters.length).fill(false);

    this.setState({
      sharedDiet: !sharedDiet,
      showCuisineList,
    });
  }

  handleToggleLike(index, like, e) {
    e.preventDefault();
    const { eaters, sharedDiet, companyDiet } = this.state;
    if (sharedDiet) {
      let { likes } = companyDiet;
      likes[like] = !likes[like];
      this.setState({ companyDiet: { ...companyDiet, likes } });
    } else {
      const { diet } = eaters[index];
      let { likes } = diet;

      likes[like] = !likes[like];
      const updatedDiet = { ...diet, likes };
      const updatedEaters = eaters.map((eater, i) =>
        i === index ? { ...eater, diet: updatedDiet } : { ...eater }
      );

      this.setState({
        eaters: updatedEaters,
      });
    }
  }

  handleToggleCuisine(index, cuisineId, e) {
    e.preventDefault();
    const { eaters, sharedDiet, companyDiet } = this.state;

    if (sharedDiet) {
      let { cuisines } = companyDiet;
      const cuisineIndex = cuisines.findIndex((c) => c === cuisineId);
      if (cuisineIndex >= 0) {
        cuisines.splice(cuisineIndex, 1);
      } else {
        cuisines.push(cuisineId);
        cuisines.sort((a, b) => (a > b ? 1 : -1));
      }
      this.setState({ companyDiet: { ...companyDiet, cuisines } });
    } else {
      const { diet } = eaters[index];
      let { cuisines } = diet;
      const cuisineIndex = cuisines.findIndex((c) => c === cuisineId);
      if (cuisineIndex >= 0) {
        cuisines.splice(cuisineIndex, 1);
      } else {
        cuisines.push(cuisineId);
      }

      const updatedDiet = { ...diet, cuisines };
      const updatedEaters = eaters.map((eater, i) =>
        i === index ? { ...eater, diet: updatedDiet } : { ...eater }
      );

      this.setState({
        eaters: updatedEaters,
      });
    }
  }

  handleChangeComments(index, e) {
    e.preventDefault();

    const { eaters, sharedDiet, companyDiet } = this.state;
    if (sharedDiet) {
      this.setState({
        companyDiet: { ...companyDiet, comments: e.target.value },
      });
    } else {
      const updatedEaters = eaters.map((eater, i) =>
        i === index
          ? { ...eater, diet: { ...eater.diet, comments: e.target.value } }
          : { ...eater }
      );
      this.setState({ eaters: updatedEaters });
    }
  }

  componentDidMount() {
    // Always load the cuisines from database, which contains
    // 1. the full list of cuisines defined (evolving)
    // 2. the mapping from cuisine id to cuisine name

    const { cuisineCatelog } = this.props;

    // componentDidMount on companyDiet and eaters (filtered and copied already)
    // 1. handle empty diets.
    // 2. convert cuisines from server format to client format, with new copy.

    // Note that both companyDiet and eater's diet are converted
    // regardless of sharedDiet value, since it can be toggled in state,
    // so both are potentially useful.

    let { eaters, companyDiet } = this.state;

    // 1. handle empty diets
    eaters = eaters.map((eater) => ({
      ...eater,
      diet: eater.diet || createDefaultDietWithCuisineIds({ cuisineCatelog }),
    }));

    companyDiet =
      companyDiet || createDefaultDietWithCuisineIds({ cuisineCatelog });

    // updating the internal states
    this.setState(
      {
        companyDiet,
        eaters,
      },
      () => {
        window.scrollTo(0, 0);
        this.props.updateShadow();
      }
    );
  }

  render() {
    const {
      onSave,
      onNext,
      onPrevious,
      mainContact,
      cuisineCatelog,
      eaters: eatersOld,
      sharedDiet: sharedDietOld,
      companyDiet: companyDietOld,
      contentRef,
      contentOverflow,
      goto,
      onCancelGoTo,
    } = this.props;

    const { eaters, sharedDiet, companyDiet } = this.state;

    const hasEmptyDiet = eaters.find((eater) => !eater.diet) || !companyDiet;
    if (hasEmptyDiet) {
      return <LoadingIcon />;
    }

    const formHeader =
      eaters.length > 1 ? (
        <div className="step-form-header">
          <button
            type="button"
            onClick={this.handleToggleSharedDiet}
            disabled={sharedDiet}
            className={`diet-method ${sharedDiet ? "selected" : "unselected"}`}
          >
            shared diet
          </button>
          <button
            type="button"
            onClick={this.handleToggleSharedDiet}
            disabled={!sharedDiet}
            className={`diet-method ${sharedDiet ? "unselected" : "selected"}`}
          >
            different diet
          </button>
        </div>
      ) : null;

    const eaterList = sharedDiet ? (
      <Diet
        diet={companyDiet}
        // showCuisineList={showCuisineList}
        onChangeComments={this.handleChangeComments.bind(this, -1)}
        // onShowCuisineList={this.handleShowCuisineList.bind(this, -1)}
        cuisineCatelog={cuisineCatelog}
        onToggleCuisine={this.handleToggleCuisine.bind(this, -1)}
        onToggleLike={this.handleToggleLike.bind(this, -1)}
      />
    ) : (
      eaters.map((eater, index) => (
        <Diet
          key={index}
          name={eater.name}
          diet={eater.diet}
          cuisineCatelog={cuisineCatelog}
          onChangeComments={this.handleChangeComments.bind(this, index)}
          onToggleLike={this.handleToggleLike.bind(this, index)}
          onToggleCuisine={this.handleToggleCuisine.bind(this, index)}
          isMainContact={eater._id === mainContact}
        />
      ))
    );

    // begins: prepare oldData and newData for FormFooter

    let oldData = {
      sharedDiet: sharedDietOld,
    };

    let newData = {
      sharedDiet,
    };

    if (sharedDiet) {
      // diet from props needs some filtering before comparing to the new diet.
      oldData.diet = getSelectFields({
        data: companyDietOld,
        fields: DIET_FIELDS,
      });
      // diet from state needs some transforming before comparing to the old diet.
      newData.diet = companyDiet;
    } else {
      // eaters from props needs some filtering before comparing to the new eaters.
      oldData.eaters = eatersOld
        .map((eater) => getSelectFields({ data: eater, fields: EATER_FIELDS }))
        .map((eater) => ({
          ...eater,
          diet: getSelectFields({
            data: eater.diet,
            fields: DIET_FIELDS,
          }),
        }));
      // eaters from state needs some transforming before comparing to the old eaters
      newData.eaters = eaters;
    }

    const equal = JSON.stringify(oldData) === JSON.stringify(newData);

    if (equal && onSave && goto > 0) {
      return <Redirect to={`/profile/${ProfileStepText[goto - 1]}`} />;
    }
    const gotoConfirm = onSave ? (
      <PopUp
        isPoppedUp={onSave && goto >= 0 && !equal}
        componentToDisplay={
          <div className="goto-confirm">
            <span>Save the changes you made?</span>
            <div className="button-wrapper">
              <button
                className="no-save-goto"
                onClick={onSave.bind(this, null, goto)}
              >
                no
              </button>
              <button
                className="save-goto"
                onClick={onSave.bind(this, newData, goto)}
              >
                save
              </button>
            </div>
          </div>
        }
        hidePopUp={onCancelGoTo}
      />
    ) : null;

    // ends: prepare oldData and newData for FormFooter

    const formFooter = onSave ? (
      <ProfileFormFooter
        onSave={onSave.bind(this, newData)}
        showShadow={contentOverflow}
      />
    ) : (
      <StepFormFooter
        isFirstStep={false}
        isLastStep={false}
        onPrevious={onPrevious}
        onNext={onNext.bind(this, oldData, newData)}
        showShadow={contentOverflow}
        dataHasError={false}
      />
    );
    return (
      <div className="step-form diets" ref={contentRef}>
        {gotoConfirm}
        {formHeader}
        {eaterList}
        {formFooter}
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  return {
    currentUser: reduxState.currentUser,
  };
}

export default connect(mapStateToProps, {})(FormDiets);
