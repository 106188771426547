import { apiCall, setTokenHeader } from "../api";
import { SET_CURRENT_USER, RELOAD_CURRENT_USER } from "../actionTypes";
import { addError, removeError } from "./errors";
import {
  resetInstances,
  cleanCuisines,
  resetDeliveries,
  resetCompanies,
  fetchCompanies,
  resetCalendar,
  goToCurrent,
  cleanUsers,
  resetDataMode,
} from "./";

import { LOGGING } from "../../hocs/constants";

/* global localStorage */

export function setCurrentUser(user) {
  LOGGING >= 3 && console.log("auth setCurrentUser called with user: ", user);
  return {
    type: SET_CURRENT_USER,
    user,
  };
}

export function reloadCurrentUser(user) {
  LOGGING >= 3 && console.log("auth setCurrentUser called with user: ", user);
  return {
    type: RELOAD_CURRENT_USER,
    user,
  };
}

export function verifyContact({ phone, isNewUser }) {
  LOGGING >= 3 && console.log("verifyContact called with phone:", phone);
  return (dispatch) => {
    // wrap our thunk in a promise so we can wait for the API call
    return new Promise((resolve, reject) => {
      return apiCall("post", `/auth/verifyContact`, { phone, isNewUser })
        .then(({ token }) => {
          LOGGING >= 1 &&
            console.log("verifyContact got token from server:", token);
          return resolve({ token });
        })
        .catch((err) => {
          dispatch(addError(err.message));
          reject(err.message); // indicate the API call failed
        });
    });
  };
}

export function authUserWithPhoneOnly(userData) {
  LOGGING >= 1 &&
    console.log("authUserWithPhoneOnly called wiht userData", userData);
  return (dispatch, getState) => {
    // wrap our thunk in a promise so we can wait for the API call
    const { adminPhone } = userData;
    if (adminPhone && adminPhone.length > 0) {
      dispatch(resetInstances());
      dispatch(cleanUsers());
    }
    return new Promise((resolve, reject) => {
      return apiCall("post", `/auth/signupwithphone`, userData)
        .then(({ token, ...user }) => {
          LOGGING >= 1 &&
            console.log("authUserWithPhoneOnly got token:", token);
          LOGGING >= 1 && console.log("authUserWithPhoneOnly got user:", user);
          if (token && user && user._id) {
            localStorage.setItem("jwtToken", token);
            setAuthorizationToken(token);
            LOGGING >= 1 && console.log("authUser got user:", user);
            dispatch(setCurrentUser(user));
            dispatch(resetDataMode());
            LOGGING >= 1 && console.log("authUser about to resetCalendar");
            dispatch(resetCalendar());
          }
          dispatch(removeError());
          dispatch(resetInstances());
          const { instances } = getState();
          LOGGING >= 1 &&
            console.log("authUser got instances after purging:", instances);
          resolve(); // indicate that the API call succeeded
        })
        .catch((err) => {
          LOGGING >= 1 && console.log("authUser got err:", err);
          dispatch(addError(err.message));
          reject(err); // indicate the API call failed
        });
    });
  };
}

export function setAuthorizationToken(token) {
  setTokenHeader(token);
}

export function logout() {
  return (dispatch) => {
    localStorage.clear();
    setAuthorizationToken(false);
    dispatch(setCurrentUser({}));
    dispatch(resetInstances());
    dispatch(cleanCuisines());
    dispatch(resetCompanies());
    dispatch(cleanUsers());
    dispatch(resetDataMode());
    dispatch(resetCalendar());
    dispatch(resetDeliveries());
  };
}

// unused:

export function reloadUser() {
  return (dispatch, getState) => {
    // wrap our thunk in a promise so we can wait for the API call
    return new Promise((resolve, reject) => {
      const { currentUser } = getState();
      return apiCall("post", `/auth/signin`, { phone: currentUser.user.phone })
        .then(({ token, ...user }) => {
          if (token && user && user._id) {
            LOGGING >= 3 && console.log("reloadUser got user:", user);
            const { company, stripeInfo, role } = user;
            LOGGING >= 3 &&
              console.log("reloadUser resetting:", { company, stripeInfo });
            dispatch(reloadCurrentUser({ company, stripeInfo, role }));
          }

          // resolve(); // indicate that the API call succeeded
        })
        .catch((err) => {
          LOGGING >= 3 && console.log("reloadUser got err:", err);
          dispatch(addError(err.message));
          reject(); // indicate the API call failed
        });
    });
  };
}

export function updateUserPassword(userData) {
  LOGGING >= 3 &&
    console.log("updateUserPassword called with userData", userData);
  return (dispatch) => {
    // wrap our thunk in a promise so we can wait for the API call
    return new Promise((resolve, reject) => {
      return apiCall("post", `/auth/updatePassword`, userData)
        .then(({ token, ...user }) => {
          LOGGING >= 3 &&
            console.log("updateUserPassword got token in res", token);
          LOGGING >= 3 &&
            console.log("updateUserPassword got user in res", user);
          localStorage.setItem("jwtToken", token);
          setAuthorizationToken(token);
          dispatch(setCurrentUser(user));
          dispatch(removeError());
          resolve(); // indicate that the API call succeeded
        })
        .catch((err) => {
          dispatch(addError(err.message));
          reject(); // indicate the API call failed
        });
    });
  };
}

export function authWithFacebook({
  name,
  email,
  picture,
  first_name,
  last_name,
  user_id,
}) {
  LOGGING >= 3 && console.log("authWithFacebook called with email", email);
  return (dispatch) => {
    // wrap our thunk in a promise so we can wait for the API call
    return new Promise((resolve, reject) => {
      return apiCall("get", `/auth/authWithFacebook`, {
        name,
        email,
        picture,
        first_name,
        last_name,
        user_id,
      })
        .then((result) => {
          LOGGING >= 3 && console.log("authWithFacebook got from api:", result);
          resolve(result); // indicate that the API call succeeded
        })
        .catch((err) => {
          dispatch(addError(err.message));
          reject(); // indicate the API call failed
        });
    });
  };
}

export function authUser(type, userData) {
  LOGGING >= 3 && console.log("authUser called wiht userData", userData);
  return (dispatch) => {
    // wrap our thunk in a promise so we can wait for the API call
    const { adminPhone } = userData;
    if (adminPhone && adminPhone.length > 0) {
      dispatch(resetInstances());
      dispatch(cleanUsers());
    }
    return new Promise((resolve, reject) => {
      return apiCall("post", `/auth/${type}`, userData)
        .then(({ token, ...user }) => {
          if (token && user && user._id) {
            localStorage.setItem("jwtToken", token);
            setAuthorizationToken(token);
            LOGGING >= 1 && console.log("authUser got user2:", user);
            dispatch(setCurrentUser(user));
            dispatch(resetDataMode());
            goToCurrent();
            if (user.role === "admin") {
              dispatch(fetchCompanies());
            }
          }
          dispatch(removeError());
          LOGGING && console.log("about to resetInstances");
          dispatch(resetInstances());
          dispatch(resetDeliveries());
          resolve(); // indicate that the API call succeeded
        })
        .catch((err) => {
          LOGGING >= 3 && console.log("authUser got err:", err);
          dispatch(addError(err.message));
          reject(); // indicate the API call failed
        });
    });
  };
}

export const deleteCard = () => {
  return (dispatch, getState) => {
    const { currentUser } = getState();

    return apiCall("delete", `/auth/${currentUser.user._id}/payment`)
      .then((updatedUser) => {
        dispatch(setCurrentUser(updatedUser));
      })
      .catch((err) => {
        dispatch(addError(err.message));
      });
  };
};
export const updateCard = ({ card, autoCharge }) => {
  return (dispatch, getState) => {
    const { currentUser } = getState();
    LOGGING >= 1 &&
      console.log("updateCard called with: ", { card, autoCharge });
    return new Promise((resolve, reject) => {
      return apiCall("post", `/auth/${currentUser.user._id}/payment`, {
        card,
        autoCharge,
      })
        .then((updatedUser) => {
          dispatch(setCurrentUser(updatedUser));
          resolve();
        })
        .catch((err) => {
          LOGGING >= 1 && console.log("updateCard got err:", err);
          reject(err.message);
        });
    });
  };
};
