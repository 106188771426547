import React, { Component } from "react";
import "./FAQ.scss";
import { ExpandToggle } from "../../widgets";

const faqs = [
  {
    Q: "Do I select my meal every day?",
    A:
      "No. If you want, you can specify a restaurant or dish for a meal on the calendar, or by texting our ops.",
  },
  {
    Q: "How many restaurants are in your network?",
    A:
      "As many as out there. You can specify your favorite restaurants and dishes.",
  },
  {
    Q: "How do I cancel a meal?",
    A:
      "You cancel on the calendar, or by texting our ops, before 11am for lunch, or before 4pm for dinner. You can cancel a week, a month or any time periods. You can also pause/resume the whole service very easily.",
  },
  {
    Q: "How do I pay?",
    A:
      "You pay online after receiving every meal and reviewing the receipt, just like in a restaurant. You can also set up automatic payment, if you prefer.",
  },
  {
    Q: "Can I sign-up for my family/team?",
    A:
      "Yes. You can have shared style or individual boxes, whichever way you want. You can have different styles on different days. You can provide preferences, schedules and ratings individually, or as a group.",
  },
];

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = { showDetails: Array(faqs.length).fill(false) };
    this.handleToggleShowDetails = this.handleToggleShowDetails.bind(this);
  }
  handleToggleShowDetails(index) {
    const { showDetails } = this.state;
    this.setState({
      showDetails: showDetails.map((show, id) => (id === index ? !show : show)),
    });
  }
  render() {
    const { showDetails } = this.state;
    return (
      <div className="user-anonymous-section faq-page">
        <h3>FAQ</h3>
        <div className="section-body">
          {faqs.map((item, index) => (
            <div
              key={index}
              className={`info-card ${showDetails[index] ? "expanded" : ""}`}
            >
              <div className="faq-question">
                <span>{item.Q}</span>
                <ExpandToggle
                  onToggle={this.handleToggleShowDetails.bind(this, index)}
                  showDetails={showDetails[index]}
                />
              </div>
              {showDetails[index] ? (
                <div className="faq-answer">{item.A}</div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default FAQ;
