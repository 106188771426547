import React, { Component } from "react"; // eslint-disable-line no-unused-vars
import "./Navbar.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/pro-regular-svg-icons";
import {
  faHeart,
  faCommentDots,
  faSignOut,
  faSignIn, // eslint-disable-line no-unused-vars
  faCog,
  faUsers,
  faCalendarAlt,
  faCreditCard,
} from "@fortawesome/pro-light-svg-icons";
import { ExpandToggle } from "../widgets";
import { connect } from "react-redux";
import { logout } from "../../store/actions";
const SectionLink = ({ text, onGoTo, isCurrentSection }) => (
  <button
    className={`section-link ${isCurrentSection ? "current-section" : ""}`}
    onClick={onGoTo}
  >
    {text}
  </button>
);
const ResetSectionButton = ({ logoText, onGoToSection }) => (
  <button className="brand-logo" to="/" onClick={onGoToSection}>
    <span>{logoText}</span>
  </button>
);
const HomeButton = ({ logoText }) => (
  <Link className="brand-logo" to="/">
    <span>{logoText}</span>
  </Link>
);

const Login = () => (
  <Link className="setting-row login" to="/login">
    <span>Log In</span>
    {/* <FontAwesomeIcon icon={faSignIn} /> */}
  </Link>
);

const LogOut = ({ onLogout, isDelegate, role }) => (
  <button type="button" onClick={onLogout} className="setting-row log-out">
    <span>{`${
      isDelegate
        ? "Exit delegate"
        : `log out${role && role !== "eater" ? ` as ${role}` : ""}`
    }`}</span>
    <FontAwesomeIcon icon={faSignOut} />
  </button>
);

const PaymentMethod = ({ user }) =>
  user.role === "eater" ? (
    <Link to="/paymentmethod" className="setting-row payment-method">
      <span>Payment Method</span>
      <FontAwesomeIcon icon={faCreditCard} />
    </Link>
  ) : null;

const CompanyEaters = ({ user }) =>
  user.role === "eater" ? (
    <Link
      to={{
        pathname: `/profile/eaters`,
        company: user.company,
      }}
      className="setting-row edit-eaters"
    >
      <span>Edit Eaters</span>
      <FontAwesomeIcon icon={faUsers} />
    </Link>
  ) : null;

const CompanyFavorites = ({ user }) =>
  user.role === "eater" ? (
    <Link
      to={{
        pathname: `/profile/favorites`,
        company: user.company,
      }}
      className="setting-row edit-favorites"
    >
      <span>Edit Favorites</span>
      <FontAwesomeIcon icon={faHeart} />
    </Link>
  ) : null;
const CompanyDiets = ({ user }) =>
  user.role === "eater" ? (
    <Link
      to={{
        pathname: `/profile/diets`,
        company: user.company,
      }}
      className="setting-row edit-diets"
    >
      <span>Edit Diets</span>
      <FontAwesomeIcon icon={faCog} />
    </Link>
  ) : null;

const CompanySchedule = ({ user }) =>
  user.role === "eater" ? (
    <Link
      to={{
        pathname: `/profile/schedule`,
        company: user.company,
      }}
      className="setting-row edit-schedule"
    >
      <span>Edit Schedule</span>
      <FontAwesomeIcon icon={faCalendarAlt} />
    </Link>
  ) : null;

const ContactAbbey = ({ user }) =>
  user.role === "eater" ? (
    <a href="sms:+16506708855" className="setting-row contact-abbey">
      <span>Contact Abbey</span>
      <FontAwesomeIcon icon={faCommentDots} />
    </a>
  ) : null;

const UserMenu = ({
  isDelegate,
  onLogout,
  currentUser,
  onHide,
  onClose,
  setupDone,
}) => {
  const { user, isAuthenticated } = currentUser;

  const companySetting =
    isAuthenticated && user.role === "eater" && setupDone ? (
      <React.Fragment>
        <li>
          <CompanyEaters user={user} onClose={onClose} />
          <CompanyDiets user={user} onClose={onClose} />
          <CompanyFavorites user={user} onClose={onClose} />
          <CompanySchedule user={user} onClose={onClose} />
        </li>
        <li>
          <PaymentMethod user={user} onClose={onClose} />
        </li>
      </React.Fragment>
    ) : null;

  const systemSetting = (
    <li>
      <ContactAbbey user={user} />
      {isAuthenticated ? (
        <LogOut onLogout={onLogout} isDelegate={isDelegate} role={user.role} />
      ) : (
        <Login />
      )}
    </li>
  );

  return (
    <div className="user-menu">
      <div className="overlay user-menu-overlay" onClick={onHide}></div>
      <ul className="user-menu-dropdown">
        {companySetting}
        {systemSetting}
      </ul>
    </div>
  );
};

class Navbar extends Component {
  constructor() {
    super();
    this.state = {
      showUserSettingDropDown: false,
      showContactInput: false,
    };
    this.handleLogout = this.handleLogout.bind(this);
    this.handleToggleShowUserMenu = this.handleToggleShowUserMenu.bind(this);
  }

  handleLogout = () => {
    const { logout } = this.props;
    logout();
    window.scrollTo(0, 0);
  };

  handleToggleShowUserMenu = () => {
    const { showUserMenu } = this.state;

    this.setState({ showUserMenu: !showUserMenu });
  };

  render() {
    const {
      isDelegate,
      currentUser,
      scrolled,
      sectionNames,
      sectionRefs,
      onGoToSection,
      currentSection,
      midNav,
      setupDone,
    } = this.props;
    const { showUserMenu } = this.state;
    const { isAuthenticated } = currentUser;

    const homeButton = isAuthenticated ? (
      <HomeButton logoText="abbey" />
    ) : (
      <ResetSectionButton
        logoText="abbey"
        onGoToSection={onGoToSection.bind(this, sectionRefs[0])}
      />
    );
    const sectionLinks = isAuthenticated
      ? null
      : sectionNames.map((name, index) => (
          <SectionLink
            text={name}
            onGoTo={onGoToSection.bind(this, sectionRefs[index + 1])}
            isCurrentSection={currentSection === index + 1}
            key={index}
          />
        ));
    const userButton = (
      <ExpandToggle
        toggleOffText={<FontAwesomeIcon icon={faUserCircle} />}
        toggleOnText={<FontAwesomeIcon icon={faUserCircle} />}
        onToggle={this.handleToggleShowUserMenu}
        showDetails={showUserMenu}
        bold={true}
      />
    );

    const barsButton = ( // eslint-disable-line no-unused-vars
      <button onClick={this.handleToggleShowUserMenu}>
        <FontAwesomeIcon icon={faBars} />
      </button>
    );

    const logInButton = <Login />;

    const navMobile = (
      <nav
        className={`nav-mobile ${
          isAuthenticated ? "nav-signed-in" : "nav-anonymous"
        }`}
      >
        <div className="nav-left">{homeButton}</div>
        {/* <div className="nav-left">{isAuthenticated ? homeButton : null}</div> */}
        {/* <div className="nav-middle">{isAuthenticated ? null : homeButton}</div> */}
        <div className="nav-right">
          {isAuthenticated ? userButton : logInButton}
        </div>
      </nav>
    );
    const navDesktop = (
      <nav
        className={`nav-desktop ${isAuthenticated ? "" : "nav-anonymous"}${
          scrolled ? " scrolled" : ""
        }`}
      >
        <div className="nav-left">{homeButton}</div>
        {midNav ? <div className="nav-middle">{midNav}</div> : null}
        <div className="nav-right">
          {sectionLinks ? sectionLinks : null}
          {isAuthenticated ? userButton : logInButton}
        </div>
      </nav>
    );

    const dropDownMenu = showUserMenu ? (
      <UserMenu
        currentUser={currentUser}
        isDelegate={isDelegate}
        onHide={this.handleToggleShowUserMenu}
        onLogout={this.handleLogout}
        onClose={this.handleGoBack}
        setupDone={setupDone}
      />
    ) : null;

    return (
      <React.Fragment>
        {navMobile}
        {navDesktop}
        {dropDownMenu}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return { currentUser: state.currentUser };
}

export default connect(mapStateToProps, { logout })(Navbar);
