import React, { Component } from "react"; // eslint-disable-line no-unused-vars
import { connect } from "react-redux";
import { verifyContact, removeErrors, authUser } from "../../store/actions";
import { LOGGING } from "../../hocs/constants";
import LogInForm from "./LogInForm";
import { Redirect } from "react-router-dom";
class PageAuthFormVerifyOnly extends Component {
  constructor(props) {
    super();
    this.state = {
      showVerifyContact: false,
      loading: false,
      verifyContactCode: "",
      verifyContactCodeTyped: "",
      verifyContactStatus: true,
      phoneNumber: "",
      phoneOps: props.location.opsPhone || "",
      phoneEater: props.location.eaterPhone || "",
    };

    // root form submit handlers
    this.handleLoginSubmit = this.handleLoginSubmit.bind(this);

    this.handleHideVerifyContact = this.handleHideVerifyContact.bind(this);
    this.handleShowVerifyContact = this.handleShowVerifyContact.bind(this);
    this.handleSubmitVerifyContact = this.handleSubmitVerifyContact.bind(this);
    this.handleResendVerifyContact = this.handleResendVerifyContact.bind(this);
    this.handleChangeVerifyContact = this.handleChangeVerifyContact.bind(this);

    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handlePhoneChangeFinish = this.handlePhoneChangeFinish.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose = (e) => {
    this.props.history.goBack();
  };
  //client side validation for phone, email and password:
  handlePhoneChangeFinish = (e) => {
    const phone = e.target.value;
    const { localErrorEmpty, localErrorInvalid } = this.state;

    if (localErrorEmpty === "phone" && phone.length > 0) {
      this.setState({ localErrorEmpty: "" });
    }

    if (phone.includes("#")) {
      if (localErrorInvalid === "") {
        this.setState({ localErrorInvalid: "phone" });
      }
    } else {
      if (localErrorInvalid === "phone") {
        this.setState({ localErrorInvalid: "" });
      }
    }
  };

  handleLoginSubmit = (e) => {
    //e is not used, since all fields are stored in this.state by their respective onChange events
    // e.preventDefault();
    const { phoneNumber, phoneEater, phoneOps } = this.state;
    const { removeErrors, isDelegate } = this.props;
    LOGGING >= 1 &&
      console.log("handleLoginSubmit called with: ", {
        phoneNumber,
        phoneEater,
        phoneOps,
      });
    removeErrors();
    this.setState(
      { loading: true, localErrorEmpty: "", localErrorInvalid: "" },
      function () {
        this.props
          .authUser("signin", {
            phone: isDelegate ? phoneEater : phoneNumber,
            opsPhone: phoneOps,
          })
          .then(() => {
            this.props.history.push("/");
          })
          .catch((err) => {
            LOGGING >= 1 && console.log("onAuth returend with err:", err);
            LOGGING >= 1 &&
              console.log(
                "onAuth returend with props.errors:",
                this.props.errors
              );
            this.setState({ loading: false });
          });
      }
    );
  };

  handlePhoneChange = (whichPhone, values) => {
    var { localErrorInvalid } = this.state;
    const { removeErrors } = this.props;

    removeErrors();

    if (values.value.length === 10) {
      if (localErrorInvalid === "phone") {
        localErrorInvalid = "";
      }
    } else {
      if (localErrorInvalid === "") {
        localErrorInvalid = "phone";
      }
    }

    this.setState({
      [whichPhone]: values.value,
      localErrorInvalid,
    });
  };

  handleResendVerifyContact = () => {
    const { phoneNumber, phoneOps } = this.state;
    const { isDelegate } = this.props;
    this.setState({ loading: true, verifyContactStatus: true }, function () {
      this.props
        .verifyContact({
          phone: isDelegate ? phoneOps : phoneNumber,
        })
        .then(({ token }) => {
          this.setState({
            showVerifyContact: true,
            verifyContactCode: token,
            loading: false,
          });
        })
        .catch((err) => {
          LOGGING >= 1 && console.log("resend verifyContact got error:", err);
          LOGGING >= 1 &&
            console.log(
              "resend verifyContact returend with props.errors:",
              this.props.errors
            );
          this.setState({ loading: false });
        });
    });
  };

  handleSubmitVerifyContact = (e) => {
    LOGGING >= 1 && console.log("handleSubmitVerifyContact called");
    e.preventDefault();
    const { verifyContactCode, verifyContactCodeTyped } = this.state;

    if (verifyContactCodeTyped === verifyContactCode) {
      this.setState(
        { verifyContactStatus: true, showVerifyContact: false },
        function () {
          this.handleLoginSubmit();
        }
      );
    } else {
      this.setState({ verifyContactStatus: false, showVerifyContact: true });
    }
  };

  handleHideVerifyContact = () => {
    this.setState({ showVerifyContact: false });
  };

  handleShowVerifyContact = () => {
    const { phoneNumber, phoneOps } = this.state;
    const { isDelegate } = this.props;

    this.setState({ loading: true, verifyContactCodeTyped: "" }, function () {
      this.props
        .verifyContact({ phone: isDelegate ? phoneOps : phoneNumber })
        .then(({ token }) => {
          this.setState({
            showVerifyContact: true,
            verifyContactCode: token,
            loading: false,
          });
        })
        .catch((err) => {
          LOGGING >= 1 && console.log("verifyContact got error:", err);
          LOGGING >= 1 &&
            console.log(
              "verifyContact returend with props.errors:",
              this.props.errors
            );
          this.setState({ loading: false });
        });
    });
  };

  handleChangeVerifyContact = (e) => {
    if (e.target.value.length <= 6) {
      this.setState({
        verifyContactCodeTyped: e.target.value,
        verifyContactStatus: true,
      });
    }
  };

  componentDidMount() {
    this.props.removeErrors();
  }
  render() {
    const {
      phoneNumber,
      phoneOps,
      phoneEater,
      verifyContactStatus,
      verifyContactCodeTyped,
      showVerifyContact,
      loading,
    } = this.state;

    const { currentUser, isDelegate, errors } = this.props;

    const isOps =
      currentUser &&
      currentUser.isAuthenticated &&
      currentUser.user.role === "ops";

    if (isDelegate && !isOps) {
      return <Redirect to="/" />;
    }

    return (
      <LogInForm
        loading={loading}
        showVerifyContact={showVerifyContact}
        verifyContactStatus={verifyContactStatus}
        verifyContactCodeTyped={verifyContactCodeTyped}
        phoneNumber={phoneNumber}
        phoneOps={phoneOps}
        phoneEater={phoneEater}
        onShowVerifyContact={this.handleShowVerifyContact}
        onSubmitVerifyContact={this.handleSubmitVerifyContact}
        onChangeVerifyContact={this.handleChangeVerifyContact}
        onResendVerifyContact={this.handleResendVerifyContact}
        onHideVerifyContact={this.handleHideVerifyContact}
        onPhoneChange={this.handlePhoneChange}
        onPhoneFinish={this.handlePhoneChangeFinish}
        onClose={this.handleClose}
        isDelegate={isDelegate}
        errors={errors}
      />
    );
  }
}
function mapStateToProps(reduxState) {
  return {
    errors: reduxState.errors,
    currentUser: reduxState.currentUser,
  };
}
export default connect(
  mapStateToProps,
  { verifyContact, removeErrors, authUser },
  null
)(PageAuthFormVerifyOnly);
