import React, { Component } from "react"; // eslint-disable-line no-unused-vars
import "./FormSchedule.scss";
import { GoogleApiWrapper } from "google-maps-react";
import Geosuggest from "react-geosuggest";
import { Schedule, getSelectFields } from ".";
import { LOGGING } from "../../hocs/constants";
import {
  PopUp,
  ProfileFormFooter,
  StepFormFooter,
  ProfileStepText,
} from "../widgets";
import { Redirect } from "react-router-dom";

const RANDOM_KEY = Math.random().toString(16).substring(2, 15);

const transformServerSchedulesToClientSchedules = (schedules) =>
  schedules ? schedules.map((schedule) => schedule.weekly) : [];

const transformClientSchedulesToServerSchedules = (eaters) => {
  let weeklyByUser = {};
  eaters.forEach((eater) => {
    weeklyByUser[eater._id] = [...eater.schedules];
  });
  return weeklyByUser;
};

const transformCompanyDeliveryServerToClient = ({
  eaters,
  address,
  deliveryInstructions,
}) => ({
  eaters: eaters
    .map((eater) =>
      getSelectFields({ data: eater, fields: ["_id", "name", "schedules"] })
    )
    .map((eater) => ({
      ...eater,
      schedules: transformServerSchedulesToClientSchedules(eater.schedules),
    })),
  deliveryInstructions: deliveryInstructions || "",
  address: address || "",
});

export const CompanyDeliveryAddress = ({
  address,
  onAddressType,
  onAddressSelect,
  addressFocused,
  onAddressFocus,
  onAddressBlur,
  deliveryInstructions,
  onDeliveryInstructionsChange,
}) => (
  <React.Fragment>
    <div
      className={`form-field ${
        addressFocused ? "dropped-down" : "folded"
      } required-field ${!address || address.length === 0 ? "invalid" : ""}`}
    >
      <label>address</label>
      <Geosuggest
        name={RANDOM_KEY}
        placeholder="Delivery address"
        initialValue={address || ""}
        onSuggestSelect={onAddressSelect}
        onFocus={onAddressFocus}
        onBlur={onAddressBlur}
        onChange={onAddressType}
        autoComplete={RANDOM_KEY}
      />
    </div>
    <div className="form-field">
      <label>Delivery Instructions</label>
      <input
        placeholder="Delivery instructions"
        className="schedule-input-section input"
        value={deliveryInstructions}
        onChange={onDeliveryInstructionsChange}
      />
    </div>
  </React.Fragment>
);

class FormSchedule extends Component {
  constructor(props) {
    super();
    let { eaters, deliveryInstructions, address } = props;
    // 1. filter only useful fields
    // 2. transform from server format to client format
    // 3. handle undefined
    this.state = {
      ...transformCompanyDeliveryServerToClient({
        eaters,
        deliveryInstructions,
        address,
      }),
      addressFocused: false,
    };
    this.handleAddressSelect = this.handleAddressSelect.bind(this);
    this.handleAddressType = this.handleAddressType.bind(this);
    this.handleDeliveryInstructionsChange = this.handleDeliveryInstructionsChange.bind(
      this
    );
    this.handleAddressOnFocus = this.handleAddressOnFocus.bind(this);
    this.handleAddressOnBlur = this.handleAddressOnBlur.bind(this);
    this.handleToggleDay = this.handleToggleDay.bind(this);
  }

  handleToggleDay(meal) {
    const { isLunch, dayOfWeek, eaterId } = meal;
    const mealIndex = dayOfWeek * 2 + (isLunch ? 0 : 1);

    const { eaters } = this.state;
    const eater = eaters.find((e) => e._id === eaterId);
    let { schedules } = eater;

    const foundMeal = schedules.indexOf(mealIndex);
    if (foundMeal > -1) {
      schedules.splice(foundMeal, 1);
    } else {
      schedules.push(mealIndex);
      schedules.sort();
    }
    const eatersUpdated = eaters.map((eater) =>
      eater._id === eaterId ? { ...eater, schedules } : { ...eater }
    );
    this.setState({ eaters: eatersUpdated });
  }

  handleAddressOnFocus() {
    const { address } = this.state;
    LOGGING >= 1 &&
      console.log("handleaddressonfocus called with address:", address);
    if (address.length > 0) {
      this.setState({ addressFocused: true });
    }
  }
  handleAddressOnBlur() {
    LOGGING >= 1 && console.log("handleAddressOnBlur called");
    this.setState({ addressFocused: false });
  }
  handleAddressType(address) {
    LOGGING >= 1 &&
      console.log("handleAddressType called with address:", address);
    this.setState({
      address,
      addressFocused: address && address.length > 0 ? true : false,
    });
  }
  handleAddressSelect(address) {
    LOGGING >= 1 &&
      console.log("handleAddressSelect called with address:", address);
    this.setState({
      address: address ? address.description : this.state.address,
      addressFocused: false,
    });
  }
  handleDeliveryInstructionsChange(e) {
    this.setState({ deliveryInstructions: e.target.value });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.updateShadow();
  }

  render() {
    const {
      onSave,
      onNext,
      onPrevious,
      contentOverflow,
      contentRef,
      mainContact,
      goto,
      onCancelGoTo,
    } = this.props;

    const {
      eaters,
      address,
      deliveryInstructions,
      addressFocused,
    } = this.state;

    LOGGING >= 1 &&
      console.log("FormSchedule rendering with", {
        props: this.props,
        state: this.state,
      });

    const formHeader = (
      <div className="step-form-header">
        <div className="required-field-notes">
          <span>*</span>
          <span>required fields</span>
        </div>
        <CompanyDeliveryAddress
          address={address}
          onAddressSelect={this.handleAddressSelect}
          onAddressType={this.handleAddressType}
          addressFocused={addressFocused}
          onAddressFocus={this.handleAddressOnFocus}
          onAddressBlur={this.handleAddressOnBlur}
          deliveryInstructions={deliveryInstructions}
          onDeliveryInstructionsChange={this.handleDeliveryInstructionsChange}
        />
      </div>
    );

    const eaterList = (
      <React.Fragment>
        <div className="form-section-title delivery-days">Delivery Days</div>
        {eaters.map((eater) => (
          <Schedule
            key={eater._id}
            eater={eater}
            onToggleDay={this.handleToggleDay}
            isMainContact={eater._id === mainContact}
          />
        ))}
      </React.Fragment>
    );

    const dataHasError = eaters.find(
      (eater) =>
        (eater.phone && eater.phone.length < 10 && eater.phone.length > 0) ||
        !eater.name ||
        eater.name.length === 0
    );

    // from server storage format to client in-memory
    let oldData = transformCompanyDeliveryServerToClient(this.props);
    // from client in-memory format to server writing format
    oldData = {
      address: oldData.address,
      deliveryInstructions: oldData.deliveryInstructions,
      weeklyByUser: transformClientSchedulesToServerSchedules(oldData.eaters),
    };

    // from client in-memory format to server writing format
    const newData = {
      address,
      deliveryInstructions,
      weeklyByUser: transformClientSchedulesToServerSchedules(eaters),
    };
    const equal = JSON.stringify(oldData) === JSON.stringify(newData);
    LOGGING >= 1 &&
      console.log({
        oldData,
        newData,
        equal,
      });
    if (equal && onSave && goto > 0) {
      return <Redirect to={`/profile/${ProfileStepText[goto - 1]}`} />;
    }
    const gotoConfirm = onSave ? (
      <PopUp
        isPoppedUp={onSave && goto >= 0 && !equal}
        componentToDisplay={
          <div className="goto-confirm">
            <span>Save the changes you made?</span>
            <div className="button-wrapper">
              <button
                className="no-save-goto"
                onClick={onSave.bind(this, null, goto)}
              >
                no
              </button>
              <button
                className="save-goto"
                onClick={onSave.bind(this, newData, goto)}
              >
                save
              </button>
            </div>
          </div>
        }
        hidePopUp={onCancelGoTo}
      />
    ) : null;
    LOGGING >= 1 &&
      console.log({
        oldData,
        newData,
        equal,
      });

    const formFooter = onSave ? (
      <ProfileFormFooter
        onSave={onSave.bind(this, newData)}
        showShadow={contentOverflow}
        dataHasError={dataHasError}
      />
    ) : (
      <StepFormFooter
        isFirstStep={false}
        isLastStep={true}
        onPrevious={onPrevious}
        onNext={onNext.bind(this, oldData, newData)}
        showShadow={contentOverflow}
        dataHasError={dataHasError}
      />
    );

    return (
      <div className="step-form schedules" ref={contentRef}>
        {gotoConfirm}
        {formHeader}
        {eaterList}
        {formFooter}
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBjfTfgJdRah5wXvVj8U7fH4ACoqKry9HI",
})(FormSchedule);
