import React from "react";
import "./HeroPage.scss";
import { PhoneForm } from "../../auth/";

const HeroPage = ({
  onBook,
  onPhoneChange,
  phoneNumber,
  errors,
  actionName,
}) => (
  <div className="user-anonymous-section hero-page">
    <div className="hero-content">
      <h3 className="hero-title">Simple, healthy, delicious</h3>
      <PhoneForm
        onBook={onBook}
        onPhoneChange={onPhoneChange}
        phoneNumber={phoneNumber}
        actionName={actionName}
        isDelegate={false}
        errors={errors}
      />
    </div>
    <div className="hero-image"></div>
  </div>
);

export default HeroPage;
