import React from "react";
import "./ServiceDetails.scss";
import { PhoneForm } from "../../auth/";
import {
  lunchCancelBy,
  lunchStart,
  lunchEnd,
  dinnerCancelBy,
  dinnerStart,
  dinnerEnd,
} from "../../../hocs/constants";

const ServiceDetails = ({
  onBook,
  onPhoneChange,
  phoneNumber,
  errors,
  actionName,
}) => (
  <div className="user-anonymous-section service-details">
    <h3>service details</h3>
    <div className="section-body">
      <div className="info-card">
        <div className="service-details-meal-type">lunch</div>
        <div className="service-details-delivery-window">
          <div className="service-details-delivery-window-hour">
            <span>{lunchStart}</span>
          </div>
          <div className="hour-range">-</div>
          <div className="service-details-delivery-window-hour">
            <span>{lunchEnd}</span>
          </div>
        </div>
        <div className="service-details-cancel-time">
          <span>Free cancellation by {lunchCancelBy}.</span>
        </div>
      </div>
      <div className="info-card">
        <div className="service-details-meal-type">dinner</div>
        <div className="service-details-delivery-window">
          <div className="service-details-delivery-window-hour">
            <span>{dinnerStart}</span>
          </div>
          <div className="hour-range">-</div>
          <div className="service-details-delivery-window-hour">
            <span>{dinnerEnd}</span>
          </div>
        </div>
        <div className="service-details-cancel-time">
          <span>Free cancellation by{dinnerCancelBy}</span>
        </div>
      </div>
    </div>
    <PhoneForm
      onBook={onBook}
      onPhoneChange={onPhoneChange}
      phoneNumber={phoneNumber}
      actionName={actionName}
      isDelegate={false}
      errors={errors}
    />
  </div>
);

export default ServiceDetails;
