import React from "react";
import { ListOfUnits, SelectMealType, SelectedDayDetails, NoMeal } from "./";
import { LOGGING } from "../../../hocs/constants";

const OpsDay = ({
  selectedMeal,
  onToggleLunchDinner,
  numOfInstances,
  contextDateMoments,
  contextDeliveries,
  showDetails,
  showAllDetails,
  onToggleDetails,
  onToggleAllDetails,
  onToggleInstance,
  onSaveETA,
  onEditETA,
  onOrder,
  onShowDishInventory,
  onGoToSelectedMonth,
  onShowDashboard,
}) => {
  const mealType = (
    <SelectMealType
      isLunch={selectedMeal.isLunch}
      numOfInstances={numOfInstances}
      dateKey={selectedMeal.dateKey}
      onToggle={onToggleLunchDinner}
    />
  );
  const { deliveriesForDay, ordersForDay, isLunch } = selectedMeal;
  const deliveriesForMeal = deliveriesForDay.filter(
    (d) => d.isLunch === isLunch
  );
  const ordersForMeal = ordersForDay.filter((o) => o.isLunch === isLunch);
  LOGGING >= 1 &&
    console.log("OpsDay rendering with:", {
      selectedMeal,
      deliveriesForMeal,
      ordersForMeal,
    });

  const deliveries = deliveriesForMeal.map((delivery) => ({
    ...delivery,
    unit: delivery.company,
    type: "delivery",
  }));

  const orders = ordersForMeal.map((order) => ({
    ...order,
    unit: order.restaurant,
    type: "order",
  }));

  const content =
    deliveries.length === 0 ? (
      <NoMeal meal={selectedMeal.isLunch ? "lunch" : "dinner"} />
    ) : (
      <React.Fragment>
        <ListOfUnits
          // only the meal on the date, a time.
          unitType="delivery"
          role="ops"
          selectedMeal={selectedMeal}
          contextDateMoments={contextDateMoments}
          //instances on context dates: both meals, all users, cancelled or not
          contextDeliveries={contextDeliveries}
          //need only deliveries for that meal, all users, cancelled or not
          unitsForMeal={deliveries}
          showDetails={showDetails}
          showAllDetails={showAllDetails.delivery}
          onToggleShowDetails={onToggleDetails.bind(this, "delivery")}
          onToggleShowAllDetails={onToggleAllDetails.bind(this, "delivery")}
          onToggleInstance={onToggleInstance}
          onShowDishInventory={onShowDishInventory}
          onSaveETA={onSaveETA.bind(this, "delivery")}
          onEditETA={onEditETA.bind(this, "delivery")}
        />
        <ListOfUnits
          unitType="order"
          role="ops"
          unitsForMeal={orders}
          selectedMeal={selectedMeal}
          showDetails={showDetails}
          showAllDetails={showAllDetails.order}
          onToggleShowDetails={onToggleDetails.bind(this, "order")}
          onToggleShowAllDetails={onToggleAllDetails.bind(this, "order")}
          onSaveETA={onSaveETA.bind(this, "order")}
          onEditETA={onEditETA.bind(this, "order")}
          onOrder={onOrder}
        />
      </React.Fragment>
    );

  return (
    <SelectedDayDetails
      selectedDay={selectedMeal}
      onGoToSelectedMonth={onGoToSelectedMonth}
      role="ops"
      onShowDashboard={onShowDashboard}
      content={
        <React.Fragment>
          {mealType}
          {content}
        </React.Fragment>
      }
    />
  );
};

export default OpsDay;
