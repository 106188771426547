import React from "react";
import "./CardForm.scss";
import NumberFormat from "react-number-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDay,
  faCreditCard,
  faLock,
  faUserCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { ErrorMessage } from "../widgets";

const CardForm = ({
  onChangeNumber,
  onChangeName,
  onSubmit,
  number,
  name,
  expiration,
  cvv,
  error,
  overflow,
}) => (
  <div className="card-form">
    <ErrorMessage message={error && error.message ? error.message : ""} />

    <div
      className={`form-field ${
        error && error.name === "name" ? "has-error" : ""
      }`}
    >
      <label>account holder name</label>
      <div className="icon-wrapper">
        <FontAwesomeIcon icon={faUserCircle} />
      </div>
      <input
        name="name"
        type="text"
        placeholder="Name on card"
        value={name}
        onChange={onChangeName}
      />
    </div>

    <div
      className={`form-field ${
        error && error.name === "number" ? "has-error" : ""
      }`}
    >
      <label>Card number</label>
      <div className="icon-wrapper">
        <FontAwesomeIcon icon={faCreditCard} />
      </div>
      <NumberFormat
        format="####-####-####-####"
        mask="X"
        type="text"
        placeholder="XXXX-XXXX-XXXX-XXXX"
        onValueChange={onChangeNumber.bind(this, "number")}
        value={number}
        name="card-number"
      />
    </div>
    <div className="form-double-field">
      <div
        className={`form-field ${
          error && error.name.includes("exp") ? "has-error" : ""
        }`}
      >
        <label>Expiration</label>
        <div className="icon-wrapper">
          <FontAwesomeIcon icon={faCalendarDay} />
        </div>
        <NumberFormat
          format="##/##"
          type="text"
          placeholder="MM/YY"
          onValueChange={onChangeNumber.bind(this, "expiration")}
          value={expiration}
        />
      </div>
      <div
        className={`form-field ${
          error && error.name === "cvv" ? "has-error" : ""
        }`}
      >
        <label>Security Code</label>
        <div className="icon-wrapper">
          <FontAwesomeIcon icon={faLock} />
        </div>
        <NumberFormat
          format="###"
          type="text"
          placeholder="CVV"
          onValueChange={onChangeNumber.bind(this, "cvv")}
          value={cvv}
        />
      </div>
    </div>
    {onSubmit ? (
      <div className={`form-footer ${overflow ? "show-shadow" : ""}`}>
        <button
          type="click"
          className="setup-nav next sumbit-card"
          onClick={onSubmit}
        >
          save
        </button>
      </div>
    ) : null}
  </div>
);

export default CardForm;
