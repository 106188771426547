// import npm packages
import React from "react";
import "./DashboardContent.scss";
import { Link } from "react-router-dom";
import {
  DataModeTypes,
  ThumbnailSortBy,
  ThumbnailCategoryBy,
  DataTypes,
} from "./";
import Category from "./DashboardCategory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/pro-regular-svg-icons";
import { LoadingIcon } from "../widgets";
import { LOGGING } from "../../hocs/constants";

const addSortKey = ({ data, keyType, childrenKey, parentKey }) => {
  switch (keyType) {
    case ThumbnailSortBy.BY_CHILDREN:
      return data.map((b) => ({
        ...b,
        sortKey: b[childrenKey].length,
      }));

    case ThumbnailSortBy.BY_PARENT:
      return data.map((b) => ({
        ...b,
        sortKey: String(b[parentKey].name).toLowerCase(),
      }));
    case ThumbnailSortBy.BY_TYPE:
      return data.map((b) => ({ ...b, sortKey: Number(b.type) }));

    case ThumbnailSortBy.BY_NAME:
      return data.map((b) => ({ ...b, sortKey: String(b.name).toLowerCase() }));
    default:
      return data;
  }
};

const addCategoryKey = ({ data, dataType, keyType, parentKey }) => {
  LOGGING >= 1 &&
    console.log("addCategoryKey called with: ", {
      data,
      dataType,
      keyType,
      parentKey,
    });
  switch (keyType) {
    case ThumbnailCategoryBy.BY_TYPE:
      return data.map((b) => ({
        ...b,
        categoryKey: DataTypes[dataType][b.type],
      }));
    case ThumbnailCategoryBy.BY_PARENT:
      return data.map((b) => ({
        ...b,
        categoryKey: b[parentKey].name || b[parentKey],
      }));
    default:
      return data;
  }
};

export const ViewEntityButton = ({ entityType, entityId }) => (
  <div className="details">
    <Link className="" to={`/${entityType}/${entityId}`}>
      <button>
        <span>details</span>
      </button>
    </Link>
  </div>
);

export const AddEntityButton = ({ type }) =>
  type !== DataModeTypes.DISHES ? (
    <div className="add-button">
      <Link to={`/${type}/new`}>
        <FontAwesomeIcon icon={faPlusCircle} />
      </Link>
    </div>
  ) : null;

const DashboardContent = ({
  data,
  type,
  sortKey,
  categoryKey,
  parentKey,
  childrenKey,
  loading,
  onDelete,
  onClose,
  onUpdate,
  onSelectDish,
  opsPhone,
  //unused
  activeOnly,
}) => {
  const addButton = <AddEntityButton type={type} />;
  const loadingIcon = loading ? <LoadingIcon /> : null;

  // step 1. filter data
  if (activeOnly) {
    data = data.filter((b) => b.archived === false);
  }

  LOGGING >= 1 && console.log("data after step 1:", data);
  // step 2. sort data
  data = addSortKey({
    data,
    keyType: sortKey,
    childrenKey,
    parentKey,
  });
  LOGGING >= 1 && console.log("data after step 2:", data);

  // step 3. categorize data
  data = addCategoryKey({
    data,
    dataType: type,
    keyType: categoryKey,
    parentKey,
  });
  LOGGING >= 1 && console.log("data after step 3:", data);

  // step 4. generate thumbnail with processed data
  const categorySet = new Set(data.map((entry) => entry.categoryKey));
  const categoryArray = data.map((entry) => entry.categoryKey);
  const categories = Array.from(categorySet);

  LOGGING >= 1 &&
    console.log("after 4: DashboardContent rendering with categories:", {
      categories,
      categorySet,
      categoryArray,
    });

  const content = categories.map((categoryEntry, index) => (
    <Category
      categoryEntry={categoryEntry}
      index={index}
      key={index}
      keyedData={data}
      categoryBy={categoryKey}
      sortBy={sortKey}
      entity={type}
      opsPhone={opsPhone}
      activeOnly={activeOnly}
      onDelete={onDelete}
      onUpdate={onUpdate}
      onSelectDish={onSelectDish}
      onClose={onClose}
    />
  ));

  // const map = <Map entity={type} data={data} />;

  return (
    <div className="dashboard-content">
      {loadingIcon}
      {content}
      {addButton}
    </div>
  );
};

export default DashboardContent;
