import React from "react";
import "./ButtonCallToAction.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/pro-regular-svg-icons";
import { PhoneFormat } from "../../hocs/constants";
const ButtonCallToAction = ({ icon, text, action }) => (
  <button className="button-call-to-action" onClick={action} type="button">
    <FontAwesomeIcon icon={icon} />
    <span>{text}</span>
  </button>
);

export const ButtonCallToPhone = ({ phone }) => (
  <a href={`tel:+1${phone}`} className="button-call-to-action">
    <FontAwesomeIcon icon={faPhoneAlt} />
    call {PhoneFormat(phone)}
  </a>
);
export default ButtonCallToAction;
