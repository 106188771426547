import {
  REMOVE_DELIVERY,
  LOAD_DELIVERIES,
  UPDATE_DELIVERY_AFTER_CANCEL,
  RESET_DELIVERIES,
} from "../actionTypes";
import { PURGE, REHYDRATE } from "redux-persist";

const delivery = (state = [], action) => {
  switch (action.type) {
    case UPDATE_DELIVERY_AFTER_CANCEL:
      return state.map((delivery) =>
        delivery._id !== action.id
          ? delivery
          : { ...delivery, endDate: action.endDate }
      );

    case REMOVE_DELIVERY:
      return state.filter((delivery) => delivery._id !== action.id);

    case LOAD_DELIVERIES:
      return action.deliveries;
    case REHYDRATE: // This added just to show that this action type also exists, can be omitted.
      return state;
    case PURGE:
      return [];
    case RESET_DELIVERIES:
      return [];
    default:
      return state;
  }
};

export default delivery;
