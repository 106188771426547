import React from "react";
import "./HowItWorks.scss";

const HowItWorks = () => {
  const steps = [
    {
      id: 1,
      content: "Name your taste and favorite restaurants.",
    },
    {
      id: 2,
      content: "Name the days of week you need takeouts.",
    },
    {
      id: 3,
      content: "We select and order a meal for you on those days.",
    },
  ];

  return (
    <div className="user-anonymous-section how-it-works">
      <h3>How it works</h3>
      <div className="section-body">
        {steps.map((step, index) => (
          <div className="info-card" key={index}>
            <div className="how-it-works-step-icon"></div>
            <div className="how-it-works-step-text">{step.content}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HowItWorks;
