// import npm packages
import React, { Component } from "react"; // eslint-disable-line no-unused-vars
import ReactDOM from "react-dom";
import "./DashboardNav.scss";
import { DataModeTypes, DataKeys, DataModeIcons } from "./";
import { ClosePage } from "../widgets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faRedo } from "@fortawesome/free-solid-svg-icons";

class ButtonGroup extends Component {
  constructor(props) {
    super(props);
    this.modeRef = React.createRef();
  }

  componentDidMount() {
    const nav = ReactDOM.findDOMNode(this);
    const button = ReactDOM.findDOMNode(this.modeRef.current);
    nav.scrollTo(0, 0);
    const buttonLeft = button.getBoundingClientRect().left;
    const buttonWidth = button.offsetWidth;
    const navWidth = buttonWidth * 7 + 2 * 6 + 30;
    const windowWidth = window.innerWidth;
    const scrollToX = Math.min(
      navWidth - windowWidth,
      Math.max(0, buttonLeft + buttonWidth / 2 - windowWidth / 2)
    );
    nav.scrollTo(scrollToX, 0);
  }
  render() {
    const dataTypes = Object.values(DataModeTypes);
    const { mode, onChangeMode, numberEntriesByMode } = this.props;
    return (
      <div className="data-buttons-wrapper">
        <div className="data-buttons">
          {dataTypes.map((dataType) => (
            <button
              className={`data-button ${dataType === mode ? "selected" : ""}`}
              type="button"
              ref={dataType === mode ? this.modeRef : null}
              key={dataType}
              onClick={onChangeMode.bind(this, dataType)}
            >
              <div className="button-content">
                <FontAwesomeIcon icon={DataModeIcons[dataType]} />
                <div className="data-button-text">
                  <span className="data-type">{dataType}</span>
                  <span className="number-of-entries">
                    ({numberEntriesByMode[dataType]})
                  </span>
                </div>
              </div>
            </button>
          ))}
        </div>
      </div>
    );
  }
}

const DashboardNav = ({
  mode,
  sortBy,
  categoryBy,
  numberEntriesByMode,
  onChangeMode,
  onChangeSortBy,
  onChangeCategoryBy,
  onClose,
  onDataReload,
}) => {
  const selectedDataKeys = DataKeys[mode];

  let selectedCategoryKeys = {};

  switch (mode) {
    case "restaurants":
    case "dishes":
      selectedCategoryKeys = {
        ...selectedCategoryKeys,
        parent: selectedDataKeys.parent,
      };
      break;
    case "ingredients":
      selectedCategoryKeys = {
        ...selectedCategoryKeys,
        type: selectedDataKeys.type,
      };
      break;
    case "companies":
    case "users":
      selectedCategoryKeys = {
        ...selectedCategoryKeys,
        parent: selectedDataKeys.parent,
        type: selectedDataKeys.type,
      };
      break;
    default:
      //cuisines
      break;
  }

  selectedCategoryKeys = { ...selectedCategoryKeys, none: "(none)" };

  const sortByOptions = Object.keys(selectedDataKeys).map((key) => (
    <option value={key} key={key}>
      {selectedDataKeys[key]}
    </option>
  ));

  const categoryByOptions = Object.keys(selectedCategoryKeys).map((key) => (
    <option value={key} key={key}>
      {selectedCategoryKeys[key]}
    </option>
  ));

  const sortBySelect = (
    <div className="data-sort">
      <label>sort by</label>
      <select value={sortBy} onChange={onChangeSortBy}>
        {sortByOptions}
      </select>
      <FontAwesomeIcon icon={faChevronDown} />
    </div>
  );

  const categoryBySelect = (
    <div className="data-sort">
      <label>category by</label>
      <select value={categoryBy} onChange={onChangeCategoryBy}>
        {categoryByOptions}
      </select>
      <FontAwesomeIcon icon={faChevronDown} />
    </div>
  );

  const redoButton = (
    <button
      type="button"
      className="data-reload"
      onClick={(e) => {
        e.preventDefault();
        onDataReload.call(this, { mode, forceLoad: true });
      }}
    >
      <FontAwesomeIcon icon={faRedo} />
    </button>
  );

  // const mapButton = (
  //   <button
  //     type="button"
  //     className={"flex-by-row toggle-map " + (mapStatus?"map-view":"list-view")}
  //     onClick={onToggleMap}
  //     disabled={false}
  //   >
  //   <FontAwesomeIcon icon={faMapMarkedAlt} />
  // </button>);

  return (
    <div className="dashboard-nav">
      <h2>Dashboard</h2>
      <ButtonGroup
        mode={mode}
        onChangeMode={onChangeMode}
        numberEntriesByMode={numberEntriesByMode}
      />
      <div className="data-shuffle">
        {categoryBySelect}
        {sortBySelect}
        {redoButton}
      </div>
      <ClosePage onClick={onClose} />
    </div>
  );
};

export default DashboardNav;
