import React from "react";
import "./Nothing.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUtensils } from "@fortawesome/pro-regular-svg-icons";

const Nothing = ({ text, icon }) => (
  <div className="nothing">
    <div className="nothing-icon">
      <FontAwesomeIcon icon={icon} />
    </div>
    <span>{text}</span>
  </div>
);

export const NoMeal = ({ meal }) => (
  <Nothing icon={faUtensils} text={`No ${meal} is scheduled.`} />
);

export default Nothing;
