import React from "react";
import "./TotalDueForm.scss";
import { CardForm, CardSaved } from "./";
import { LoadingIcon, CheckBox } from "../widgets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
const TotalDueForm = ({
  currentUser,
  error,
  totalDue,
  onPay,
  processing,
  number,
  name,
  expiration,
  cvv,
  showCard,
  showCardMenu,
  onToggleShowCard,
  saveCard,
  autoCharge,
  onToggleSaveCard,
  onToggleAutoCharge,
  onToggleShowCardMenu,
  onChangeName,
  onChangeNumber,
  onDeleteCard,
}) => {
  const { stripeInfo } = currentUser.user;
  const { card, paymentMethodId } = stripeInfo;

  const totalDueHeader = (
    <div className="total-due-header">
      <label>Total Due</label>
      <div className="filler"></div>
      <span className="sum">{`$${totalDue.toFixed(2)}`}</span>
    </div>
  );

  const payByCardButton = (
    <button type="button" onClick={onToggleShowCard} className="invoice-action">
      pay by card
    </button>
  );

  const hasCard = card && paymentMethodId;
  const payByCardForm = (
    <form className="pay-by-card" onSubmit={onPay}>
      <div className="page-title">
        <span>pay by card</span>
      </div>
      <button className="close-page" onClick={onToggleShowCard}>
        <FontAwesomeIcon icon={faTimes} />
      </button>
      {totalDueHeader}
      <CardForm
        onChangeName={onChangeName}
        onChangeNumber={onChangeNumber}
        number={number}
        cvv={cvv}
        name={name}
        expiration={expiration}
        error={error}
        fromInvoice={true}
      />
      <CheckBox
        labelText="Remember card."
        value={saveCard}
        name="save-card"
        onChange={onToggleSaveCard}
      />
      {saveCard ? (
        <CheckBox
          labelText="Charge card automatically in the future."
          value={autoCharge}
          name="auto-charge"
          onChange={onToggleAutoCharge}
        />
      ) : null}
      <button type="submit" disabled={processing} className="invoice-action">
        Pay
      </button>
    </form>
  );

  return (
    <React.Fragment>
      {showCard ? (
        payByCardForm
      ) : (
        <form
          className={`total-due-form ${hasCard ? "with-card" : "without-card"}`}
          onSubmit={onPay}
        >
          {processing ? <LoadingIcon /> : null}
          {totalDueHeader}
          <div className="form-payment">
            {hasCard ? (
              <React.Fragment>
                <CardSaved
                  card={card}
                  onDelete={onDeleteCard}
                  showMenu={showCardMenu}
                  onToggleShowMenu={onToggleShowCardMenu}
                />
                <CheckBox
                  labelText="Charge automatically in the future."
                  value={autoCharge}
                  onChange={onToggleAutoCharge}
                  className="auto-charge"
                />
              </React.Fragment>
            ) : null}
          </div>
          {hasCard ? (
            <button
              type="submit"
              className="invoice-action"
              disabled={processing}
            >
              Pay
            </button>
          ) : (
            payByCardButton
          )}
        </form>
      )}
    </React.Fragment>
  );
};

export default TotalDueForm;
