import React from "react";
import "./Diet.scss";
import Textarea from "react-textarea-autosize";
import { EaterName, SelectChip } from "../widgets";

const LikeNames = {
  chicken: "chicken",
  beef: "beef",
  pork: "pork",
  seafood: "seafood",
  vegetarian: "vegetarian",
  spicy: "spicy",
  lowCarb: "low-carb",
};

const DietComments = ({ comments, onChange }) => (
  <div className="diet-section diet-comments">
    <div className="form-section-title">Comments</div>
    <div className="comments-wrapper">
      <Textarea
        placeholder="Any other requests"
        value={comments}
        onChange={onChange}
        spellCheck={false}
      />
    </div>
  </div>
);

const DietLikeTags = ({ likes, onToggleLike }) => (
  <div className="diet-section diet-like-tags">
    <div className="form-section-title">Diet Restrictions</div>
    <div className="diet-likes">
      {Object.keys(likes).map((item, index) => (
        <SelectChip
          key={index}
          onToggle={onToggleLike.bind(this, item)}
          isSelected={likes[item]}
          text={LikeNames[item]}
        />
      ))}
    </div>
  </div>
);

const DietCuisineTags = ({ cuisines, cuisineCatelog, onToggleCuisine }) => {
  return (
    <div className="diet-section diet-like-tags">
      <div className="form-section-title">Cuisines</div>
      <div className="diet-likes">
        {cuisineCatelog
          .map((c) => ({ ...c, name: c.name.toLowerCase() }))
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map(({ name, _id }) => (
            <SelectChip
              key={_id}
              onToggle={onToggleCuisine.bind(this, _id)}
              isSelected={cuisines.findIndex((c) => c === _id) >= 0}
              text={name}
            />
          ))}
      </div>
    </div>
  );
};

const DietEntry = ({
  diet,
  cuisineCatelog,
  onChangeComments,
  onToggleLike,
  onToggleCuisine,
}) => (
  <div className="diet-entry">
    <DietLikeTags likes={diet.likes} onToggleLike={onToggleLike} />
    <DietCuisineTags
      cuisines={diet.cuisines}
      cuisineCatelog={cuisineCatelog}
      onToggleCuisine={onToggleCuisine}
    />
    <DietComments comments={diet.comments} onChange={onChangeComments} />
  </div>
);

const Diet = ({
  diet,
  name,
  cuisineCatelog,
  onChangeComments,
  onToggleCuisine,
  onToggleLike,
  isMainContact,
}) => (
  <div className="step-content-card">
    {name ? <EaterName name={name} isMainContact={isMainContact} /> : null}
    <DietEntry
      diet={diet}
      cuisineCatelog={cuisineCatelog}
      onChangeComments={onChangeComments}
      onToggleLike={onToggleLike}
      onToggleCuisine={onToggleCuisine}
    />
  </div>
);

export default Diet;
