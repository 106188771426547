import { apiCall } from "../api";
import { LOAD_ORDERS, RESET_ORDERS } from "../actionTypes";
import { addError } from "./errors";
import { LOGGING } from "../../hocs/constants";
import { convertOrderServerToClient } from "./";
import { loadDeliveries } from "./deliveries";

export const loadOrders = ({ orders }) => ({
  type: LOAD_ORDERS,
  orders,
});

export const resetOrders = () => ({
  type: RESET_ORDERS,
});

export const fetchOrders = ({ beginUTC, endUTC }) => {
  LOGGING >= 1 &&
    console.log("fetchOrders called with: ", { beginUTC, endUTC });

  return (dispatch, getState) => {
    const { currentUser } = getState();
    return apiCall(
      "GET",
      `/users/${currentUser.user._id}/orders/window/${beginUTC}/${endUTC}`
    )
      .then((result) => {
        LOGGING >= 1 && console.log("fetchOrders got", result);
        const orders = result.map((order) => {
          LOGGING >= 1 &&
            console.log("fetchOrders looping over order: ", order);
          const convertedOrder = convertOrderServerToClient(order);
          LOGGING >= 1 &&
            console.log(
              "fetchOrders looping over convertedOrder: ",
              convertedOrder
            );
          return convertedOrder;
        });
        LOGGING >= 1 && console.log("got client style orders: ", orders);
        dispatch(loadOrders({ orders }));
        return "ok";
      })
      .catch((err) => {
        LOGGING >= 1 && console.log("fetchOrders got error: ", err);
        dispatch(addError(err.message));
      });
  };
};

export const fetchOrder = (orderId) => {
  LOGGING >= 1 && console.log("fetchOrder called with orderId: ", orderId);

  return (dispatch, getState) => {
    const { currentUser, orders } = getState();
    return apiCall("GET", `/users/${currentUser.user._id}/orders/${orderId}`)
      .then((result) => {
        LOGGING >= 1 && console.log("fetchOrder got result", result);
        const convertedOrder = convertOrderServerToClient(result);
        LOGGING >= 1 &&
          console.log("fetchOrder got convertedOrder: ", convertedOrder);
        dispatch(
          loadOrders({
            orders: orders.map((o) => (o._id === orderId ? convertedOrder : o)),
          })
        );
        return convertedOrder;
      })
      .catch((err) => {
        LOGGING >= 1 && console.log("fetchOrder got error: ", err);
        dispatch(addError(err.message));
      });
  };
};

export const fetchOrdersById = (orderIds) => {
  LOGGING >= 3 &&
    console.log("fetchOrdersById called with orderIds: ", orderIds);

  return (dispatch, getState) => {
    const { currentUser } = getState();
    return apiCall("post", `/users/${currentUser.user._id}/orders/byId`, {
      orderIds,
    })
      .then((orders) => {
        LOGGING >= 3 && console.log("fetchOrdersById got orders", orders);
        const convertedOrders = orders.map((order) =>
          convertOrderServerToClient(order)
        );
        LOGGING >= 3 &&
          console.log("fetchOrdersById got convertedOrder: ", convertedOrders);
        return convertedOrders;
      })
      .catch((err) => {
        LOGGING >= 1 && console.log("fetchOrdersById got error: ", err);
        dispatch(addError(err.message));
      });
  };
};

export const updateOrder = ({ _id, order }) => {
  LOGGING >= 3 && console.log("updateOrder called with: ", { _id, order });

  return (dispatch, getState) => {
    const { currentUser, orders } = getState();
    return apiCall("POST", `/users/${currentUser.user._id}/orders/${_id}`, {
      order,
    })
      .then((updatedOrder) => {
        LOGGING >= 3 &&
          console.log("updateOrder got updatedOrder:", updatedOrder);
        const convertedOrder = convertOrderServerToClient(updatedOrder);
        LOGGING >= 3 &&
          console.log("updateOrder got convertedOrder:", convertedOrder);
        const updatedOrders = orders.map((o) =>
          o._id === _id ? convertedOrder : o
        );
        LOGGING >= 3 &&
          console.log("updateOrder got updatedOrders:", updatedOrders);
        dispatch(loadOrders({ orders: [...updatedOrders] }));
        return convertedOrder;
      })
      .catch((err) => {
        LOGGING >= 1 && console.log("updateOrder got error: ", err);
        dispatch(addError(err.message));
      });
  };
};

export const removeInstanceFromOrder = ({ orderId, instanceId }) => {
  LOGGING >= 1 &&
    console.log("removeInstanceFromOrder called with: ", {
      orderId,
      instanceId,
    });
  return (dispatch, getState) => {
    const { currentUser, orders, errors } = getState();
    LOGGING >= 1 &&
      console.log("removeInstanceFromOrder got from state: ", {
        currentUser,
        orders,
        errors,
      });
    return apiCall("PUT", `/users/${currentUser.user._id}/orders/`, {
      orderId,
      instanceId,
    })
      .then((updatedOrder) => {
        LOGGING >= 1 &&
          console.log(
            "removeInstanceFromOrder got updatedOrder:",
            updatedOrder
          );
        let updatedOrders = [];
        // server returns {_id, ...}, order still has instances
        LOGGING >= 1 &&
          console.log(
            "removeInstanceFromOrder got updatedOrder._id:",
            updatedOrder._id
          );

        if (updatedOrder._id) {
          LOGGING >= 1 &&
            console.log(
              "removeInstanceFromOrder got updatedOrder._id non null"
            );
          const convertedOrder = convertOrderServerToClient(updatedOrder);

          updatedOrders = orders.map((o) =>
            o._id === orderId ? convertedOrder : o
          );
          LOGGING >= 1 &&
            console.log("removeInstanceFromOrder got:", {
              convertedOrder,
              updatedOrders,
            });
        } else {
          LOGGING >= 1 &&
            console.log("removeInstanceFromOrder got updatedOrder._id  null");
          updatedOrders = orders.filter((o) => o._id !== orderId);
          LOGGING >= 1 &&
            console.log("removeInstanceFromOrder got:", {
              updatedOrders,
            });
        }

        dispatch(loadOrders({ orders: updatedOrders }));
        return updatedOrders;
      })
      .catch((err) => {
        LOGGING >= 1 && console.log("removeInstanceFromOrder got error: ", err);
        dispatch(addError(err.message));
      });
  };
};

export const addInstanceToOrder = ({
  orderId,
  instanceId,
  restaurantId,
  deliveryId,
}) => {
  LOGGING >= 1 &&
    console.log("addInstanceToOrder called with: ", {
      orderId,
      instanceId,
      restaurantId,
      deliveryId,
    });
  return (dispatch, getState) => {
    const { currentUser } = getState();
    return apiCall("POST", `/users/${currentUser.user._id}/orders/`, {
      orderId,
      instanceId,
      restaurantId,
    })
      .then((updatedOrder) => {
        let { deliveries } = getState();
        LOGGING >= 1 &&
          console.log("addInstanceToOrder got:", {
            deliveries,
            updatedOrder,
          });
        deliveries = deliveries.map((d) =>
          d._id === deliveryId
            ? {
                ...d,
                instances: d.instances.map((i) =>
                  i._id === instanceId ? { ...i, order: updatedOrder._id } : i
                ),
              }
            : d
        );
        LOGGING >= 1 &&
          console.log("addInstanceToOrder got updated deliveries:", deliveries);
        dispatch(loadDeliveries({ deliveries }));
      })
      .catch((err) => {
        LOGGING >= 1 && console.log("addInstanceToOrder got error: ", err);
        dispatch(addError(err.message));
      });
  };
};
