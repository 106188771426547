import React, { Component } from "react";
import { connect } from "react-redux";
import { FormEaters, FormDiets, FormSchedule, FormFavorites } from ".";
import { Navbar } from "../home";
import { LOGGING } from "../../hocs/constants";
import { updateCompany, fetchCuisines, updateCard } from "../../store/actions";
import {
  LoadingIcon,
  SetupHeader,
  OnBoardingStepIndex,
  OnBoardingStepText,
  OnBoardingNextStep,
  OnBoardingPreviousStep,
} from "../widgets";

class PageSetUp extends Component {
  constructor(props) {
    super();
    const { company, phone, stripeInfo } = props.currentUser.user;
    const { onBoardingStep, eaters } = company;
    const { specified_step } = props.match.params;
    this.state = {
      // 1. controlling states

      // 1.1 loading:
      // true when waiting for backend async calls to return
      // shows a loading icon
      loading: false,
      offsetY: 0,
      // 1.2. step:
      // shows the last step the user has saved
      // by clicking next
      step: OnBoardingStepText[onBoardingStep],

      // 1.3 edit:
      // shows the current step the user has navigated to
      // by clicking previous and next
      edit: specified_step,

      // 2. data states

      company: {
        ...company,

        // eaters:
        // sorting so that the current user sees himself
        // on top of the company's eater list.
        eaters: eaters.sort((a, _) => (a.phone === phone ? -1 : 1)),
      },
      stripeInfo,

      // 3. rendering states

      // 3.1 contentOverflow:
      // true turns on the top border shadow for step-form-footer
      contentOverflow: false,

      // 3.2 contentScrolled:
      // true turns on the bottom border shadow for step-header
      contentScrolled: false,

      error: null,
    };

    // handleNext():
    // go to next step and save if there's updates
    this.handleNext = this.handleNext.bind(this);

    // handlePrevious():
    // go to prevous step
    this.handlePrevious = this.handlePrevious.bind(this);

    // handleGoToStep():
    // go to a specified step
    this.handleGoToStep = this.handleGoToStep.bind(this);

    // handleClose()
    this.handleClose = this.handleClose.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

    // updateShadow():
    // updates states contentOverflow and contentOverflow,
    // which decide the shadow of header and footer.
    //
    // It is
    // a. called by child components when their height changes due to component changes,
    // b. called by componentDidMount at initialization
    // c. listening to window scrolls in general.

    this.updateShadow = this.updateShadow.bind(this);

    // turnOnLoading()
    this.turnOnLoading = this.turnOnLoading.bind(this);

    // ref for styling purpose
    this.contentRef = React.createRef();
  }
  handleScroll() {
    this.setState({ offsetY: window.pageYOffset });
  }
  // handleNext():
  // go to next step and save if there's updates
  // called by individual child containers/forms
  handleNext(dataOld, dataNew) {
    const { step, edit } = this.state; //text format
    // deep checks if data on the calling form has changed
    const edited = JSON.stringify(dataOld) !== JSON.stringify(dataNew);
    const proceeded = edit === step;
    const nextStep = OnBoardingNextStep[edit];
    LOGGING >= 1 &&
      console.log("handleNext called with: ", {
        dataOld,
        dataNew,
        proceeded,
        edit,
        step,
        onBoardingStep: OnBoardingStepIndex[edit] + 1,
      });
    // call backend to save data if
    // a. it's the first time the user clicks on "next" at this state,
    // or
    // b. it's not the first time, but the data has been changed.
    if (proceeded || edited) {
      // Composing data...
      // (data contains and only contains fields
      // that need to be updated in the company)

      // data needs to have all fields relavant on the calling form
      // FormEaters: eaters
      // FormDiets: eaters, sharedDiet, companyDiet
      let data = { ...dataNew };

      // if the form's "next" is clicked for the first time,
      // onBoardingStep is udpated in backend
      // updatedStep is updated in state
      if (proceeded) {
        data = { ...data, onBoardingStep: OnBoardingStepIndex[edit] + 1 };
      }

      // need company to re-compose state data with new data
      const { company } = this.props.currentUser.user;

      // need _id to direct api call
      const { _id } = company;

      this.setState({ loading: true }, () => {
        this.props
          .updateCompany({
            id: _id,
            data,
            edit,
          })
          .then(() => {
            if (nextStep === "done") {
              this.props.history.push("/");
            } else {
              this.props.history.push(`/setup/${nextStep}`);
            }
          })
          .catch((err) => {
            LOGGING >= 1 &&
              console.log("updateCompany returned with error:", err);
          });
      });
    } else {
      // edit < step and edited===false
      if (nextStep === "done") {
        this.props.history.push("/");
      } else {
        this.props.history.push(`/setup/${nextStep}`);
      }
    }
  }

  // handlePrevious():
  // go to prevous step
  handlePrevious() {
    const { edit } = this.state;
    this.props.history.push(`/setup/${OnBoardingPreviousStep[edit]}`);
  }

  handleGoToStep(step, e) {
    e.preventDefault();
    this.setState({ edit: step });
  }

  handleClose = () => {
    this.props.history.push("/");
  };
  // updateShadow():
  // updates contentOverflow and contentOverflow
  // a. called by child components when their height changes due to component changes,
  // b. called by componentDidMount at initialization
  // c. listening to window scrolls in general.

  updateShadow() {
    const windowHeight = window.innerHeight;
    let contentOverflow = false;
    let contentScrolled = false;

    if (this.contentRef.current) {
      const { top, bottom } = this.contentRef.current.getBoundingClientRect();
      contentOverflow = bottom > windowHeight;
      contentScrolled = top < 0;
    }

    this.setState({ contentOverflow, contentScrolled });
  }

  turnOnLoading() {
    this.setState({ loading: true });
  }

  // hooks
  componentDidMount() {
    window.addEventListener("scroll", this.updateShadow);
    this.updateShadow();

    if (this.props.cuisines.length === 0) {
      this.setState({ loading: true });
      this.props.fetchCuisines().then(() => {
        this.setState({ loading: false });
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.updateShadow);
  }

  render() {
    const {
      //control
      loading,
      step,
      edit,
      offsetY,

      //data
      company,

      //styling
      contentOverflow,
      contentScrolled,
    } = this.state;

    LOGGING >= 1 &&
      console.log("PageSetUp rendering with:", {
        state: this.state,
        props: this.props,
        step,
        ref: this.contentRef.currentUser,
      });

    const stepHeader = (
      <SetupHeader
        step={step}
        edit={edit}
        showShadow={contentScrolled}
        onGoToStep={this.handleGoToStep}
        onClose={this.handleClose}
      />
    );
    const navbar = (
      <Navbar
        scrolled={offsetY > 0}
        {...this.props}
        midNav={stepHeader}
        setupDone={false}
      />
    );

    const form =
      edit === "eaters" ? (
        <FormEaters
          //data
          eaters={company.eaters}
          mainContact={company.mainContact}
          sharedDiet={company.sharedDiet}
          //navigation
          onNext={this.handleNext}
          onPrevious={this.handlePrevious}
          //dynamic styling
          contentOverflow={contentOverflow}
          updateShadow={this.updateShadow}
          contentRef={this.contentRef}
        />
      ) : edit === "diets" ? (
        <FormDiets
          //data
          mainContact={company.mainContact}
          sharedDiet={company.sharedDiet}
          eaters={company.eaters}
          companyDiet={company.diet}
          cuisineCatelog={this.props.cuisines}
          //navigation
          onNext={this.handleNext}
          onPrevious={this.handlePrevious}
          //dynamic styling
          contentOverflow={contentOverflow}
          updateShadow={this.updateShadow}
          contentRef={this.contentRef}
        />
      ) : edit === "favorites" ? (
        <FormFavorites
          //data
          favorites={company.favorites}
          //navigation
          onNext={this.handleNext}
          onPrevious={this.handlePrevious}
          //dynamic styling
          contentOverflow={contentOverflow}
          updateShadow={this.updateShadow}
          contentRef={this.contentRef}
        />
      ) : edit === "schedule" ? (
        <FormSchedule
          //data
          mainContact={company.mainContact}
          address={company.address}
          deliveryInstructions={company.deliveryInstructions}
          eaters={company.eaters}
          //navigation
          onNext={this.handleNext}
          onPrevious={this.handlePrevious}
          //dynamic styling
          contentOverflow={contentOverflow}
          updateShadow={this.updateShadow}
          contentRef={this.contentRef}
        />
      ) : null;

    return (
      <div className={`homepage setup ${step === "done" ? "done" : ""}`}>
        {navbar}
        {stepHeader}
        {loading ? <LoadingIcon /> : null}
        {form}
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  return {
    currentUser: reduxState.currentUser,
    cuisines: reduxState.cuisines,
  };
}

export default connect(mapStateToProps, {
  updateCompany,
  fetchCuisines,
  updateCard,
})(PageSetUp);
