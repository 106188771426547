import React from "react";
import "./Sticker.scss";
const Sticker = ({ icon, content, action, className }) => (
  <div
    className={`sticker${action ? " with-action" : ""} ${
      className ? className : ""
    }`}
  >
    <div className="sticker-icon">{icon}</div>
    <div className="sticker-content">{content}</div>
    <div className="sticker-action">{action}</div>
  </div>
);
export default Sticker;
