import React from "react";
import "./DeliveryComment.scss";
// import Textarea from "react-textarea-autosize";

const DeliveryComment = ({ delivery, onEdit, onSave, isEditting }) => (
  <div className="delivery-comment">
    {isEditting ? (
      <div className="overlay" onClick={onSave.bind(this, delivery)}></div>
    ) : null}
    <input
      value={delivery.comment}
      onChange={onEdit.bind(this, delivery)}
      placeholder="Add a comment..."
      spellCheck={false}
    />
  </div>
);

export default DeliveryComment;
