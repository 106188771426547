import React from "react";
import "./FormRestaurant.scss";
import { Week, TimeFormat } from "../../hocs/constants";
import { DishList } from "./";
import { FormWrapper, EntityField, Input, Select } from "./FormWidgets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUtensils } from "@fortawesome/free-solid-svg-icons";
import { faTrash, faPlusCircle } from "@fortawesome/pro-regular-svg-icons";

import Geosuggest from "react-geosuggest";
import NumberFormat from "react-number-format";
import TimeField from "react-simple-timefield";

export const OrderingMethodNames = {
  ORDER_PHONE_PAY_SITE: 0,
  ORDER_WEB_PAY_SITE: 1,
  ORDER_PAY_WEB: 2,
  ORDER_PAY_APP: 3,
  ORDER_PAY_SITE: 4,
  SPECIFY: 100,
};

export const OrderingLatencies = {
  0: "under 5 minutes",
  1: "10 minutes",
  2: "15 minutes",
  3: "20 minutes",
  4: "30 minutes",
  5: "40 minutes",
  6: "50 minutes",
  7: "1 hour",
};
const OrderingMethods = {
  0: "order by phone, pay onsite",
  1: "order on web, pay onsite",
  2: "order and pay on web",
  3: "order and pay in app",
  4: "order and pay onsite",
  100: "specify",
};
const OpenHours = ({ dayOfWeek, onChange, onDelete, onAdd, value }) => {
  const openTime =
    value && value.open && value.close
      ? `${TimeFormat(value.open.hour)}:${TimeFormat(value.open.minute)}`
      : "";
  const closeTime =
    value && value.open && value.close
      ? `${TimeFormat(value.close.hour)}:${TimeFormat(value.close.minute)}`
      : "";
  return value && value.open && value.close ? (
    <div className="open-hours">
      <div className="day-of-week">{Week[dayOfWeek].slice(0, 3)}</div>
      <div className="time-segment">
        <button
          className="delete-time-segment"
          onClick={(e) => {
            e.preventDefault();
            onDelete(dayOfWeek);
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
        <TimeField
          value={openTime}
          onChange={onChange.bind(this, dayOfWeek)}
          name="open"
          colon=":"
          showSeconds={false}
        />
        <div className="between-open-and-close">-</div>
        <TimeField
          value={closeTime}
          onChange={onChange.bind(this, dayOfWeek)}
          colon=":"
          name="close"
          showSeconds={false}
        />
      </div>
    </div>
  ) : (
    <div className="open-hours">
      <div className="day-of-week">{Week[dayOfWeek].slice(0, 3)} </div>
      <div className="time-segment closed-day">
        <button
          className="add-time-segment"
          onClick={(e) => {
            e.preventDefault();
            onAdd(dayOfWeek);
          }}
        >
          <FontAwesomeIcon icon={faPlusCircle} />
          <span>add hours</span>
        </button>
      </div>
    </div>
  );
};

const Schedule = ({ values, onChange, onDelete, onAdd }) => (
  <EntityField
    fieldLabel={"Restaurant Schedule"}
    fieldContent={[1, 2, 3, 4, 5].map((i) => (
      <OpenHours
        dayOfWeek={i}
        key={i}
        value={values[Week[i]]}
        onChange={onChange}
        onDelete={onDelete}
        onAdd={onAdd}
      />
    ))}
  />
);

const Phone = ({ labelText, value, onChange }) => (
  <EntityField
    fieldLabel={labelText}
    fieldContent={
      <NumberFormat
        format="(###) ###-####"
        mask="#"
        type="tel"
        placeholder="Enter a phone number"
        onValueChange={onChange}
        value={value}
      />
    }
  />
);

export const Address = ({
  labelText,
  value,
  onChange,
  onSelect,
  placeholderText,
}) => (
  <EntityField
    fieldLabel={labelText}
    fieldContent={
      <Geosuggest
        name={`building-address-${Math.random()}`}
        placeholder={placeholderText}
        initialValue={value}
        onSuggestSelect={onSelect}
        onChange={onChange}
        placeDetailFields={["address_components", "adr_address"]}
      />
    }
  />
);

const FormRestaurant = ({
  restaurant,
  showOptions,
  showDishOptions,
  onChangeInput,
  onChangeAddress,
  onSelectAddress,
  onChangePhone,
  onChangeSelect,
  onToggleSelectOptions,
  onAddTime,
  onDeleteTime,
  onChangeTime,
  onDeleteDish,
  onToggleDish,
  onCancel,
  onSave,
}) => {
  const {
    name,
    address,
    dishes,
    phone,
    orderingLatency,
    orderingMethod,
    orderingMethodSpecify,
    appName,
    website,
    schedule,
  } = restaurant;

  const restaurantName = (
    <Input
      value={name}
      onChange={onChangeInput.bind(this, "name")}
      placeholderText="Type restaurant name."
      labelText="restaurant name"
      name="restaurant-name"
    />
  );

  const restaurantAddress = (
    <Address
      labelText="restaurant address"
      value={address}
      onChange={onChangeAddress}
      onSelect={onSelectAddress}
      placeholderText="Type restaurant address."
    />
  );

  const restaurantPhone = (
    <Phone
      labelText="restaurant phone"
      value={phone}
      onChange={onChangePhone}
    />
  );

  const restaurantOrderingMethod = (
    <Select
      labelText="ordering method"
      value={orderingMethod}
      valueToTag={OrderingMethods}
      onChange={onChangeSelect.bind(this, "orderingMethod")}
      name="orderingMethod"
      options={Object.keys(OrderingMethods).map((o) => Number(o))}
      onToggleShowOptions={onToggleSelectOptions.bind(this, "orderingMethod")}
      showOptions={showOptions.orderingMethod}
    />
  );

  const restaurantOrderingLatency = (
    <Select
      labelText="order to ready latency"
      value={orderingLatency}
      valueToTag={OrderingLatencies}
      onChange={onChangeSelect.bind(this, "orderingLatency")}
      name="orderingLatency"
      options={Object.keys(OrderingLatencies).map((o) => Number(o))}
      onToggleShowOptions={onToggleSelectOptions.bind(this, "orderingLatency")}
      showOptions={showOptions.orderingLatency}
    />
  );

  const restaurantWebAppName =
    orderingMethod === OrderingMethodNames.SPECIFY ? (
      <Input
        labelText="specify ordering method"
        value={orderingMethodSpecify}
        onChange={onChangeInput.bind(this, "orderingMethod")}
        placeholderText="Type special ordering and payment method."
        name="orderingMethodSpecify"
      />
    ) : orderingMethod === OrderingMethodNames.ORDER_PAY_APP ? (
      <Input
        labelText="App name"
        value={appName}
        onChange={onChangeInput.bind(this, "appName")}
        placeholderText="Type restaurant app name."
        name="appName"
      />
    ) : orderingMethod === OrderingMethodNames.ORDER_PAY_WEB ? (
      <Input
        labelText="Website"
        value={website}
        onChange={onChangeInput.bind(this, "website")}
        placeholderText="Type restaurant website."
        name="website"
      />
    ) : null;

  const restaurantSchedule = (
    <Schedule
      values={schedule}
      onAdd={onAddTime}
      onChange={onChangeTime}
      onDelete={onDeleteTime}
    />
  );

  const restaurantDishes = (
    <EntityField
      fieldLabel="dishes"
      fieldClass="list"
      fieldContent={
        <DishList
          entries={dishes}
          entity="dishes"
          parent={restaurant}
          parentRequired={true}
          icon={faUtensils}
          onDeleteEntry={onDeleteDish}
          onToggleEntry={onToggleDish}
          showOptions={showDishOptions}
        />
      }
    />
  );

  const formSectionLeft = (
    <React.Fragment>
      {restaurantName}
      {restaurantAddress}
      {restaurantPhone}
      {restaurantOrderingMethod}
      {restaurantOrderingLatency}
      {restaurantWebAppName}
      {restaurantSchedule}
    </React.Fragment>
  );

  const formSectionRight = restaurantDishes;

  return (
    <FormWrapper
      formName={restaurant.name || "new restaurant"}
      formSections={[formSectionLeft, formSectionRight]}
      onSave={onSave}
      onCancel={onCancel}
    />
  );
};

export default FormRestaurant;
