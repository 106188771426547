import React from "react";
import { ListOfUnits, SelectMealType, SelectedDayDetails, NoMeal } from "./";

const DriverDay = ({
  selectedMeal,
  numOfInstances,
  onToggleLunchDinner,
  onGoToSelectedMonth,
  showDetails,
  onToggleShowDetails,
  onOrder,
  onPickUp,
  onDeliver,
}) => {
  const { deliveriesForDay, ordersForDay, isLunch } = selectedMeal;
  const deliveriesForMeal = deliveriesForDay.filter(
    (d) => d.isLunch === isLunch
  );
  const ordersForMeal = ordersForDay.filter((o) => o.isLunch === isLunch);

  const mealType = (
    <SelectMealType
      isLunch={selectedMeal.isLunch}
      dateKey={selectedMeal.dateKey}
      numOfInstances={numOfInstances}
      onToggle={onToggleLunchDinner}
    />
  );

  let unitsForMeal = deliveriesForMeal.map((delivery) => ({
    ...delivery,
    unit: delivery.company,
    type: "delivery",
  }));

  unitsForMeal = unitsForMeal.concat(
    ordersForMeal.map((order) => ({
      ...order,
      unit: order.restaurant,
      type: "order",
    }))
  );
  console.log({ unitsForMeal });
  unitsForMeal = unitsForMeal.sort((x, y) =>
    x.etaByOps > y.etaByOps
      ? 1
      : x.etaByOps < y.etaByOps
      ? -1
      : x.type === "order" //if same eta then restaurant first
      ? -1
      : 1
  );

  const content =
    unitsForMeal.length === 0 ? (
      <NoMeal meal={selectedMeal.isLunch ? "lunch" : "dinner"} />
    ) : (
      <ListOfUnits
        // only the meal on the date, a time.
        selectedMeal={selectedMeal}
        role="driver"
        //need only deliveries for that meal, all users, cancelled or not
        unitsForMeal={unitsForMeal}
        showDetails={showDetails}
        onToggleShowDetails={onToggleShowDetails}
        onOrder={onOrder}
        onPickUp={onPickUp}
        onDeliver={onDeliver}
      />
    );

  return (
    <SelectedDayDetails
      selectedDay={selectedMeal}
      onGoToSelectedMonth={onGoToSelectedMonth}
      role="driver"
      content={
        <React.Fragment>
          {mealType}
          {content}
        </React.Fragment>
      }
    />
  );
};

export default DriverDay;
