import {
  SET_DATAMODE_SORT_BY,
  SET_DATAMODE_CATEGORY_BY,
  SET_DATAMODE_SELECTED,
  RESET_DATAMODE,
  SET_DATAMODE_LOADED,
} from "../actionTypes";
import { PURGE } from "redux-persist";
import {
  DefaultDataModeState,
  DataModeSortByDefault,
} from "../../screens/dashboard";

const dataMode = (state = DefaultDataModeState, action) => {
  switch (action.type) {
    case SET_DATAMODE_SELECTED:
      return {
        ...state,
        selected: action.selected,
        sortBy: DataModeSortByDefault[action.selected],
      };

    case SET_DATAMODE_SORT_BY:
      return {
        ...state,
        sortBy: { ...state.sortBy, [action.dataType]: action.sortByKey },
      };

    case SET_DATAMODE_CATEGORY_BY:
      return {
        ...state,
        categoryBy: {
          ...state.categoryBy,
          [action.dataType]: action.categoryByKey,
        },
      };

    case SET_DATAMODE_LOADED:
      return {
        ...state,
        loaded: { ...state.loaded, [action.dataType]: action.loadedStatus },
      };
    case PURGE:
      return {};
    case RESET_DATAMODE:
      return DefaultDataModeState;
    default:
      return state;
  }
};

export default dataMode;
