import React from "react";
import "./Tags.scss";
import { PopUp, Sticker, AddEntry } from "./";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/pro-regular-svg-icons";
const Tags = ({
  selected,
  showOptions,
  onToggleShowOptions,
  onToggleTag,
  options,
  name,
  icon,
}) => {
  const selectedList = selected
    .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
    .map((value, index) => (
      <Sticker
        key={index}
        icon={<FontAwesomeIcon icon={icon} />}
        content={value.name}
      />
    ));

  let optionList = options
    .sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1))
    .map((tag, index) => (
      <button
        type="button"
        key={index}
        className={`${
          selected.some((e) => e._id === tag._id) ? "selected" : "unselected"
        }`}
        onClick={onToggleTag.bind(this, tag)}
      >
        {tag.name}
      </button>
    ));

  optionList.push(
    <AddEntry path={`/${name}/new`} buttonText={`new ${name}`} key={-1} />
  );

  const editButton = (
    <button type="button" onClick={onToggleShowOptions} className="edit-tags">
      <FontAwesomeIcon icon={faPen} />
    </button>
  );

  return (
    <React.Fragment>
      {editButton}
      {selectedList}
      <PopUp
        isPoppedUp={showOptions}
        componentToDisplay={
          <div className="tags-list-wrapper">
            <div className="tags-list-title">
              {name} ({options.length})
            </div>
            <ul className="tags-list">{optionList}</ul>
            <div className="tags-list-footer">
              <button type="button" onClick={onToggleShowOptions}>
                OK
              </button>
            </div>
          </div>
        }
        hidePopUp={onToggleShowOptions}
      />
    </React.Fragment>
  );
};

export default Tags;
