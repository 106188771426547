import React from "react";
import "./ErrorMessage.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
const ErrorMessage = ({ message }) => (
  <div
    className={`error-message ${
      message && message.length > 0 ? "has-error" : "no-error"
    }`}
  >
    <FontAwesomeIcon icon={faExclamationCircle} />
    <span>{message}</span>
  </div>
);
export default ErrorMessage;
